'use strict';

/**
 * @description Checkout Step Indicator bar section, here we prepare variables and we are exporting object so it can be accessible
 * also we are rendering status in Step Indicator bar in case that payment, contact and shipping sections are valid
 */
var selected = 'is-selected';
var valid = 'is-valid';
var contactSelector = $('.step-contact-details');
var paymentSelector = $('.step-payment');
var deliverySelector = $('.step-delivery');
var shippingSelector = $('.js-shipping-methods-container');

var stepIndicator = {
	contact: {
		selected: function() {
			elementSelected(contactSelector);
		},
		valid: function(isValid) {
			elementValid(contactSelector, isValid);
		}
	},
	delivery: {
		selected: function() {
			elementSelected(deliverySelector);
		},
		valid: function(isValid) {
			elementValid(deliverySelector, isValid);
		}
	},
	payment: {
		selected: function() {
			elementSelected(paymentSelector);
		},
		valid: function(isValid) {
			elementValid(paymentSelector, isValid);
		}
	},
	validation: function() {
		var contactSection = 'js-contactdetails-fieldset';
		var deliverySection = 'js-shipping-form';
		var paymentSection = 'js-creditcard-fieldset';
		var billingSection = 'js-billing-form';

		//here we attaching event on focus, when its clicked on input field, and then we are updating state in Step Indicator bar
		$('body').on('focus', 'input', function() {
			var $target = $(this).parent().closest('fieldset');
			if ($target.hasClass(contactSection)) {
				stepIndicator.contact.selected();
			}
			else if ($target.hasClass(deliverySection)) {
				stepIndicator.delivery.selected();
			}
			else if (($target.hasClass(paymentSection))) {
				stepIndicator.payment.selected();
			}
			else if (($target.hasClass(billingSection))) {
				stepIndicator.payment.selected();
			}
		});

		shippingSelector.on('click', function() {
			stepIndicator.delivery.selected();
		});
	}
};

//here we are passing element which we want to select
function elementSelected(element) {
	element.addClass(selected);
	$('.js-step-indicator li').not(element).removeClass(selected);
}

//here we are passing element which we want to check as valid and also we are passing isValid param, which can be true or false
function elementValid(element, isValid) {
	if (isValid) {
		element.addClass(valid);
	}
	else {
		element.removeClass(valid);
	}
}

module.exports = stepIndicator;
