/**
 * @file Utility file to initialize the Yotpo code, in a callback
**/

"use strict";


exports.refreshWidgets = function () {
	try {
		// wrapper in a try/catch to avoid invoking the API.refresh Widgets if it's not loaded (network, not enabled, etc)
		if (typeof Yotpo !== 'undefined') {
			var api = new Yotpo.API(yotpo);
			api.refreshWidgets();
		}
	}
	catch (e) {
		console.log('Error initializing Yotpo. ' + e);
	}
};