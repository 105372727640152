'use strict';

var dialog = require('./dialog'),
	util = require('./util'),
	forms = require('./forms'),
	progress = require('./progress'),
	validator = require('./validator');

var contactus = {
	init: function() {
		var $btnPopup = $('.js-contact-us');
		var $orderNumber = $('.js-contact-us-order-number');

		validator.init();
		forms.init();
		initPopupEvents();

		// to show the contact us form as a modal
		$btnPopup.off('click').on('click', function(e) {
			e.preventDefault();

			dialog.open({
				url: util.ajaxUrl(Urls.contactUs),
				options: {
					dialogClass: 'dialog-large',
				},
				callback: function() {
					initPopupEvents();
					initChangeFormEvents();
				}
			});
		});

		//Force uppercase value for order ids
		$($orderNumber).off('change').on('change', function () {
			var $this = $(this);
			$this.val($this.val().toUpperCase());
		});

		initCCPA();
		initChangeFormEvents();
	}
};

function initChangeFormEvents() {
	function changeChangeUsForm(type) {
		var data = {
			formType: type
		};

		progress.show();

		var isDialog = $('[data-js="contactUsForm"]').parents().hasClass('dialog-wrap');

		$.ajax({
			type: 'POST',
			url: Urls.contactUs,
			data: data,
			success: function(response) {
				if (response) {
					$('[data-js="contactUsForm"]').replaceWith($(response).find('[data-js="contactUsForm"]'));
					if (isDialog) {
						initPopupEvents();
					}
					else {
						contactus.init();
					}
				}
				progress.hide();
			},
			failure: function() {
				progress.hide();
			}
		});
	}

	$('body').off('click.contactUsEmail', '[data-js="contactUsEmail"]').on('click.contactUsEmail', '[data-js="contactUsEmail"]', function() {
		if ($(this).hasClass('contact-us__btn--active')) {
			return;
		}
		changeChangeUsForm('email');
	});

	$('body').off('click.contactUsPhone', '[data-js="contactUsPhone"]').on('click.contactUsPhone', '[data-js="contactUsPhone"]', function() {
		if ($(this).hasClass('contact-us__btn--active')) {
			return;
		}
		changeChangeUsForm('phone');
	});
}


var initPopupEvents = function() {
	var $contactUsForm = $('.js-contactus-form');
	var $submit = $contactUsForm.find('button[name$="_contactus_send"]');
	$($submit).off('click').on('click', function(e) {
		e.preventDefault();

		if (!$contactUsForm.valid()) {
			return;
		}

		progress.show();

		var data = $contactUsForm.serialize();

		// add form action to data
		data += '&' + $submit.attr('name') + '=';

		// make sure the server knows this is an ajax request
		if (data.indexOf('ajax') === -1) {
			data += '&format=ajax';
		}

		var isDialog = $('[data-js="contactUsForm"]').parents().hasClass('dialog-wrap');
		data += '&isDialog=' + isDialog;

		$.ajax({
			type: 'POST',
			url: Urls.contactUsSubmit,
			data: data,
			success: function(response) {
				if (typeof response === 'object'
					&& !response.success
					&& response.error === 'CSRF Token Mismatch') {
					page.redirect(Urls.csrffailed);
				}
				else if (typeof response === 'string') {
					// popup form not successfull
					var $response = $(response);
					//attach event to the button in contactus.isml
					$response.find('#close-dialog-btn').on('click', function () {
						dialog.close();
					});
					$('[data-js="formWrapper"]').replaceWith($response.find('[data-js="formWrapper"]'));
					$('button[title="Close"]').trigger('focus');
					initPopupEvents();
					progress.hide();
				}
			},
			failure: function() {
				dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
				progress.hide();
			}
		});
	});

	validator.init();
	forms.init();
	initCCPA();
};

/**
 * @description Initialization of CCPA event
 */
var initCCPA = function() {
	var reasonCodeSelector = '#dwfrm_contactus_myquestion';
	var ccpaContent = $('.ccpa-content');
	$('body').off('change', reasonCodeSelector).on('change', reasonCodeSelector, function() {
		var $this = $(this);
		var reasonCode = $this.val();
		var isDialog = $('.dialog-wrap').find('.js-contactus-form').length > 0;
		if (reasonCode === Resources.CCPA_LABEL) {
			hideNonCCPAInputs(true, isDialog);
			ccpaContent.removeClass('u-hidden');
		}
		else {
			hideNonCCPAInputs(false, isDialog);
			ccpaContent.addClass('u-hidden');
		}
	});
};

/**
 * @description Method for hide/show contact us inputs for CCPA reason code
 * @param {Boolean} hide
 */
function hideNonCCPAInputs(hide, isDialog) {
	var $contactUsForm = isDialog ? $('.dialog-wrap').find('.js-contactus-form') : $('.js-contactus-form');
	var inputs = $contactUsForm.find('input, textarea');
	var requiredInputFields = ['dwfrm_contactus_firstname', 'dwfrm_contactus_lastname', 'dwfrm_contactus_email', 'dwfrm_contactus_phone'];
	var dialogSelector = $('.dialog-wrap');

	inputs.each(function(index, element) {
		if (!requiredInputFields.includes(element.id)) {
			var inputsParentContainer = isDialog ? dialogSelector.find('#' + element.id).closest('.input-container')
				: $('#' + element.id).closest('.input-container');
			var captchaContainer = isDialog ? dialogSelector.find('.js-captcha-wrapper') : $('.js-captcha-wrapper');
			var submitButton = isDialog ? dialogSelector.find('.js-validation-button') : $('.js-validation-button');
			if (hide) {
				inputsParentContainer.addClass('u-hidden');
				captchaContainer.addClass('u-hidden');
				submitButton.parent().addClass('u-hidden');
			}
			else {
				inputsParentContainer.removeClass('u-hidden');
				captchaContainer.removeClass('u-hidden');
				submitButton.parent().removeClass('u-hidden');
			}
		}
	});
}

module.exports = contactus;
