'use strict';

var util = require('./util'),
	accessibility = require('./accessibility'),
	minicart = require('./minicart');

/**
 * @function
 * @description : Main Search for desktop and Mobile
 */

module.exports = (function () {
	var events = {
		init: function () {
			// Mobile: Enable the search if the user click on the Search Icon
			$('[data-js="icon-search-mob"]').off('click').on('click', function() {
				// Close the search
				if ($(this).hasClass('is-open')) {
					$('body').removeClass('search-is-open');
					$('.js-header-search .js-header-search-form').removeClass('active');
					$(this).removeClass('is-open');
				}
				// Open the search
				else {
					$(this).addClass('is-open');
					$('body').addClass('search-is-open');
					$('.js-header-search .js-search-field').trigger('focus');
					$('.js-header-search .js-header-search-form').addClass('active');
				}
			});

			// Desktop & Mobile : Clean the input in case the user click on the clear icon
			$('[data-js="header-search-reset"]').off('click').on('click', function (e) {
				e.stopPropagation();
				e.preventDefault();

				const $searchForm = $('.js-header-search-form');
				const $searchInput = $('.js-search-field');

				// simulate click on search input if search input is empty and not expanded
				if (!$searchForm.hasClass('open')
					&& (!$searchInput.val() || $searchInput.val() === $searchInput.attr('placeholder'))) {
					$searchInput.trigger('click');
					return;
				}

				$searchInput
					.val('')
					.trigger('keyup');
				$searchForm.removeClass('with-phrase');
				util.closeSearch();
			});

			$('.js-search-field').off('blur.mainSearch').on('blur.mainSearch', function() {
				var $this = $(this);
				if ($this.val() !== $this.attr('placeholder')) {
					$('.js-header-search-form').addClass('with-phrase');
				}
			});

			$('.js-header-search-form').off().on('submit', function(e) {
				var searchInputField = $(this).children('.js-search-field');

				if (searchInputField.val().trim() === '') {
					searchInputField.val('');
					e.preventDefault();
				}
			});

			$('[data-js="search-icon"]').off().on('click', function(e) {
				e.preventDefault();
				e.stopPropagation();

				var searchForm = $(this).closest('form');
				var searchInputField = searchForm.children('.js-search-field');

				if ($('body').is('.search-is-open')) {
					if ((searchInputField.val().trim() !== '') && (searchInputField.val() !== searchInputField.attr('placeholder'))) {
						searchForm.submit();
					}
				}
				else {
					searchInputField.trigger('click');
				}
			});
		},
		resize: function () {
			$('.js-header-search .js-search-field').off('click').on('click', function(_, isShowInstantly = false) {
				if ($('body').is('.search-is-open')) {
					return;
				}

				const TRANSITION_DURATION = 200;

				// TODO: handle click outside of mini-card and closing mini-cart in minicart.js
				const miniCartIsOpen = $('.js-c-mini-cart').hasClass('opened');
				if (miniCartIsOpen) {
					minicart.close();
				}

				var $this = $(this);
				$this.focus();

				$('.js-overlay').fadeIn();
				$('body').addClass('search-is-open');

				const $searchForm = $this.closest('.js-header-search-form');
				$searchForm
					.addClass('open')
					.css({
						position: 'absolute',
						top: '0',
						left: '0',
						transform: `translateX(${$searchForm.position().left}px)`,
						width: $searchForm.width() + 'px',
						zIndex: 1
					});

				setTimeout(() => {
					if (!isShowInstantly) {
						$searchForm.addClass('u-transition');
					}
					$searchForm.addClass('is-search-expanded');
				});

				setTimeout(() => {
					$('[data-js="nav-container"]').addClass('is-search-expanded');
				}, isShowInstantly ? 0 : TRANSITION_DURATION);

				const $searchWrap = $('[data-js="search-wrap"]');
				$searchWrap.stop();
				setTimeout(() => {
					$searchWrap
						.css({
							'max-height': util.getNavDropdownMaxHeight()
						})
						.slideDown();
				}, isShowInstantly ? 0 : TRANSITION_DURATION + 50);

				accessibility.hideSearchSuggestionOnEsc($this);
			});

			$('[data-js="user-search-mobile"] .js-search-field').off('click').on('click', function () {
				$('.js-header-search .js-search-field').trigger('click', [/*isShowInstantly=*/true]);
			});
		},

		populateSearch: function() {
			var searchPhrase = $('.searchphraselink');
			var searchField = $('.js-search-field');
			var searchForm = $('.js-header-search-form');
			var mobileSearchWrapper = $('[data-js="user-search-mobile"]');
			var activeClass = 'with-phrase';
			if (searchPhrase.length) {
				searchField.val(searchPhrase.attr('title'));
				if (searchField.val()) {
					mobileSearchWrapper.addClass(activeClass);
					searchForm.addClass(activeClass);
				}
			}
		}

	};

	function init() {
		events.init();
		events.resize();
		events.populateSearch();
	}

	function resize () {
		events.resize();
	}

	return {
		init: init,
		resize: resize
	};
})();
