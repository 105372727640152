'use strict';

var _ = require('lodash'),
	dialog = require('./dialog'),
	product = require('./pages/product'),
	util = require('./util'),
	swiperCarousel = require('./swiper'),
	video = require('./video'),
	keyboard = require('./constants').keyboard,
	progress = require('./progress');

var accessibility = (util.isMobile()) ? require('./accessibilityForMobile') : require('./accessibility');

var makeUrl = function(url, source, productListID) {
	if (source) {
		url = util.appendParamToURL(url, 'source', source);
	}
	if (productListID) {
		url = util.appendParamToURL(url, 'productlistid', productListID);
	}
	url = util.removeHashFromURL(url);
	return url;
};

var removeParam = function(url) {
	if (url.indexOf('?') !== -1) {
		return url.substring(0, url.indexOf('?'));
	}

	return url;
};

/**
 * Appends the quickviewExpanded/editProduct param to page URL to indicate it was expanded, also stores the variation URL in it's value
 * @param {String} paramValue - URL of the variation that will be shown in the expanded quickview
 */
var appendQuickviewParamToCurrentURL = function(paramKey, paramValue) {
	var currentURL = window.location.href;
	var updatedPageURL = util.appendParamToURL(currentURL, paramKey, paramValue);
	util.updateBrowserURLByReplacingTheCurrentState(updatedPageURL);
};

/**
 * Removes the quickviewExpanded/editProduct param from page URL when the quickview is closed
 */
var removeQuickviewParamFromCurrentURL = function(paramKey) {
	var currentURL = window.location.href;
	var updatedPageURL = util.removeParamFromURL(currentURL, paramKey);
	util.updateBrowserURLByReplacingTheCurrentState(updatedPageURL);
};

var quickview = {
	init: function() {
		accessibility.attachTooltipKeyboardEvents();
		if (!this.exists()) {
			this.$container = $('<div/>').attr('id', 'QuickViewDialog').appendTo(document.body);
		}
		this.productLinks = $('#search-result-items .thumb-link').map(function(index, thumbLink) {
			return $(thumbLink).attr('href');
		});
	},

	setup: function(qvUrl) {
		var $btnNext = $('.quickview-next'),
			$btnPrev = $('.quickview-prev');

		product.initializeEvents(true);

		this.productLinkIndex = _(this.productLinks).findIndex(function(url) {
			return removeParam(url) === removeParam(qvUrl);
		});

		// hide the buttons on the compare page or when there are no other products
		if (this.productLinks.length <= 1 || $('.compareremovecell').length > 0) {
			$btnNext.hide();
			$btnPrev.hide();
		}

		if (this.productLinkIndex === this.productLinks.length - 1) {
			$btnNext.attr('disabled', 'disabled');
		}
		if (this.productLinkIndex === 0) {
			$btnPrev.attr('disabled', 'disabled');
		}

		$btnNext.on('click', function(e) {
			e.preventDefault();
			this.navigateQuickview(1);
		}.bind(this));

		$btnPrev.on('click', function(e) {
			e.preventDefault();
			this.navigateQuickview(-1);
		}.bind(this));

		swiperCarousel.initializePdpSwiper(true);
		swiperCarousel.initializeRightPdpSwiper(true);

		video.init();

		// Keyboard accessibility - PLP - Quick view popup main slider
		$('.dialog-is-open .ui-dialog.quickview-dialog').each(function() {
			$('.ui-dialog.quickview-dialog .ui-dialog-titlebar-close').trigger('focus');
			$('.ui-dialog.quickview-dialog .product-main-slider .swiper-wrapper').removeAttr('tabIndex');
		});
	},

	/**
	 * @param {Number} step - How many products away from current product to navigate to. Negative number means navigate backward
	 */
	navigateQuickview: function(step) {
		// default step to 0
		this.productLinkIndex += (step ? step : 0);
		var url = makeUrl(this.productLinks[this.productLinkIndex], 'quickview');
		dialog.replace({
			url: url,
			callback: this.setup.bind(this, url)
		});
	},

	/**
	 * @description show quick view dialog
	 * @param {Object} options
	 * @param {String} options.url - url of the product details
	 * @param {String} options.source - source of the dialog to be appended to URL
	 * @param {String} options.productlistid - to be appended to URL
	 * @param {Function} options.callback - callback once the dialog is opened
	 * @param {Object} options.origin - element that triggers the quickview - to reuse the reviews
	 * @param {Boolean} options.loader - indicates if there was a loader initialized which needs to be closed after quickview opens
	 */
	show: function(options) {
		var url;
		if (!this.exists()) {
			this.init();
		}

		url = makeUrl(options.url, options.source, options.productlistid);
		var dialogClass = 'quickview-dialog';
		var paramKey = 'quickviewExpanded';

		var plpWrapper = $('[data-js="plpWrapper"]');
		if (plpWrapper.length) {
			//check if we have campaign specific quickview
			var campaignValue = plpWrapper.data('campaign');
			dialogClass = dialogClass + ' ' + campaignValue + '-' + dialogClass;
		}

		if (options.source === 'editproduct') {
			dialogClass += ' edit-product';
			paramKey = 'editProduct';
		}

		dialog.open({
			target: this.$container,
			url: url,
			options: {
				dialogClass: dialogClass,
				closeOnEscape: false,
				open: function() {
					if (options.source === 'editproduct') {
						this.$container.parent().attr('data-js', 'edit-product');
					}
					$('html').addClass('dialog-is-open');
					$('body').addClass('quickview-is-open');
					dialog.bindOverlayClose(this.$container);
					this.setup(url);
					if (typeof options.callback === 'function') {
						options.callback();
					}
					$(document).trigger('showQuickview');

					appendQuickviewParamToCurrentURL(paramKey, options.url);
					if (options.loader) {
						progress.hide();
					}
					var dialogContainer = this.$container;
					$('body').on('keydown', function(ev) {
						if (ev.keyCode === keyboard.ESC_KEY && !$('body').hasClass('js-flyout-dialog') && $('body').hasClass('quickview-is-open')) {
							dialogContainer.dialog('close');
						}
					});
				}.bind(this),
				close: function () {
					$('body').removeClass('quickview-is-open');
					$('html').removeClass('dialog-is-open');
					$('#QuickViewDialog').html('');
					swiperCarousel.initializePdpSwiper();

					removeQuickviewParamFromCurrentURL(paramKey);
				}
			}
		});
	},
	exists: function() {
		return this.$container && (this.$container.length > 0);
	}
};

module.exports = quickview;
