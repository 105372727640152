'use strict';

var currentRequest;
var ajax = require('../../ajax');

var getStoreAvailability = function() {
	cancelRequest();
	var availabilityContainer = $('[data-js="storeavailability"]');
	if (availabilityContainer.length > 0) {
		currentRequest = ajax.load({
			url: availabilityContainer.data('url'),
			target: availabilityContainer
		});
		currentRequest.done(function () {
			var loaderContainer = $('[data-js="availabilityloader"]');
			if (loaderContainer.length > 0) {
				loaderContainer.remove();
			}
			currentRequest = null;
		});
	}
};

var cancelRequest = function() {
	if (currentRequest) {
		currentRequest.abort();
	}
};

var storeavailability = {
	getStoreAvailability: getStoreAvailability,
	cancelRequest: cancelRequest
};

module.exports = storeavailability;
