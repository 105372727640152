'use strict';

var keyboard = require('./constants').keyboard;

var originalAccessibilityFunctions = require('./accessibility');
var originalAccessibilityActionNames = Object.keys(originalAccessibilityFunctions);

/**
 * @function
 * @description copy the actions from the generalized accessibility file
 */

originalAccessibilityActionNames.forEach(function (actionName) {
	exports[actionName] = originalAccessibilityFunctions[actionName];
});

exports.init = function() {
	attachCheckoutBagEvents();
	// TODO: investigate which functions from accessibility.js need to be initialized for mobile as well
	originalAccessibilityFunctions.init();
};

function attachCheckoutBagEvents() {
	$('body').on('keydown', '[data-js="checkout-bag-toggler"]', function(e) {
		if (e.which === keyboard.ENTER_KEY || e.which === keyboard.SPACE_KEY) {
			e.preventDefault();
			$(this).trigger('click');
		}

		if (e.which === keyboard.TAB_KEY && !e.shiftKey) {
			var isBagExpanded = $('[data-js="checkout-bag-block-inner"]').hasClass('open');
			if (!isBagExpanded) {
				e.preventDefault();
				var $emailInput = $('#dwfrm_checkout_billing_email_emailAddress');
				originalAccessibilityFunctions.simulateTabKeyFocus($emailInput);
			}
		}
	});

	$('body').on('keydown', '#dwfrm_checkout_billing_email_emailAddress', function(e) {
		if (e.which === keyboard.TAB_KEY && e.shiftKey) {
			e.preventDefault();
			var isBagExpanded = $('[data-js="checkout-bag-block-inner"]').hasClass('open');
			if (!isBagExpanded) {
				var $bagToggleLink = $('[data-js="checkout-bag-toggler"]');
				originalAccessibilityFunctions.simulateTabKeyFocus($bagToggleLink);
			}
			else {
				var $lastProductInBag = $('.product-name:last-of-type');
				originalAccessibilityFunctions.simulateTabKeyFocus($lastProductInBag);
			}
		}
	});

	$('body').on('keydown', '.product-name:last-of-type', function(e) {
		if (e.which === keyboard.TAB_KEY && !e.shiftKey) {
			e.preventDefault();
			var $emailInput = $('#dwfrm_checkout_billing_email_emailAddress');
			originalAccessibilityFunctions.simulateTabKeyFocus($emailInput);
		}
	});
}