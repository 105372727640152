'use strict';

var _ = require('lodash');
var util = require('./util');
var keyboard = require('./constants').keyboard;

var isKeyboardNavigationActive = false;

/**
 * @function
 * @description Initializes the accessibility events
 */
exports.init = function() {
	attachMenuAccessibilityEvents();
	attachSkipLinkEvents();
	attachProductPriceTooltipEvent();
	attachProductAllImagesDisplaEvent();
	attachPLPEvents();
	attachLoggedInUserEvents();
	attachMinicartEvents();
	attachPaymentEvents();
	attachShippingMethodEvents();
	attachAriaExpandedEvents();
	attachAccountEvents();
	attachDialogEvents();
	attachStoreAvailabilityEvents();
	attachPickupPointEvents();
	attachPromoCodeEvents();
	attachHamburgerMenuEvents();
	attachProductImageEvent();
	attachNewsletterCheckboxEvent();
	attachCheckboxEventPageDesigner();
	attachRedesignEvents();
};

exports.setKeyboardNavigation = setKeyboardNavigation;
exports.attachPLPEvents = attachPLPEvents;
exports.setFocusOnFirstProduct = setFocusOnFirstProduct;
exports.setFocusOnAddToCart = setFocusOnAddToCart;
exports.setFocusOnPlaceOrder = setFocusOnPlaceOrder;
exports.setFocusOnReviews = setFocusOnReviews;
exports.setFocusOnShoeCare = setFocusOnShoeCare;
exports.attachDialogEvents = attachDialogEvents;
exports.focusToFirstSize = focusToFirstSize;
exports.focusTrap = focusTrap;
exports.attachGiftWrappingEvents = attachGiftWrappingEvents;
exports.setFocusOnSelectedSwatch = setFocusOnSelectedSwatch;
exports.simulateTabKeyFocus = simulateTabKeyFocus;
exports.attachStoreAvailabilityEvents = attachStoreAvailabilityEvents;
exports.setFocusOnFirstPickupPoint = setFocusOnFirstPickupPoint;
exports.attachPickupPointEvents = attachPickupPointEvents;
exports.attachTooltipKeyboardEvents = attachTooltipKeyboardEvents;
exports.attachCheckboxEventPageDesigner = attachCheckboxEventPageDesigner;
exports.attachGiftCardDialogEvents = attachGiftCardDialogEvents;
exports.attachAfterPayDialogEvents = attachAfterPayDialogEvents;
exports.attachZipPayDialogEvents = attachZipPayDialogEvents;
exports.isKeyBoardNavigationActive = isKeyBoardNavigationActive;
exports.updateFilterFlyoutTabindexes = updateFilterFlyoutTabindexes;
exports.updateDesktopFlyoutAccessibility = updateDesktopFlyoutAccessibility;
exports.updateMobileFlyoutAccessibility = updateMobileFlyoutAccessibility;
exports.updateFilterContentTabindexes = updateFilterContentTabindexes;
exports.updateFilterContentAccessibility = updateFilterContentAccessibility;
exports.hideSearchSuggestionOnEsc = hideSearchSuggestionOnEsc;

function setKeyboardNavigation(enabled) {
	isKeyboardNavigationActive = !!enabled;
}

function isKeyBoardNavigationActive() {
	return isKeyboardNavigationActive;
}

/**
 * @description Attaches events to the menu elements
 */
function attachMenuAccessibilityEvents() {
	if (!util.isMobile()) {
		$('.js-level-2-item').removeAttr('aria-expanded');
	}

	// show/hide menu dropdown on esc/enter key
	$('.menu-navigation > .menu-links > ul > li.has-children').on('keydown', function(e) {
		var $this = $(this);
		var $expandableElement = $(e.target).siblings('.js-arrow-accessibility');

		if ($expandableElement.length === 0) {
			$expandableElement = $(e.target);
		}

		switch (e.which) {
			case keyboard.ESC_KEY:
				// hide menu dropdown
				$this.removeClass('is-open').find('.js-nav-dropdown').stop().slideUp(300);
				$this.children('.js-arrow-accessibility').attr('aria-expanded', 'false');
				$expandableElement.attr('aria-expanded', 'false');
				simulateTabKeyFocus($expandableElement);
				break;
			case keyboard.ENTER_KEY:
				// prevent link redirect if it is arrow for drop down
				if ($(e.target).hasClass('js-arrow-accessibility')) {
					e.preventDefault();

					// show menu dropdown
					$this.addClass('is-open').find('.js-nav-dropdown').stop().slideDown(400);
					$expandableElement.attr('aria-expanded', 'true');
					return;
				}

				// toggle aria expanded
				if ($expandableElement.attr('aria-expanded') == 'true') {
					$expandableElement.attr('aria-expanded', 'false');
				}
				else {
					$expandableElement.attr('aria-expanded', 'true');
				}
				break;
			default:
				break;
		}
	});

	$(document).on('keydown', function(e) {
		// here we are listening keydown event on document and if it is Esc button then we close all opened sub-menus
		// so if a sub-menu is opened by hovering a main category name then hitting Esc button will be able to close it back
		if (e.keyCode === keyboard.ESC_KEY) {
			$('.menu-navigation > .menu-links > ul > li.has-children.is-open').each(function() {
				var currentItem = $(this);
				currentItem.removeClass('is-open').find('.js-nav-dropdown').stop().slideUp(300);
				currentItem.children('.js-arrow-accessibility').attr('aria-expanded', 'false');
			});
		}
	});

	// hide menu dropdown when returning back to main menu items
	$('.menu-navigation > .menu-links > ul > li.has-children a:not(.js-arrow-accessibility)').on('focusout',
		_.debounce(function() {
			var nextElement = $(document.activeElement),
				currentElementContainer = $(this).closest('.is-open > .js-nav-dropdown'),
				visibleContainer = $('.menu-navigation > .menu-links > ul .is-open');

			// collapse menu if top level menu is changed or if current menu does not contain element
			if ((currentElementContainer.length === 0 && visibleContainer.length > 0)
				|| (currentElementContainer.length > 0 && currentElementContainer.find(nextElement).length < 1)
			) {
				var currentOpenElement = $('.menu-navigation > .menu-links > ul > li.has-children.is-open');
				currentOpenElement.removeClass('is-open').find('.js-nav-dropdown:not(.level-3)').stop().slideUp(300);
				currentOpenElement.find('.js-arrow-accessibility').attr('aria-expanded', 'false');
			}
		}, 200));

	// Fix for NVDA screen readers to expand sub categories
	$('.js-arrow-accessibility').on('click', function() {
		$(this).siblings('.js-header-menu-item').trigger('mouseenter');
	});
}

function hideSearchSuggestionOnEsc(element) {
	$(document).on('keydown', function(e) {
		if (e.keyCode === keyboard.ESC_KEY && $('body').hasClass('search-is-open')) {
			util.closeSearch();
			element.trigger('blur');
		}
	});
}

/**
 * @description Attaches the Skip Link to the Dom
 */

function attachSkipLinkEvents() {
	// this is needed so it would prevent default behaviour, we don't want to trigger popstate change (search.js -> $(window).on('popstate'...)
	$('a.js-skip-link').on('click', function(e) {
		e.preventDefault();
		handleSkipLinkEvent(this);
	});
}

/**
 * @description Adds a Skip link animation with Tab Key
 */

function handleSkipLinkEvent(DOMObject) {
	var $target = $($(DOMObject).attr('href'));
	if ($target.length > 0) {
		$('html,body').animate({ scrollTop: $target.offset().top - ($('header.header').height() + 20) }, 'slow');
		simulateTabKeyFocus($target.find(':visible:focusable:first'));
	}
}

/**
 * @description Initialize event listener for skip tile events
 */
function attachSkipTileEvents() {
	$('body').off('keydown', '[data-js="skip-tile"]').on('keydown', '[data-js="skip-tile"]', function(e) {
		// Switches focus from product tile to inside product tile when tab is pressed on skip link
		if (e.which === keyboard.TAB_KEY && e.target === this) {
			_toggleTileFocus($(this).parent());
		}
		// if enter is pressed then a function for moving focus to next tile is called
		else if (e.which === keyboard.ENTER_KEY && e.target === this) {
			handleSkipTileEvent(this);
		}
	});
}

/**
 * @description Setting focus to the next product tile or on Show more button if current tile is the last one
 */
function handleSkipTileEvent(DOMObject) {
	var $target = $($(DOMObject).data('href'));
	if ($target.length > 0) {
		$('html,body').animate({ scrollTop: $target.offset().top - ($('header.header').height() + 20) }, 'slow');
		if ($target.attr('id') === 'showMoreProducts') {
			simulateTabKeyFocus($target);
		}
		else {
			simulateTabKeyFocus($target.parent());
		}
	}
}

/**
 * @description Adds a skip link to the currently focused product tile
 */
function prependSkipTileLink(el) {
	if ($(el).find('[data-js="skip-tile"]').length !== 1) {
		var nextTileId = $(el).nextAll('li').not('.plp-grid-banner, .plp-product-highlight').find('.js-product-tile')
			.attr('id');
		if (nextTileId) {
			$(el).prepend('<a tabindex="3" href="javascript:;" data-href="#' + nextTileId + '" class="skip-link" data-js="skip-tile" aria-label="' + Resources.SKIP_TO_NEXT_PRODUCT + '" aria-hidden="true">' + Resources.SKIP_TO_NEXT_PRODUCT + '</a>');
		}
		else {
			var $showMoreBtn = $('#showMoreProducts');
			if ($showMoreBtn.length) {
				$(el).prepend('<a tabindex="3" href="javascript:;" data-href="#showMoreProducts" class="skip-link" data-js="skip-tile" aria-label="' + Resources.SKIP_TO_NEXT_PRODUCT + '" aria-hidden="true">' + Resources.SKIP_TO_NEXT_PRODUCT + '</a>');
			}
		}
	}
	attachSkipTileEvents();
}

/**
 * @function
 * @description Keeps the focus in a container by listing all the accessible elements and when the last and first ones are encountered, focus is redirected within the container
 * @param {String} $element, dialog container DOM element
 * @param {Boolean} areElementsPrepared true if we are sending array of elements for focus trap, false if we are sending container in which we are trapping focus
 */
function focusTrap($element, areElementsPrepared) {
	var inputs = $element;

	if (!areElementsPrepared) {
		inputs = $element.find('a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"], [role="button"]');
	}

	var firstInput = inputs.first();
	var lastInput = inputs.last();

	/* set focus on first input */
	firstInput.trigger('focus');

	/* redirect last tab to first input */
	lastInput.on('keydown', function(e) {
		if (e.which === keyboard.TAB_KEY && !e.shiftKey) {
			e.preventDefault();
			firstInput.trigger('focus');
		}
	});

	/* redirect first shift+tab to last input */
	firstInput.on('keydown', function(e) {
		if (e.which === keyboard.TAB_KEY && e.shiftKey) {
			e.preventDefault();
			lastInput.trigger('focus');
		}
	});
}

/**
 * @description Attaches picing policy tooltip events
 */
function attachProductPriceTooltipEvent() {
	// Show/hide pricing policy on click
	$('body').on('click', function(e) {
		var isTooltipElement = $(e.target).offsetParent().hasClass('js-pricing-policy-tooltip');
		var element = $('.js-pricing-policy-tooltip');
		var tooltip = $('.tooltip-content');

		if (isTooltipElement) {
			tooltip.toggleClass('tooltip-is-active');
			$('body').toggleClass('tooltip-open');
			focusTrap($(tooltip));
		}
		else {
			if (tooltip.hasClass('tooltip-is-active')) {
				tooltip.removeClass('tooltip-is-active');
				$('body').removeClass('tooltip-open');
				element.removeClass('focus-visible').removeAttr('data-focus-visible-added');
				if (isKeyboardNavigationActive) {
					simulateTabKeyFocus(element);
					isKeyboardNavigationActive = false;
				}
			}
		}
	});
}

function attachTooltipKeyboardEvents() {
	// Simulate click on enter, disable left/right slider navigation
	$('#pdpMain').on('keydown', '.js-pricing-policy-tooltip', function(e) {
		if (e.which === keyboard.ENTER_KEY) {
			$(e.target).children().trigger('click');
		}
	});

	// Simulate click on esc inside of tooltip
	$('#pdpMain').on('keydown', '.pricing-policy-tooltip, .js-pricing-policy-tooltip', function(e) {
		if (e.which === keyboard.ESC_KEY) {
			if ($('.tooltip-content').hasClass('tooltip-is-active')) {
				e.stopPropagation();
				var isKeyboardNav = $(this).hasClass('focus-visible');
				setKeyboardNavigation(isKeyboardNav);
				$(this).trigger('click');
			}
		}
	});
}

/**
 * @description Attaches keydown event to see all images button on the pdp
 */
function attachProductAllImagesDisplaEvent() {
	// If enter is pressed show images and hide the button
	$('.pt_product-detail .js-see-all-images, .pt_product-detail .js-mob-counter').on('keydown', function(e) {
		if (e.keyCode === keyboard.ENTER_KEY) {
			e.preventDefault();
			$('.js-product-craftsmanship-block img').show();
			$('.js-see-all-images').addClass('hide');
			$('.js-mob-counter').hide();
		}
	});
}

/**
 * @description Attaches events to the PLP elements
 */
function attachPLPEvents() {
	initializePLPTabindexes();
	attachFilterEvents();

	//Do not attach events in case of Content Search Results page
	var isContentSearchResults = $('.js-search-result-articles').data('search-phrase');
	if (isContentSearchResults) {
		return;
	}

	$('.js-search-result-articles > li').on('keydown', function(e) {
		var $productTile = $(this);

		if (e.which === keyboard.TAB_KEY && e.target === this) {
			$productTile.toggleClass('tile-focused');
			$productTile.find('[data-js="skip-tile"]').attr('aria-hidden', 'false');
		}
	});

	// prevent opening detail on enter key when tile is in focus
	$('body').on('click', '.js-search-result-articles li.focus-visible a', function(e) {
		e.preventDefault();

		var $productTile = $(this).closest('li');
		_toggleTileFocus($productTile);
	});

	// unselect all tiles except this one
	$('.js-search-result-articles > li').on('focusin', function() {
		$('.js-search-result-articles > li').not(this).removeClass('force-hover');
		$('.js-search-result-articles > li').not(this).find('[tabindex]').each(function(index, value) {
			$(value).attr('tabindex', -1);
		});
		$('.js-search-result-articles > li').not(this).removeClass('tile-focused');
		$('.js-search-result-articles > li').not(this).find('[data-js="skip-tile"]').remove();
		prependSkipTileLink(this);
	});

	$('body').on('focusin', '.js-search-result-articles li.force-hover a', function(e) {
		if ($(this).hasClass('js-plp-swatch')) {
			$(this).closest('li').addClass('focused-wrapper');
		}
	});

	$('body').on('focusout', '.js-search-result-articles li.force-hover a', function(e) {
		if ($(this).hasClass('js-plp-swatch')) {
			$(this).closest('li').removeClass('focused-wrapper');
		}
	});

	$('.js-search-result-articles > li').on('hover', function() {
		$('.js-search-result-articles > li').not(this).removeClass('tile-focused');
	});

	// PLP product tile color swatch keydown to catch tabbing and backward tabbing
	$('.swatch-list a.swatch').on('keydown', function(e) {
		if (e.which === keyboard.TAB_KEY) {
			// if tabbing back direction then just do what the default behavior is
			if (e.shiftKey) {
				return;
			}

			// get current color swatch element and its color value
			var swatch = $(this);
			var color = swatch.children('img').filter(':first').data('color');

			// then get the sizes element of that current color swatch
			var sizes = swatch.closest('.js-product-color').find('.js-product-sizes-' + color);

			if (sizes.length) {
				// find the first selectable size and set focus to it
				var firstSelectableSize = sizes.find('.js-selectable:first');

				if (firstSelectableSize.length) {
					e.preventDefault();
					e.stopImmediatePropagation();
					simulateTabKeyFocus(firstSelectableSize);
					return;
				}
			}
		}
	});

	// When we go back from product name on tile we return to product tile
	$('.pt_product-search-result .name-link').on('keydown', function(e) {
		var productTileElement = $(this).closest('.pt_product-search-result .js-search-result-articles > li');
		if (e.which === keyboard.TAB_KEY && e.shiftKey) {
			e.preventDefault();
			e.stopImmediatePropagation();
			simulateTabKeyFocus(productTileElement);
			_toggleTileFocus(productTileElement);
		}
	}).on('focusout', function() {
		$(this).removeClass('focus-visible');
	});
}

/**
 *  @description Initalize plp tab indexes of interactive elements so filter button comes before sort by
 */
function initializePLPTabindexes() {
	var page = $('.pt_product-search-result');
	page.find('.header').find('a, input, button, [role="button"]').not('[data-swiper="true"]').attr('tabindex', 1);
	page.find('footer').find('a, input, button, [role="button"]').attr('tabindex', 3);
	page.find('.breadcrumb-element').attr('tabindex', 1);
	page.find('.page-next, .plp-product-highlight .btn').attr('tabindex', 3);
}

/**
 * @description Toggles the focus from tile to inside of that tile
* @param {Object} $tile
 */
function _toggleTileFocus($tile, tabindex = '3') {
	$tile.toggleClass('force-hover');

	$tile.find('[tabindex]').not(exclude()).each(function(index, value) {
		if ($tile.hasClass('force-hover')) {
			$tile.attr('aria-expanded', 'true');
			$(value).attr({
				'tabindex': tabindex,
				'aria-hidden': 'false'
			});
		}
		else {
			$tile.attr('aria-expanded', 'false');
			$(value).attr({
				'tabindex': '-1',
				'aria-hidden': 'true'
			});
		}
	});
	// skipping-excluding every classes listed while tabbing - otherwise tab focus is getting lost in plp tiles stars
	function exclude() {
		return '.thumb-link, .categorysnippet-wrapper > *';
	}
}

/**
 * @description Focus on the add to cart button after color and/or size are selected and button is enabled
 */
function setFocusOnAddToCart() {
	simulateTabKeyFocus($('#product-content .js-add-to-cart'));
}

/**
 * @description Focus on the first product after sort by or filter is selected on plp
 */
function setFocusOnFirstProduct() {
	if (!isKeyboardNavigationActive) { return; }
	var $el = $('ul.js-search-result-articles').find('>:first-child');
	simulateTabKeyFocus($el);
}

/**
 * @description Focus on the first pickup point after list is updated
 */
function setFocusOnFirstPickupPoint() {
	if (!isKeyboardNavigationActive) { return; }
	var $el = $('.js-pickuppoints-list .post-office-radio input[type = "radio"]').first();
	simulateTabKeyFocus($el);
}

/**
 * @description Attaches events for the pick up point delivery availability
 */
function attachPickupPointEvents() {
	// when enter is pressed while input trigger button click
	$('body').on('keydown', '.js-pickuppoint-postal', function(e) {
		if (e.which === keyboard.ENTER_KEY) {
			setKeyboardNavigation(true);
			$('.js-display-pickuppoints').trigger('click');
		}
	});
}


/**
 * @description Attaches events to the profile-link inside menu
 */
function attachLoggedInUserEvents () {
	// Hide account block when login icon is pressed with escape or tab + shift
	$('body').on('keydown', '.js-account-link-loggedin', function(e) {
		var value = e.which;
		var shiftPressed = e.shiftKey;
		// Tab and shift key together
		if (value === keyboard.TAB_KEY && shiftPressed) {
			hideLoggedinBlock();
		}
		if (value === keyboard.ESC_KEY) {
			hideLoggedinBlock();
		}
	});

	// Hide loggedin block when focused out of block inner account links
	$('body').on('keydown', '.account-link', function(e) {
		var $this = $(this);
		var value = e.which;
		var shiftPressed = e.shiftKey;
		// Tab key : If it's the last element of the account block we hide the block when tab is pressed
		if (value === keyboard.TAB_KEY && !shiftPressed) {
			if ($this.is(':last-child')) {
				hideLoggedinBlock();
			}
		}
		// Tab and shift key together : If it's the first element of the account block we hide the block when these two are pressed
		if (value === keyboard.TAB_KEY && shiftPressed) {
			if ($this.is(':first-child')) {
				hideLoggedinBlock();
			}
		}
		if (value === keyboard.ESC_KEY) {
			hideLoggedinBlock();
		}
	});
}

/**
 * @description Hide loogedin block
 */
function hideLoggedinBlock () {
	if ($('.js-account-links').is(':visible')) {
		$('.js-account-link-loggedin').trigger('click');
	}
}

/**
 * @description Opens the minicart for accessibility usage (it will allow you to tab out of it instead of having to close it manually)
 */
function openMiniCart(e) {
	var $minicartContainer = $('.js-c-mini-cart'),
		$miniCartLink = $(this);
	// If the the cart is not empty
	if ($minicartContainer.length) {
		e.preventDefault();
		$minicartContainer.addClass('opened').attr('aria-hidden', 'false');
		$miniCartLink.attr('aria-expanded', 'true');
		simulateTabKeyFocus($minicartContainer);
		focusTrap($minicartContainer);

		disablePageScroll();
	}
}

/**
 * @description Attaches events to both the mini cart elements and mini-cart-link inside the menu
 */
function attachMinicartEvents () {
	// Minicart icon event
	$('body').on('click', '.js-mini-cart', function(e) {
		openMiniCart(e);
	});

	// Close event on esc or on close button / Disabling slider navigation
	$('body').on('keydown', '.js-c-mini-cart', function(e) {
		var $minicartContainer = $(this),
			$miniCartLink = $('.js-mini-cart'),
			value = e.which;

		$('.pt_product-search-result .js-mini-cart').attr('tabindex', 1);

		if (value === keyboard.ESC_KEY
			|| (value === keyboard.ENTER_KEY && $(e.target).hasClass('js-close-mini-cart'))
		) {
			$minicartContainer.removeClass('opened').attr('aria-hidden', 'true');
			$miniCartLink.attr('aria-expanded', 'false');
			simulateTabKeyFocus($miniCartLink);

			enablePageScroll();
		}
	});

	// when we leave minicart, close it
	$('.js-c-mini-cart, .js-c-mini-cart > a, .js-c-mini-cart > button').on('focusout',
		_.debounce(function() {
			var $miniCart = $('.js-c-mini-cart'),
				$miniCartLink = $('.js-mini-cart'),
				cartInFocus = $miniCart.find(':focus').length > 0 || $miniCart.is(':focus');

			if ($miniCart.is(':visible') && !cartInFocus) {
				$miniCart.removeClass('opened').attr('aria-hidden', 'true');
				$miniCartLink.attr('aria-expanded', 'false');

				enablePageScroll();
			}
		}, 200));

	// Adds class to focused product
	$('body').on('focus', '.mini-cart-product a', function() {
		$(this).addClass('focus-visible').attr('data-focus-visible-added', '');
	});

	// when focus is moved out of the close icon on minicart then remove class from close icon
	$('body').on('blur', '.js-close-mini-cart', function() {
		$(this).removeClass('focus-visible');
	});

	// Removes class from focused product
	$('body').on('blur', '.mini-cart-product a', function() {
		$(this).removeClass('focus-visible').removeAttr('data-focus-visible-added');
	});

	// Fix for NVDA screen readers to expand minicart
	$('.js-arrow-accessibility-minicart').on('click keypress', function(e) {
		// if clicked or the key pressed was enter
		if (e.type === 'click' || e.which === keyboard.ENTER_KEY) {
			openMiniCart(e);
		}
	});
}

/**
 * @description Attaches element to js-radio-container-payment (pajment method radio buttons on checkout page)
 */
function attachPaymentEvents () {
	$('body').on('keydown', '.js-radio-container-payment', function(e) {
		var value = e.which;
		var $this = $(this);

		if (value === keyboard.ENTER_KEY) {
			// Trigger only if the payment method value changes
			if ($('.js-payment-method:checked').val() !== $this.find('.js-payment-method').val()) {
				$this.find('.js-payment-method')
					.prop('checked', true)
					.trigger('change');
				$(document).trigger('paymentmethod-changed', $('.js-payment-method').val());
			}

			// Focus on the first element of the panel
			if ($this.hasClass('is-credit_card')) {
				var $el = $('.js-payment-panel').find('input:first');
				$el.closest('.input-container').addClass('is-filled');
				simulateTabKeyFocus($el);
			}
		}
	});

	/**
	 * @description Attaches aria label for accessibility in the  credit cart section
	 */
	$('.js-radio-container-payment').on('change', function() {
		$('.js-radio-container-payment[aria-checked=true]').attr('aria-checked', 'false');
		$(this).attr('aria-checked', $(this).find('input').is(':checked'));
	});
}

/**
 * @description Attach JS events related to shipping options
 */
function attachShippingMethodEvents() {
	// When enter is pressed on shipping method radio button simulate click for that button
	$('body').on('keydown', '.js-shipping-methods-container > div, .js-shipping-method-option, .js-checkout-delivery-options > div, .js-delivery-option-accordion', function(e) {
		if (e.which === keyboard.ENTER_KEY) {
			$(this).trigger('click');
		}
	});
}

/**
 * @description Attaches event to show/hide dropdown on click/hover on search and menu li items
 */
function attachAriaExpandedEvents () {
	if (!util.isSmallScreenSize()) {
		// Toggle Aria Expanded For flyout menu
		$('.menu-navigation > .menu-links > ul > li.has-children').on('mouseenter',
			function() {
				$(this).find('[aria-expanded]').attr('aria-expanded', true);
			}).on('mouseleave',
			function() {
				$(this).find('[aria-expanded]').attr('aria-expanded', false);
			});
	}
}

/**
 *  @description Focus for elements in My account page
 */
function attachAccountEvents() {
	$('.js-focus-account-overview').on('focus', function() {
		var recomExist = $('.js-focus-account-recommendation').length;
		if (recomExist > 0) {
			$('.js-focus-account-recommendation .c-products-list-recomm a').first().trigger('focus');
		}
	});

	$('.js-focus-banner').on('focus', function() {
		$('.js-focus-personal-info').trigger('focus');
	});

	$('.js-focus-account-banner').on('focus', function() {
		$('.js-focus-account-overview').trigger('focus');
	});

	$('.js-focus-order-history').on('keydown', function(e) {
		if (e.which === keyboard.TAB_KEY) {
			$('input.js-footer-newsletter-email-address').trigger('focus');
		}
	});

	$(document).on('keydown', '#dwfrm_profile_customer_shoesizedefault', function(e) {
		if (e.which === keyboard.ENTER_KEY) {
			e.preventDefault();
			$(this).trigger('mousedown');
		}
	});
}

/**
 *  @description Add events for the keybard navigation in the dialogs
 */
function attachDialogEvents() {
	if (!isKeyboardNavigationActive) { return; }

	var $el = $('.dialog-content');
	if ($el.find('[tabindex]').length) {
		$el.removeAttr('tabindex');
	}
	else {
		$el.attr('tabindex', '0');
		simulateTabKeyFocus($el);
	}
}

/**
 *  @description Add events for the promo code dialog
 */
function attachPromoCodeEvents() {
	$('body').on('focusout', '.js-add-manage-promotion-codes', function() {
		removePromoCodeNotification();
	});

	$('body').on('click', '[data-js="cart-wrapper"], .checkout-wrapper', function() {
		removePromoCodeNotification();
	});

	$('body').on('keydown', '.js-promotion-input', function(e) {
		if (e.which === keyboard.ENTER_KEY) {
			$('.js-add-coupon').trigger('click');
		}
	});

	$('[data-js="promotion-code-toggler"]').off('keypress').on('keypress', function(e) {
		if (e.which === keyboard.ENTER_KEY || e.which === keyboard.SPACE_KEY) {
			// trigger the search button
			$(this).trigger('click');
		}
	});
}

/**
 * @description Removes notification which shows when promo code is applied or removed
 */
function removePromoCodeNotification() {
	var $notifications = $('#promo-code-notification');

	if (!$notifications.text().trim().length) {
		$notifications.hide();
	}
	else {
		$notifications.hide(500);
	}

	$notifications.attr('aria-hidden', 'true');
}

/**
 * @description Put element into focus, add focus-visible class and data-focus-visible-added attribute
 * @param {Object} $element
 */
function simulateTabKeyFocus($element) {
	$element.addClass('focus-visible').attr('data-focus-visible-added', '').trigger('focus');
}

/**
 * @description Disable page scroll. Lock scroll position, but retain settings for later
 */
function disablePageScroll() {
	var scrollPosition = [
		self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
		self.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
	];
	var body = $('body');
	body.data('scroll-position', scrollPosition);
	body.data('previous-overflow', body.css('overflow'));
	body.css('overflow', 'hidden');
	window.scrollTo(scrollPosition[0], scrollPosition[1]);
}

/**
 * @description Enable page scroll. Un-lock scroll position
 */
function enablePageScroll() {
	var body = $('body');
	var scrollPosition = body.data('scroll-position');
	if (scrollPosition) {
		body.css('overflow', body.data('previous-overflow'));
		window.scrollTo(scrollPosition[0], scrollPosition[1]);
	}
}

/**
 * @description Attaches events for the store availability
 */
function attachStoreAvailabilityEvents() {
	// when enter is pressed while input trigger button click
	$('body').on('keydown', '.js-available-stores-input', function(e) {
		if (e.which === keyboard.ENTER_KEY) {
			isKeyboardNavigationActive = true;
			e.preventDefault();
			$(this).parents('.js-pickup-store-wrap').find('.js-search-stores').trigger('click');
		}
	});

	$('body').on('keydown', '[data-js="store-availability-item"]', function(e) {
		var $storeTile = $(this);

		if (e.target === this && e.which === keyboard.ENTER_KEY) {
			_toggleTileFocus($storeTile, '0');
		}
	});

	$('body').on('keydown', '.js-picked-by-someone-else', function(e) {
		if (e.target === this && e.which === keyboard.ENTER_KEY) {
			$(this).trigger('click');
		}
	});
}

/**
 * @description Focus on the add to cart button after color and/or size are selected and button is enabled
 */
function setFocusOnPlaceOrder() {
	var $placeOrderBtn = $('.js-checkout-btn');
	if ($placeOrderBtn.length > 0 && isKeyboardNavigationActive) {
		$placeOrderBtn = $placeOrderBtn.attr('disabled') ? $placeOrderBtn.parent() : $placeOrderBtn;
		simulateTabKeyFocus($placeOrderBtn);
	}
}

/**
 * Sets focus on yotpo review stars
 */
function setFocusOnReviews() {
	if (!isKeyboardNavigationActive) {
		return;
	}

	var starsElement = $('.yotpo-display-wrapper .yotpo-regular-box:not(.yotpo-hidden) .bottom-line-items');

	if (!starsElement.length) {
		//looks like yotpo has two different styling for stars so in case that we don't have first one we check second one;
		starsElement = $('.yotpo-display-wrapper .yotpo-regular-box .yotpo-bottomline-box-1');
	}

	if (!starsElement.length) {
		return;
	}

	simulateTabKeyFocus(starsElement, true);
}

/**
 * Sets focus first visible product of shoecare recommendation
 */
function setFocusOnShoeCare() {
	if (!isKeyboardNavigationActive) {
		return;
	}

	var shoecareProduct = $('#shoecare-recommendation [data-js="product-highlight-tile"] .product-name').first();

	if (!shoecareProduct.length) {
		return;
	}

	simulateTabKeyFocus(shoecareProduct, true);
}


/**
 * @description Attaches event to handle the gift wrapping checkbox with keyboard
 */
function attachGiftWrappingEvents() {
	// Keyboard accessibility - Gift wrapping custom checkbox
	$('body').on('keydown', '.order-giftwrap label', function(e) {
		var checkbox = $(this);
		if (e.which === keyboard.ENTER_KEY) {
			var check = checkbox.attr('aria-checked') == 'false';
			checkbox.attr('aria-checked', `${!check}`);
			checkbox.trigger('click');
			checkbox.trigger('focus');
		}
	});
}

/**
 * Switches focus to first size swatch if it exist
* @param {Object} sizeSelector
 */
function focusToFirstSize(sizeSelector) {
	if (!isKeyboardNavigationActive) {
		return;
	}
	var sizeSwatchSelector = '.c-size-list.size';
	var altSizeSwatchSelector = '.c-size-list.size-alt';

	if ($('#pdpMain').length) {
		sizeSwatchSelector = $('.js-color-swatch.size');
		altSizeSwatchSelector = $('.js-size-swatch.size-alt');
	}

	var content = $('body');
	if (sizeSelector) {
		content = sizeSelector.parents('[data-js="filter-content"]');
	}

	var sizeSwatches = content.find(sizeSwatchSelector);
	if (sizeSwatches.hasClass('u-hidden')) {
		sizeSwatches = content.find(altSizeSwatchSelector);
	}

	if (sizeSwatches.length) {
		var firstSizeSwatch = sizeSwatches.children('li:not(.unselectable)').first().children('a');
		simulateTabKeyFocus(firstSizeSwatch);
	}
}

function setFocusOnSelectedSwatch(wrapperId) {
	if (!isKeyboardNavigationActive) {
		return;
	}

	var swatches = $(`#${wrapperId}`).children('li');
	swatches.each(function() {
		var $this = $(this);
		if ($this.hasClass('selected')) {
			simulateTabKeyFocus($this.children('a'));
		}
	});
}

/**
 * @description Attach events for hamburger menu
 */
function attachHamburgerMenuEvents() {
	var lastElement = $('.menu-bottom a:last');
	var menuBottom = $('.menu-bottom');

	if (menuBottom.find('li.language-selector-mobile').length !== 0) {
		lastElement = $('.menu-bottom').find('.language-selector');
	}

	if (lastElement.length === 0) {
		lastElement = $('.js-level-1-item:last');
	}

	// focus trap
	$('body').on('keydown', 'button.js-icon-burger', function(e) {
		if (e.which === keyboard.TAB_KEY && e.shiftKey) {
			e.preventDefault();
			simulateTabKeyFocus(lastElement);
		}
	});

	lastElement.on('keydown', function(e) {
		var closeButton = $('button.js-icon-burger.active');

		// if close button exists
		if (e.which === keyboard.TAB_KEY && !e.shiftKey && closeButton.length !== 0) {
			e.preventDefault();
			simulateTabKeyFocus(closeButton);
		}
	});
}

function attachProductImageEvent() {
	$('body').on('keydown', '.item-image img, .mini-cart-image img', function(e) {
		if (e.which === keyboard.ENTER_KEY) {
			$(this).trigger('click');
		}
	});
}

/**
 * @description Attaching event for checkbox in newsletter section
 */
function attachNewsletterCheckboxEvent() {
	$('body').on('focus', '.newsletter-privacy-wrapper .input-checkbox', function() {
		$(this).parent().addClass('has-focus');
	});

	$('body').on('blur', '.newsletter-privacy-wrapper .input-checkbox', function() {
		$(this).parent().removeClass('has-focus');
	});
}

function attachCheckboxEventPageDesigner() {
	$('body').on('focus', '[data-js="pd-form-checkbox"]', function() {
		$(this).parent().addClass('has-focus');
	});

	$('body').on('blur', '[data-js="pd-form-checkbox"]', function() {
		$(this).parent().removeClass('has-focus');
	});
}

/**
 * @description Attach events on gift card balance dialog
 */
function attachGiftCardDialogEvents() {
	var $el = $('.giftcard-dialog');
	$el.attr('tabindex', '0').trigger('focus');
}

/**
 * @description Attach events on afterpay dialog on pdp
 */
function attachAfterPayDialogEvents() {
	var $el = $('.afterpay-info-content-dialog');
	var $linkSelector = $('.afterpay-modal__img');
	$el.attr('tabindex', '0').trigger('focus');

	$linkSelector.on('keydown', function(e) {
		if (e.which === keyboard.ENTER_KEY) {
			$linkSelector.trigger('click');
		}
	});
}

/**
 * @description Attach events on zippay dialog on pdp
 */
function attachZipPayDialogEvents() {
	var $el = $('.zippay-info-content-dialog');
	var $linkSelector = $('.zippay-modal__img');
	$el.attr('tabindex', '0').trigger('focus');

	$linkSelector.on('keydown', function(e) {
		if (e.which == keyboard.ENTER_KEY) {
			$linkSelector.trigger('click');
		}
	});
}

/**
 * Checkout redesign events
 */
function attachRedesignEvents() {
	$('body').off('keydown', '[data-js="save-contact-details"]').on('keydown', '[data-js="save-contact-details"]', function(e) {
		if (e.which === keyboard.ENTER_KEY) {
			$(this).trigger('click');
		}
	});
}

/**
 * Sets tabindexes of filter flyout
 * @param {String} value new tabindex value
 * @param {Boolean} isDesktop is desktop view
 */
function updateFilterFlyoutTabindexes(value, isDesktop) {
	var plpRefineFlyout = $('[data-js="plp-refine-flyout"]');
	var sortByContent = plpRefineFlyout.find('[data-js="product-sort-categories-flyout"]');
	var refinementBlocks = plpRefineFlyout.find('[data-js="filter-block"]');

	refinementBlocks.find('[data-js="filter-toggler"]').attr('tabindex', value);
	updateFilterContentTabindexes(value, sortByContent);

	if (isDesktop) {
		var closeButton = plpRefineFlyout.find('[data-js="close-flyout-filters"]');
		closeButton.attr('tabindex', value);
		updateFilterContentTabindexes(value, refinementBlocks);
		return;
	}

	var mobileHeader = plpRefineFlyout.find('[data-js="mobile-header"]');
	var mobileCloseButton = mobileHeader.find('[data-js="filter-mobile-close"]');
	var mobileResetAllBtn = mobileHeader.find('[data-js="clear-filters"]');
	var mobileBtnWrapButtons = plpRefineFlyout.find('[data-js="filter-flyout-mobile-btn-wrap"] a');
	var mobileColumnSwitch = plpRefineFlyout.find('[data-js="column-switch"] > div');

	mobileCloseButton.attr('tabindex', value);
	mobileResetAllBtn.attr('tabindex', value);
	mobileBtnWrapButtons.attr('tabindex', value);
	mobileColumnSwitch.attr('tabindex', value);

	if (value === '-1') {
		updateFilterContentTabindexes(value, refinementBlocks, true);
	}
}

/**
 * Sets tabindexes of flyout filter content
 * @param {String} value new tabindex value
 * @param {Object} filterContent wrapper for the content
 * @param {Boolean} isMobile is mobile view
 */
function updateFilterContentTabindexes(value, filterContent, isMobile) {
	var filterContentElements = filterContent.find('a, button');
	filterContentElements.attr('tabindex', value);

	if (!isMobile) {
		return;
	}

	var mobileBackButton = filterContent.find('[data-js="filter-mobile-back"]').first();
	var mobileResetAllBtn = filterContent.find('[data-js="clear-filters"]');
	var mobileBtnWrapButtons = $('[data-js="plp-refine-flyout"] [data-js="filter-flyout-mobile-btn-wrap"] a');
	mobileBtnWrapButtons.attr('tabindex', value);

	mobileBackButton.attr('tabindex', value);
	mobileResetAllBtn.attr('tabindex', value);
}

/**
 * Updates accessibility logic for the flyout desktop view (updates the focus trap)
 * @param {Boolean} clearEvents
 */
function updateDesktopFlyoutAccessibility(clearEvents) {
	if (!isKeyboardNavigationActive && !clearEvents) {
		return;
	}

	var plpRefineFlyout = $('[data-js="plp-refine-flyout"]');
	var closeButton = plpRefineFlyout.find('[data-js="close-flyout-filters"]');
	var lastInteractableElement = getLastInteractableElement(plpRefineFlyout);


	if (clearEvents) {
		closeButton.off('keydown.accessibility');
		lastInteractableElement.off('keydown.accessibility');
		return;
	}

	closeButton.off('keydown.accessibility').on('keydown.accessibility', function(e) {
		if (e.keyCode === keyboard.TAB_KEY && e.shiftKey) {
			e.preventDefault();
			simulateTabKeyFocus(lastInteractableElement);
		}
	});

	lastInteractableElement.off('keydown.accessibility').on('keydown.accessibility', function(e) {
		if (e.keyCode === keyboard.TAB_KEY && !e.shiftKey) {
			e.preventDefault();
			simulateTabKeyFocus(closeButton);
		}
	});
}

/**
 * Returns last interactable element of Desktop Filter Flyout
 * @param {Object} refineFlyout
 */
function getLastInteractableElement(refineFlyout) {
	var lastFilterBlock = refineFlyout.find('[data-js="filter-block"]:visible').last();

	// In case that there are no filters on flyout we return last sorting option
	if (!lastFilterBlock.length) {
		return refineFlyout.find('[data-js="product-sort-categories-flyout"]').find('a').last();
	}

	// We first remove accessibility events of both toggler and last interactable element on last filter block
	lastFilterBlock.find('[data-js="filter-toggler"]').off('keydown.accessibility');
	lastFilterBlock.find('a').last().off('keydown.accessibility');
	var isLastBlockExpanded = lastFilterBlock.hasClass('js-expanded');

	// If the last filter block is expanded we return it
	if (isLastBlockExpanded) {
		return lastFilterBlock.find('a').last();
	}

	// In case that last block is collapsed we use its toggler as last element
	return lastFilterBlock.find('[data-js="filter-toggler"]');
}

/**
 * Updates accessibility logic for the flyout mobile view (updates the focus trap)
 * @param {Object} content wrapper for the content
 */
function updateMobileFlyoutAccessibility(content) {
	if (!isKeyboardNavigationActive) {
		return;
	}

	var plpRefineFlyout = $('[data-js="plp-refine-flyout"]');
	var lastInteractableElement = plpRefineFlyout.find('[data-js="filter-flyout-mobile-btn-wrap"] a:visible').last();
	var firstInteractableElement = plpRefineFlyout.find('[data-js="filter-mobile-close"]').first();

	plpRefineFlyout.find('[data-js="filter-flyout-mobile-btn-wrap"] a').off('keydown.accessibility');

	if (content) {
		firstInteractableElement = content.find('[data-js="filter-mobile-back"]').first();
	}

	firstInteractableElement.off('keydown.accessibility').on('keydown.accessibility', function(e) {
		if (e.keyCode === keyboard.TAB_KEY && e.shiftKey) {
			e.preventDefault();
			simulateTabKeyFocus(lastInteractableElement);
		}
	});

	lastInteractableElement.off('keydown.accessibility').on('keydown.accessibility', function(e) {
		if (e.keyCode === keyboard.TAB_KEY && !e.shiftKey) {
			e.preventDefault();
			simulateTabKeyFocus(firstInteractableElement);
		}
	});
}

/**
 * Updates accessibility logic for the refinement bar (updates focus trap)
 * @param {Object} filterToggler toggler
 * @param {Object} filterContent wrapper for the content
 */
function updateFilterContentAccessibility(filterToggler, filterContent) {
	var filterContentElements = filterContent.find('[tabindex="1"]:not([data-js="clear-filters"])');
	var contentFirstElement = filterContentElements.first();
	var contentLastElement = filterContentElements.last();

	filterToggler.off('keydown.accessibility').on('keydown.accessibility', function(e) {
		var isExpanded = $(this).attr('aria-expanded') == 'true';
		if (e.keyCode === keyboard.TAB_KEY && e.shiftKey && isExpanded) {
			filterToggler.trigger('click');
		}
	});

	contentFirstElement.off('keydown.accessibility').on('keydown.accessibility', function(e) {
		if (e.keyCode === keyboard.TAB_KEY && e.shiftKey) {
			filterToggler.trigger('click');
		}
	});

	// For sizes we have to check which size type is displayed so we can get proper last element
	var selectedSizeList = filterContent.find('[data-js="size-list"]:not(.u-hidden)');
	if (selectedSizeList.length) {
		contentLastElement = selectedSizeList.find('[tabindex="1"]').last();
	}

	contentLastElement.off('keydown.accessibility').on('keydown.accessibility', function(e) {
		if (e.keyCode === keyboard.TAB_KEY) {
			filterToggler.trigger('click');
		}
	});
}

function attachFilterEvents() {
	// Handle all accessibility Enter events for (togglers, flyout open/close buttons, mobile content open/close buttons)
	$('[data-js="filter-toggler"],[data-js="open-filter-flyout"],[data-js="filter-mobile-back"],[data-js="clear-filters"],[data-js="filter-mobile-close"],[data-js="plp-refine-bar"] [data-js="product-sort-tag"], [data-js="column-switch"] > div').on('keydown', function(e) {
		if (e.keyCode === keyboard.ENTER_KEY) {
			isKeyboardNavigationActive = true;
			$(this).trigger('click');
		}
	});
}
