'use strict';

/**
 * @function
 * @description  Calculate Filter Height of the PLP Filter based on specific media query
 */
function initializePlpMobileFilter() {
	const headerHeight = $('[data-js="header"]').height();
	const breakpoint = window.matchMedia('(max-width:1000.5px)');

	if (breakpoint.matches === true) {
		$('[data-js="filter-plp-position"]').css('top', headerHeight);
		$('[data-js="filter-plp-position"]').css('padding-bottom', headerHeight + 130);
	}
	else {
		$('[data-js="filter-plp-position"]').css('top', '');
		$('[data-js="filter-plp-position"]').css('padding-bottom', '');
	}
}

exports.init = function () {
	initializePlpMobileFilter();
};
