var util = require('../../util'),
	forms = require('../../forms'),
	accessibility =	(util.isMobile()) ? require('../../accessibilityForMobile') : require('../../accessibility');


/**
 * Scrolls to first available section and focuses on first field if it is keyboard navigation
 */
function scrollToNextSection() {
	var contactDetailsFieldset = $('[data-js="contact-details-fieldset"]');
	var contactDetailsSection = $('.js-checkout-contact');

	var shippingDetailsFieldset = $('.js-shipping-form');
	var shippingDetailsSection = $('.js-checkout-method');

	var paymentMethods = $('.payment-methods-row');
	var billingDetailsFieldset = $('.js-billing-form');
	var paymentDetailsSection = $('.js-checkout-payment');

	var checkoutButton = $('.js-checkout-btn-placeorder');
	var firstInput;

	// Contact setion
	if (contactDetailsFieldset.length) {
		scrollToSection(contactDetailsSection);
		if (accessibility.isKeyBoardNavigationActive()) {
			firstInput = contactDetailsFieldset.find(':visible:focusable:first');
			accessibility.simulateTabKeyFocus(firstInput);
		}
		return;
	}

	// Shipping section
	if (shippingDetailsFieldset.length && !shippingDetailsFieldset.hasClass('u-hidden')) {
		scrollToSection(shippingDetailsSection);
		if (accessibility.isKeyBoardNavigationActive()) {
			firstInput = shippingDetailsFieldset.find(':visible:focusable:first');
			accessibility.simulateTabKeyFocus(firstInput);
		}
		// trigger deliveryInstructionsTextLimit when shipping section is visible (elements part of the DOM)
		forms.deliveryInstructionsTextLimit();
		forms.inputFieldMaxlength();
		return;
	}

	// Payment section mayment methods
	if (paymentMethods.length) {
		scrollToSection(paymentDetailsSection);
		if (accessibility.isKeyBoardNavigationActive()) {
			firstInput = paymentMethods.find(':visible:focusable:first');
			accessibility.simulateTabKeyFocus(firstInput);
		}
		forms.inputFieldMaxlength();
		return;
	}

	// Payment section billing address
	if (billingDetailsFieldset.length) {
		scrollToSection(paymentDetailsSection);
		if (accessibility.isKeyBoardNavigationActive()) {
			firstInput = billingDetailsFieldset.find(':visible:focusable:first');
			accessibility.simulateTabKeyFocus(firstInput);
		}
		forms.inputFieldMaxlength();
		return;
	}

	//default:
	if (checkoutButton.length > 0) {
		scrollToSection(checkoutButton);
		if (accessibility.isKeyBoardNavigationActive()) {
			accessibility.setFocusOnPlaceOrder();
		}
	}
}


/**
 * Scrolls to provided section
 * @param {Object} section
 */
function scrollToSection(section) {
	if (!section) {
		return;
	}

	section.trigger('trackCheckoutStep');

	var headerHeight = $('.header').height();
	var stepIndicator = $('.js-step-indicator');

	var stepIndicatorSizeDifference = 20; // difference of size when step indicator sticky
	var scrollTopSize = $(section).offset().top;

	if (stepIndicator.length === 0) {
		stepIndicator = $('[data-js="step-indicator"]');
	}
	if (stepIndicator.length > 0) {
		headerHeight += stepIndicator.outerHeight();
		scrollTopSize = $(section).offset().top - ((stepIndicator.outerHeight() * 2));
		!util.isTabletAndBelow() && (scrollTopSize -= stepIndicatorSizeDifference);
	}

	$('html, body').animate({
		scrollTop: scrollTopSize
	}, 500);
}

exports.scrollToNextSection = scrollToNextSection;
exports.scrollToSection = scrollToSection;