'use strict';

var tooltip = require('../../tooltip'),
	util = require('../../util'),
	addToCart = require('./addToCart'),
	availability = require('./availability'),
	image = require('./image'),
	productNav = require('./productNav'),
	productSet = require('./productSet'),
	recommendations = require('./recommendations'),
	variant = require('./variant'),
	sizeSelector = require('../../sizeselector'),
	flyout = require('../../flyout'),
	productDetail = require('./productDetailCta'),
	wishlist = require('../wishlist'),
	storeSelector = require('../../storeselector'),
	storeavailability = require('./storeavailability'),
	pdpPaymentWidgets = require('../../pdpPaymentWidgets');

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom() {
	productNav();
	recommendations();
	tooltip.init();
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents(isQuickView) {
	var $pdpMain = $('#pdpMain');

	if (isQuickView) {
		$pdpMain = $('#pdpMain.quickview');
	}

	addToCart();
	availability();
	variant.init();
	image();
	productSet();
	wishlist.init();
	pdpPaymentWidgets.init();
	tooltip.initializeCustomTooltip();
	tooltip.image360Tooltip();

	// Add to Wishlist and Add to Gift Registry links behaviors
	$pdpMain.on('click', '[data-action="gift-registry"]', function () {
		var data = util.getQueryStringParams($('.pdpForm').serialize());
		if (data.cartAction) {
			delete data.cartAction;
		}
		var url = util.appendParamsToUrl(this.href, data);
		this.setAttribute('href', url);
	});

	// product options
	$pdpMain.on('change', '.product-options select', function () {
		var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
		var selectedItem = $(this).children().filter(':selected').first();
		salesPrice.text(selectedItem.data('combined'));
	});

	// prevent default behavior of thumbnail link and add this Button
	$pdpMain.off('click', '.thumbnail-link, .unselectable a').on('click', '.thumbnail-link, .unselectable a', function (e) {
		e.preventDefault();
		var $this = $(this);

		// Remove border from previously clicked OOS size
		$pdpMain.find('.unselectable.oos-clicked').removeClass('oos-clicked');
		$pdpMain.find('.out-of-stock-size.msg-error').removeClass('u-hidden');
		$pdpMain.find('.js-color-swatch li .oos-clicked').removeClass('oos-clicked');
		// Add border to clicked OOS size
		$this.parent().addClass('oos-clicked');

		// if user clicks on add button without size selected we remove previous error msg
		if (!$pdpMain.find('#add-to-cart-description').hasClass('u-hidden')) {
			$pdpMain.find('#add-to-cart-description').addClass('u-hidden');
			$('[data-js="js-pdp-sticky-bar"]').find('[data-js="sticky-error"]').addClass('u-hidden');
		}
	});

	// pdp craftsman gallery block
	if ($('.js-product-craftsmanship-block').length) {
		setTimeout(function() {
			$('.js-mob-counter').height($('.js-product-col-left img').height());
		}, 1000);
	}
	$('.js-see-all-images, .js-mob-counter').on('click', function (e) {
		e.preventDefault();

		$('.js-product-craftsmanship-block img').show();
		$('.js-see-all-images').addClass('hide');
		$('.js-mob-counter').hide();
	});

	$pdpMain.off('click', '[data-js="pdp-thumb-images"]').on('click', '[data-js="pdp-thumb-images"]', function () {
		const $activeThumbImage = $(this);
		$activeThumbImage.siblings().removeClass('active');
		$activeThumbImage.addClass('active');

		const imgIndex = parseInt($activeThumbImage.data('index'), 10);

		$('[data-js="pdp-images-slider"] [data-js="pdp-product-image"]')
			.get(imgIndex)
			.scrollIntoView({ behavior: 'smooth', block: 'center' });
	});

	productDetail.init(isQuickView);
	sizeSelector.init(isQuickView);
	flyout.init();
	storeSelector.init('availability', storeSelectionCallback);
	storeavailability.getStoreAvailability();
}

/**
 * @description Callback for store selection on PDP
 * @param {String} response
 */
function storeSelectionCallback(response) {
	var $response = $(response);

	if ($('[data-js="store-wrapper"]').length) {
		$('[data-js="store-wrapper"]').replaceWith($response);
	}

	storeavailability.getStoreAvailability();
}

$(window).on('resize', function() {
	if ($('.js-product-craftsmanship-block').length) {
		setTimeout(function() {
			$('.js-mob-counter').height($('.js-c-product-col-narrow img').height());
		}, 1000);
	}
});

//  We have some inline styles, and the html code is not on 
//  our side (we could not find where it sits) so removing 
//  with JS was our only option for now.

function removeStyleAttributeYotpo() {
	if (typeof yotpo == 'undefined') {
		return;
	}

	yotpo.onAllWidgetsReady(function () {
		var yotpoRegularBoxMobile = $('.yotpo-regular-box.yotpo-bottomline.bottom-line-items-container-mobile');
		yotpoRegularBoxMobile.removeAttr('style');
	});
}

var product = {
	initializeEvents: initializeEvents,
	init: function () {
		initializeDom();
		initializeEvents();
		sizeSelector.init();
		flyout.init();
		removeStyleAttributeYotpo();
	}
};

module.exports = product;
