/**
 * @fileoverview Utilities to show and control the store selection dialog
 * Manages AJAX calls to the backend, geocoding user input (location string query),
 * and updates the information on the page. This is used in PDP, Cart and Checkout.
 * @package OMNI
 */

'use strict';

var Promise = require('promise'),
	progress = require('./progress'),
	ajax = require('./ajax'),
	detailsmodaldialog = require('./detailsmodaldialog'),
	util = require('./util'),
	map = require('./omnimap'),
	constants = require('./constants'),
	isMobile = !util.isTabletAndAbove(),
	accessibility = isMobile ? require('./accessibilityForMobile') : require('./accessibility'),
	pdpCtaHeight = require('./pages/product/productDetailCta'),
	forms = require('./forms'),
	validator = require('./validator'),
	dialog = require('./dialog'),
	ometriaTracker = require('./tracking/ometriaTracking'),
	variant = require('./pages/product/variant'),
	showOnlyInStockChecked = false;

var storeLocatorType, initialized, selectionCallback;

/**
 * @description Initialize events for product store availability function (OMNI feature)
 */
function init(type, storeSelectionCallback) {
	if (initialized) {
		return;
	}

	detailsmodaldialog.init();

	storeLocatorType = type;
	selectionCallback = storeSelectionCallback;
	//Sends ajax request to server which returns the template to be rendered in dialog
	$('body').on('click keydown', '.js-open-storeselector', function(e, isEditClicked) {
		if (e.keyCode == 13 || e.type === 'click') {
			e.preventDefault();
			accessibility.setKeyboardNavigation($(this).hasClass('focus-visible'));

			if (storeLocatorType === 'availability') {
				var productId = $('#product-top-info').attr('data-variant-id');
				if (!productId) {
					productId = $('#product-top-info').data('variantgroup-id');
				}

				var wrapper = $(this).parents('[data-js="select-store-wrapper"]');
				var isReserveEnabled = wrapper.data('pdp-reservation-enabled');
				var url = isReserveEnabled ? Urls.reservationOpenPopup : Urls.storeAvailability;
				var storeAvailabilityURL = util.appendParamToURL(url, 'productId', productId);
				var previousSearch = util.readCookie('previous-store-search');

				if (!isEditClicked) {
					getGeolocationAndPerformSearch(previousSearch, false);
				}

				openStoreLocator(storeAvailabilityURL, 'store-availability', isEditClicked && previousSearch ? previousSearch : null);
				$(document).trigger('checkStoreAvailability', productId);
			}
			else {
				openStoreLocatorInline();
			}
		}
	});

	//Sends ajax request to the server which will save selected store in session
	$('body').on('click', '.js-select-store-button', function() {
		var storeId = $(this).data('storeid');

		var selectedStore = $('.is-selected');
		if (selectedStore.length) {
			var selectedStoreId = selectedStore.data('storeid');
			if (selectedStoreId == storeId) {
				$('.store-availability__results-wrapper').find('.is-selected').removeClass('is-selected');
				$('.select-store-btn').html('<span>' + Resources.SELECT_STORE + '</span>');

			}
		}

		var pickerOption = $('.js-picked-by-someone-else');
		var data = {};

		var currentPage = $('#js-rawurl').val();
		if (currentPage.toLowerCase().indexOf('product-show') > 0) {
			// In case that we are on PDP we send product id
			data.pid = $('#pid').val();
		}

		if (pickerOption && pickerOption.length > 0) {
			var isBeingPickedBySomeoneElse = pickerOption.is(':checked');
			data.pickingBySomeoneElse = isBeingPickedBySomeoneElse;

			if (isBeingPickedBySomeoneElse) {
				data.pickerFirstname = $('.js-picker-firstname').val();
				data.pickerLastname = $('.js-picker-lastname').val();
			}
		}

		var url = $(this).data('url');
		progress.show();

		$(document).trigger('selectStore', storeId);

		if (!isMobile || currentPage.toLowerCase().indexOf('product-show') > 0) {
			detailsmodaldialog.close();
		}
		accessibility.setKeyboardNavigation($(this).hasClass('focus-visible'));

		ajax.load({
			url: url,
			data: data,
			callback: function(result) {
				//reload page if there is no callback
				if (selectionCallback) {
					selectionCallback(result);
					progress.hide();
					pdpCtaHeight.init();
				}
				else {
					location.reload();
				}
			}
		});
	});

	$('body').on('click', '[data-js="reserveStore"]', function() {
		var error = $('[data-js="reserve-error"]');
		error.addClass('u-hidden');
		//Select store for reservation
		ajax.load({
			url: $(this).data('url'),
			callback: function(result) {
				progress.hide();

				if (!result) {
					error.removeClass('u-hidden');
					error.html(Resources.PRODUCT_RESERVATION_SELECTSTOREERROR);
					return;
				}

				$('[data-js="findinstoreblock"]').hide();
				$('[data-js="reservationblock"]').html($(result).find('[data-js="reservationblock"]').html());
				$('[data-js="storeAvailabilityPopupWrapper"]').removeClass('with-results');
				$('[data-js="storeAvailabilityPopupWrapper"]').addClass('reservation-flow');
				validator.initForm($('[data-js="reservationblock"]').find('form'));
				forms.checkInputFields();
			}
		});
	});

	$('body').on('click', '[data-js="reservationblock"] .privacy-policy', function(e) {
		e.preventDefault();
		var reservationblock = $('[data-js="reservationblock"]');
		var url = $(this).attr('href');
		var privacypolicyblock = $('[data-js="privacypolicyblock"]');
		var privacypolicycontent = $('[data-js="privacypolicycontent"]');
		var returnFunc = function() {
			dialog.removeCloseFunction();
			privacypolicycontent.html('');
			reservationblock.removeClass('u-hidden');
			privacypolicyblock.addClass('u-hidden');
		};
		ajax.load({
			url: url,
			callback: function(result) {
				privacypolicycontent.html(result);
				reservationblock.addClass('u-hidden');
				privacypolicyblock.removeClass('u-hidden');
				dialog.addCloseFunction(returnFunc);
			}
		});
		return false;
	});

	$('body').on('click', '[data-js="placeReservation"]', function() {
		var error = $('[data-js="place-reserve-error"]');
		error.addClass('u-hidden');
		var storeId = $(this).data('store-id');
		var productId = $(this).data('product-id');

		if (!storeId || !productId) {
			error.removeClass('u-hidden');
			error.html(Resources.PRODUCT_RESERVATION_TECHNICALERROR);
			return;
		}

		var contactDetailsForm = $('[data-js="contactDetails"]').closest('form');
		var contactDetailsInputs = $('[data-js="contactDetails"] input');
		var isValid = contactDetailsForm.valid();
		var data = [];

		data.push({ name: 'storeId', value: storeId });
		data.push({ name: 'productId', value: productId });

		contactDetailsInputs.each(function() {
			var field = $(this);
			data.push({ name: field.attr('name'), value: field.val() });
		});

		if (!isValid) {
			return;
		}
		progress.show();

		//Place reservation
		ajax.load({
			url: $(this).data('url'),
			data: data,
			callback: function(result) {
				progress.hide();

				if (!result) {
					error.removeClass('u-hidden');
					error.html(Resources.PRODUCT_RESERVATION_SERVERERROR);
					return;
				}

				$('[data-js="storeAvailabilityPopupWrapper"]').html($(result).filter('[data-js="storeAvailabilityPopupWrapper"]').html());
				// Track order on PDP reservation
				ometriaTracker.trackPlaceOrder();
				// updateContent is called to update PDP page (behind popup) when reservation is happened in case product is OOS after reservation to prevent additonal add to cart or store reservation process
				variant.updateContent(false,window.location.href,null,null,null,null);

			}
		});
	});

	$('body').on('click', '[data-js="reserve-edit-contact"]', function() {
		$('[data-js="reserve-readonly-contact"]').remove();
		$('[data-js="contactDetails"]').removeClass('u-hidden');
		forms.checkInputFields();
	});

	$('body').on('click', '.js-add-item-to-bag', function() {
		detailsmodaldialog.close();
		$('.js-add-to-cart').trigger('click');
	});

	// triggers the search of closeby stores with stock
	$('body').on('click', '[data-js="search-stores-near"]', function(e) {
		var data = {};

		// remove previously searched location in case Near Me is selected
		util.eraseCookie('previous-store-search');

		if (storeLocatorType === 'availability') {
			data.productId = $('#product-top-info').attr('data-variant-id');
		}

		if (!data.productId) {
			var selectSizeMsg = $('#select-size-msg');
			selectSizeMsg.removeClass('u-hidden');
			return;
		}
		//we need to clear search input
		$('.js-available-stores-input').val('');

		accessibility.setKeyboardNavigation($(this).hasClass('focus-visible'));

		// if the promise calls the resolve function it means it managed to access coordinates from browser
		// coords are then stored in the data object and sent to server for search
		// otherwise search is triggered without coords which results in using IP coords on server side
		getLocationCoordinates().then(
			function(coords) {
				if ('latitude' in coords && 'longitude' in coords) {
					data.latitude = coords.latitude;
					data.longitude = coords.longitude;
				}
				getSearchResults(data);
			},
			function() {
				getSearchResults(data);
			}
		);
	});

	// triggers search of closeby stores
	// first geolocates the user query, then sends it to the backend for a search
	$('body').on('click', '.js-search-stores', function(e) {
		var isInDialog = !!$(this).parents('#dialog-container').length || $('#dialog-container').length;
		var productId = $('#product-top-info').attr('data-variant-id');
		if (!productId && isInDialog) {
			var selectSizeMsg = $('#select-size-msg');
			selectSizeMsg.removeClass('u-hidden');
			return;
		}
		$('.store-availability-feedback').children().first().remove();
		if ($('.store-availability__results-wrapper').length) {
			$('.store-availability__results-wrapper').remove();
		}
		//since we can have multiple input fields (one in popup, one in page) fe need to specify which one we need
		var $searchInput = $(this).parents('.js-pickup-store-wrap').find('.js-available-stores-input');
		var searchString = $searchInput.val();
		//we need to check both input and button for keyboard navigation
		var isKeyboardNavidationActive = $(this).hasClass('focus-visible') || $searchInput.hasClass('focus-visible');
		accessibility.setKeyboardNavigation(isKeyboardNavidationActive);

		if (!searchString) {
			var storeAvailabilityFeedbackSelector = isInDialog ? '#dialog-container  .store-availability-feedback'
				: '#main-content .store-availability-feedback';
			$(storeAvailabilityFeedbackSelector).html('<span>' + Resources.STOREAVAILABILITY_SEARCH_EMPTY + '</span>');
			return;
		}
		getGeolocationAndPerformSearch(searchString, isInDialog);
	});

	$('body').on('click', '.swatchanchor-dialog', function(e) {
		e.preventDefault();
		var $this = $(this);
		var $wrapper = $this.closest('.c-store-availability');
		var productInfo = $this.data('productinfo');
		var selectSizeMsg = $wrapper.find('#select-size-msg');
		selectSizeMsg.addClass('u-hidden');
		$wrapper.find('[data-js="variation-size-selected"]').html(productInfo.displayValue);
		$wrapper.find('[data-js="variation-size-alt-selected"]').html(productInfo.alternateSizeDisplayValue);
		$wrapper.find('.store-availability__product-size .selected').removeClass('selected');
		var $swanchor = $('[data-size="' + $this.data('size') +'"]').parent();
		$swanchor.addClass('selected');
		$('[data-js="' + $this.data('size') + '"]').trigger('click');
	});

	$('body').on('click', '.js-product-edit', function (e) {
		e.preventDefault();
		$('.js-open-storeselector').trigger('click', true);
	});

	$('body').on('click', '.js-change-store', function (e) {
		e.preventDefault();
		$('[data-js="reservationblock"]').html('');
		$('[data-js="findinstoreblock"]').show();
		$('[data-js="storeAvailabilityPopupWrapper"]').addClass('with-results');
		$('[data-js="storeAvailabilityPopupWrapper"]').removeClass('reservation-flow');

	});

	// shows the help dialog
	$('body').on('click', '.js-pickup-in-store-help-link', function(e) {
		e.preventDefault();
		detailsmodaldialog.open({
			url: $('.js-pickup-in-store-help-link').attr('href'),
			options: {
				dialogClass: 'pdp-pickup-in-store-help'
			},
			closeAll: false
		});
	});
	// displays the form section for another person's pickup
	$('body').on('change click', '.js-picked-by-someone-else', function(e) {
		if ($(this).is(':checked')) {
			$('.js-picking-person-form').removeClass('u-hidden');
		}
		else {
			$('.js-picking-person-form').addClass('u-hidden');
		}
	});

	//change event with which we populate other search inputs with data from the current one
	$('body').on('change', '.js-available-stores-input', function(e) {
		$('.js-available-stores-input').val($(this).val());
		e.stopImmediatePropagation();
	});

	$('body').off('click', '[data-js="expand-store-hours"]').on('click', '[data-js="expand-store-hours"]', function() {
		var hoursWrapper = $('.store-map-hours');
		var timeTable = $('[data-js="store-hours-timetable"]');
		$(this).closest(hoursWrapper).find(timeTable).toggleClass('visible');
	});

	initialized = true;
}

/**
 * @description Attempts to access the geolocation object from browser and to return the coordinates inside a promise object
 * @returns promise
 */
function getLocationCoordinates() {
	var coords = {};

	return new Promise(function (resolve, reject) {
		navigator.geolocation.getCurrentPosition(
			function (position) {
				coords.latitude = position.coords.latitude;
				coords.longitude = position.coords.longitude;
				resolve(coords);
			},
			function() {
				reject();
			}
		);
	});
}

/**
 * @description Shows the store locator feature in a dialog. It has two modes: store availability and store selection
 *
 * @param {url} String can be `Urls.storeAvailability`, or `Urls.storeSelection`
 * @param {dialogClass} String can be `openStoreLocator` or `store-selection`
 */
function openStoreLocator(url, dialogClass, previousSearch) {
	var scrollSave = $(window).scrollTop();
	var dialogParams = {
		url: url,
		callback: function() {
			$('body, html').css('overflow', 'hidden');

			if (previousSearch) {
				$('input#pickup-store-input').val(previousSearch);
			}
		},
		options: {
			dialogClass: dialogClass,
			beforeClose: function() {
				if (dialog.closeFunction) {
					dialog.closeFunction();
					return false;
				}
				$('body, html').css('overflow', '');
				$(window).scrollTop(scrollSave);
				triggerGACloseDialog(dialogClass);
			}
		}
	};

	detailsmodaldialog.open(dialogParams);
}

function triggerGACloseDialog (dialogClass) {
	var selectedStore = $('.ui-dialog.' + dialogClass + '').find('.store-availability-item.is-selected');
	var storeId = null;
	if (selectedStore.length > 0) {
		storeId = selectedStore.data('storeid');
	}
	$(document).trigger('closeStoreDialog', storeId);
}

function openStoreLocatorInline() {
	var previousSearch = util.readCookie('previous-store-search');
	if (previousSearch === null || previousSearch === undefined || previousSearch === '') {
		openSearchStoreTemplateInline();
	}
	else {
		getGeolocationAndPerformSearch(previousSearch);
	}
}

function openSearchStoreTemplateInline() {
	progress.show();
	ajax.load({
		url: Urls.storeSelection,
		callback: function (response) {
			if (response) {
				$('.js-checkout-delivery-details').replaceWith($(response).find('.js-checkout-delivery-details'));
			}
			progress.hide();
		}
	});
}

function getGeolocationAndPerformSearch(searchString, callFromDialog) {
	if (searchString == null) {
		return;
	}

	var currentCountry = $('#js-currentcountry').val();
	var search = searchString;
	// Gets lat and long form google maps api
	var url = 'https://maps.googleapis.com/maps/api/geocode/json';

	var searchData = {
		address: search,
		components: `country:${currentCountry}`,
		key: SitePreferences.GOOGLEMAP_APIKEY
	};

	// For Australia some queries don't return results if customer enters just valid postal code (i.e.: 2000)
	// Since it works properly for other countries we will restrict this modified search on AU only
	if (currentCountry === 'AU') {
		searchData.address = util.modifyPostalCodeSearch(searchString, currentCountry);
	}

	// clear store results
	$('[data-js="store-availability-results"]').remove();

	ajax.getJson({
		url: url,
		data: searchData,
		callback: function (response) {
			var storeAvailabilityFeedbackSelector = callFromDialog ? '#dialog-container .store-availability-feedback'
				: '#main-content .store-availability-feedback';
			if (!response) {
				$(storeAvailabilityFeedbackSelector).html('<span>' + Resources.STOREAVAILABILITY_NOT_FOUND + '</span>');
				return;
			}

			var result = response.results[0];

			if (!result) {
				$(storeAvailabilityFeedbackSelector).html('<span>' + Resources.STOREAVAILABILITY_NOT_FOUND + '</span>');
				return;
			}

			var location = result.geometry.location;
			var data = {
				searchString: search, //result.formatted_address,
				latitude: location.lat,
				longitude: location.lng
			};

			if (storeLocatorType === 'availability') {
				data.productId = $('#product-top-info').attr('data-variant-id');
			}
			// save search string in session cookie
			document.cookie = 'previous-store-search=' + searchString + '; path=/';
			getSearchResults(data);
		}
	});
}

/**
 * Operations after user clicked on Show In Store option
 */
function filterInStock(isChecked, isNearMe, searchString) {
	if (isChecked) {
		$('.js-store-results-list .js-store-not-available').addClass('u-hidden');
		$('.js-store-results-list .js-store-availability-item:visible:first').addClass('first-child');
		showOnlyInStockChecked = true;
	}
	else {
		$('.js-store-results-list .js-store-not-available').removeClass('u-hidden');
		showOnlyInStockChecked = false;
	}

	$('.js-store-results-list').scrollTop(0);

	var numberOfShownStores = $('.js-store-results-list [data-js="store-availability-item"]:visible').length;

	$('.js-store-results-list').removeClass('no-results');
	$('.js-not-in-stock').addClass('u-hidden');
	$('[data-js="store-availability-results"]').removeClass('not-in-stock-store');

	var text;
	if (numberOfShownStores === 0) {
		$('.js-store-results-list').addClass('no-results');
		$('.js-not-in-stock').removeClass('u-hidden');
		$('[data-js="store-availability-results"]').addClass('not-in-stock-store');
	}
	else if (isNearMe) {
		text = isChecked ? Resources.STOREAVAILABILITY_FOUND_NEAR_YOU_INSTOCK : Resources.STOREAVAILABILITY_FOUND_NEAR_YOU;
		$('[data-js="store-results-message"]').text(text.replace('{0}', numberOfShownStores));
	}
	else {
		text = isChecked ? Resources.STOREAVAILABILITY_FOUND_INSTOCK : Resources.STOREAVAILABILITY_FOUND;
		$('[data-js="store-results-message"]').text(text.replace('{0}', numberOfShownStores).replace('{1}', searchString));
	}

	map.updateMarkers(isChecked);
}

/**
 * @description Sends ajax request to sever which will triger service call for store availability
 * @param {Object} data
 */
function getSearchResults(data) {
	data.isClickAndReserveSelected = $('.click-and-reserve.is-selected').length > 0 || $('.click-and-reserve.js-is-selected').length > 0;
	var currentPageUrl = $('#js-rawurl').val();
	data.currentPageUrl = currentPageUrl;
	progress.show();

	var isNearMe = !data.searchString;
	if (storeLocatorType === 'availability') {
		if (data.productId) {
			var isReserveEnabled = $('[data-js="select-store-wrapper"]').data('pdp-reservation-enabled');
			var url = isReserveEnabled ? Urls.reservationSearchStores : Urls.productAvailability;
			ajax.load({
				url: url,
				data: data,
				callback: function (response) {
					progress.hide();

					if (response) {
						$('#dialog-container').html(response);

						$('[data-js="in-stock-filter"]').off('click').on('click', function() {
							var isChecked = $(this).is(':checked');
							filterInStock(isChecked, isNearMe, data.searchString);
						});

						if (showOnlyInStockChecked) {
							$('[data-js="in-stock-filter"]').trigger('click');
						}

						map.init(isReserveEnabled ? constants.storeAvailabilityCheckType.PDP_RESERVATION
							: constants.storeAvailabilityCheckType.STOCK);
						$(document).trigger('searchStores', isNearMe);
					}
					else {
						$('.store-availability-feedback').html('<div class="error"><span>' + Resources.STOREAVAILABILITY_ERROR + '</span></div>');
					}
					if (isMobile) {
						$('[data-js="js-list-view-button"]').trigger('click');
					}

					$('input#pickup-store-input').val(data.searchString);
				}
			});
		}
	}
	else {
		ajax.load({
			url: Urls.storeSearch,
			data: data,
			callback: function (response) {
				progress.hide();
				$('.store-availability-feedback').html('');
				var openInDialog = !isMobile;

				if (!response) {
					var storeAvailabilityFeedbackSelector = openInDialog ? '#dialog-container  .store-availability-feedback' : '#main-container  .store-availability-feedback';
					$(storeAvailabilityFeedbackSelector).html('<div class="error"><span>' + Resources.STOREAVAILABILITY_ERROR + '</span></div>');
					return;
				}

				if (openInDialog) {
					var dialogClass = 'store-dialog';
					detailsmodaldialog.open({
						html: response,
						options: {
							dialogClass: dialogClass,
							beforeClose: function() {
								if (dialog.closeFunction) {
									dialog.closeFunction();
									return false;
								}
								triggerGACloseDialog(dialogClass);
							}
						}
					});
				}
				else {
					$('.js-store-selector-wrapper').html(response);
				}

				$(document).trigger('searchStores', isNearMe);
			}
		});
	}
}

exports.init = init;
exports.openStoreLocator = openStoreLocator;
