'use strict';

/**
 * Checks the page for an element with a dedicated id.
 * If it exists then gets the recommendations html via an AJAX call and replaces the html of that element with retrieved html.
 **/
exports.init = function() {
	var recommendationsContainer = $('[id^=js-inline-einstein-recommendations-]');

	if (recommendationsContainer.length === 0) {
		return;
	}

	recommendationsContainer.each(function() {
		var recommendationId = $(this).attr('id').replace('js-inline-einstein-recommendations-', '');

		getRecommendation(recommendationId, $(this));
	});
};

/**
 * Gets the html of the recommendation and assigns to the wrapper.
 * @param {integer} recommendationId Recommendation id to get html for
 * @param {html div} element Html element to put html in
 */
function getRecommendation(recommendationId, element) {
	var data = {
		sid: recommendationId
	};

	$.ajax({
		data: data,
		dataType: 'html',
		url: Urls.getRecommendations,
	}).done(function (response) {
		if (response) {
			element.html(response);
		}
	});
}
