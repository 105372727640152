var progress = require('../../progress'),
	util = require('../../util'),
	dav = require('./deliveryaddressverification');

var shippingMethodSelector = '.js-shipping-method-option.is-selected',
	isPickupInStoreSelectedSelector = '.pickup-store.is-selected',
	isClickAndReserveSelectedSelector = '.click-and-reserve.is-selected',
	isPickupPointDeliverySelectedSelector = '.pickup-point.is-selected',
	useReadOnlyShippingMethodSelector = false;

/**
 * In case that delivery address validation (DAV) is enabled instead of placing order sends ajax call with
 * shipping address for verification on BE
 * @param {Object} e
 */
function performCheckout(e) {
	e.preventDefault();

	var isDavEnabled = $('#js-dav-enabled').val() === 'true';
	var isPickupInStoreSelected = $('.pickup-store.is-selected').length == 1 || $('.pickup-store.js-is-selected').length == 1;
	var isClickAndReserveSelected = $('.click-and-reserve.is-selected').length > 0 || $('.click-and-reserve.js-is-selected').length > 0;
	var isPickupPointDeliverySelected = $('.js-pickuppoint-section').length > 0;

	progress.show();

	if (!isDavEnabled || isPickupInStoreSelected || isPickupPointDeliverySelected || isClickAndReserveSelected) {
		//in case DAV is not enabled or we are on pickup point or pickup in store shipping method we need to skip address verification place order
		placeOrder();
		return;
	}

	var $checkoutForm = $('#dwfrm_checkout');
	var data = $checkoutForm.find("[name*='shipping_address']").serializeArray();

	dav.davCheck(data, placeOrder);
}

/**
 * Prepare the data for productLineItems, order totals etc. for the current flow
 */
function prepareDataForCurrentTotals() {
	var currentPLIs = [];
	var currentPLIQty = 0;
	var $giftWrappingInput = $('[id="gift-wrapping-checkbox"]');
	$('[data-js="order-product"]').each(function () {
		currentPLIs.push({
			productId: $(this).data('variant-id'),
			qty: $(this).data('quantity')
		});
		currentPLIQty += parseInt($(this).data('quantity'));
	});
	if ($giftWrappingInput.length > 0) {
		var giftWrappingChecked = $giftWrappingInput.is(':checked');
		if (giftWrappingChecked) {
			currentPLIs.push({
				productId: Constants.GIFT_WRAPPING_PRODUCT_ID,
				qty: 1
			});
			currentPLIQty += 1;
		}
	}
	util.addHiddenInputFieldToForm('dwfrm_checkout', 'currentPLIs', JSON.stringify(currentPLIs));
	util.addHiddenInputFieldToForm('dwfrm_checkout', 'totalPLIQty', currentPLIQty);
	var currentTax = $('#currentTax').val();
	if (currentTax) {
		util.removeHiddenInputFieldFromForm('dwfrm_checkout', 'currentTax');
		util.addHiddenInputFieldToForm('dwfrm_checkout', 'currentTax', currentTax);
	}
	var currentTotalDiscount = $('#currentTotalDiscount').val();
	if (currentTotalDiscount) {
		util.removeHiddenInputFieldFromForm('dwfrm_checkout', 'currentTotalDiscount');
		util.addHiddenInputFieldToForm('dwfrm_checkout', 'currentTotalDiscount', currentTotalDiscount);
	}
	var currentOrderSubTotal = $('#currentOrderSubTotal').val();
	if (currentOrderSubTotal) {
		util.removeHiddenInputFieldFromForm('dwfrm_checkout', 'currentOrderSubTotal');
		util.addHiddenInputFieldToForm('dwfrm_checkout', 'currentOrderSubTotal', currentOrderSubTotal);
	}
	var currentOrderTotal = $('#currentOrderTotal').val();
	if (currentOrderTotal) {
		util.removeHiddenInputFieldFromForm('dwfrm_checkout', 'currentOrderTotal');
		util.addHiddenInputFieldToForm('dwfrm_checkout', 'currentOrderTotal', currentOrderTotal);
	}
}

/**
 * Prepare form data based on the shipping method that is selected
 */
function prepareDataForSelectedShippingMethod() {
	var isPickupInStoreSelected = $(isPickupInStoreSelectedSelector).length > 0;
	var isClickAndReserveSelected = $(isClickAndReserveSelectedSelector).length > 0;
	var isPickupPointDeliverySelected = $(isPickupPointDeliverySelectedSelector).length > 0;

	if (isPickupInStoreSelected) {
		if (!useReadOnlyShippingMethodSelector) {
			shippingMethodSelector = '.pickup-store.is-selected, .pickup-store.js-is-selected';
		}

		var storeIdField = $('[data-js="storeid"]');
		if (storeIdField.length) {
			util.addHiddenInputFieldToForm('dwfrm_checkout', 'selectedStoreID', storeIdField.data('storeid'));
		}
	}

	if (isClickAndReserveSelected) {
		if (!useReadOnlyShippingMethodSelector) {
			shippingMethodSelector = '.click-and-reserve.is-selected, .click-and-reserve.js-is-selected';
		}

		var storeIdFieldCR = $('[data-js="storeid"]');
		if (storeIdFieldCR.length) {
			util.addHiddenInputFieldToForm('dwfrm_checkout', 'selectedStoreID', storeIdFieldCR.data('storeid'));
		}
	}

	if (isPickupPointDeliverySelected) {
		if (!useReadOnlyShippingMethodSelector) {
			shippingMethodSelector = '.pickup-point-provider.is-selected, .pickup-point-provider.js-is-selected';
		}

		var pickupPoint = $('#pickuppoint-data');
		util.addHiddenInputFieldToForm('dwfrm_checkout', 'dwfrm_checkout_shipping_address_address1', pickupPoint.data('address'));
		util.addHiddenInputFieldToForm('dwfrm_checkout', 'dwfrm_checkout_shipping_address_address2', pickupPoint.data('officename'));
		util.addHiddenInputFieldToForm('dwfrm_checkout', 'dwfrm_checkout_shipping_address_city', pickupPoint.data('city'));
		util.addHiddenInputFieldToForm('dwfrm_checkout', 'dwfrm_checkout_shipping_address_companyName', pickupPoint.data('officename'));
		util.addHiddenInputFieldToForm('dwfrm_checkout', 'dwfrm_checkout_shipping_address_stateCode', pickupPoint.data('state'));
		util.addHiddenInputFieldToForm('dwfrm_checkout', 'dwfrm_checkout_shipping_address_postalCode', pickupPoint.data('postalcode'));
		util.addHiddenInputFieldToForm('dwfrm_checkout', 'dwfrm_checkout_shipping_address_countryCode', pickupPoint.data('countrycode'));
		util.addHiddenInputFieldToForm('dwfrm_checkout', 'pickupPointID', pickupPoint.data('officeid'));
	}

	// setup the selected shipping method in form
	var shippingMethodId = $(shippingMethodSelector).data('shippingmethodid');
	$('#currentShippingMethodID').val(shippingMethodId);
}

/**
 * Places order by submitting checkout form
 */
function placeOrder() {
	prepareFormSubmit();

	$('#dwfrm_checkout').submit();
	$('button.js-checkout-btn').attr('disabled', 'disabled');
}

function prepareFormSubmit() {
	progress.show();
	trimCardNumber();

	prepareDataForSelectedShippingMethod();
	prepareDataForCurrentTotals();
	$('.js-checkout-payment').trigger('trackSaveDetails');
}

function trimCardNumber() {
	var cardNumberField = $('#dwfrm_checkout_payment_creditCard_number');
	if (cardNumberField.length && cardNumberField.attr('data-is-masked') !== 'true') {
		var cardNumber = cardNumberField.val().replace(/\s/g, '');
		cardNumberField.val(cardNumber);
	}
}

function init() {
	shippingMethodSelector = '.readonly-delivery-title';
	useReadOnlyShippingMethodSelector = true;
	isPickupInStoreSelectedSelector = '.readonly-delivery-info.pickup-in-store-selected';
	isClickAndReserveSelectedSelector = '.readonly-delivery-info.click-and-reserve-selected';
	isPickupPointDeliverySelectedSelector = '.readonly-delivery-info.pickup-point-selected';
}

exports.init = function() {
	// Removing all click events attached to checkout button and reattaching new event
	$('button.js-checkout-btn').off('click').on('click', performCheckout);
};

exports.placeOrder = placeOrder;
exports.prepareFormSubmit = prepareFormSubmit;
exports.init = init;