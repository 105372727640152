'use strict';

var dialog = require('../dialog');

/**
 * Add a click event on all links with class js-content-modal
 *
 * If the link have an attribute data-modal then we search for an element with the same id like the value of the attribute
 * We load this element in the popup
 *
 * Otherwise we take the value of the href and load the page in the popup
 **/
exports.init = function () {
	$(document).off('click', '.js-content-modal').on('click', '.js-content-modal', openPopup);
};

var openPopup = function(e) {
	e.preventDefault();

	var $this = $(this);
	var hasExpandedAttr = $this[0].hasAttribute('aria-expanded');

	var config = {
		options: {
			dialogClass: 'service-dialog',
			open: function() {
				//Toggle data-expanded
				if (hasExpandedAttr) {
					$this.attr('aria-expanded', true);
				}

				var openDialog = $(this);
				$(this).closest('.dialog-wrap').find('.ui-dialog-titlebar-close').attr('aria-label', Resources.CLOSE);
				dialog.bindOverlayClose(openDialog);

				$('html').addClass('dialog-is-open');
				dialog.setMouseUpOnAnchor();

				openDialog.on('keydown', function(e) {
					if (e.which === keyboard.TAB_KEY) {
						$(this).trigger('focus');
					}
				});
			},
			close: function () {
				//Toggle data-expanded
				if (hasExpandedAttr) {
					$this.attr('aria-expanded', false);
				}

				$(this).dialog('close');
				$(this).empty();
				$(this).dialog('destroy').remove();
				// we remove the class only when all dialogs are closed
				if (!$('.ui-dialog').is(':visible')) {
					$('html').removeClass('dialog-is-open');
				}
			}
		}
	};

	if ($this.attr('data-modal')) {
		var modalContentWrapper = $this.attr('data-modal');
		config.html = $('#' + modalContentWrapper).html();
	}
	else {
		config.url = $this.attr('href');
		config.callback = function() {
			// service pages might contain links which cause a bug in jquery dialogs
			// https://stackoverflow.com/questions/19038346/jquery-dialog-box-auto-scrolling-to-bottom
			// fortunatelly we have controll via the rendering template
			$('.js-service-page-block').scrollTop(0);
		};
	}

	dialog.open(config);
};
