'use strict';


var quickview = require('./quickview'),
	wishlist = require('./pages/wishlist'),
	yotpoReviews = require('./yotpoReviews'),
	video = require('./video'),
	qtyUpdate = require('./quantitySelector'),
	util = require('./util');

const JS_CLASS_BADGE = 'js-badge';
const JS_CLASS_DISCOUNT_BADGE = 'js-discount-badge';

function initQuickViewButtons() {
	$('body').off('click.quickview', '.js-quickviewbutton').on('click.quickview', '.js-quickviewbutton', function(e) {
		if (util.isMobile() || util.isTabletAndBelow()) {
			//on mobile and smaller screens we don't show quick shop view
			return;
		}

		e.preventDefault();
		quickview.show({
			url: $(this).attr('href'),
			source: 'quickview',
			origin: $(this).closest('.product-tile'),
			callback: function() {
				yotpoReviews.refreshWidgets();
				video.initWistia();
			}
		});
	});

	// open modal dialog for editing product line item on cart page
	$('body').off('click', '[data-js="product-edit"]').on('click', '[data-js="product-edit"]', function(e) {
		e.preventDefault();
		var url = $(this).attr('href');
		var $lineItemContainer = $(this).closest('.js-cart-row');
		var selectedSize = $lineItemContainer.data('size');
		var stockLevel = $lineItemContainer.data('stock-level');

		// if variant is not available then size is not selected and in that case we want to open edit dialog for variation group
		if (!selectedSize || stockLevel === 'no_stock') {
			url = url.replace($lineItemContainer.data('variant-id'), $lineItemContainer.data('variantgroup-id'));
		}

		url = util.appendParamToURL(url, 'lineItemID', $lineItemContainer.data('uuid'));
		url = util.appendParamToURL(url, 'currentQuantity', $lineItemContainer.data('quantity'));

		quickview.show({
			url: url,
			source: 'editproduct',
			callback: function() {
				yotpoReviews.refreshWidgets();
				video.initWistia();
				qtyUpdate.updateQty();
			}
		});

		util.rememberScrollPosition('cart-edit-product-popup');
	});
}

function showBadge(element, text, isSoldOutBadge, isDiscountBadge) {
	if (!element || element.length === 0) {
		return;
	}

	element[0].className = isDiscountBadge ? 'js-discount-badge badge' : 'js-badge badge';

	if (!!text) {
		element
			.append(`<span class="badge-title">${text}</span>`)
			.show();
	}

	// show sold-out badge
	var tile = element.closest('.js-sold-out-badge');
	if (isSoldOutBadge) {
		element.find(':first-child').addClass('u-hidden');

		if (tile.find('.sold-out-badge').length < 1) {
			tile.find('.product-image > .thumb-link').prepend('<div class="sold-out-badge">' + text + '</div>');
		}
	}
	else {
		element.find(':first-child').removeClass('u-hidden');
	}
}

function hideBadge($element, jsBadgeClassName) {
	if (!$element || $element.length === 0) {
		return;
	}

	$element[0].className = `${jsBadgeClassName} badge`;
	$element.empty().hide();
}

function hideSoldOutBadge($element) {
	var $tile = $element.closest('.js-sold-out-badge');
	$tile.find('.sold-out-badge').remove();
}

/**
 * Being called when focus is out or mouse leaves the color swatches and sizes block on a product tile at PLP
 */
function colorSwatchBlockOutEvent(element) {
	var focusedChildElement = $(element).find('.focus-visible');

	// since this is actually not a focusable element (div) then it is being fired everytime any child element of is focused out
	// so if the focus is still on another child element then it means the wrapping div that we trigger the event for is still focused
	if (focusedChildElement.length) {
		return;
	}

	// Restore current thumb image and price
	var $tile = $(element).closest('.product-tile'),
		$thumb = $tile.find('.product-image .thumb-link img').eq(0),
		$price = $tile.find('.js-price-container'),
		$badge = $tile.find('.' + JS_CLASS_BADGE),
		$discountBadge = $tile.find('.' + JS_CLASS_DISCOUNT_BADGE),
		data = $thumb.data('current'),
		badgeData = $badge.data('current'),
		discountBadgeData = $discountBadge.data('current'),
		defaultPrice = $(element).find('li img:first').data('defaultprice'),
		$defaultSizesWrapper = $tile.find('.js-default-sizes');

	if (data !== undefined) {
		$thumb.attr({
			src: data.src,
			alt: data.alt,
			title: data.title
		});
	}
	$price.html(defaultPrice);

	hideBadge($badge, JS_CLASS_BADGE);
	hideBadge($discountBadge, JS_CLASS_DISCOUNT_BADGE);
	hideSoldOutBadge($badge);

	if (badgeData !== '' && !!badgeData.text) {
		showBadge($badge, badgeData.text, badgeData.isSoldOutBadge, false);
	}

	if (discountBadgeData !== '') {
		showBadge($discountBadge, discountBadgeData, false, true);
	}

	// show the size options of default color swatch
	$tile.find('[data-js="product-sizes"]').addClass('u-hidden');
	$defaultSizesWrapper.removeClass('u-hidden');
}

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {
	initQuickViewButtons();
	wishlist.init();
	yotpoReviews.refreshWidgets();
	$('.js-product-color').on('mouseleave focusout', function () {
		var element = this;
		setTimeout(function() { colorSwatchBlockOutEvent(element); }, 300);
	});

	$('.swatch-list .swatch').on('mouseenter focusin', function () {
		// get current thumb and price details
		var $tile = $(this).closest('.product-tile'),
			$thumb = $tile.find('.product-image .thumb-link img').eq(0),
			$price = $tile.find('.js-price-container'),
			$badge = $tile.find('.' + JS_CLASS_BADGE),
			$discountBadge = $tile.find('.' + JS_CLASS_DISCOUNT_BADGE),
			$swatch = $(this).children('img').filter(':first'),
			thumbData = $swatch.data('thumb'),
			priceData = $swatch.data('price'),
			badgeData = $swatch.data('badge'),
			discountBadgeData = $swatch.data('discountbadge'),
			current = $thumb.data('current'),
			colorValue = $swatch.data('color'),
			$sizesWrapper = $tile.find('.js-product-sizes-' + colorValue);

		// If this is the first time, then record the current img
		if (!current) {
			$thumb.data('current', {
				src: $thumb[0].src,
				alt: $thumb[0].alt,
				title: $thumb[0].title
			});
		}

		// Set the tile image and price to the values provided on the swatch data attributes
		$thumb.attr({
			src: thumbData.src,
			alt: thumbData.alt,
			title: thumbData.title
		});
		$price.html(priceData);

		hideBadge($badge, JS_CLASS_BADGE);
		hideBadge($discountBadge, JS_CLASS_DISCOUNT_BADGE);
		hideSoldOutBadge($badge);

		if (badgeData && !!badgeData.text) {
			showBadge($badge, badgeData.text, badgeData.isSoldOutBadge, false);
		}

		if (!!discountBadgeData) {
			showBadge($discountBadge, discountBadgeData, false, true);
		}

		// show the size options of currently focused/hovered color swatch
		$tile.find('[data-js="product-sizes"]').addClass('u-hidden');
		$sizesWrapper.removeClass('u-hidden');
	});

	$('[data-js="product-image-second"] > img').one('load', function() {
		var $this = $(this);
		$this.parent().parent().addClass('second-image-loaded');
	});

	// update browser history when redirecting from PLP to PDP so customer can return to same part of PLP
	$('[data-js="product-detail-page-link"]').one('click', function(e) {
		e.preventDefault();
		var $this = $(this);
		var currentChunk = $this.closest('.js-search-result-articles');
		var currentProductTile = $this.closest('[data-js="product-tile"]');

		// get ID of clicked product
		var productId = currentProductTile.data('variantgroup-id');

		if (currentChunk.length === 1) {
			// get url of the chunk to be stored in browser history
			var updatedUrl = currentProductTile.parent().data('current-product-chunk');
			var state = history.state ? history.state : {};

			// store product ID in histroy state
			// ID will be used to scroll back to clicked product
			state.productId = productId;
			// override browser history with the current data
			history.replaceState(state, '', updatedUrl);
		}

		// redirect to PLP
		window.location = $this.attr('href');
	});
}

/**
 * Updates the aria label of each product tile on the page or on part of the page
 * @param {Object} productBlock part of the page with product tiles
 */
function updateProductTilesAriaLabel(productBlock) {
	var productTilesSelector = '.js-recommendations a.thumb-link, .js-search-result-articles > li';
	var productTiles = $(productTilesSelector);


	if (productBlock) {
		productTiles = productBlock.find('a.thumb-link');
	}

	if (!productTiles.length) {
		return;
	}

	productTiles.each(function() {
		var tile = $(this);
		var isLabelUpdated = tile.data('labelupdated');

		if (isLabelUpdated) {
			return;
		}

		var label = tile.attr('aria-label');
		var salePriceSelector = '.js-price-container > .price-standard';
		var discountPriceSelector = '.js-price-container > .price-discount';

		//on plp page we use page tille but on other pages tile is wrapped
		var tileWrapper = $('.pt_product-search-result').length ? tile : tile.closest('.js-product-tile');

		label += getPriceText(tileWrapper, salePriceSelector);
		label += getPriceText(tileWrapper, discountPriceSelector);

		tile.attr('aria-label', label);
		tile.data('labelupdated', true);
	});
}
/**
 * Updates the aria label of each swatch on the product tile
 */
function updateSwatchLabel() {
	var swatchSelector = 'li > a.swatch';
	var productSwatch = $(swatchSelector);

	productSwatch.each(function() {
		var tile = $(this);

		var label = tile.attr('aria-label');
		var swatchSelector = '.swatch-image';

		var tileWrapper = $('.pt_product-search-result').length ? tile : tile.closest('.js-product-tile');

		label = prepareSwatchAriaLabel(tileWrapper, swatchSelector);

		tile.attr('aria-label', label);
	});
}
/**
 * Reads and prepares prices from product tile
 * @param {Object} tileElement
 * @param {String} priceSelector
 * @returns {String}
 */
function getPriceText(tileElement, priceSelector) {
	if (!tileElement.length) {
		return '';
	}

	var priceElement = tileElement.find(priceSelector);

	if (!priceElement.length) {
		return '';
	}

	return ' ' + priceElement.attr('title').trim() + ' ' + priceElement.find('span').html().trim();
}
/**
 * Reads and prepares data for swatch aria-label
 * @param {Object} tileElement
 * @param {String} priceSelector
 * @returns {String}
 */
function prepareSwatchAriaLabel(tileElement, priceSelector) {
	if (!tileElement.length) {
		return '';
	}

	var swatchElement = tileElement.find(priceSelector);

	if (!swatchElement.length) {
		return '';
	}

	var price = '';

	var priceString = '<div>' + swatchElement.data('price').trim() + '</div>';

	var priceHTML = $(priceString);

	var salePriceSelector = '.price-standard';
	var discountPriceSelector = '.price-discount';

	price += getPriceText(priceHTML, salePriceSelector);
	price += getPriceText(priceHTML, discountPriceSelector);

	var badge = '';
	var dataBadge = swatchElement.data('badge');
	if (dataBadge) {
		if (dataBadge.text != null) {
			badge = dataBadge.text;
		}
	}
	var color = swatchElement.attr('alt').trim();

	return price + ' ' + badge + ' ' + color;
}

/**
 * Updates product urls (both tile/image url and quick shop btn url) with campaign type
 * on product tiles (highlighted product, pd component products)
 * @param {Object} container
 */
function updateProductUrls(container) {
	if (!container) {
		container = $('body');
	}

	var productImages = container.find('.highlighted-products-detail .thumb-link, .pd-product-tile > a, .js-quickviewbutton');

	productImages.each(function(e) {
		var url = $(this).attr('href');
		$(this).attr('href', url);
	});
}
function removeYotpoStars() {
	 if (typeof yotpo === 'undefined') {
	 	return;
	}

	yotpo.onAllWidgetsReady(function() {
		var plpYotpoWrapper = $('.plp-yotpo-wrapper');
		plpYotpoWrapper.each(function (e) {
			var stars = $(this).find('.yotpo-stars');
			if (stars.length == 0) {
				$(this).remove();
			}
		});
	  });
}

exports.init = function () {
	initializeEvents();
	updateProductTilesAriaLabel();
	updateProductUrls();
	updateSwatchLabel();

	$(document).on('recommendationsloaded', 'div.js-recommendations', function() {
		updateProductTilesAriaLabel($(this));
		updateProductUrls($(this));
	});
	removeYotpoStars();
};
