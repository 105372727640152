'use strict';

var util = require('./util');

var currentQuery = null,
	lastQuery = null,
	runningQuery = null,
	listTotal = -1,
	listCurrent = -1,
	delay = 30,
	$resultsContainer,
	$resultsDisplayContainer,
	$searchPresets,
	$suggestedPhraseItem;
/**
 * @function
 * @description Handles keyboard's arrow keys
 * @param keyCode Code of an arrow key to be handled
 */
function handleArrowKeys(keyCode) {
	switch (keyCode) {
		case 38:
			// keyUp
			listCurrent = (listCurrent <= 0) ? (listTotal - 1) : (listCurrent - 1);
			break;
		case 40:
			// keyDown
			listCurrent = (listCurrent >= listTotal - 1) ? 0 : listCurrent + 1;
			break;
		default:
			// reset
			listCurrent = -1;
			return false;
	}

	$resultsContainer.children().removeClass('selected').eq(listCurrent).addClass('selected');
	$('input[name="q"]').val($resultsContainer.find('.selected .suggestionterm').first().text());
	return true;
}

var searchsuggest = {
	/**
     * @function
     * @description Configures parameters and required object instances
     */
	init: function (container, defaultValue) {
		var $searchContainer = $(container);
		var $searchForm = $searchContainer.find('form[name="simpleSearch"]');
		var $searchField = $searchForm.find('input[name="q"]');

		$resultsDisplayContainer = $('.js-search-wrapper');
		$searchPresets = $('[data-js="search-presets"]');
		$suggestedPhraseItem = $('[data-js="suggested-phrase"]');

		// disable browser auto complete
		$searchField.attr('autocomplete', 'off');

		// on focus listener (clear default value)
		$searchField.on('focus', function () {
			if (!$resultsContainer) {
				// create results container if needed
				$resultsContainer = $('<div/>').attr({
					id: 'search-suggestions',
					class: 'c-header-search-suggestions'
				}).appendTo($resultsDisplayContainer);
			}
			if ($searchField.val() === defaultValue) {
				$searchField.val('');
			}
		});

		$suggestedPhraseItem.off('click').on('click', function () {
			$searchField.val($(this).text());
			$searchForm.submit();
		});

		$(document).on('click', function (e) {
			if (!$searchContainer.is(e.target)) {
				// setTimeout(this.clearResults, 200);
			}
		}.bind(this));

		$searchField.on('clearall', function() {
			this.clearResults();
			this.defaultView();
		}.bind(this));

		// on key up listener
		$searchField.on('keyup', function (e) {
			// get keyCode (window.event is for IE)
			var keyCode = e.keyCode || window.event.keyCode;

			// check and treat up and down arrows
			if (handleArrowKeys(keyCode)) {
				return;
			}
			// check for ESC
			if (keyCode === 27) {
				this.clearResults();
				this.defaultView();
				return;
			}

			// check for an ENTER key
			// do nothing on enter: the event will propagate and the form will be submitted
			if (keyCode === 13) {
				return;
			}

			currentQuery = $searchField.val().trim();

			// no query currently running, init an update
			if (!runningQuery) {
				runningQuery = currentQuery;
				setTimeout(this.suggest.bind(this), delay);
			}
		}.bind(this));
	},

	defaultView: function() {
		$resultsContainer.empty();
		$resultsContainer.hide();
		$searchPresets.fadeIn(200);
	},

	/**
     * @function
     * @description trigger suggest action
     */
	suggest: function () {
		// check whether query to execute (runningQuery) is still up to date and had not changed in the meanwhile
		// (we had a little delay)
		if (runningQuery !== currentQuery) {
			// update running query to the most recent search phrase
			runningQuery = currentQuery;
		}

		// if it's empty clear the results box and return
		if (runningQuery.length === 0) {
			this.clearResults(0);
			runningQuery = null;
			this.defaultView();

			return;
		}

		// if the current search phrase is the same as for the last suggestion call, just return
		if (lastQuery === runningQuery) {
			runningQuery = null;
			return;
		}

		// build the request url
		var reqUrl = util.appendParamToURL(Urls.searchsuggest, 'q', runningQuery);

		// execute server call
		$.get(reqUrl, function (data) {
			var suggestionHTML = data,
				ansLength = suggestionHTML.trim().length;

			// if there are results populate the results div
			if (ansLength === 0) {
				this.clearResults(0);
				$searchPresets.fadeIn(400);
			}
			else {
				// update the results div
				$searchPresets.hide();
				$resultsContainer.html(suggestionHTML).fadeIn(200);
				$(document).trigger('showSearchSuggestions');

				//hide 5th popular product in order to fit content results
				$('[data-js="with-content"]').find('[data-js="product-highlight-tile"]:eq(4)').hide();

				// bind tracking to category suggestions click
				$('.js-search-suggestion-phrase').off('click').on('click', function() {
					var phrase = $(this).attr('data-phrase');
					var destinationUrl = $(this).attr('href');
					$(document).trigger('searchSuggestionPhraseClicked', [phrase, destinationUrl]);
				});

				// highlight the search pattern in the results
				$('.phrase-suggestions').mark(runningQuery);
			}

			// record the query that has been executed
			lastQuery = runningQuery;
			// reset currently running query
			runningQuery = null;

			// check for another required update (if current search phrase is different from just executed call)
			if (currentQuery !== lastQuery) {
				// ... and execute immediately if search has changed while this server call was in transit
				runningQuery = currentQuery;
				setTimeout(this.suggest.bind(this), delay);
			}
			this.hideLeftPanel();
		}.bind(this));
	},
	/**
     * @function
     * @description
     */
	clearResults: function (fadeDuration = 200) {
		if (!$resultsContainer) { return; }

		if (fadeDuration) {
			$resultsContainer.fadeOut(fadeDuration, function () { $resultsContainer.empty(); });
		}
		else {
			$resultsContainer.hide().empty();
		}
	},
	/**
     * @function
     * @description
     */
	hideLeftPanel: function () {
		//hide left panel if there is only a matching suggested custom phrase
		if ($('.search-suggestion-left-panel-hit').length === 1 && $('.search-phrase-suggestion a').text().replace(/(^[\s]+|[\s]+$)/g, '').toUpperCase() === $('.search-suggestion-left-panel-hit a').text().toUpperCase()) {
			$('.search-suggestion-left-panel').css('display', 'none');
			$('.search-suggestion-wrapper-full').addClass('search-suggestion-wrapper');
			$('.search-suggestion-wrapper').removeClass('search-suggestion-wrapper-full');
		}
	}
};

module.exports = searchsuggest;
