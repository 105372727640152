'use strict';

/**
 * @function
 * @description  Scroll to Top
 */

function scrollToTop() {
	var scrollToTopButton = $('[data-js="scroll-top"]');
	var lastScroll = 0;
	var delta = 5; // sensitivity buffer on scroll - 5 pixels

	$(window).on('scroll', function() {
		var st = $(this).scrollTop();

		// if the scroll is smaller then the sensitivity buffer that we defined - nothing changes
		if (Math.abs(lastScroll - st) < delta) {
			return;
		}

		if (st > lastScroll || st < 250) {
			// scroll down detected or less than 250px from top of screen - hide button
			scrollToTopButton.hide();
		}
		else {
			// scroll up detected - show button
			scrollToTopButton.show();
		}
		lastScroll = st;
	});

	scrollToTopButton.off('click').on('click', function() {
		$('html, body').animate({ scrollTop: 0 }, 'slow');
		return false;
	});
}

exports.init = function () {
	scrollToTop();
};
