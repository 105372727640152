'use strict';

var forms = require('../forms');
var local = (SitePreferences.LOCALE) ? SitePreferences.LOCALE.substring(0, 2) : '';
var lastFilledForm;

function initialize(scriptURL, cssURL) {
	//If address complete service is already rendered on page we need to reload it
	//the html is rebuilt in the load method so we first need to empty the old snippet
	if ($('.pca').length > 0) {
		$('body').children('.pca').empty();
		addressComplete.load();
		return;
	}
	//early return if there are no urls deffined
	if (!scriptURL || !cssURL) { return; }
	//load css
	$('head').append('<link rel="stylesheet" type="text/css" href="' + cssURL + '">');
	//load script
	$.getScript(scriptURL).done(
		function(script, textStatus) {
			attachAdditionalEvents();
		}
	);
}

function attachAdditionalEvents() {
	addressComplete.listen('ready', function() {
		if (!local) { return; }
		addressComplete.setCulture(local);
	});

	var originalSetValueFunction = pca.setValue;
	pca.setValue = function(id, value) {
		originalSetValueFunction(id, value);

		var $inputElement = ($.type(id) === 'string') ? $('#' + id) : $(id),
			$container = $inputElement.closest('.input-container'),
			$form = $inputElement.closest('form');

		if ($form.length != 1) { return; }
		// in the 'populate' event defined below we have no reference to the form that was submitted.
		// Since we have to know which form was changed, we store the last changed form here so we can send that information.
		lastFilledForm = $inputElement.closest('fieldset');

		$container.addClass('is-filled');
		var isValid = $form.validate().element($inputElement);
		if (isValid) {
			$container.addClass('is-valid');
			forms.checkInputFields();
		}
	};

	addressComplete.listen('load', function(control) {
		control.listen("populate", function () {
			$(document).trigger('addressautocompleted', lastFilledForm);
		});
	});

	loadLocalisation();


	//this is used for address complete functionality only, the issue was https://jira.emakina.nl/browse/ECCO-4599
	//because validation is triggered on focus, same as the third party component, validation moves fields down before component is rendered (popup showed)
	//with this solution we create custom focus event where we first hide autocomplete footer, we remove this custom event after 200ms and we trigger resize event so the position of the autocomplete footer is fixed
	//this new event is attached only on init and removed after first focus on the field
	addressComplete.bindings.forEach(form => {
		form.fields.forEach(field => {
			if (field.mode != pca.fieldMode.DEFAULT) { return; }

			var $field = $('#' + field.element);

			$field.on('focus.new', function(e) {
				if (e.type == 'focus') {
					// hide the Autocomplete Footer until we fix its position
					var autocompleteFooter = $('.pcafooter').closest('.pcaautocomplete.pcatext');
					autocompleteFooter.addClass('u-hidden');

					setTimeout(function() {
						// trigger resize event which will fix the positioning of Autocomplete Footer
						var resizeEvent = window.document.createEvent('UIEvents');
						resizeEvent.initUIEvent('resize', true, false, window, 0);
						window.dispatchEvent(resizeEvent);

						$field.unbind('focus.new');

						// show the Autocomplete Footer since we fixed its position
						autocompleteFooter.removeClass('u-hidden');
					}, 200);
				}
			});
		});
	});
}

function loadLocalisation() {
	if (!local) { return; }

	pca.messages[local] = {
		DIDYOUMEAN: Resources.PCA_DIDYOUMEAN,
		NORESULTS: Resources.PCA_NORESULTS,
		KEEPTYPING: Resources.PCA_KEEPTYPING,
		RETRIEVEERROR: Resources.PCA_RETRIEVEERROR,
		SERVICEERROR: Resources.PCA_SERVICEERROR,
		COUNTRYSELECT: Resources.PCA_COUNTRYSELECT,
		NOLOCATION: Resources.PCA_NOLOCATION,
		NOCOUNTRY: Resources.PCA_NOCOUNTRY,
	};
}

exports.init = function(context) {
	if (context.toLowerCase() === 'account') {
		initialize(Urls.profileAddressCompleteScriptURL, Urls.profileAddressCompleteCSSURL);
	}
	else {
		initialize(Urls.shippingAddressCompleteScriptURL, Urls.shippingAddressCompleteCSSURL);
	}
};