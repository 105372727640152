'use strict';

var productDetail = require('./pages/product/productDetailCta');
var util = require('./util');
var accessibility = (util.isMobile()) ? require('./accessibilityForMobile') : require('./accessibility');

/**
 * @function
 * @description based on selected size (size/size-alt), select the right size selector
 */
function selectSizeSelectorRadioButton(selectedSize) {
	var mainSize = $('.size-selector-radio[data-tab="size"]');
	var altSize = $('.size-selector-radio[data-tab="size-alt"]');

	if (selectedSize === 'size') {
		mainSize.attr('aria-checked', true).addClass('selected-size');
		altSize.removeClass('selected-size');
	}
	else {
		altSize.attr('aria-checked', true).addClass('selected-size');
		mainSize.removeClass('selected-size');
	}
}

/**
 * @function
 * @description Initializes size selector
 */
function updateSelectedSizeType(activeTag) {
	if (!activeTag) {
		activeTag = $(document.body).data('sizetype') !== undefined
			? $(document.body).data('sizetype')
			: $('#defaultsizeselector').val();
	}
	$('ul.size, ul.size-alt').addClass('u-hidden');

	$('ul.' + activeTag)
		.removeClass('u-hidden')
		.addClass('selected');

	//show/hide selected size or selected alternative size
	$('.js-variation-size-selected').addClass('u-hidden');
	$('.js-variation-size-selected.' + activeTag).removeClass('u-hidden');

	//show hide size or alternative size on filtered items
	$('.js-filtered-size').addClass('u-hidden');
	$('.js-filtered-size.' + activeTag).removeClass('u-hidden');

	//in case of changing size on quickview also change size on PLP
	selectSizeSelectorRadioButton(activeTag);

	// update also for PDP sticky bar
	productDetail.updateSelectedSizeTypeForStickyBar();
}

/**
 * Updates the size selector to display the selected one for the element supplied.
 * This will make sure the size selection is correct when data is retrieved using AJAX.
 */
exports.updateSizeSelector = function (el) {
	var activeTag = $(document.body).data('sizetype') !== undefined
		? $(document.body).data('sizetype')
		: $('#defaultsizeselector').val();

	el.find('ul.size, ul.size-alt').addClass('u-hidden');

	el.find('ul.' + activeTag).removeClass('u-hidden').addClass('selected');

	//show/hide selected size or selected alternative size
	el.find('.js-variation-size-selected').addClass('u-hidden');
	el.find('.js-variation-size-selected.' + activeTag).removeClass('u-hidden');

	//show hide size or alternative size on filtered items
	el.find('.js-filtered-size').addClass('u-hidden');
	el.find('.js-filtered-size.' + activeTag).removeClass('u-hidden');

	el.find('.size-selector-radio').attr('aria-checked', false);
	el.find('.size-selector-radio[data-tab="' + activeTag + '"]').attr('aria-checked', true);

	return el;
};

exports.init = function (isQuickView) {
	var moreDetailsLink = $('.details-link').attr('href');
	var selectedSizeType = util.getQueryStringParams(window.location).sizetype;
	if (selectedSizeType) {
		$(document.body).data('sizetype', selectedSizeType);
	}
	updateSelectedSizeType();
	productDetail.init(isQuickView);

	// correct size-selector link need to be 'selected' when visitor change the selected size.
	if ($(document.body).data('sizetype') !== undefined) {
		$('.size-selector-radio').attr('aria-checked', false);
		$('.size-selector-radio[data-tab="' + $(document.body).data('sizetype') + '"]').attr('aria-checked', true);
	}

	var cookieValue = util.readCookie('_ecco_size_selection');

	// in case the the cookie exists set the size-selector by using the cookie value
	if (cookieValue) {
		$('.size-selector-radio').attr('aria-checked', false);
		selectSizeSelectorRadioButton(cookieValue);
		updateSelectedSizeType(cookieValue);
	}

	$(document).ready(function() {
		if ($('.c-size-list.size-alt').length && $('.c-size-list.size-alt').children().length === 0) {
			$('.size-selector').addClass('u-hidden');
		}

		$('.size-selector-radio').on('click', function() {
			var $this = $(this);
			var selectedTab = $this.data('tab');

			$('.size-selector-radio').attr('aria-checked', false);
			$this.attr('aria-checked', true);
			$(document.body).data('sizetype', selectedTab);
			updateSelectedSizeType(selectedTab);
			util.createCookie('_ecco_size_selection', selectedTab === 'size' ? 'size' : 'size-alt', 30);

			if (moreDetailsLink) {
				moreDetailsLink = util.removeParamFromURL(moreDetailsLink, 'sizetype');
				$('.details-link').attr('href', util.appendParamToURL(moreDetailsLink, 'sizetype', selectedTab));
			}

			var isKeyboardNav = $this.hasClass('focus-visible');
			accessibility.setKeyboardNavigation(isKeyboardNav);

			if (accessibility.isKeyBoardNavigationActive()) {
				accessibility.focusToFirstSize($this);
				var isPDP = !!$('.pt_product-detail').length;
				var isFlyout = !!$this.parents('[data-js="plp-refine-flyout"]').length;

				if (!isPDP && !isFlyout) {
					var filterContent = $this.parents('[data-js="filter-block"]').find('[data-js="filter-content"]');
					var filterToggler = $this.parents('[data-js="filter-block"]').find('[data-js="filter-toggler"]');
					accessibility.updateFilterContentAccessibility(filterToggler, filterContent);
				}
			}
		});
	});
};
