'use strict';

/**
 * @function
 * @description : Main Nav menu
 */

var util = require('./util'),
	trackingHelper = require('./tracking/trackingHelper');

module.exports = (function () {
	var events = {
		init: function () {
			var $mobileMenuNav = $('[data-js="menu-navigation"]');

			var selectedNavigation = $('.current-category').data('category-name');
			// Set active state to the current selected navigation
			if (util.isDesktopAndAbove()) {
				if (selectedNavigation) {
					$('.menu-list .js-level-1-item').each(function() {
						if ($(this).attr('href').indexOf('/' + selectedNavigation.replace(/\s+/g, '-').toLowerCase()) > -1) {
							$(this).addClass('active');
						}
					});
				}
			}

			//Sub-categories Functions to add special classes
			$('.js-nav-dropdown li').each(function () {
				var $this = $(this);

				if (!($this.hasClass('nav-dropdown-highlights')) && ($this.children('.js-nav-dropdown').length)) {
					$this.addClass('has-children');
				}
			});

			//Sub-categories Functions to add special classes
			$('.menu-list > li').each(function () {
				var $this = $(this);

				if ($this.children('.js-nav-dropdown').length) {
					$this.addClass('has-children');
				}
			});

			// Page designer submenu navigation
			$('body').off('click.nav.category').on('click.nav.category', '[data-js="category__campaign-subtitle"]', function (e) {
				if (util.isSmallScreenSize()) {
					e.preventDefault();
					var $this = $(this);
					var categoryList = $('.category__campaign-list');
					$this.toggleClass('is-open');
					categoryList.slideToggle(function () {
						if ($this.hasClass('is-open')) {
							$this.attr('aria-expanded', 'true');
						}
						else {
							$this.attr('aria-expanded', 'false');
						}
					});
				}
			});

			// Burger Menu click Events
			$('body').off('click.nav.burger').on('click.nav.burger', '.js-icon-burger', function (e, args = {}) {
				const { isCloseForDesktop } = args;

				$('.js-overlay')
					.addClass('u-pointer-events-none')
					.fadeIn();

				var $burger = $(this);
				// class attachments
				$('body').removeClass('search-is-open');
				$('body').addClass('no-scroll');

				$mobileMenuNav.css({
					'height': util.getNavDropdownMaxHeight()
				});

				// a11y
				$burger.toggleClass('active');
				$burger.attr('aria-expanded', 'true');
				$burger.parent().parent().parent().toggleClass('is-open');
				e.stopPropagation();

				// When we close the menu is missing
				if (!$burger.hasClass('active')) {
					// remove class attachments
					$burger.addClass('closing');
					$('body').removeClass('no-scroll');
					// a11y
					$burger.attr('aria-expanded', 'false');
					$mobileMenuNav
						.one('transitionend', function () {
							// clean up submenu classes
							$('.is-slide-left').removeClass('is-slide-left');
							$('.js-menu-navigation, .js-nav-dropdown').removeClass('u-visibility-hidden');
							if (isCloseForDesktop) {	// remove 'height: 0' for desktops
								$(this).css('height', '');
							}
						})
						.css('height', 0);
					$('.js-overlay').removeClass('u-pointer-events-none').fadeOut();
				}
			});
		},

		// Resize Events
		resize: function () {
			//Reset elements
			$('[data-js="nav-dropdown"]').removeAttr('style');
			//Remove mobile listener
			$('.menu-list .has-children > a').off('click');
			//Remove desktop listener
			$('.menu-navigation > .menu-links > ul > .has-children').off('mouseenter mouseleave');
			const $navWrap = $('.nav-wrap');
			// Desktop main navigation calculation
			if (util.isDesktopAndAbove() && $navWrap.length > 0) {
				$navWrap.toggleClass('is-max-width', $navWrap.get(0).clientWidth < $navWrap.get(0).scrollWidth);
			}

			document.documentElement.style.setProperty('--window-height', `${window.innerHeight}px`);

			// Remove the calculation class in case is small devices
			if (util.isTabletAndBelow() && $navWrap.length > 0) {
				$navWrap.removeClass('is-max-width');
			}

			// close mobile menu for desktop if it was opened
			const $burger = $('.js-icon-burger');
			if ($burger.hasClass('active') && util.isDesktopAndAbove()) {
				$burger.trigger('click', [{ isCloseForDesktop: true }]);
			}

			// Desktop Main menu animation
			if (util.isDesktopAndAbove()) {
				var menuList = $('[data-js="menu-list"]');
				var navContainer = $('[data-js="nav-container"]');

				menuList.on('mouseleave', function() {
					$(this).removeClass('menu-list__hover');
				});

				//if main menu category doesn't have any subcategories we need to remove menu-list__hover class on mouse enter
				//in order to have accordion effect for next main category mouse enter event
				$('.menu-navigation > .menu-links > ul > li:not(.has-children)').on('mouseenter', function() {
					menuList.removeClass('menu-list__hover');
				});

				$('.menu-navigation > .menu-links > ul > .has-children')
					.on('mouseenter', function () {
						$(this).addClass('is-open');
						$(this).find('> .js-nav-dropdown')
							.css({
								'height': `calc(80vh - ${$navWrap.get(0).getBoundingClientRect().bottom}px - 1px)`
							});
						if (menuList.hasClass('menu-list__hover')) {
							$(this).find('> .js-nav-dropdown').show();
						}
						else {
							$('.js-overlay').fadeIn();
							$(this).find('> .js-nav-dropdown').stop().slideDown(400);
						}

						$(this).children('.js-arrow-accessibility').attr('aria-expanded', 'true');

						menuList.addClass('menu-list__hover');
						navContainer.addClass('is-open');
					})
					.on('mouseleave', function (e) {
						var nextItem = $(e.relatedTarget);

						$(this).removeClass('is-open');
						if (nextItem.hasClass('has-children') || nextItem.hasClass('level-1-item')) {
							$(this).find('> .js-nav-dropdown').hide();
						}
						else {
							$(this).find('> .js-nav-dropdown').stop().slideUp(300);
							$('.js-overlay').fadeOut();
						}

						$(this).children('.js-arrow-accessibility').attr('aria-expanded', 'false');
						navContainer.removeClass('is-open');
					});

				// when on desktop - trigger tracking event on click of any menu item
				$('.js-header-menu-item').off('click').on('click', function () {
					triggerTrackingEvent($(this));
				});
			}
			else {
				$('.menu-list .has-children > a').on('click', function (e) {
					//stop propagation so the event is not triggered in tracking.js
					//for mobile devices we don't need to trigger tracking if item has sub menus
					e.preventDefault();
					e.stopImmediatePropagation();

					var clickedItem = $(this),
						clickedItemDropDown = clickedItem.siblings('.js-nav-dropdown');

					clickedItemDropDown
						.one('transitionend', () => {
							clickedItem.closest('.js-menu-navigation, .js-nav-dropdown').addClass('u-visibility-hidden');
						})
						.addClass('is-slide-left');
				});

				$('.js-link-back').off('click').on('click', function() {
					const $nav = $(this).parent();
					$nav.removeClass('is-slide-left');
					$nav.parent().closest('.js-menu-navigation, .js-nav-dropdown').removeClass('u-visibility-hidden');
				});

				// when on mobile - trigger tracking event only on click of menu items with no children
				$('.menu-list li:not(.has-children) > a').off('click').on('click', function () {
					triggerTrackingEvent($(this));
				});
			}
		},

		//(Desktop only) Assigning second level categories to columns and sorting columns in increasing order
		desktopCategoryLayout: function() {
			const COLUMN_COUNT = 3;

			if (util.isTabletAndAbove()) {
				$('[data-js="nav-dropdown-categories-level-2"]').each(function () {
					var $wrapperCat = $(this);
					var $secondLevelCats = $wrapperCat.find('[data-js="cat-level-2"]');

					for (var column = 1; column <= COLUMN_COUNT; column++) {
						const categoriesPerColumn = $secondLevelCats.filter(function() {
							return $(this).data('columnno') == column;
						});
						const wrapperHtml = '<li data-js="wrapper-col" data-wrappercol="' + column + '"><ul></ul></li>';
						if (categoriesPerColumn.length) {
							categoriesPerColumn.wrapAll(wrapperHtml);
						}
						else {
							$wrapperCat.append(wrapperHtml);
						}
					}

					var $wrapperColumns = $(this).find('[data-js="wrapper-col"]');
					$wrapperColumns.sort(function (a, b) {
						return ($(a).data('wrappercol')) > ($(b).data('wrappercol')) ? 1 : -1;
					}).appendTo($wrapperCat);
				});
			}
		}
	};

	// collects neccessary params for the data layer from the front end, and send them to the tracking object
	function triggerTrackingEvent(clickedLink) {
		var label = clickedLink.attr('data-gtm-label');
		var destinationUrl = clickedLink.attr('href');
		var action = clickedLink.attr('data-gtm-action');
		var category = 'Primary Navigation';

		// if action is undefined then user either clicked on a category content link or top level content link
		if (action === undefined) {
			var parentWrapper = clickedLink.parents('.js-category-highlights');

			if (parentWrapper && parentWrapper.length > 0) {
				// then this is a content link belongs to a top level category
				action = parentWrapper.attr('data-gtm-action');
			}
			else {
				// then this is top level content link
				action = label;
				label = '';
			}
		}

		trackingHelper.pushTrackingEventAndGoToDestination(category, action, label, destinationUrl);

		return false;
	}

	function init() {
		events.init();
		events.resize();
		$(document).ready(function() {
			events.desktopCategoryLayout();
		});
	}

	function resize() {
		events.resize();
	}

	return {
		init: init,
		resize: resize
	};
})();
