'use strict';

var util = require('../util');

exports.init = function init () {
	var $recommendations = $('.js-recommendations');
	if ($recommendations.length > 0 && util.isMobile()) {
		$recommendations.each(function() {
			$(this).find('[data-js="product-highlight-tile"]').addClass('product-tile__touch-device');
		});
	}

	$(document).on('recommendationsloaded', 'div.js-recommendations', function() {
		if (util.isMobile()) {
			$(this).find('[data-js="product-highlight-tile"]').addClass('product-tile__touch-device');
		}
	});
}