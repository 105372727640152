var submitForm = require('./submitForm');

function initializeEvents() {
	$('.js-giftcard-acc').off('click').on('click', function() {
		var $parent = $(this).parent();
		var $content = $parent.find('.checkout-giftcard-content');
		if ($parent.hasClass('open')) {
			$content.slideUp();
			$parent.removeClass('open');
		}
		else {
			$content.slideDown();
			$parent.addClass('open');
		}

		// aria accessibility attribute
		$(this).find('> .acc-icon').attr('aria-expanded', $parent.hasClass('open'));
	});

	$('body').off('click', '.js-add-giftcard').on('click', '.js-add-giftcard', addGiftcard);
	$('body').off('click', '.js-remove-giftcard').on('click', '.js-remove-giftcard', removeGiftCard);
}

/**
 * Function to handle the add giftcard to cart event
 */
var addGiftcard = function (e) {
	e.preventDefault();
	var captchaValid = true;
	var captchaActive = false;

	var captchaElement = $('.js-captcha-wrapper');
	var giftCardCode = $('.js-input-giftcard').val();
	if (captchaElement && captchaElement.length > 0) {
		captchaValid = $('#reCaptchaResponse').val() != '';
		captchaActive = true;
	}

	if (giftCardCode == '') {
		$('.msg-wrapper').empty();
		$('.msg-wrapper').append("<span class='error'>" + Resources.GIFT_CARD_ERROR + '</span>');
		return;
	}

	if (!captchaValid) {
		$('.js-captcha-error').text(Resources.CAPTCHA_ERROR);
		$('.js-captcha-error').show();
		$('.js-captcha-error').removeClass('u-visually-hidden');
		return;
	}

	var data = [
		{ name: 'giftCardCode', value: giftCardCode }
	];

	if (captchaActive) {
		data.push({ name: 'reCaptchaResponse', value: $('#reCaptchaResponse').val() });
	}

	var options = {
		action: $(this).data('action'),
		extraParameters: data,
		skipPageData: true,
		callback: updateCaptcha
	};

	submitForm.submitAjaxForm(options);
};

/**
 * This function is to remove the giftcard in the checkout page:
 */
function removeGiftCard(e) {
	e.preventDefault();
	var giftCardPIid = $(this).data('giftcardpi');

	var options = {
		action: 'removeGiftCard',
		extraParameters: [
			{ name: 'uuid', value: giftCardPIid }
		],
		skipPageData: true,
		callback: updateCaptcha
	};

	submitForm.submitAjaxForm(options);
}

/**
 * Initialize captcha if it is active
 */
function updateCaptcha() {
	var captchaElement = $('.js-captcha-wrapper');
	if (captchaElement && captchaElement.length > 0) {
		captchaOnloadCallback(); // loaded from template captchascript.isml
	}

	updateScroll();
}

/**
 * This function is to update the scroll of the sidebar in the checkout
 */
function updateScroll() {
	window.scrollTo(window.scrollX, window.scrollY + 1);
	window.scrollTo(window.scrollX, window.scrollY - 1);
}

exports.init = function() {
	initializeEvents();
};