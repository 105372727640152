'use strict';

var Map = require('./map'),
	storelocator = require('./storelocator');

var mapObject;

exports.init = function() {
	var mapElement = $('#js-googlemapstores');
	var mapType = 'googlemap';
	var sortBy = storelocator.getSortBy();

	if (!mapElement.length) {
		mapType = 'nomap';
	}

	mapObject = new Map(mapType, sortBy, storelocator);
	storelocator.init(mapObject);
};