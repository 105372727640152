'use strict';

var util = require("./util.js");

/**
 * @function
 * @description Initializes the Sticky Step Indicator in the Checkout
 */

exports.init = function() {
	if (SitePreferences.IS_RESKIN) {
		reskinStepIndicator();
	}
	else {
		defaultStepIndicator();
	}
};

function reskinStepIndicator() {
	var checkoutHeader = $('[data-js="checkout-header"]');
	var checkoutHeaderHeight = checkoutHeader.outerHeight();
	var checkoutOrderBlock = $('.js-checkout-order-block');

	// Checking if we have scrolled more then the  checkoutHeader height is, and if we did, adding position sticky to stepIndicator and to checkoutOrderBlock
	if ($(window).scrollTop() > checkoutHeaderHeight) {
		checkoutOrderBlock.addClass('sticky-order-block');
	}
	else {
		checkoutOrderBlock.removeClass('sticky-order-block');
	}
}

function defaultStepIndicator() {
	var checkoutHeader = $('[data-js="checkout-header"]');
	var checkoutHeaderHeight = checkoutHeader.outerHeight();
	var checkoutWrapper = $('[data-js="checkout-wrapper"]');
	var stepIndicator = $('[data-js="step-indicator"]');
	var stepIndicatorHeight = stepIndicator.outerHeight();
	var checkoutOrderBlock = $('.js-checkout-order-block');

	//Checking if there is step indicator on the page
	if (stepIndicator.length) {
		//Checking if we have scrolled more then the  checkoutHeader height is, and if we did, adding position sticky to stepIndicator and to checkoutOrderBlock
		if ($(window).scrollTop() > checkoutHeaderHeight) {
			stepIndicator.addClass('is-sticky');
			checkoutWrapper.css({ 'padding-top': stepIndicatorHeight });
			checkoutOrderBlock.css({ 'position': 'sticky', 'top': stepIndicatorHeight, 'align-self': 'flex-start' });
		}
		else {
			stepIndicator.removeClass('is-sticky');
			checkoutOrderBlock.css({ 'position': 'relative', 'top': '0' });
			checkoutWrapper.css({ 'padding-top': 0 });
		}
	}
}