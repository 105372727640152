
var Promise = require('promise'),
	_ = require('lodash'),
	util = require('../../util'),
	dialog = require('../../dialog'),
	progress = require('../../progress'),
	ajax = require('../../ajax'),
	accessibility = (util.isMobile()) ? require('../../accessibilityForMobile') : require('../../accessibility'),
	keyboard = require('../../constants').keyboard,
	storeAvailabilityCheckType = require('../../constants').storeAvailabilityCheckType,
	shippingdetailsoverlay = require('./shippingdetailsoverlay'),
	submitForm = require('./submitForm'),
	map = require('../../omnimap'),
	ometriaTracker = require('../../tracking/ometriaTracking'),
	showOnlyPickUpInStoreChecked = false;


function initializeEvents() {
	// if customer choses to change selected store
	$('body').off('click keydown', '.js-open-storeselector')
		.on('click keydown', '.js-open-storeselector', function(e) {
			if (e.keyCode == keyboard.ENTER_KEY || e.type === 'click') {
				e.preventDefault();
				submitForm.submitAjaxForm({
					action: 'openStoreSelectorInline'
				});
			}

			// accessibility handling
			var $pickupPersonCheckox = $('.js-picked-by-someone-else');
			if (e.keyCode === keyboard.TAB_KEY) {
				accessibility.simulateTabKeyFocus($pickupPersonCheckox);
			}
		});

	// triggers the search of closeby stores with stock
	$('body').off('click', '[data-js="search-stores-near"]')
		.on('click', '[data-js="search-stores-near"]', function(e) {
			var data = [];

			// remove previously searched location in case Near Me is selected
			util.eraseCookie('previous-store-search');

			progress.show();
			// if the promise calls the resolve function it means it managed to access coordinates from browser
			// coords are then stored in the data object and sent to server for search
			// otherwise search is triggered without coords which results in using IP coords on server side
			getLocationCoordinates().then(
				function(coords) {
					if ('latitude' in coords && 'longitude' in coords) {
						data.push({ name: 'latitude', value: coords.latitude });
						data.push({ name: 'longitude', value: coords.longitude });
					}
					getSearchResults(data);
				},
				function() {
					getSearchResults(data);
				}
			);
		});

	// triggers search of closeby stores
	// first geolocates the user query, then sends it to the backend for a search
	$('body').off('click', '.js-search-stores')
		.on('click', '.js-search-stores', function(e) {
			var searchString = $('.js-available-stores-input').val();
			$('[data-js="store-result-wrapper"]').remove();
			if (!searchString) {
				$('.store-availability-feedback').html('<span>' + Resources.STOREAVAILABILITY_SEARCH_EMPTY + '</span>');
				return;
			}

			// in cases where user entered a search string we first want to clean up previous results
			$('.store-availability-list').empty();

			getGeolocationAndPerformSearch(searchString);
		});

	//Sends ajax request to the server which will save selected store in session
	$('body').off('click', '.js-select-store-button').on('click', '.js-select-store-button', function() {
		var url = $(this).data('url');
		var selectedStoreId = $('.store-availability__results-wrapper').find('.is-selected').data('storeid');
		var storeId = $(this).data('storeid');

		if (selectedStoreId == storeId) {
			$('.store-availability__results-wrapper').find('.is-selected').removeClass('is-selected');
			$('.js-select-store-button').html('<span>' + Resources.SELECT_STORE + '</span>');
		}

		$(document).trigger('selectStore', storeId);

		submitForm.submitAjaxForm({
			action: 'setStore',
			url: url,
			callback: function() {
				shippingdetailsoverlay.close();
			}
		});
	});

	$('body').off('click', '[data-js="unavailable-products-detail"]').on('click', '[data-js="unavailable-products-detail"]', function(e) {
		e.preventDefault();

		var url = $(this).attr('href');

		submitForm.submitAjaxForm({
			action: 'unavailableItemsPopup',
			isDetailsPopup: true,
			url: url
		});
	});

	$('body').off('click', '[data-js="return-checkout-button"]').on('click', '[data-js="return-checkout-button"]', function() {
		dialog.close();
	});

	$('body').off('click', '[data-js="continue-reservation-button"]').on('click', '[data-js="continue-reservation-button"]', function() {
		var url = $(this).data('url');

		submitForm.submitAjaxForm({
			action: 'continueReservation',
			url: url,
			callback: function(response) {
				// redirect to cart page if all items were removed
				if (response.indexOf('data-js="iscartpage"') > -1) {
					progress.show();
					window.location.href = window.Urls.cartShow;
					return;
				}
				shippingdetailsoverlay.close();
				dialog.close();
				ometriaTracker.ometriaTrackBasket();

			}
		});
	});

	$('body').off('click', '[data-js="continue-overnight-button"]').on('click', '[data-js="continue-overnight-button"]', function() {
		var url = $(this).data('url');

		// when the "Continue" button clicked on the popup and if no items remaining in the basket then we redirect to cart page and we want to keep loader open.
		// but in case not all items are being removed then we close the "loader" in the callback function with "progress.hide()"
		submitForm.submitAjaxForm({
			action: 'continueOvernightShippingMethod',
			url: url,
			keepLoaderOpen: true,
			callback: function() {
				progress.hide();
				shippingdetailsoverlay.close();
				dialog.close();
				ometriaTracker.ometriaTrackBasket();
			}
		});
	});	

	// displays the form section for another person's pickup
	$('body').off('change click', '.js-picked-by-someone-else')
		.on('change click', '.js-picked-by-someone-else', function(e) {

			if ($(this).is(':checked')) {
				$('.js-picking-person-form').removeClass('u-hidden');
			}
			else {
				$('.js-picking-person-form').addClass('u-hidden');
			}
		});

	// shows the help dialog
	$('body').off('click', '.js-pickup-in-store-help-link')
		.on('click', '.js-pickup-in-store-help-link', function(e) {
			e.preventDefault();
			dialog.open({
				url: $('.js-pickup-in-store-help-link').attr('href'),
				options: {
					dialogClass: 'pdp-pickup-in-store-help'
				},
				closeAll: false
			});
		});

	// if pickup in store is selected shipping method and there is no store selected and there are no store results
	// then try to find previous search term from the cookie and if found - perform search
	if (($('.pickup-store.is-selected.js-store-not-selected').length === 1 || $('.pickup-store.js-is-selected.js-store-not-selected').length === 1 || $('.click-and-reserve.is-selected.js-store-not-selected').length === 1 || $('.click-and-reserve.js-is-selected.js-store-not-selected').length === 1 || $('[data-js="trigger-store-search"]').length === 1)
        && $('.js-store-results').find('.js-store-results-list').length === 0
        && $('.js-store-results').find('.js-store-results-message').length === 0) {
		var previousSearch = util.readCookie('previous-store-search');

		if (previousSearch && previousSearch.length > 0 && previousSearch != 'null') {
			$('[data-js="search-stores-near"]').prop('disabled', true);
			$('.js-available-stores-input').prop('disabled', true);
			$('.js-search-stores').prop('disabled', true);

			getGeolocationAndPerformSearch(previousSearch);
		}
	}

	$('body').off('click', '[data-js="expand-store-hours"]').on('click', '[data-js="expand-store-hours"]', function() {
		var hoursWrapper = $('.store-map-hours');
		var timeTable = $('[data-js="store-hours-timetable"]');
		$(this).closest(hoursWrapper).find(timeTable).toggleClass('visible');
	});
}

/**
 * @description Sends ajax request to sever which will triger service call for store availability
 * @param {Object} data
 */
function getSearchResults(data) {
	var searchStringIndex = _.findIndex(data, function(el) { return el.name == 'searchString'; });
	var isNearMe = !(searchStringIndex >= 0 && data[searchStringIndex].value != 'null');
	var storeCheckType = storeAvailabilityCheckType.CLICK_AND_COLLECT;

	if ($('.click-and-reserve.is-selected').length > 0 || $('.click-and-reserve.js-is-selected').length > 0) {
		storeCheckType = storeAvailabilityCheckType.CLICK_AND_RESERVE;
	}

	data.push({
		name: 'isClickAndReserveSelected',
		value: $('.click-and-reserve.is-selected').length > 0 || $('.click-and-reserve.js-is-selected').length > 0
	});

	var keepAllUnavailableAlert = $('[data-js="trigger-store-search"]').length > 0;

	submitForm.submitAjaxForm({
		action: 'getStores',
		keepAllUnavailableAlert: keepAllUnavailableAlert,
		extraParameters: data,
		callback: function() {
			var searchString = '';
			_.each(data, function(el) {
				if (el.name === 'searchString') {
					searchString = el.value;
				}
			});

			$('[data-js="search-stores-near"]').prop('disabled', false);
			$('.js-available-stores-input').prop('disabled', false);
			$('.js-search-stores').prop('disabled', false);
			$('input#pickup-store-input').val(searchString);

			var $firstStoreTile = $('.js-checkout-delivery-details').find('[data-js="store-availability-item"]').first();
			if ($firstStoreTile && accessibility.isKeyBoardNavigationActive()) {
				accessibility.simulateTabKeyFocus($firstStoreTile);
			}

			$(document).trigger('searchStores', isNearMe);

			$('[data-js="in-stock-filter"]').off('click').on('click', function() {
				var isChecked = $(this).is(':checked');
				filterInStock(isChecked, isNearMe, searchString);
			});

			if (showOnlyPickUpInStoreChecked) {
				$('[data-js="in-stock-filter"]').trigger('click');
			}
			map.init(storeCheckType);
		}
	});
}

/**
 * Operations after user clicked on Show In Store option
 */
function filterInStock(isChecked, isNearMe, searchString) {
	if (isChecked) {
		$('.js-store-results-list .js-store-not-available').addClass('u-hidden');
		$('.js-store-results-list .js-store-availability-item:visible:first').addClass('first-child');
		showOnlyPickUpInStoreChecked = true;
	}
	else {
		$('.js-store-results-list .js-store-not-available').removeClass('u-hidden');
		showOnlyPickUpInStoreChecked = false;
	}

	$('.js-store-results-list').scrollTop(0);

	var numberOfShownStores = $('.js-store-results-list [data-js="store-availability-item"]:visible').length;

	$('.js-store-results-list').removeClass('no-results');
	$('.js-not-in-stock').addClass('u-hidden');

	if (numberOfShownStores === 0) {
		$('.js-store-results-list').addClass('no-results');
		$('.js-not-in-stock').removeClass('u-hidden');
	}
	else if (isNearMe) {
		var text = isChecked ? Resources.STOREAVAILABILITY_FOUND_NEAR_YOU_INSTOCK : Resources.STOREAVAILABILITY_FOUND_NEAR_YOU;
		$('[data-js="store-results-message"]').text(text.replace('{0}', numberOfShownStores));
	}
	else {
		var text = isChecked ? Resources.STOREAVAILABILITY_FOUND_INSTOCK : Resources.STOREAVAILABILITY_FOUND;
		$('[data-js="store-results-message"]').text(text.replace('{0}', numberOfShownStores).replace('{1}', searchString));
	}
	map.updateMarkers(isChecked);
}

function getGeolocationAndPerformSearch(searchString) {
	if (searchString == null) {
		return;
	}

	progress.show(shippingdetailsoverlay.$el);
	shippingdetailsoverlay.show();

	var currentCountry = $('#js-currentcountry').val();

	// Gets lat and long form google maps api
	var url = 'https://maps.googleapis.com/maps/api/geocode/json';
	var searchData = {
		address: searchString,
		components: `country:${currentCountry}`,
		key: SitePreferences.GOOGLEMAP_APIKEY
	};

	// For Australia some queries don't return results if customer enters just valid postal code (i.e.: 2000)
	// Since it works properly for other countries we will restrict this modified search on AU only
	if (currentCountry === 'AU') {
		searchData.address = util.modifyPostalCodeSearch(searchString, currentCountry);
	}

	ajax.getJson({
		url: url,
		data: searchData,
		callback: function (response) {
			if (response) {
				var result = response.results[0];
			}

			if (!!result) {
				var location = result.geometry.location;
				var data = [
					{ name: 'searchString', value: searchString }, //result.formatted_address,
					{ name: 'latitude', value: location.lat },
					{ name: 'longitude', value: location.lng }
				];
				// save search string in session cookie
				util.createCookie('previous-store-search', searchString);
				getSearchResults(data);
			}
			else {
				$('[data-js="search-stores-near"]').prop('disabled', false);
				$('.js-available-stores-input').prop('disabled', false);
				$('.js-search-stores').prop('disabled', false);
				$('.store-availability-feedback').html('<span class="js-store-results-message">'
                                                        + Resources.STOREAVAILABILITY_NOT_FOUND
                                                        + '</span>');
				progress.hide();
			}
		}
	});
}

/**
 * @description Attempts to access the geolocation object from browser and to return the coordinates inside a promise object
 * @returns promise
 */
function getLocationCoordinates() {
	var coords = {};

	return new Promise(function (resolve, reject) {
		navigator.geolocation.getCurrentPosition(
			function (position) {
				coords.latitude = position.coords.latitude;
				coords.longitude = position.coords.longitude;
				resolve(coords);
			},
			function() {
				reject();
			}
		);
	});
}

exports.init = function() {
	initializeEvents();
};
exports.getGeolocationAndPerformSearch = getGeolocationAndPerformSearch;