'use strict';

var util = require('../../util');
var stepIndicator = require('./stepIndicator');

function initializeEvents() {
	initValidatePreviousFields();
	validateProductsAndStore(false);
}

function initValidatePreviousFields() {
	$('#dwfrm_checkout input').on('click', function (event) {
		event.stopImmediatePropagation();

		// do not validate the form in case the checkbox for "someone else picking up the order for c&c"
		if ($(event.currentTarget).hasClass('js-picked-by-someone-else')) {
			return;
		}

		var focusedElementReached = false;

		$('#dwfrm_checkout').find('input, select').each(function () {
			if ($(event.currentTarget).is($(this))) {
				focusedElementReached = true;
			}
			else if (!focusedElementReached) {
				validateField($(this));
			}
		});
	});
}

/**
 * @description Validates given element
 * @param {Object} element
 */
function validateField(element) {
	var isCCExpField = element.is('#dwfrm_checkout_payment_creditCard_expiration_monthAndYear');
	var isDDField = element.is('#dwfrm_checkout_designateddelivery_designatedDeliveryDate, #dwfrm_checkout_designateddelivery_designatedDeliveryTime');
	var isRequiredField = element.hasClass('required');
	var isInFocus = element.hasClass('focus-visible');

	//if the field is in focus then we don't validate it.
	if (isInFocus) {
		return;
	}

	if (isCCExpField || isDDField || isRequiredField) {
		element.valid();
	}
}

function isContactSectionValid() {
	var $contactDetails = $('.js-contactdetails-fieldset');

	// here we check if contact form is rendered, if it is we are validating form data
	var contactDetailsNotRendered = $contactDetails.length === 0;
	var contactDetailsValid = contactDetailsNotRendered || util.validateFieldset($contactDetails); // contact details check

	return contactDetailsValid;
}

function isShippingSectionValid() {
	var noShippingMethod = $('[data-js="no-shipping-methods"]');

	if (noShippingMethod.length) {
		return false;
	}

	var $shippingForm = $('.js-shipping-form');

	// if shipping form is rendered - validate shipping form data, if not rendered then pickup-in-store is used and validation should be performed on Store address on server side
	var shippingFormNotRendered = $shippingForm.length === 0;
	var shippingAddressValid = shippingFormNotRendered || util.validateFieldset($shippingForm);
	var pickUpPointDeliveryValid = isPickUpPointDeliveryValid();

	// check for designated delivery
	var designatedDeliveryValid = checkDDFields();
	var storeSelectionIsValid = checkPickupInStore();
	var clickAndReserveValid = validateClickAndReserveFlow();

	// INDIA specific
	// pincode-input-field exists only on india and the check for valid pincode in shipping is needed before enabling order button
	var pincodeWrapper = $('.pincode-input-field');
	if (pincodeWrapper.length) {
		if (pincodeWrapper.hasClass('with-error')) {
			shippingAddressValid = false;
		}
	}

	// Click&Collect
	var clickAndCollectSectionValid = checkClickAndCollect();

	var isReadonlyPostalCodeValid = true;
	var $readonlyPostalCode = $('#dwfrm_checkout_shipping_address_postal.readonly');

	if ($readonlyPostalCode.length > 0) {
		//we need to add specific validation for readonly KR postal code field
		//because jquery validator skips readonly fields when validating formgroups
		var fieldHasError = $readonlyPostalCode.hasClass('error');
		isReadonlyPostalCodeValid = $readonlyPostalCode.valid();

		if (!fieldHasError) {
			//since valid() also adds error class and message we need to revert readonlyPostal code to previous state
			clearError($readonlyPostalCode);
		}
	}

	return shippingAddressValid && pickUpPointDeliveryValid
		&& designatedDeliveryValid && storeSelectionIsValid
		&& isReadonlyPostalCodeValid && clickAndCollectSectionValid
		&& clickAndReserveValid;
}

function isBillingSectionValid() {
	var $billingForm = $('.js-billing-form');
	// neccessary to check if checkbox exists because it doesn’t get rendered when it is free order flow
	var useAsBillingAddress = $('input[id$="_useAsBillingAddress"]');
	var $useShippingAsBillingAddr = useAsBillingAddress.length === 1 ? useAsBillingAddress[0].checked : true;

	// if billing form is not rendered then we have a free order flow and billing section is always valid
	// if shipping address is used for billing address then billing is valid if shipping is valid; if not then validate billing form data
	var billingFormNotRendered = $billingForm.length === 0;
	var billingAddressValid = billingFormNotRendered
							|| ($useShippingAsBillingAddr && isShippingSectionValid())
							|| (!$useShippingAsBillingAddr && util.validateFieldset($billingForm));

	return billingAddressValid;
}

function isPaymentSectionValid() {
	var selectedPaymentMethodID =  $('input[name$=_selectedPaymentMethodID]:checked').val();
	var paymentDetailsValid = true;
	var ccFieldsAreValid = true;

	switch (selectedPaymentMethodID) {
        case 'econtext_stores':
            paymentDetailsValid = window.CardValid;
            break;

        case 'econtext_seven_eleven':
            paymentDetailsValid = window.CardValid;
            break;

        case 'CREDIT_CARD':
            paymentDetailsValid = ccFieldsAreValid;
            break;
    }

	if ($('.js-creditcard-fieldset').length) {
		//in case that we have our payment form we are validating it
		ccFieldsAreValid = checkCCFields();
	}

	paymentDetailsValid = $('input[name$=_selectedPaymentMethodID]:checked').val() !== 'CREDIT_CARD' || ccFieldsAreValid;

	return paymentDetailsValid && isBillingSectionValid();
}

function isPickUpPointDeliveryValid() {
	var $pickupPointDeliveryForm = $('.js-pickuppoint-section');

	var pickupPointDeliveryValid = $pickupPointDeliveryForm.length > 0 ? checkPickupPointDelivery($pickupPointDeliveryForm) : true;

	return pickupPointDeliveryValid;
}

function checkPickupPointDelivery(pickupPointDeliveryForm) {
	var billingPersonValid = true;

	var freeOrderBillingPersonForm = $('.js-free-order-billing-person-form');
	//check if billing person is valid only if billing person form is rendered
	if (freeOrderBillingPersonForm.length) {
		billingPersonValid = util.validateFieldset(freeOrderBillingPersonForm);
	}
	var pickuppointselected = $('.js-pickuppoints-list .post-office-radio input[type = "radio"]:checked').length > 0
							|| ($('.checkout-shipping-readonly').length > 0 && $('.js-pickuppoint-section').length > 0);
	return billingPersonValid && util.validateFieldset(pickupPointDeliveryForm) && pickuppointselected;
}

/**
 * @description Checks if pickup in store is valid (if store is selected and pickup person is valid)
 * @returns {Boolean} true if fields are valid, false if they are not.
 */
function checkPickupInStore() {
	var billingPersonValid = true;
	if ($('.pickup-store.is-selected, .pickup-store.js-is-selected').length) {
		if ($('.js-store-not-selected').length) {
			// check if store is selected
			return false;
		}
		var freeOrderBillingPersonForm = $('.js-free-order-billing-person-form');
		//check if billing person is valid only if billing person form is rendered
		if (freeOrderBillingPersonForm.length) {
			billingPersonValid = util.validateFieldset(freeOrderBillingPersonForm);
		}
		//check if pickup person is valid
		var pickupPerson = $('.js-picking-person-form');
		if (pickupPerson.length) {
			var pickupPersonValid = util.validateFieldset(pickupPerson);
			return pickupPersonValid && billingPersonValid;
		}
		return billingPersonValid;
	}
	// return true if pickup in store is not selected
	return true;
}


/**
 * @description Check store and product error, if both of them allow click and collect
 * @returns {Boolean}
 */
function checkClickAndCollect() {
	var storeErrorSelector = $('.information-msg');
	var productErrorSelector = $('.js-item-clickandcollect-error');
	var storeAllowsClickAndCollect = true;
	var productsAllowsClickAndCollect = true;

	if ($('.pickup-store.is-selected, .pickup-store.js-is-selected').length) {
		// check store error
		if (storeErrorSelector.length > 0 && storeErrorSelector.hasClass('is-error')) {
			storeAllowsClickAndCollect = false;
		}

		// check product error
		if (productErrorSelector.length > 0) {
			productsAllowsClickAndCollect = false;
		}
	}
	return storeAllowsClickAndCollect && productsAllowsClickAndCollect;
}

/**
 * @description Checks if DD date and time fields are valid (at least one of them needs to be provided)
 * @returns {Boolean} true if fields are valid, false if they are not.
 */
function checkDDFields() {
	var $designatedDeliveryForm = $('.js-designated-delivery');
	var $designatedDeliveryState = $('.js-designateddelivery-detail-container .js-state');
	var isDDValid = true;

	if ($designatedDeliveryState.length) {
		isDDValid = $designatedDeliveryState.val() != '' && $designatedDeliveryState.val() != Resources.DEFAULT_SELECT_OPTION;
	}

	if (isDDValid && $designatedDeliveryForm.length) {
		var ddDate = $('#dwfrm_checkout_designateddelivery_designatedDeliveryDate');
		var ddTime = $('#dwfrm_checkout_designateddelivery_designatedDeliveryTime');
		isDDValid = ddDate.val() != '' || ddTime.prop('selectedIndex') > 0;
	}


	return isDDValid;
}

/**
 * Checks if creditcard fields are valid.
 * @private
 * @returns {boolean}
 */
function checkCCFields() {
	//in case that section is hidden do not validate
	//we need to do this check manually as we dont use jquery validator for this section
	var isSectionVisible = $('.js-checkout-payment').is(':visible');

	if (isSectionVisible) {
		var $creditCardFieldset = $('.js-creditcard-fieldset');
		return util.validateFieldset($creditCardFieldset);
	}

	return true;
}

/**
 * If there is error or success styling left after emptying the value of input field, remove it
 */
function clearError(inputField) {
	var inputContainerClasses = '.input-container';

	if (inputField.closest(inputContainerClasses).hasClass('has-error')) {
		inputField.closest(inputContainerClasses).removeClass('has-error');
	}

	if (inputField.hasClass('error')) {
		inputField.removeClass('error');
	}

	if (inputField.closest(inputContainerClasses).hasClass('is-valid')) {
		inputField.closest(inputContainerClasses).removeClass('is-valid');
	}

	if (inputField.hasClass('valid')) {
		inputField.removeClass('valid');
	}

	var spanWithErrorMessage = '#' + inputField.attr('id') + '-error';
	if ($(spanWithErrorMessage).length > 0) {
		$(spanWithErrorMessage).remove();
	}
}

/**
 * @description check if we are redirected from paypal, and then we validate
 * postal and state input fields if they are visible.
 **/
function validatePostalAndStateAfterPayPalExpress() {
	var param = '?paypalSuccessful=true';
	var postal = $('#dwfrm_checkout_shipping_address_postal');
	var state = $('#dwfrm_checkout_shipping_address_states_state');
	if (window.location.search.indexOf(param) > -1 && state.is(':visible') && postal.is(':visible')) {
		state.valid();
		postal.valid();
	}
}

/**
 * Update step indicator bar
 * @private
 */
function stepIndicatorUpdate() {
	stepIndicator.contact.valid(isContactSectionValid());
	stepIndicator.delivery.valid(isShippingSectionValid());
	stepIndicator.payment.valid(isPaymentSectionValid());
}

// click & reserve products and store validation
var validateClickAndReserveFlow = function() {
	var storeSelected = validateStorePickup();

	//check if pickup person is valid
	var clickAndReservePerson = $('.js-clickandreserve-person-form');
	var clickAndReservePersonValid = clickAndReservePerson.length > 0 ? util.validateFieldset(clickAndReservePerson) : true;


	return validateProductsAndStore() && storeSelected && clickAndReservePersonValid;
};

// check if store allows click and reserve
var validateStore = function() {
	if ($('.js-store-not-selected').length) {
		return false;
	}

	var storeClickAndReserveErrorSelector = $('.store-reserve-error');
	if (storeClickAndReserveErrorSelector.length > 0) {
		return false;
	}
	return true;
};

var validateProductsAndStore = function () {
	var storeValid = validateStore();
	var productsValid = validateProductsForClickAndReserve();
	return storeValid && productsValid;
};

var validateStorePickup = function() {
	if ($('.js-store-selected').val() === 'false') {
		return false;
	}
	return true;
};

// check if some products in cart are not avaliable for reservation
var validateProductsForClickAndReserve = function() {
	var isOneOrMoreProductsUnavailable = $('.js-mini-cart .item-reserve-error').length > 0;
	if (isOneOrMoreProductsUnavailable) {
		$('.items-reserve-available-error').removeClass('u-hidden');
		return false;
	}
	return true;
};

// check if some products in cart are not avaliable for reservation  
var validateProductsForClickAndCollect = function() {
	var isOneOrMoreProductsUnavailable =$('.js-mini-cart .js-item-clickandcollect-error').length > 0;

	if (isOneOrMoreProductsUnavailable) {
		$('.items-collect-available-error').removeClass('u-hidden');
		return false;
	}
	return true;
};

exports.init = function () {
	initializeEvents();
};

exports.clearError = clearError;
exports.validatePostalAndStateAfterPayPalExpress = validatePostalAndStateAfterPayPalExpress;
exports.initValidatePreviousFields = initValidatePreviousFields;
exports.validateProductsForClickAndReserve = validateProductsForClickAndReserve;
exports.validateProductsForClickAndCollect = validateProductsForClickAndCollect;
