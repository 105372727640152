'use strict';

var util = require('./util');
var languageswitcher = {
	init: function() {
		var $langSelect = $('.language-selector');
		var $footerLangSelect = $('.js-footer-language');
		var $footerLangSelectBtn = $('.js-footer-language-btn');
		var $footerLangSelectLink = $('.js-footer-language-link');

		$langSelect.on('change', function() {
			var newLocale = $(this).val();
			util.createCookie('_ecco_locale', newLocale, 30);
			location.href = $(this).find(':selected').data('href');
		});

		$footerLangSelectLink.on('click', function(e) {
			e.preventDefault();
			var btnLabel = $(this).text();
			var newLocale = $(this).val();
			util.createCookie('_ecco_locale', newLocale, 30);
			location.href = $(this).data('href');
			$footerLangSelectBtn.text(btnLabel);
		});

		$footerLangSelectBtn.on('click', function() {
			$footerLangSelect.toggleClass('is-open');
		});

		$('body').on('click', function(e) {
			if (!$(e.target).is('.js-footer-language-btn')) {
				$footerLangSelect.removeClass('is-open');
			}
		});
	}
};

module.exports = languageswitcher;
