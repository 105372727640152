'use strict';

var keyboard = require('./constants').keyboard;
var mailcheck = require('mailcheck');

/**
 * @function
 * @description Forms validations
 */

// Input Label effect
function inputFocusEffect() {
	// Global var for the forms handler
	var fieldSelectorClasses = '.input-text:not(.readonly, .ignore-validation), .input-textarea, .input-select';
	var containerSelectorClasses = '.input-container, .select-container';
	var inputValidClass = 'is-valid';

	var isStorelocator = !!$('.pt_storelocator').length;
	//We skip it on storelocator since it causes errors
	if (isStorelocator) {
		return;
	}

	setTimeout(function() {
		$('body')
			.find(fieldSelectorClasses)
			.filter(function() {
				return !!this.value;
			})
			.each(function() {
				// if we get invalid field back from BE on account page
				// don`t add "is-valid" class to it
				var isAccount = $(this).closest('[class^="pt_account"]').length > 0;
				if (isAccount && $(this).hasClass('error')) {
					return;
				}
				var $this = $(this);
				if ($this.valid()) {
					$this.closest(containerSelectorClasses).addClass(inputValidClass);
				}
			});
	}, 100);
	// On Focus
	$('body').off('focus.fieldselector', fieldSelectorClasses).on('focus.fieldselector', fieldSelectorClasses, function() {
		var $this = $(this);
		$this.removeClass('error');
		$this.siblings('span.error').remove();

		var inputContainerClasses = '.input-container, .select-container';
		$this.closest(inputContainerClasses).removeClass('has-error');
		$this.closest(inputContainerClasses).find('span.error').remove();
	});
	// On Blur
	$('body').off('blur.fieldselector', fieldSelectorClasses).on('blur.fieldselector', fieldSelectorClasses, function() {
		var $this = $(this);
		var labelText = $this
			.closest(containerSelectorClasses)
			.find('label span:not(.required-indicator)')
			.text();

		if ($this.valid()) {
			$this
				.attr('aria-label', labelText + ' ' + window.Resources.VALIDATE_ISVALID)
				.closest(containerSelectorClasses)
				.addClass(inputValidClass);
		}
		else {
			$this
				.removeAttr('aria-label')
				.closest(containerSelectorClasses)
				.removeClass(inputValidClass);
		}
	});
}

// Keyboard accessibility Custom Checkboxes
function formCheckboxKeyboardFocusEffects() {
	$('.checkbox-row').each(function() {
		if ($(this).has('.content-asset').length > 0) {
			$(this)
				.find('label span')
				.attr(
					'aria-label',
					$(this)
						.find('.content-asset')
						.text()
				);
		}
	});

	$('.checkbox-row').find('[type=checkbox]').on('change', function() {
		var $this = $(this);
		$this.siblings('label').attr('aria-checked', $this.is(':checked'));
	});
}

// Keyboard accessibility Radiobuttons
function formRadiobuttonKeyboardFocusEffects() {
	var radioButtonContainer = $('.radio-container').not('.js-radio-container-payment');

	radioButtonContainer.each(function() {
		var inputField = $(this).find('input');
		var labelField = $(this).find('label');

		inputField.attr('tabindex', '-1');
		labelField.attr('tabindex', '0').attr('role', 'radio');
		labelField.attr('aria-checked', inputField.is(':checked'));
	});

	radioButtonContainer.on('keydown', function(e) {
		var radioButtonLabel = $(this).find('label');
		if (e.keyCode === keyboard.ENTER_KEY || e.keyCode === keyboard.SPACE_KEY) {
			radioButtonLabel.trigger('click');
			radioButtonLabel.attr('aria-checked', 'true');
			radioButtonLabel.trigger('focus');
		}
	});
}

// Keyboard accessibility - postal code button
function formPostalCodeButtonFocusEffects() {
	$('.js-search-postal-code').attr('tabindex', '0');

	$('.js-search-postal-code').on('keydown', function(e) {
		if (e.keyCode === keyboard.ENTER_KEY) {
			$('.js-search-postal-code').trigger('click');
		}
	});
}

// Enable submit button when form is valid
function inputvalidation() {
	$('.js-validation-form').on('keyup blur change', function() {
		// fires on every keyup & blur
		var formValid = $(this)
			.validate()
			.checkForm();
		var captchaValid = true;

		var captchaElement = $('.js-captcha-wrapper');
		if (captchaElement && captchaElement.length > 0) {
			captchaValid = $('#reCaptchaResponse').val() !== '';
		}

		var validationPassed = formValid && captchaValid;
		var submitButton = $(this).find('.js-validation-button');

		if (validationPassed) {
			submitButton.prop('disabled', false); // enables button
			submitButton.removeAttr('aria-describedby');
			submitButton.removeAttr('tabindex');
		}
		else {
			submitButton.prop('disabled', 'disabled'); // disables button
			submitButton.attr('aria-describedby', submitButton.attr('data-aria-describedby'));
			submitButton.attr('tabindex', '0');
		}
	});
	$('body').on('click', '.js-search-postal-code', function() {
		setTimeout(function() {
			checkInputFields();
		}, 300);
	});
}

function checkInputFields() {
	// On page load check if input fields are filled
	var $inputFilledClass = $('.input-text, .input-textarea, .input-select');

	if ($inputFilledClass.length) {
		var i, n = $inputFilledClass.length;
		for (i = 0; i < n; i += 1) {
			if ($($inputFilledClass[i]).val() !== null) {
				if ($($inputFilledClass[i]).val().trim() !== '') {
					$($inputFilledClass[i]).parent().addClass('input-with-focus');
				}
			}
		}
	}
}

function floatingLabels() {
	var inputsSelector = ('.input-text, .input-textarea, .input-select');
	var inputFilledClass = 'is-filled';

	$('body').off('focus', inputsSelector).on('focus', inputsSelector, function() {
		var $this = $(this);
		$this.parent().addClass('input-with-focus');
	});
	$('body').off('change blur', inputsSelector).on('change blur', inputsSelector, function() {
		var $this = $(this);

		if ($this.val().trim() === '') {
			$this.parent().removeClass('input-with-focus');
			$this.closest(inputsSelector).removeClass(inputFilledClass);
		}
		else {
			$this.parent().addClass('input-with-focus');
		}
	});

	// Adyen specific floating label
	$('body').off('change blur', '.adyen-checkout__card__holderName__input').on('change blur', '.adyen-checkout__card__holderName__input', function() {
		var $this = $(this);
		var cardHolderSelector = $('.adyen-checkout__card__holderName');

		if ($this.hasClass('valid') || $this.hasClass('adyen-checkout__input--valid')) {
			cardHolderSelector.addClass('adyen-checkout__label--focused');
		}
		else {
			cardHolderSelector.removeClass('adyen-checkout__label--focused');
		}
	});
}

function deliveryInstructionsTextLimit() {
	var deliveryField = $('[data-js="delivery-instructions"]');
	var charactersLeftPlaceholder = $('.js-characters-left');
	var charactersMax = charactersLeftPlaceholder.data('maxLength');
	if (deliveryField.length) {
		deliveryField.on('blur keyup paste change', countCharacterLimit);
	}
	function countCharacterLimit () {
		var $this = $(this);
		var textLength = deliveryField.val().length;
		var textRemaining = charactersMax - textLength;
		charactersLeftPlaceholder.text(textLength + ' ' + Resources.DELIVERY_INSTRUCTIONS_CHARACTERS + ', ' + textRemaining + ' ' + Resources.DELIVERY_INSTRUCTIONS_LEFT);

		if ($this.val().length > charactersMax) {
			$this.val($this.val().substring(0, charactersMax));
			charactersLeftPlaceholder.text(charactersMax + ' ' + Resources.DELIVERY_INSTRUCTIONS_CHARACTERS + ', 0 ' + Resources.DELIVERY_INSTRUCTIONS_LEFT);
		}
	}
}

// Show Hide / Password
function showHidePassword() {
	var showPassword = $('[data-js="show-password"]');
	showPassword.off('click').on('click', function () {
		var $this = $(this);
		var $inputPassword = $this.parent().find('.input-text');
		$this.toggleClass('is-available');
		if ($inputPassword.attr('type') === 'password') {
			$inputPassword.attr('type', 'text');
		}
		else {
			$inputPassword.attr('type', 'password');
		}
	});
}

//initializing email typo suggestion tool for certain email fields (emailsuggestion="true") and suggested email click
function initEmailTypoSuggestionEvents() {
	var $mailSelector = $('input[type="email"][emailsuggestion="true"]');
	$mailSelector.off('blur.type_email').on('blur.type_email', function(e) {
		e.preventDefault();
		var $emailField = $(this);
		var $suggestionSpan = $emailField.siblings('.js-info-row').find('[data-js="email-suggestion"]');

		mailcheck.run({
			email: $emailField.val(),
			suggested: function(suggestion) {
				$suggestionSpan.css('display', 'flex');
				var suggestionText = '<span class="c-suggestion-text">' + suggestion.full + '</span>';
				var resourceText = Resources.DID_YOU_MEAN;
				resourceText = resourceText.replace('{0}', '&nbsp;' + suggestionText + '&nbsp;');
				$suggestionSpan.html(resourceText);
				$suggestionSpan.val(suggestion.full);
			},
			empty: function() {
				$suggestionSpan.hide();
			}
		});
	});

	var $suggestionSelector = $('[data-js="email-suggestion"]');
	$suggestionSelector.off('click.emailsuggestion').on('click.emailsuggestion', function () {
		var $emailContainer = $(this).parents('.js-info-row').siblings('input[type="email"]');
		$emailContainer.val($(this).val());
		$(this).hide();
		$(this).text('');
		$(this).val('');
	});
}

/**
 * Strip the characters if it is longer than the max allowed length only on Android devices
 */
function inputFieldMaxlength() {
	var ua = navigator.userAgent.toLowerCase();
	var isAndroid = ua.indexOf('android') > -1;
	if (!isAndroid) {
		return;
	}

	var inputText = $('.input-text');
	inputText.on('blur keyup paste change', function() {
		var $this = $(this);

		if ($this && $this.length > 0) {
			var fieldMaxLength = $this.attr('maxlength');

			if (fieldMaxLength) {
				$this.val($this[0].value.substring(0, fieldMaxLength));
			}
		}
	});
}

exports.init = function() {
	inputFocusEffect();
	formCheckboxKeyboardFocusEffects();
	formRadiobuttonKeyboardFocusEffects();
	formPostalCodeButtonFocusEffects();
	inputvalidation();
	checkInputFields();
	floatingLabels();
	deliveryInstructionsTextLimit();
	showHidePassword();
	initEmailTypoSuggestionEvents();
	inputFieldMaxlength();

	$(document).off('checkInputFields').on('checkInputFields', checkInputFields);
};

exports.checkInputFields = checkInputFields;
exports.initEmailTypoSuggestionEvents = initEmailTypoSuggestionEvents;
exports.formRadiobuttonKeyboardFocusEffects = formRadiobuttonKeyboardFocusEffects;
exports.deliveryInstructionsTextLimit = deliveryInstructionsTextLimit;
exports.inputFieldMaxlength = inputFieldMaxlength;
