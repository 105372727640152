'use strict';

var swiper = require('./swiper');
var util = require('./util');

/**
 * @function
 * @description  Selector on PLP mobile for customers to choose between displaying product tile on 1 column or 2 columns
 */
function switchTileViewMobile() {
	var singleColumn = $('[data-js="plp-switch-single"]');
	var doubleColumn = $('[data-js="plp-switch-double"]');
	var clearFilters = $('[data-js="clear-filters"]');
	var plpContent = $('[data-js="plp-content"]');
	var singleHeader = $('[data-js="plp-row-single"]');
	var doubleHeader = $('[data-js="plp-row-double"]');
	const activeClass = 'active';
	const tileViewCookieName = '_ecco_plp_tile_view_class';
	const singleColumnClass = 'plp-view__single-column';
	const hiddenClass = 'u-hidden';

	// if the cookie exists use the single tile view, otherwise use the default one - double column view
	if (util.readCookie(tileViewCookieName)) {
		displaySingleColumn(activeClass, singleColumn, doubleColumn, plpContent, singleColumnClass, singleHeader, doubleHeader, hiddenClass);
	}

	singleColumn.off('click').on('click', function() {
		displaySingleColumn(activeClass, singleColumn, doubleColumn, plpContent, singleColumnClass, singleHeader, doubleHeader, hiddenClass);
		util.createCookie(tileViewCookieName, singleColumnClass, 30);
	});
	doubleColumn.off('click').on('click', function() {
		displayDoubleColumn(activeClass, singleColumn, doubleColumn, plpContent, singleColumnClass, singleHeader, doubleHeader, hiddenClass);
		util.eraseCookie(tileViewCookieName);
	});
	clearFilters.off('click').on('click', function() {
		if (util.readCookie(tileViewCookieName)) {
			displaySingleColumn(activeClass, singleColumn, doubleColumn, plpContent, singleColumnClass, singleHeader, doubleHeader, hiddenClass);
		}
	});
}

function displaySingleColumn(activeClass, singleColumn, doubleColumn, plpContent, singleColumnClass, singleHeader, doubleHeader, hiddenClass) {
	doubleColumn.removeClass(activeClass);
	singleColumn.addClass(activeClass);
	plpContent.addClass(singleColumnClass);
	singleHeader.removeClass(hiddenClass);
	doubleHeader.addClass(hiddenClass);
	swiper.initializeProductTileSwiper();
}

function displayDoubleColumn(activeClass, singleColumn, doubleColumn, plpContent, singleColumnClass, singleHeader, doubleHeader, hiddenClass) {
	singleColumn.removeClass(activeClass);
	doubleColumn.addClass(activeClass);
	plpContent.removeClass(singleColumnClass);
	singleHeader.addClass(hiddenClass);
	doubleHeader.removeClass(hiddenClass);
	swiper.initializeProductTileSwiper();
}

exports.init = function () {
	switchTileViewMobile();
	$('body').on('filtersclosed', switchTileViewMobile);
};
