'use strict';

var keyboard = require('./constants').keyboard;
var util = require('./util');

function updateToTopPosition(tooltipContent, labelHeight) {
	if (util.isMobileAndBelow()) {
		tooltipContent.css('bottom', '');
		tooltipContent.css('top', '');
	}
	else {
		var top = tooltipContent.outerHeight() * -1 + (labelHeight || 0);
		tooltipContent.css('bottom', 'inherit');
		tooltipContent.css('top', top + 'px');
	}
}

exports.initializeCustomTooltip = function() {
	var tooltipSelector = 'custom-tooltip';
	var tooltipIcon = $('.custom-tooltip');
	var tooltipContent = $('.tooltip-content');
	var tooltipButton = '';

	if (tooltipIcon.length === 0) {
		return;
	}

	$('[data-js="custom-tooltip"]').off('keydown').on('keydown', function(e) {
		if (e.keyCode === keyboard.ENTER_KEY) {
			e.preventDefault();
			$(this).trigger('click.customtooltip');
		}
	});

	$('[data-js="tooltip-close"]').on('click', function(e) {
		e.preventDefault();
		e.stopPropagation();
		$(this).parent().removeClass('tooltip-content-active');
	});

	$('body').off('click.customtooltip', '[data-js="custom-tooltip"]').on('click.customtooltip', '[data-js="custom-tooltip"]', function(e) {
		if ($(e.target).offsetParent().hasClass('tooltip-content')) {
			return;
		}

		var useTopPositioning = $(this).attr('data-useTopPositioning') === 'true';
		var labelHeight = $(this).attr('data-labelHeight') ? parseInt($(this).attr('data-labelHeight'), 10) : null;

		var isTooltipIconClicked = $(e.target).offsetParent().hasClass(tooltipSelector) || $(e.target).hasClass(tooltipSelector);

		if (isTooltipIconClicked) {
			tooltipButton = $(e.currentTarget);
			var currentTooltip = $(tooltipButton).find('.js-tooltip-content');

			currentTooltip.toggleClass('tooltip-content-active');
			if (currentTooltip.hasClass('tooltip-content-active') && useTopPositioning && !($(tooltipButton).parent().hasClass('static-position'))) {
				updateToTopPosition(currentTooltip, labelHeight);
			}

			if (currentTooltip.hasClass('tooltip-content-active') && !useTopPositioning) {
				var screenWidth = document.body.getBoundingClientRect().width;
				var contentPosition = currentTooltip[0].getBoundingClientRect();

				if (contentPosition.right > screenWidth - 15) {
					currentTooltip.css('left', 0);
					currentTooltip.addClass('align-bottom');
					contentPosition = currentTooltip[0].getBoundingClientRect();

					var leftPosition = screenWidth - contentPosition.right - 15;
					currentTooltip.css('left', (leftPosition > 0 ? leftPosition * -1 : leftPosition));
				}

				if (tooltipButton[0].getBoundingClientRect().top < currentTooltip[0].getBoundingClientRect().top) {
					currentTooltip.addClass('align-bottom');
				}

				currentTooltip.css('--myVar', (tooltipButton[0].getBoundingClientRect().x - currentTooltip[0].getBoundingClientRect().left) + 'px');
			}
		}
		else {
			if (tooltipContent.hasClass('tooltip-content-active')) {
				tooltipContent.removeClass('tooltip-content-active');
			}
		}
	});
};

exports.image360Tooltip = function() {
	var image360Container = $('[data-js="image-360"]').find('.cloudimage-inner-box');
	var tooltip = $('[data-js="image360-tooltip"]');

	image360Container.on('mousemove', function(e) {
		var rect = e.target.getBoundingClientRect();
		var pageX = e.clientX - rect.left;
		var pageY = e.clientY - rect.top;
		tooltip.css({
			'left': pageX + 'px',
			'top': pageY + 12 + 'px'
		});
	});

	image360Container.on('mousedown mouseleave', function() {
		tooltip.css('opacity', 0);
	});

	image360Container.on('mouseup mouseenter', function() {
		tooltip.css('opacity', 1);
	});
};

/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
exports.init = function () {
	$(document).tooltip({
		items: '.tooltip',
		track: true,
		content: function () {
			return $(this).find('.tooltip-content').html();
		}
	});

	$('.share-link').on('click', function (e) {
		e.preventDefault();
		var target = $(this).data('target');
		if (!target) {
			return;
		}
		$(target).toggleClass('active');
	});
};
