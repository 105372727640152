'use strict';

var detailsmodaldialog = require('./detailsmodaldialog');

function initializeEvents() {
	$('body').off('click', '[data-js="js-click-and-reserve-more-info"]').on('click', '[data-js="js-click-and-reserve-more-info"]', function(e) {
		e.preventDefault();
		var $this = $(this);
		var dialogContent = $('.click-and-reserve-content');
		var hasExpandedAttr = $this[0].hasAttribute('aria-expanded');

		var dialogParams = {
			html: dialogContent.html(),
			options: {
				dialogClass: 'click-and-reserve-info-dialog',
				open: function() {
					//Toggle data-expanded
					if (hasExpandedAttr) {
						$this.attr('aria-expanded', true);
					}
				},
				close: function () {
					//Toggle data-expanded
					if (hasExpandedAttr) {
						$this.attr('aria-expanded', false);
					}
				}
			},
			focusVisible: $this.hasClass('focus-visible')
		};

		detailsmodaldialog.open(dialogParams);
	});
}

exports.init = function() {
	initializeEvents();
};