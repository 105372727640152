var validator = require('./validator'),
	progress = require('../../progress'),
	util = require('../../util'),
	submitForm = require('./submitForm'),
	dav = require('./deliveryaddressverification'),
	checkoutUtils = require('./checkoututils'),
	accessibility =	(util.isMobile()) ? require('../../accessibilityForMobile') : require('../../accessibility');
if (SitePreferences.AMAZON_PAY_ENABLED) {
	var amazonPayments = require('../../amazonPayments');
}

/**
 * Init shipping details events
 */
function initializeEvents() {
	$('body').off('click', '[data-js="save-shipping-details"]').on('click', '[data-js="save-shipping-details"]', function(e) {
		e.preventDefault();

		if ($('[data-js="warnings-errors-shippment"]').length) {
			var shippingDetailsSection = $('.js-checkout-method');
			checkoutUtils.scrollToSection(shippingDetailsSection);

			return;
		}

		var isReadOnly = $('.checkout-shipping__readonly').length;
		var isSectionValid = validator.validateShippingSection();
		var pickupInStoreWrapper = $('[data-js="pickup-store"]');

		// Opens popup window if there are unavailable products for C&C
		if (pickupInStoreWrapper.length && pickupInStoreWrapper.hasClass('js-unavailable-products-detail') && !isSectionValid) {
			var url = pickupInStoreWrapper.attr('data-href');
			submitForm.submitAjaxForm({
				action: 'unavailableItemsPopup',
				isDetailsPopup: true,
				url: url
			});

			return;
		}

		if (!isSectionValid || isReadOnly) {
			return;
		}

		var keyboardNavActive = $(this).hasClass('focus-visible');
		accessibility.setKeyboardNavigation(keyboardNavActive);
		checkShippingDetailsDav();
	});

	$('body').off('click', '[data-js="edit-shipping-details"]').on('click', '[data-js="edit-shipping-details"]', function(e) {
		e.preventDefault();
		var keyboardNavActive = $(this).hasClass('focus-visible');
		accessibility.setKeyboardNavigation(keyboardNavActive);
		submitForm.submitAjaxForm(
			{
				action: 'editShippingDetails',
				callback: function () {
					checkoutUtils.scrollToNextSection();
					if (SitePreferences.AMAZON_PAY_ENABLED) {
						amazonPayments.init();
					}
				}
			}
		);
	});

	var davValidation = $('[data-js="needs-dav-validation"]');
	if (davValidation.length > 0 && davValidation.val() == 'true') {
		davValidation.val('false');
		progress.show();
		var $checkoutForm = $('#dwfrm_checkout');
		var data = $checkoutForm.find("[name*='shipping_address']").serializeArray();
		dav.davCheck(data, function(addressUpdated) {
			if (addressUpdated) {
				saveShippingDetails(true);
			}
			else {
				progress.hide();
			}
		});
	}
	//Validate billing person name for free pick-up in store orders
	if ($('.js-free-order-billing-person-form').length > 0) {
		$('.js-free-order-billing-person-form').find('input').each(function(i, input) {
			$(input).off('blur').on('blur', function() {
				validator.validateSection(input);
			});
		});
	}
}

/**
 * Save shipping details via Ajax
 * @param {Boolean} useOriginalAddress
 */
function saveShippingDetails(calledAfterDAVStandardize) {
	submitForm.submitAjaxForm(
		{
			action: 'saveShippingDetails',
			extraParameters: [{ name: 'calledAfterDAVStandardize', value: calledAfterDAVStandardize || false }],
			callback: function () {
				checkoutUtils.scrollToNextSection();
				if (SitePreferences.AMAZON_PAY_ENABLED) {
					amazonPayments.init();
				}
			}
		}
	);
	$('.js-checkout-method').trigger('trackSaveDetails');
}

function checkShippingDetailsDav() {
	var isDavEnabled = $('#js-dav-enabled').val() === 'true';
	var isPickupInStoreSelected = $('.pickup-store.is-selected, .pickup-store.js-is-selected').length === 1;
	var isClickAndReserveSelected = $('.click-and-reserve.is-selected, .click-and-reserve.js-is-selected').length > 0;
	var isPickupPointDeliverySelected = $('.js-pickuppoint-section').length > 0;
	var shippingDetailsFieldset = $('.js-shipping-form');
	var data = shippingDetailsFieldset.serializeArray();

	progress.show();

	if (!isDavEnabled || isPickupInStoreSelected || isPickupPointDeliverySelected || isClickAndReserveSelected) {
		//in case DAV is not enabled or we are on pickup point or pickup in store shipping method we need to skip address verification place order
		saveShippingDetails();
		return;
	}

	dav.davCheck(data, saveShippingDetails);
}

exports.init = function () {
	initializeEvents();
};