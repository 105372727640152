'use strict';

var Promise = require('promise'),
	account = require('../account'),
	minicart = require('../../minicart'),
	ajax = require('../../ajax'),
	util = require('../../util'),
	tooltip = require('../../tooltip'),
	bonusProductsView = require('../../bonus-products-view'),
	progress = require('../../progress'),
	detailsModalDialog = require('../../detailsmodaldialog'),
	promotions = require('./promotions'),
	accessibility = (util.isMobile()) ? require('../../accessibilityForMobile') : require('../../accessibility'),
	wishlist = require('../wishlist'),
	storeSelector = require('../../storeselector'),
	clickAndReserve = require('../../clickandreserve'),
	fbconversion = require('../../fbconversion'),
	keyboard = require('../../constants').keyboard,
	ometriaTracker = require('../../tracking/ometriaTracking'),
	uspBanner = require('../../uspBanner');

if (SitePreferences.AMAZON_PAY_ENABLED) {
	var amazonPayments = require('../../amazonPayments');
}

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
	$(window).on('scroll', manageShoppingInfoTotalContainerStickyness);
	manageShoppingInfoTotalContainerStickyness();

	$('#cart-table').on('click', '.bonus-item-actions a, .item-details .bonusproducts a', function (e) {
		e.preventDefault();
		bonusProductsView.show(this.href);
	});

	// override enter key for coupon code entry
	$('form input[name$="_couponCode"]').on('keydown', function (e) {
		if (e.which === keyboard.ENTER_KEY && $(this).val().length === 0) { return false; }
	});

	//to prevent multiple submissions of the form when removing a product from the cart
	var removeItemEvent = false;
	$(document).off('click', 'button[name$="deleteProduct"]').on('click', 'button[name$="deleteProduct"]', function (e) {
		e.preventDefault();

		if (removeItemEvent) {
			return;
		}

		removeItemEvent = true;

		var $form = $(this).closest('form');
		var url = util.ajaxUrl($form[0].action);

		var dataArray = $form.serializeArray();
		var data = {};
		dataArray.forEach(function (element) {
			data[element.name] = element.value;
		});

		var $btn = $(this);
		var attrname = $btn.attr('name');
		data[attrname] = attrname;
		accessibility.setKeyboardNavigation(isKeyboardNavActive($(this)));
		deleteProduct(url, data, $(this));
	});

	$('.pt_cart .js-product-variation-selector').on('change', function () {
		var variationAttributeID = $(this).data('variationAttributeId');
		var variationAttributeValueID = $(this).val();
		var uuid = $(this).data('uuid');
		//We send product information that is about to be changed on cart page
		//So it can be removed before it can be added as workaround on ometria
		var productSelector = $('.js-cart-row[data-uuid=' + uuid + ']');
		var product={};
		product.variantGroupId = productSelector.data('variantgroup-id');
		product.variantId = productSelector.data('variant-id');
		product.price = productSelector.data('price');
		product.quantity = productSelector.data('quantity');
		ometriaTracker.ometriaTrackBasket(product);
		accessibility.setKeyboardNavigation(isKeyboardNavActive($(this)));
		updateVariationValue(uuid, variationAttributeID, variationAttributeValueID);
		$('body').removeClass('items-is-open');
	});

	// handle cart line item update from the modal dialog for editing
	$('body').off('click', '[data-js="update-cart"]').on('click', '[data-js="update-cart"]', function () {
		var $container = $('.js-cart-products-redesign');
		var uuid = $container.find('.js-pdp-product-info').data('line-item-uuid');

		if (!uuid) { return; }

		var $productVariationsContainer = $container.find('.product-variations').first();
		var selectedColor = $productVariationsContainer.data('attributes').color.value;
		var selectedSize = $productVariationsContainer.data('attributes').size.value;
		var selectedQuantity = $container.find('[data-js="cart-qty-input"]').val();

		if (!selectedColor || !selectedQuantity) {
			//color and quantity will always be selected in edit-product dialog but this is a safety check
			return;
		}

		if (!selectedSize) {
			//show message for size not selected
			var messageElement = $container.find('#add-to-cart-description');
			messageElement.removeClass('u-hidden');
			var messageElementMobile = $container.find('#add-to-cart-description-mobile');
			messageElementMobile.removeClass('u-hidden');
			return;
		}

		var lineItemRowSelector = '.js-cart-row[data-uuid=' + uuid + ']';
		var $prevLineItemRow = $(lineItemRowSelector);

		// if color, size and quantity all remain same there is no need for ajax call
		if ($prevLineItemRow.data('color-value') == selectedColor
			&& $prevLineItemRow.data('size') == selectedSize
			&& $prevLineItemRow.data('quantity') == selectedQuantity) {
			closeEditProductDialog();
			return;
		}

		var requestData = {
			uuid: uuid,
			color: selectedColor,
			size: selectedSize,
			qty: selectedQuantity
		};

		progress.show();

		ajax.load({
			url: Urls.cartUpdateLineItem,
			type: 'POST',
			data: requestData,
			callback: function (response) {
				var $response = $(response);
				var $updatedLineItemRow = $response.find(lineItemRowSelector);

				// update page elements
				$('.js-cart-shopping-block').replaceWith($response.find('.js-cart-shopping-block'));
				$('.js-cart-order-total').replaceWith($response.find('.js-cart-order-total'));
				$('.cart-errors-wrapper').replaceWith($response.filter('.cart-errors-wrapper'));

				progress.hide();
				closeEditProductDialog();
				initializeEvents();
				triggerUpdateItemTrackingEvents($prevLineItemRow, $updatedLineItemRow, requestData);
			}
		});
	});

	$('body').off('change').on('change', '.js-state', updateState);

	$('body').on('change', '#gift-wrapping-checkbox', function () {
		accessibility.setKeyboardNavigation(isKeyboardNavActive($(this)));
		updateGiftWrapping(this.checked);
	});

	//Keyboard accessibility - Gift wrapping custom checkbox
	$('.order-giftwrap label').off('keydown').on('keydown', function (e) {
		var checkbox = $(this);
		if (e.which === keyboard.ENTER_KEY) {
			if (checkbox.attr('aria-checked') === 'false') {
				checkbox.attr('aria-checked', 'true');
				checkbox.trigger('click');
			}
			else {
				checkbox.attr('aria-checked', 'false');
				checkbox.trigger('click');
			}
			checkbox.trigger('focus');
		}
	});

	// Event listener on PayPal express button that saves the pickup person to cart
	$('body').on('click', '.js-payment-method', function(e) {
		var pickerOption = $('.js-picked-by-someone-else');
		var isBeingPickedBySomeoneElse = $('.picking-person-check').find('input, select').serializeArray();
		var data = $('.js-picking-person-form').find('input, select').serializeArray();

		if (pickerOption && pickerOption.is(':checked')) {
			data.push(isBeingPickedBySomeoneElse[0]);
		}

		//in case of paypal express we need to send it so we can add to session
		if ($(e.target).parent('.checkout-paypal').length) {
			data.push({ name: 'isPaypalExpress', value: true });
		}

		ajax.load({
			url: Urls.savePickupPersonToCart,
			data: data
		});
	});

	$('.js-cart-quantity').on('focus', function () {
		$(this).data('initval', $(this).val());
	});

	$('.js-cart-quantity').on('change', function (e) {
		e.preventDefault();

		var initval = parseInt($(this).data('initval'), 10);
		var newval = parseInt($(this).val(), 10);

		var $form = $(this).closest('form');
		var url = util.ajaxUrl($form[0].action);

		var dataArray = $form.serializeArray();
		var data = {};
		dataArray.forEach(function (element) {
			data[element.name] = element.value;
		});

		// get the submit button for this product row to update the quantity
		var cartSubmit = $(this).siblings('.js-cart-submit');
		var attrname = cartSubmit.attr('name');
		data[attrname] = attrname;
		var keyboardNavActive = isKeyboardNavActive($(this));
		updateProductQuantity(url, data, initval, newval, $(this), !!keyboardNavActive);
	});

	$('.js-product-edit').on('click', function (e) {
		e.preventDefault();
		var $form = $(this).closest('form');
		$form.find('.select-wrapper').addClass('is-open');
		$('body').addClass('items-is-open');
	});

	//swich between delivery options on cart page
	$('body').off('click', '.js-shipping-methods-container > div').on('click', '.js-shipping-methods-container > div', function () {
		var $this = $(this);
		if ($this.hasClass('is-selected')) { return; }
		var methodId = $this.data('shippingmethodid');

		if (methodId) {
			accessibility.setKeyboardNavigation(isKeyboardNavActive($this));
			updateShippingValue(methodId, '', '.js-shipping-method-option.is-selected', function() {
				if ($('.js-store-not-selected').length) {
					storeSelector.openStoreLocator(Urls.storeSelection, 'store-selection');
				}
			});
		}
	});

	//close modal for shipping methods
	$('body').off('click', '.js-shipping-method-change-cancel').on('click', '.js-shipping-method-change-cancel', function() {
		$(this).closest('.ui-dialog').find('button.ui-dialog-titlebar-close').trigger('click');
	});

	//save data for designated delivery to cart object on each change of data on cart page
	$('body').on('change', '.js-designateddelivery-detail-container .js-designated-delivery-time', function() {
		saveDesignatedToCartObj($(this));
	});

	// SFCC-1963 : The selection is working but need more backend work
	// Shipping options JS ( TODO : move this to a separated file)

	//shipping options click handler
	var deliveryOptionsElement = $('.js-checkout-delivery-options > div');
	if (SitePreferences.IS_RESKIN) {
		deliveryOptionsElement = $('.js-checkout-delivery-options input[type=radio]');
	}
	deliveryOptionsElement.off('click').on('click', function () {
		var $this = $(this);
		if ($this.hasClass('is-selected')) { return; }

		var selectedShippingMethod = $this.data('shippingmethodid');
		if (selectedShippingMethod) {
			accessibility.setKeyboardNavigation(isKeyboardNavActive($this));
			updateShippingValue(selectedShippingMethod, '', '.js-checkout-delivery-options > div.is-selected');
		}
	});

	tooltip.initializeCustomTooltip();

	util.calculateSize('[data-js="product-variation-selector"]');
	account.initCartLogin();
	initPaypalFunctionality();
	detailsModalDialog.init();
	util.initializeDatePickers(null, saveDesignatedToCartObj);
	storeSelector.init('selection', updateCartView);
	promotions.init(initializeEvents);
	wishlist.init();
	clickAndReserve.init();

	if (SitePreferences.AMAZON_PAY_ENABLED) {
		amazonPayments.init();
	}

	validateProducts();

	$('form.cart-action-checkout').off('submit').on('submit', validateStorePickupAndReserve);

	$('[data-js="paypal-express"]').off('click').on('click', validateStorePickupAndReserve);

	showWishlistNotification();

	uspBanner.init();
	initCustomSelects();
}

/**
 * Trigger GTM events when updating the cart line item from the new modal dialog
 */
var triggerUpdateItemTrackingEvents = function(prevLineItemContainer, updatedLineItemContainer, requestData) {
	var previousColor = prevLineItemContainer.data('color-value');
	var previousSize = prevLineItemContainer.data('size');
	var previousQty = prevLineItemContainer.data('quantity');

	var isVariantUpdated = previousColor != requestData.color || previousSize != requestData.size;
	var isQuantityUpdated = previousQty != requestData.qty;
	var variantId = updatedLineItemContainer.data('variant-id');
	var quantity = updatedLineItemContainer.data('quantity');

	// here we trigger GTM events for updated variation
	if (isVariantUpdated) {
		// then trigger adding of the new variation
		var variantUpdatedData = {
			eventId: util.generateUUID(),
			event: 'AddToCart',
			productId: variantId,
			quantity: quantity
		};
		fbconversion.triggerEvent(variantUpdatedData);
		$(document).trigger('addtocart', [variantUpdatedData.eventId, updatedLineItemContainer]);
		// trigger the rest of the events
		$(document).trigger('updatedcart');
		$(document).trigger('updatecheckout');
	}
	else if (isQuantityUpdated) {
		// here we trigger GTM events for updated quantity
		if (previousQty < requestData.qty) {
			var qtyUpdatedData = {
				eventId: util.generateUUID(),
				event: 'AddToCart',
				productId: variantId,
				quantity: requestData.qty - previousQty
			};
			fbconversion.triggerEvent(qtyUpdatedData);
			$(document).trigger('addtocart', [qtyUpdatedData.eventId, $('.js-cart-row[data-uuid="' + requestData.uuid + '"]'), requestData.qty - previousQty]);
		}
		// trigger the rest of the events
		$(document).trigger('updatedcart');
		$(document).trigger('updatecheckout');
	}
};

/**
 * Closes pop up for editing cart line item and restores the scroll position
 */
var closeEditProductDialog = function() {
	$('#QuickViewDialog').dialog('close');
	util.restoreScrollPosition('cart-edit-product-popup');
};

var validateStorePickup = function(e) {
	if ($('.js-store-selected').val() === 'false') {
		e.preventDefault();
		e.stopImmediatePropagation();
		$('.store-selected-error').removeClass('u-hidden');
		util.scrollBrowser($('.store-selected-error').position().top);
	}
};

// click & reserve products and store validation
var validateStorePickupAndReserve = function(e) {
	validateStock(e);
	validateProductsForCheckout(e);
	validateStore(e);
	validateStorePickup(e);
};

var validateStock = function(e) {
	var isProductQuantitiesUnavailable = $('.js-cart-articles tbody tr [data-js="not-available-error"]').length > 0;

	if (isProductQuantitiesUnavailable) {
		var offsetTop = $('.js-cart-articles tbody tr [data-js="not-available-error"]').eq(0).closest('tr')[0].offsetTop;
		var headerHeight = $('.js-cart-articles tbody tr [data-js="not-available-error"]').eq(0).closest('tr').position().top - $('.c-header-nav').height();
		util.scrollBrowser(offsetTop + 40);

		e.preventDefault();
		e.stopImmediatePropagation();
	}
};

// check if some products in cart are not avaliable for reservation
var validateProducts = function() {
	var productsValid = true;

	var isOneOrMoreProductsUnavailable = $('.js-cart-articles tbody tr .item-reserve-error').length > 0;
	if (isOneOrMoreProductsUnavailable) {
		$('.items-reserve-available-error').removeClass('u-hidden');
		util.scrollBrowser($('.items-reserve-available-error').position().top);
		productsValid = false;
	}

	var isOneOrMoreProductsNotAvailableForPIS = $('.js-cart-articles tbody tr item-clickandcollect-error js-item-clickandcollect-error').length > 0;

	if (isOneOrMoreProductsNotAvailableForPIS) {
		$('.items-clickandcollect-available-error').removeClass('u-hidden');
		util.scrollBrowser($('.items-clickandcollect-available-error').position().top);
		productsValid = false;
	}
	return productsValid;
};

var validateProductsForCheckout = function(e) {
	var areProductsValid = validateProducts();
	if (!areProductsValid) {
		e.preventDefault();
		e.stopImmediatePropagation();
	}
};

// check if store allows click and reserve
var validateStore = function(e) {
	var storeClickAndReserveErrorSelector = $('.store-reserve-error');
	if (storeClickAndReserveErrorSelector.length > 0) {
		e.preventDefault();
		e.stopImmediatePropagation();
		util.scrollBrowser($(storeClickAndReserveErrorSelector).position().top);
	}
};

var updateCartView = function(data) {
	$('[data-js="cart-wrapper"]').html(data);
	initializeEvents();
	updateMiniCart();
};

/**
 * @description Handler to handle the update product quantity
 */
var updateProductQuantity = function (url, requestData, initval, newval, element, isKeyboardNavActive) {
	progress.show();

	getPickupInStoreOptions(requestData);

	ajax.load({
		url: url,
		target: $('[data-js="cart-wrapper"]'),
		requestType: 'POST',
		data: requestData,
		callback: function () {
			// trigger gtm event
			if (initval > newval) {
				$(document).trigger('updatedcart');
			}
			else if (initval < newval) {
				var qtyUpdatedData = {
					eventId: util.generateUUID(),
					event: 'AddToCart',
					productId: element.closest('.js-cart-row').data('variant-id'),
					quantity: newval - initval
				};
				fbconversion.triggerEvent(qtyUpdatedData);
				$(document).trigger('addtocart', [qtyUpdatedData.eventId, element.closest('.js-cart-row'), newval - initval]);
				$(document).trigger('updatedcart');
			}
			initializeEvents();
			updateMiniCart();
			//Update GTM events
			$(document).trigger('updatecheckout');
			if (isKeyboardNavActive) {
				$('.btn.js-payment-method').trigger('focus');
			}
		}
	});
};

/**
 * Updates cart object selected state
 * @param {jQuery} changedInput
 */
var updateState = function() {
	progress.show();
	var $this = $(this);
	var stateCode = $this.val();
	var $form = $this.closest('form');
	var url = util.ajaxUrl($form[0].action);
	var reqData = {
		stateCode: stateCode,
		dwfrm_cart_updateState: 'dwfrm_cart_updateState'
	};

	accessibility.setKeyboardNavigation(isKeyboardNavActive($this));
	ajax.load({
		url: url,
		target: $('[data-js="cart-wrapper"]'),
		requestType: 'POST',
		data: reqData,
		callback: function () {
			progress.hide();
			initializeEvents();
			if (accessibility.isKeyBoardNavigationActive()) {
				accessibility.simulateTabKeyFocus($('.js-state'));
			}
		}
	});
};

/**
 * Updates cart object with selected designated delivery data on each change of the inputs
 * @param {jQuery} changedInput
 */
var saveDesignatedToCartObj = function(changedInput) {
	progress.show();
	var dataArray = $('.js-designated-delivery').find('select, input, select').serializeArray();

	var reqData = {};
	dataArray.forEach(function (element) {
		reqData[element.name] = element.value;
	});

	reqData.dwfrm_cart_updateDesignatedDelivery = 'dwfrm_cart_updateDesignatedDelivery';

	var $form = changedInput.closest('form');
	var url = util.ajaxUrl($form[0].action);

	ajax.load({
		url: url,
		requestType: 'POST',
		data: reqData,
		callback: function() {
			progress.hide();
		}
	});
};

var isKeyboardNavActive = function(element) {
	return !!element.hasClass('focus-visible');
};

var updateGiftWrapping = function (checked) {
	progress.show();

	var url = Urls.UpdateGiftWrappingCart,
		requestData = {
			giftWrappingEnabled: checked
		};

	getPickupInStoreOptions(requestData);

	ajax.load({
		url: url,
		requestType: 'POST',
		data: requestData,
		callback: function (data) {
			if (data) {
				$('.js-cart-order-total').replaceWith($(data).find('.js-cart-order-total'));

				if ($('[data-js="amazon-pay-wrapper"]').length > 0) {
					$('[data-js="amazon-pay-wrapper"]').html($(data).find('[data-js="amazon-pay-wrapper"]').html());
					$('[data-js="amazon-pay-wrapper"]').attr('data-initialized', false);
				}
			}
			progress.hide();
			initializeEvents();
			if (accessibility.isKeyBoardNavigationActive()) {
				accessibility.simulateTabKeyFocus($('.btn.js-payment-method'));
			}
		}
	});
};

/**
 * @description To update the minicard in the header
 */
var updateMiniCart = function () {
	Promise.resolve($.ajax({
		type: 'GET',
		url: util.ajaxUrl(Urls.updateMiniCart)
	})).then(function (response) {
		// handle error in the response
		if (response.error) {
			throw new Error(response.error);
		}
		else {
			minicart.update(response);
		}
	});
};

/**
 * @description Handler to handle the delete product from cart event
 */
var deleteProduct = function (url, requestData, element) {
	progress.show();

	getPickupInStoreOptions(requestData);

	ajax.load({
		url: url,
		target: $('[data-js="cart-wrapper"]'),
		requestType: 'POST',
		data: requestData,
		callback: function () {
			initializeEvents();
			//Trigger GTM events
			$(document).trigger('updatedcart');
			$(document).trigger('updatecheckout');
			if (accessibility.isKeyBoardNavigationActive()) {
				accessibility.simulateTabKeyFocus($('.btn.js-payment-method'));
			}
		}
	});
};

var updateShippingValue = function (shippingMethodID, stateCode, focusElementSelector, callback) {
	var url = Urls.cartUpdateShippingValue,
		requestData = {
			shippingMethodID: shippingMethodID,
			stateCode: stateCode
		};

	getPickupInStoreOptions(requestData);

	progress.show();
	ajax.load({
		url: url,
		target: $('[data-js="cart-wrapper"]'),
		type: 'POST',
		data: requestData,
		callback: function (data) {
			progress.hide();
			initializeEvents();

			if (callback) {
				callback(data);
			}

			if (accessibility.isKeyBoardNavigationActive() && focusElementSelector) {
				accessibility.simulateTabKeyFocus($(focusElementSelector));
			}
		}
	});
};

/**
 * This method updates the request data with the person details who will pickup the order from store.
 * @param {request data which will be used for an AJAX call} reqdata
 */
function getPickupInStoreOptions(reqdata) {
	var pickerOption = $('.js-picked-by-someone-else');

	if (pickerOption && pickerOption.length > 0) {
		var isBeingPickedBySomeoneElse = pickerOption.is(':checked');
		reqdata.pickingBySomeoneElse = isBeingPickedBySomeoneElse;

		if (isBeingPickedBySomeoneElse) {
			reqdata.pickerFirstname = $('.js-picker-firstname').val();
			reqdata.pickerLastname = $('.js-picker-lastname').val();
		}
	}
}

function closeEditOverlay() {
	$('.select-wrapper').removeClass('is-open');
	$('body').removeClass('items-is-open');
}

var updateVariationValue = function (uuid, variationAttributeID, variationAttributeValueID) {
	progress.show();

	var url = Urls.cartUpdateVariationValue,
		requestData = {
			uuid: uuid,
			variationAttributeID: variationAttributeID,
			variationAttributeValueID: variationAttributeValueID
		};

	getPickupInStoreOptions(requestData);

	ajax.load({
		url: url,
		target: $('[data-js="cart-wrapper"]'),
		type: 'POST',
		data: requestData,
		callback: function () {
			progress.hide();
			initializeEvents();
			// here we trigger GTM addtocart event with updated variation
			var productSelector = $('.js-cart-row[data-uuid=' + uuid + ']');
			var variantId = productSelector.data('variant-id');
			var quantity = productSelector.data('quantity');
			var data = {
				eventId: util.generateUUID(), event: 'AddToCart', productId: variantId, quantity: quantity
			};
			fbconversion.triggerEvent(data);
			$(document).trigger('addtocart', [data.eventId, productSelector]);
			$(document).trigger('updatedcart');
			$(document).trigger('updatecheckout');
			if (accessibility.isKeyBoardNavigationActive()) {
				accessibility.simulateTabKeyFocus($('.btn.js-payment-method'));
			}
		}
	});
};

/**
* @private
* @function
* @description Initialize additional functionality related with PayPal integration
*/
function initPaypalFunctionality() {
	var $expressButton = $('.js_paypal_start_ba_checkout');

	$expressButton.on('click', function () {
		if ($expressButton.data('is-address-exist') === true) {
			return true;
		}
		detailsModalDialog.open({
			url: $expressButton.data('edit-address-url'),
			options: {
				title: $expressButton.data('edit-address-title'),
				open: initEditDefaultShippingAddressForm
			}
		});
		return false;
	});

	function initEditDefaultShippingAddressForm() {
		var $form = $('#paypalEditDefaultShippingAddress');
		$form.on('click', '.apply-button', function () {
			if (!$form.valid()) {
				return false;
			}
			var applyName = $form.find('.apply-button').attr('name');
			var options = {
				url: $form.attr('action'),
				data: $form.serialize() + '&' + applyName + '=x',
				type: 'POST'
			};
			$.ajax(options).done(function (data) {
				if (typeof (data) !== 'string') {
					if (data.success) {
						detailsModalDialog.close();
						window.location = $expressButton.attr('href');
					}
					else {
						window.alert(data.message);
						return false;
					}
				}
				else {
					$('#dialog-container').html(data);
					initEditDefaultShippingAddressForm();
				}
			});
			return false;
		});

		$form.on('click', '.cancel-button, .close-button', function () {
			detailsModalDialog.close();
			return false;
		});

		$('#paypalSelectSavedAddress').on('change', function () {
			var data = $(this).val();
			try {
				data = JSON.parse(data);

				for (name in data) {
					var val = data[name];
					if (typeof val === 'string') {
						val = val.replace(/\^/g, "'");
					}
					$('#dwfrm_profile_address_' + name).val(val);
				}
			}
			catch (e) {
				$form.find('input:text').val('');
				$form.find('select').val('');
			}
		});

		// For SiteGenesis v100- the code bellow no need
		$('select[id$="_country"]', $form).on('change', function () {
			util.updateStateOptions($form);
		});
	}
}

var wishlistTimeOut;

/**
 * When cart page loads this function checks if there was a wishlist action saved to cookie from previous request.
 * If there is a cookie we parse it to retrieve information about which action is performed on wishlist and on which item of the cart.
 * According to that data we attach the proper notification to the proper row of the cart
 */
function showWishlistNotification() {
	var notificationCookie = util.readCookie('_ecco_wishlist_notification');

	if (!notificationCookie) {
		return;
	}

	// if the cookie exists we parse it to retrieve the action and index
	var notificationCookieArray = notificationCookie.split(' ');
	var notificationAction = notificationCookieArray[0];
	var notificationIndex = parseInt(notificationCookieArray[1], 10);

	// after parsing we remove the cookie so it doesn't persist in the session
	util.eraseCookie('_ecco_wishlist_notification');

	if (!notificationAction || isNaN(notificationIndex)) {
		return;
	}

	var notificationWrapper = $('<tr class="cart-row"><td class="information-msg is-info" colspan="3">' + Resources.ITEM_IN_WISHLIST + '</td></tr>').hide();
	var cartArticlesTable = $('.js-cart-articles tbody');

	// we attach the proper notification to the proper row of the cart articles table
	if (notificationIndex > 0) {
		notificationWrapper.insertAfter(cartArticlesTable.children('tr').eq(notificationIndex - 1));
	}
	else {
		notificationWrapper.insertBefore(cartArticlesTable.children('tr').eq(0));
	}
	notificationWrapper.fadeIn(500);

	// we remove the notification after 5 seconds
	clearTimeout(wishlistTimeOut);
	wishlistTimeOut = setTimeout(function () {
		notificationWrapper.fadeOut(500, function () {
			$(this).remove();
		});
	}, 5000);
}

function manageShoppingInfoTotalContainerStickyness() {
	var secureCheckoutButton = $('[data-js="secure-checkout"]');
	var stickyInfo = $('[data-js="shopping-info-total"]');

	if (!secureCheckoutButton.length || !stickyInfo) { return; }

	var isVisible = util.elementInViewport(secureCheckoutButton.get(0), 0);
	stickyInfo.toggleClass('is-sticky', !isVisible);

	if (isVisible) {
		stickyInfo.find('button').attr('tabindex', '-1');
	}
	else {
		stickyInfo.find('button').attr('tabindex', '0');
	}
}

/**
 * Loads custom dropdown
 */
function initCustomSelects() {
	$('[data-js="custom-dropdown-btn"]').off('click').on('click', function(e) {
		e.preventDefault();

		$(this).parent('.custom-select').toggleClass('active');

		var isExpanded = $(this).attr('aria-expanded') === 'true';
		$(this).attr('aria-expanded', isExpanded ? 'false' : 'true');
	});

	$('.select-dropdown li input').off('click').on('click', function(e) {
		e.preventDefault();
		e.stopImmediatePropagation();
	});

	$('.js-cart-submit').off('click').on('click', function(e) {
		e.preventDefault();
	});

	$('.select-dropdown li input').off('keyup').on('keyup', function(e) {
		e.preventDefault();
		e.stopImmediatePropagation();
	});

	$('.js-cart-submit').off('keyup').on('keyup', function(e) {
		e.preventDefault();
	});

	$(document).on('click', function(e) {
		var clickedCustomSelect = null;

		if (e.target && e.target.className.indexOf('js-custom-click') > -1) {
			clickedCustomSelect = $(e.target).closest('.custom-select');
		}

		$('.custom-select').each(function() {
			if (clickedCustomSelect == null || $(this).attr('id') !== clickedCustomSelect.attr('id')) {
				$(this).removeClass('active');
			}
		});
	});

	const optionsList = document.querySelectorAll('.select-dropdown li');

	optionsList.forEach((option) => {
		if ($(option).hasClass('disabled')) {
			return;
		}

		function handler(e) {
			var element = $(e.target); // label
			var parentElement = element.closest('[data-js="custom-dropdown"]');
			var defaultText = parentElement.attr('data-text');
			var actualSelectCssClass = parentElement.attr('data-css-class');
			var currentValue = parentElement.attr('data-selected-value');
			var newValue = $(this.children[1]).parent().find('input').val();

			// Click Events
			if (e.type === 'click' && e.clientX !== 0 && e.clientY !== 0) {
				if (currentValue === newValue) {
					element.closest('.custom-select').removeClass('active');
					return;
				}

				parentElement.find('[data-js="selected-value"]').text(defaultText + ': ' + this.children[1].textContent);
				element.closest('.custom-select').removeClass('active');

				parentElement.parent().find('.' + actualSelectCssClass).val(newValue);
				parentElement.parent().find('.' + actualSelectCssClass).trigger('change');
			}

			// Enter Key Events
			if (e.keyCode === 13) {
				if (currentValue === newValue) {
					element.closest('.custom-select').removeClass('active');
					return;
				}

				parentElement.find('[data-js="selected-value"]').text(defaultText + ': ' + this.children[1].textContent);
				element.closest('.custom-select').removeClass('active');

				parentElement.parent().find('.' + actualSelectCssClass).val(newValue);
				parentElement.parent().find('.' + actualSelectCssClass).trigger('change');
			}
		}

		$(option).off('keyup').on('keyup', handler);
		$(option).off('click').on('click', handler);
	});
}

exports.init = function () {
	initializeEvents();
};