'use strict';

const constants = require('../constants').storeAvailabilityCheckType;
var availabilityCheckType = constants.STOCK; //checking if product is in stock is default check type
var selectStoreCallback = null;
var googleMap;
var googleMarkers = [];
var storeDataArray = [];
var isScriptInitialized = false;
/**
 * Initializes google map wrapper
 * @param {Integer} checkType
 * @param {Array} storesData
 * @param {Function} callback
 */
function init(checkType, storesData, callback) {
	availabilityCheckType = checkType;
	storeDataArray = storesData;
	selectStoreCallback = callback;
	loadMap();
}

/**
 * Initializes google map and loads store pins
 */
function loadMap() {
	googleMap = null;
	googleMarkers = [];
	var mapElement = document.getElementById('js-googlemapstore');
	var mapWrapper = $('[data-js="map-wrapper"]');

	if (!mapElement) {
		return;
	}

	if (isScriptInitialized) {
		initGoogleMapObject(mapElement, mapWrapper);
		return;
	}
	// initialize the map
	var locale = $('#js-currentlocale').val().split('_')[0];
	$.getScript('//maps.googleapis.com/maps/api/js?key=' + SitePreferences.GOOGLEMAP_APIKEY + '&libraries=geometry&language=' + locale, function () {
		isScriptInitialized = true;
		initGoogleMapObject(mapElement, mapWrapper);
	});
}

function initGoogleMapObject(mapElement, mapWrapper) {
	var mapCenter = {
		lat: mapWrapper.data('latitude'),
		lng: mapWrapper.data('longitude')
	};

	//initialize google maps object
	googleMap = new google.maps.Map(mapElement, {
		center: mapCenter,
		scrollwheel: true,
		zoom: 14,
		minZoom: 3
	});

	initMarkers();
}

/**
 * Method that initialize markers
 * @param {Boolean} inStockOnly
 */
function initMarkers (isFilterOn) {
	for (var i = 0; i < storeDataArray.length; i++) {
		var storeMarker = storeDataArray[i];

		var isStoreAvailable = false;

		switch (availabilityCheckType) {
			case 0:
				isStoreAvailable = storeMarker.inStockFlag || storeMarker.reservationFlag;
				break;

			case 1:
				isStoreAvailable = storeMarker.clickAndCollectFlag;
				break;

			case 2:
				isStoreAvailable = storeMarker.reservationFlag;
				break;

			case 3:
				isStoreAvailable = storeMarker.inStockFlag;
				break;
		}

		if (isFilterOn && !isStoreAvailable) {
			continue;
		}

		var iconUrl = getStoreIconPath(storeMarker);
		var marker = new google.maps.Marker({
			map: googleMap,
			name: storeMarker.name,
			storeId: storeMarker.storeId,
			url: storeMarker.url,
			position: storeMarker.coordinates,
			coordinates: storeMarker.coordinates,
			icon: iconUrl,
			inStockMsg: storeMarker.inStockMsg,
			inStockFlag: storeMarker.inStockFlag,
			reservationFlag: storeMarker.reservationFlag,
			clickAndCollectFlag: storeMarker.clickAndCollectFlag,
			reserveurl: storeMarker.reserveurl,
			reservebuttontext: storeMarker.reservebuttontext,
			selectstoretext: storeMarker.selectstoretext,
			isselected: storeMarker.isselected
		});

		googleMarkers.push(marker);
		google.maps.event.addListener(marker, 'click', function () {
			var marker = this;
			if (selectStoreCallback) {
				selectStoreCallback(marker.storeId);
			}
		});
	}
}

/**
 * Returns store object
 * @param {String} storeId
 */
function getStoreObject(storeId) {
	var storeMarker = googleMarkers.filter(function(element) {
		return element.storeId == storeId;
	})[0];

	return storeMarker;
}

/**
 * Updates store pins icons
 * @param {String} storeId
 * @param {Boolean} isSelected
 */
function updateStoreIcon(storeId, isSelected) {
	if (!storeId) {
		return;
	}

	var storeMarker = googleMarkers.filter(function(element) {
		return element.storeId == storeId;
	})[0];

	//update selected marker icon
	var iconPath = getStoreIconPath(storeMarker, isSelected);
	storeMarker.setIcon(iconPath);
}

/**
 * Returns icon path for store pin.
 * Based on availabilityCheckType we either check store stock, c&c or c&r flags.
 * There are 4 icons that we use:
 * 	selected-green-store-pin (selected store and is available)
 * 	selected-gray-store-pin (selected store but it is unavailable)
 * 	green-store-pin (available store that is not selected)
 * 	green-gray-pin (unavailable store that is not selected)
 * @param {Object} storeMarker
 * @param {String} storeId
 * @returns {String}
 */
function getStoreIconPath(storeMarker, isSelectedStore) {
	var storeIconPath = window.Urls.staticPath + 'img/mixed/';
	var pinColor;

	if (isSelectedStore) {
		storeIconPath += 'selected-';
	}

	switch (availabilityCheckType) {
		case constants.CLICK_AND_RESERVE:
			pinColor = storeMarker.reservationFlag ? 'green' : 'gray';
			break;
		case constants.CLICK_AND_COLLECT:
			pinColor = storeMarker.clickAndCollectFlag ? 'green' : 'gray';
			break;
		default:
			pinColor = storeMarker.inStockFlag ? 'green' : 'gray';
			break;
	}

	storeIconPath += pinColor;
	return storeIconPath + '-store-pin.png';
}

/**
 * Removes current pins and renders new ones
 * @param {Boolean} inStockOnly
 */
function updateStoreMarkers(isFilterOn) {
	removePreviousMarkers();
	initMarkers(isFilterOn);
}

/**
 * Removes markers from map object and empties marker array
 */
function removePreviousMarkers() {
	if (googleMarkers != null) {
		for (var i = 0; i < googleMarkers.length; i++) {
			googleMarkers[i].setMap(null);
		}
	}
	googleMarkers = [];
}

/**
 * Update map and markers
 * @param {Object} coordinates
 * @param {String} storeId
 * @param {String} previouslySelectedStoreId
 */
function updateMap(coordinates, storeId, previouslySelectedStoreId) {
	googleMap.setCenter(coordinates);
	updateStoreIcon(storeId, true);
	if (previouslySelectedStoreId && previouslySelectedStoreId != storeId) {
		updateStoreIcon(previouslySelectedStoreId, false);
	}
}

module.exports = {
	init: init,
	updateMap: updateMap,
	getStoreObject: getStoreObject,
	updateStoreMarkers: updateStoreMarkers,
	updateStoreIcon: updateStoreIcon
};