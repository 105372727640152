'use strict';

var trackingHelper = require('./trackingHelper'),
	impressionsEvents = require('./impressions'),
	pageTitle = $('meta[property="og:title"]').attr('content');

/**
 * Pushes data to track store exclusivity PD component
 * @param {*} event
 * @param {*} componentId
 * @param {*} wrappedStoresList
 */
function pushStoreExclusivity(event, componentId, wrappedStoresList) {
	var eventLabel = 'Page name ' + pageTitle + ' | Page component ' + componentId;
	trackingHelper.pushBasicTrackingEvent('PageDesigner Storefinder', 'Storefinder Expand | Storefinder Collapse', eventLabel);

	// promotion impressions
	if (wrappedStoresList) {
		impressionsEvents.promotionImpressions(wrappedStoresList);
	}
}

/**
 * Pushes data to track newsletter form PD component
 * @param {*} event
 * @param {*} componentId
 * @param {*} wrappedSuccessSection
 */
function pushNewsletter(event, componentId, wrappedSuccessSection) {
	var eventLabel = 'Page name ' + pageTitle + ' | Page component ' + componentId;
	trackingHelper.pushBasicTrackingEvent('PageDesigner Newsletter', 'Submit', eventLabel);

	// promo impressions
	if (wrappedSuccessSection) {
		impressionsEvents.promotionImpressions(wrappedSuccessSection);
	}
}

function initializeEvents() {
	$(document).on('triggerStoreExclusivity', pushStoreExclusivity);
	$(document).on('triggerNewsletter', pushNewsletter);
}

/**
 * Initialize all events for pagedesigner GTM tracking
 */
exports.init = function () {
	initializeEvents();
};