'use strict';

var progress = require('./progress'),
	util = require('./util');

var currentRequests = [];

/**
 * @function
 * @description Ajax request to get json response
 * @param {Boolean} async  Asynchronous or not
 * @param {String} url URI for the request
 * @param {Object} data Name/Value pair data request
 * @param {Function} callback  Callback function to be called
 */
var getJson = function (options) {
	options.url = util.toAbsoluteUrl(options.url);
	var action = util.getAction(options.data);
	var isSameRequest = action ? currentRequests[options.url] && currentRequests[action] : currentRequests[options.url];
	// return if no url exists or url matches a current request (in case that we have action we check it also)
	if (!options.url || isSameRequest) {
		return;
	}

	currentRequests[options.url] = true;
	currentRequests[action] = true;

	// make the server call
	return $.ajax({
		dataType: 'json',
		url: options.url,
		async: (typeof options.async === 'undefined' || options.async === null) ? true : options.async,
		data: options.data || {}
	})
		.done(function (response) {
			// success
			if (options.callback) {
				options.callback(response);
			}
		})
		.fail(function (xhr, textStatus) {
			// failed
			if (textStatus === 'parsererror') {
				window.alert(Resources.BAD_RESPONSE);
			}
			if (options.callback) {
				options.callback(null);
			}
		})
		.always(function () {
			// executed on success or fail
			// remove current request from hash
			if (currentRequests[options.url]) {
				delete currentRequests[options.url];
			}
			if (currentRequests[action]) {
				delete currentRequests[action];
			}
		});
};
/**
 * @function
 * @description ajax request to load html response in a given container
 * @param {String} url URI for the request
 * @param {Object} data Name/Value pair data request
 * @param {Function} callback  Callback function to be called
 * @param {Object} target Selector or element that will receive content
 */
var load = function (options) {
	options.url = util.toAbsoluteUrl(options.url);
	var action = util.getAction(options.data);
	var isSameRequest = action ? currentRequests[options.url] && currentRequests[action] : currentRequests[options.url];
	// return if no url exists or url matches a current request (in case that we have action we check it also)
	if (!options.url || isSameRequest) {
		return;
	}

	currentRequests[options.url] = true;
	currentRequests[action] = true;

	var requestType = 'GET';
	if (options.requestType !== 'undefined') {
		requestType = options.requestType;
	}

	// make the server call
	return $.ajax({
		dataType: 'html',
		url: util.appendParamToURL(options.url, 'format', 'ajax'),
		data: options.data,
		xhrFields: {
			withCredentials: true
		},
		type: requestType
	})
		.done(function (response) {
			// success
			if (options.target) {
				$(options.target).empty().html(response);
			}
			if (options.callback) {
				options.callback(response);
			}
		})
		.fail(function (xhr, textStatus) {
			// failed
			if (textStatus === 'parsererror') {
				window.alert(Resources.BAD_RESPONSE);
			}
			if (options.callback) {
				options.callback(null, textStatus);
			}
		})
		.always(function () {
			// keepLoaderOpen in order to prevent progress.hide() when needed
			if (!options.keepLoaderOpen) {
				progress.hide();
			}

			// remove current request from hash
			if (currentRequests[options.url]) {
				delete currentRequests[options.url];
			}
			if (currentRequests[action]) {
				delete currentRequests[action];
			}
		});
};

exports.getJson = getJson;
exports.load = load;
