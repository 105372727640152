'use strict';

exports.init = function init () {
	// Add support for background images to lazysizes plugin
	// See https://github.com/aFarkas/lazysizes
	document.addEventListener('lazybeforeunveil', function(e) {
		var bg = e.target.getAttribute('data-bg');
		if (bg) {
			e.target.style.backgroundImage = 'url(' + bg + ')';
		}
	});
}