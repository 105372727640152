'use strict';

/**
 * @function
 * @description : Hide all modals when click on windows - only desktop
 */

var util = require('./util'),
	minicart = require('./minicart');

module.exports = (function () {
	var events = {
		resize: function () {
			$('body')
				.off('click', '.js-mini-cart') //unbid minicart on mobile
				.off('click', hideModals); //unbind on mobile

			$('body').on('click', hideModals);
		}
	};

	function hideModals (e) {
		// On click everywhere on the window, hide all the modals
		// Except on the areas defined below
		var $area = $(e.target).parents().addBack();
		if (!($area.is('.js-search-field'))
			&& !($area.is('.search-wrap'))
			&& !($area.is('.js-mini-cart'))
			&& !($area.is('.js-c-mini-cart'))
			&& !($area.is('.js-account-link-loggedin'))
			&& !($area.is('.side-flyout-wrapper'))
			&& !($area.is('[data-js="header-search-reset"]'))
			&& !$('.nav-container').is('.is-open')
			&& !$('.menu-list').is('.menu-list__hover')
			&& !($area.is('.js-nav-dropdown'))) {
			if ($('body').hasClass('search-is-open')) {
				util.closeSearch();
			}
			else if (!util.isSmallScreenSize()) {
				$('.js-overlay').fadeOut();
				minicart.close();
			}
		}
	}

	function init() {
		events.resize();
	}

	return {
		init: init
	};
})();
