var submitForm = require('./submitForm'),
	util = require('../../util'),
	accessibility = (util.isMobile()) ? require('../../accessibilityForMobile') : require('../../accessibility');

function initializeEvents() {
	accessibility.attachGiftWrappingEvents();

	/**
	 * Makes an AJAX call to add/remove gift wrapping.
	 */
	$('#gift-wrapping-checkbox').off('change').on('change', function() {
		submitForm.submitAjaxForm({ action: 'updateGiftWrapping' });
	});
}

exports.init = function() {
	initializeEvents();
};
