'use strict';
var _ = require('lodash');

/**
 * Loads images based on the viewport of the device.
 * To use this feature:
 * 1) add the following to your <img> tag:
 * <img data-js="desktop-image" data-desktop-src="<url-to-the-image" />
 * <img data-js="mobile-image" data-mobile-src="<url-to-the-image" />
 * 2) remove src attribute from <img> tag because otherwise browser
 * will load the image from src directly
 *
 *
 * It is also possible to use background images, use the following syntax for that:
 * <div data-js="desktop-image" data-desktop-bg="<url-to-the-image" />
 * <div data-js="mobile-image" data-mobile-bg="<url-to-the-image" />
 */
function loadDeviceSpecificImages() {
	if ($(window).width() > 1024) {
		var desktopImages = $('[data-js="desktop-image"]');
		desktopImages.each(function () {
			var $this = $(this);
			var src = $this.data('desktopSrc');
			if (src) {
				$this.attr('data-src', src);
			} else {
				var bg = $this.data('desktopBg');
				if (bg) {
					$this.attr('data-bg', bg);
				}
			}
			$this.addClass('lazyload');
			$this.attr('data-js', '');
		});
	} else {
		var mobileImages = $('[data-js="mobile-image"]');
		mobileImages.each(function () {
			var $this = $(this);
			var src = $this.data('mobileSrc');
			if (src) {
				$this.attr('data-src', src);
			} else {
				var bg = $this.data('mobileBg');
				if (bg) {
					$this.attr('data-bg', bg);
				}
			}
			$this.addClass('lazyload');
			$this.attr('data-js', '');
		});
	}
}

/**
 * Adds events to load device-specific images. On load it will update images directly.
 * On resize it has a delay to avoid slowing down the browser too much.
 */
function initializeDeviceSpecificImages() {
	$(window).on('load', loadDeviceSpecificImages);
	$(window).on('resize', _.debounce(loadDeviceSpecificImages, 250));
	$(document).on('updateProductListing', loadDeviceSpecificImages);
}

exports.init = initializeDeviceSpecificImages;