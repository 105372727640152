'use strict';

var inputValidation = require('./inputvalidation');
var adyenCheckout = null;

if (SitePreferences.ADYEN_ENABLED) {
	adyenCheckout = require('../../adyen-checkout');
}

function validateSection(sectionFieldsSelector) {
	var validationResult = true;
	$(sectionFieldsSelector).each(function () {
		validationResult = $(this).valid() && validationResult;
	});
	return validationResult;
}

function validateContactDetailsSection() {
	var contactDetailsFields = $('[data-js="contact-details-fieldset"] input');

	if (!contactDetailsFields.length) {
		//if we don't have contact details fields they are valid and readonly
		return true;
	}

	return validateSection(contactDetailsFields);
}

function validateShippingSection() {
	var shippingDetailsFields = $('[data-js="shipping-details-block"]').find('input, select');

	var isPickupInStoreSelected = $('.pickup-store').hasClass('is-selected');
	var isClickAndReserveSelected = $('.click-and-reserve').hasClass('is-selected');
	var isPickUpPointSelected = $('.js-pickup-point-delivery-option').hasClass('is-selected');
	var isShipToMeSelected = $('.ship-me').hasClass('is-selected');

	if (SitePreferences.IS_RESKIN) {
		isPickupInStoreSelected = $('.pickup-store').hasClass('js-is-selected');
		isClickAndReserveSelected = $('.click-and-reserve').hasClass('js-is-selected');
		isPickUpPointSelected = $('.js-pickup-point-delivery-option').hasClass('js-is-selected');
		isShipToMeSelected = $('.ship-me').hasClass('js-is-selected');
	}

	var storeError = !!$('[data-js="store-error"]').length;

	if (isPickupInStoreSelected) {
		// this checks if any of the products is not allowed for C&C
		var productsAreInStockForCC = inputValidation.validateProductsForClickAndCollect();
		var pickupinstoreUnAvailableProductsError = $('.js-cc-unavailable-item-exists').length > 0;

		if ($('.pickup-store').hasClass('js-store-not-selected') || !productsAreInStockForCC || storeError || pickupinstoreUnAvailableProductsError) {
			// check if store is selected
			$('[data-js="no-store-error"]').removeClass('u-hidden');
			return false;
		}
	}
	else if (isClickAndReserveSelected) {
		var productsAreInStockForCR = inputValidation.validateProductsForClickAndReserve();

		if ($('.click-and-reserve').hasClass('js-store-not-selected') || !productsAreInStockForCR || storeError) {
			// check if store is selected
			$('[data-js="no-store-error"]').removeClass('u-hidden');
			return false;
		}
	}
	else if (isPickUpPointSelected) {
		if ($('.js-pickup-point-delivery-option').hasClass('js-store-not-selected') || storeError) {
			// check if pickup point is selected
			$('[data-js="no-store-error"]').removeClass('u-hidden');
			return false;
		}
	}
	else if (isShipToMeSelected) {
		if (storeError) {
			return false;
		}
	}

	if (!shippingDetailsFields.length) {
		//if we don't have shipping details fields they are valid and readonly
		return true;
	}

	return validateSection(shippingDetailsFields);
}

function validatePaymentSection() {
	var privacyPolicyCheckbox = $('input[name$=_checkout_acceptprivacypolicy]');
	var isPrivacyPolicyValid = (privacyPolicyCheckbox.length ? privacyPolicyCheckbox.valid() : true);
	var isPaymentValid = validatePaymentOptions();
	var isBillingValid = validateBillingForm();

	var captchaValid = true;

	var captchaElement = $('.js-captcha-wrapper');
	if (captchaElement && captchaElement.length > 0) {
		captchaValid = $('#reCaptchaResponse').val() !== '';
	}

	if (!captchaValid) {
		$('.js-captcha-error').text(Resources.CHECKOUT_CAPTCHA_ERROR);
		$('.js-captcha-error').show();
		$('.js-captcha-error').removeClass('u-visually-hidden');
	}

	return captchaValid && isPaymentValid && isBillingValid && isPrivacyPolicyValid;
}

function validatePaymentOptions() {
	var ccFieldsAreValid = false;
	var paymentOptionNotCC = $('input[name$=_selectedPaymentMethodID]:checked').val() !== 'CREDIT_CARD';
	var isAdditionalDataValid = true;

	if (adyenCheckout) {
		ccFieldsAreValid = adyenCheckout.adyenValidation();

		if ($('input[name$=_selectedPaymentMethodID]:checked').val() === 'upi_collect') {
			if ($('input[name$=_vpa]') && $('input[name$=_vpa]').length > 0) {
				isAdditionalDataValid = $('input[name$=_vpa]').valid();
			}
			else {
				isAdditionalDataValid = false;
			}
		}
	}

	if ($('.js-creditcard-fieldset').length) {
		//in case that we have our payment form we are validating it
		ccFieldsAreValid = validateSection('.js-creditcard-fieldset input');
	}

	return (paymentOptionNotCC || ccFieldsAreValid) && isAdditionalDataValid;
}

function validateBillingForm() {
	var billingDetailsFields = $('.js-billing-form input, select');

	// neccessary to check if checkbox exists because it doesn’t get rendered when it is free order flow
	var useAsBillingAddress = $('input[id$="_useAsBillingAddress"]');
	var $useShippingAsBillingAddr = useAsBillingAddress.length === 1 ? useAsBillingAddress[0].checked : true;

	if (!billingDetailsFields.length) {
		// if we don't have billing details fields the order is free and billing is considered valid
		return true;
	}

	if ($useShippingAsBillingAddr) {
		// if we use shipping as billing address, we consider billing valid as the billing form is hidden as long as shipping is invalid
		return true;
	}

	return validateSection(billingDetailsFields);
}

function validateForPlaceOrder() {
	var isContactDetailsValid = validateContactDetailsSection();
	var isShippingValid = validateShippingSection();
	var isPaymentValid = validatePaymentSection();

	return isContactDetailsValid && isShippingValid && isPaymentValid;
}

exports.validateContactDetailsSection = validateContactDetailsSection;
exports.validateShippingSection = validateShippingSection;
exports.validatePaymentSection = validatePaymentSection;
exports.validateForPlaceOrder = validateForPlaceOrder;
exports.validateSection = validateSection;
exports.init = function() {
	inputValidation.initValidatePreviousFields(true);
	inputValidation.validateProductsForClickAndCollect();
	inputValidation.validateProductsForClickAndReserve();
};