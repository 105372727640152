'use strict';

var util = require('./util');

function lazyloadImages() {
	$(document).ready(function() {
		// lazyload yotpo image
		setTimeout(function() {
			var yotpoImage = $('.yotpo-lightbox-product').find('img');
			adjustImgElementForLazyloading(yotpoImage);
		}, 500);
	});
}

function adjustImgElementForLazyloading(imgElement) {
	if (util.isMobile()) {
		imgElement.attr('data-js', 'mobile-image');
		imgElement.attr('data-mobile-src', imgElement.attr('src'));
	}
	else {
		imgElement.attr('data-js', 'desktop-image');
		imgElement.attr('data-desktop-src', imgElement.attr('src'));
	}
	imgElement.removeAttr('src');
}

exports.init = function () {
	lazyloadImages();
};