'use strict';

var detailsmodaldialog = require('../../detailsmodaldialog'),
	util = require('../../util'),
	ajax = require('../../ajax'),
	progress = require('../../progress'),
	accessibility = (util.isMobile()) ? require('../../accessibilityForMobile') : require('../../accessibility'),
	forms = require('../../forms'),
	checkoutPromotions = require('../checkout/promotions');

var hostPageInitEvents = null;

function initializeEvents() {
	initializePromoBlockEvents();

	$('body').off('click', '.js-add-coupon').on('click', '.js-add-coupon', addCoupon);
	$('body').off('click', '.js-remove-coupon').on('click', '.js-remove-coupon', removeCoupon);
	$('body').off('click', '.js-add-manage-promotion-codes').on('click', '.js-add-manage-promotion-codes', openPromoPopup);
}

/**
 * This function is related PromoCode in the Cart and Checkout
 */
function initializePromoBlockEvents() {
	$('[data-js="promotion-code-toggler"]').off('click').on('click', function() {
		var $parent = $(this).closest('[data-js="promotion-inline"]');
		var $content = $('[data-js="promotion-content"]');

		if ($parent.hasClass('open')) {
			$content.slideUp();
			$parent.removeClass('open');
			$(this).attr('aria-expanded', false);
			$(this).attr('title', Resources.PROMO_BLOCK_EXPAND);
			document.cookie = 'promoblock-expanded=false; path=/';
		}
		else {
			$content.slideDown();
			$parent.addClass('open');
			$(this).attr('aria-expanded', true);
			$(this).attr('title', Resources.PROMO_BLOCK_COLLAPSE);
			document.cookie = 'promoblock-expanded=true; path=/';
		}

		// aria accessibility attribute
		$(this).attr('aria-expanded', $parent.hasClass('open'));
	});
}

/**
 * Function to Handle the add coupon to cart event
 */
var addCoupon = function (e) {
	e.preventDefault();
	var $this = $(this);
	var $container = $this.closest('.js-promotion-popup');

	if ($container.length == 0) {
		$container = $('.js-promotion-block');
	}

	var data = getPickupPersonData();
	var couponCode = $('.js-promotion-input').val();
	var attrname = $this.attr('name');
	data.push({
		name: attrname,
		value: attrname
	});
	data.push({
		name: 'couponCode',
		value: couponCode
	});
	var isFocusVisible = $container.find('.focus-visible').length > 0;
	accessibility.setKeyboardNavigation(isFocusVisible);

	var url = Urls.cartApplyCoupon;

	progress.show();
	ajax.load({
		url: url,
		requestType: 'POST',
		data: data,
		callback: function(response) {
			handleCouponCallback(response, true, isFocusVisible);
		}
	});
};

/**
 * Handles remove coupon from cart event
 * @param {Object} e
 */
var removeCoupon = function (e) {
	e.preventDefault();
	var $this = $(this);
	var $container = $this.closest('.js-promotion-popup');

	if ($container.length == 0) {
		$container = $('.js-promotion-block');
	}

	var data = getPickupPersonData();
	var attrname = $this.attr('name');
	data.push({
		name: attrname,
		value: attrname
	});

	var isFocusVisible = $container.find('.focus-visible').length > 0;
	accessibility.setKeyboardNavigation(isFocusVisible);

	var url = Urls.cartRemoveCoupon;
	progress.show();
	ajax.load({
		url: url,
		requestType: 'POST',
		data: data,
		callback: function(response) {
			handleCouponCallback(response, false, isFocusVisible);
		}
	});
};

/**
 * Handles open promotion popup event
 * @param {Object} e
 */
function openPromoPopup(e) {
	e.preventDefault();
	var url = Urls.cartOpenPromotionDialog;
	var isFocusVisible = $(this).hasClass('focus-visible');
	progress.show();
	ajax.load({
		url: url,
		requestType: 'GET',
		callback: function(response) {
			progress.hide();
			if (!response) {
				return;
			}
			var promotionBlock = $(response).filter('.js-promotion-popup');

			if (promotionBlock.length == 0) {
				return;
			}

			detailsmodaldialog.open({
				html: promotionBlock,
				options: {
					closeOnEscape: false,
					dialogClass: 'no-close'
				}
			});

			if (isFocusVisible) {
				accessibility.simulateTabKeyFocus($('.js-promotion-input'));
			}
		}
	});
}

/**
 * Handle the server response after sending ajax call to add/remove the coupon
 */
function handleCouponCallback(response, isAddCoupon, isFocusVisible) {
	var $response = $(response);
	var error = $response.find('[data-js="promotion-error"]');
	var isPromoModal = $('.js-add-manage-promotion-codes');

	//if there are no errors reload the page
	if (!error.length) {
		refreshDataContainers(response);
		checkoutPromotions.addNotification(isAddCoupon);

		//if coupon successfully applied we want to clear the input
		if (isAddCoupon) {
			$('[data-js="promotion-input"]').val('');
		}

		//Check if promotion block is displayed in dialog(modal)
		if (isPromoModal.length) {
			detailsmodaldialog.close();
		}

		$(document).trigger('updatecarttotals');

		// accessibility feature - returns focus to add promo codes link after dialog is closed
		if (accessibility.isKeyBoardNavigationActive()) {
			var toggleIcon = $('[data-js="promotion-code-toggler"]');
			if (toggleIcon.length > 0) {
				accessibility.simulateTabKeyFocus($('[data-js="promotion-code-toggler"]'));
			}
			else {
				accessibility.simulateTabKeyFocus($('.js-add-manage-promotion-codes'));
			}
		}
	}
	else {
		$('.js-promotion-popup').html($response.filter('.js-promotion-popup').html());
		$('[data-js="promotion-inline"]').html($response.filter('[data-js="promotion-inline"]').html());

		if (SitePreferences.IS_RESKIN) {
			$('[data-js="promotion-input"]').addClass('error');
		}

		if (accessibility.isKeyBoardNavigationActive()) {
			accessibility.simulateTabKeyFocus($('.js-promotion-input'));
		}
	}

	initializePromoBlockEvents();
	forms.checkInputFields();
	progress.hide();
}

/**
 * This function will replace parts of the cart page that are affected when a coupon is applied:
 * - products
 * - delivery options
 * - totals
 * Last it will re-initialize events from the main page
 * @param {Object} response The response of the AJAX call containing the cart or checkout page
 */
var refreshDataContainers = function(response) {
	var $response = $(response);
	$('.js-cart-shopping-block').html($response.find('.js-cart-shopping-block').html());
	$('.js-cart-order-total').html($response.find('.js-cart-order-total').html());
	$('.js-checkout-delivery-options').html($response.find('.js-checkout-delivery-options').html());
	$('.js-checkout-delivery-details').html($response.find('.js-checkout-delivery-details').html());

	if ($('[data-js="amazon-pay-wrapper"]').length > 0) {
		var amazonPayWrapper = $response.find('[data-js="amazon-pay-wrapper"]');
		if (amazonPayWrapper.length) {
			$('[data-js="amazon-pay-wrapper"]').html(amazonPayWrapper.html());
			$('[data-js="amazon-pay-wrapper"]').attr('data-initialized', false);
		}
		else {
			// we don't display the Amazon Pay button any more since it is not available anymore. The reason might be the order is now free after entering some coupon codes
			$('[data-js="amazon-pay-wrapper"]').html('');
		}
	}

	if ($('[data-js="amazon-pay-wrapper-mobile"]').length > 0) {
		$('[data-js="amazon-pay-wrapper-mobile"]').html($response.find('[data-js="amazon-pay-wrapper-mobile"]').html());
	}

	if (hostPageInitEvents) {
		hostPageInitEvents();
	}
};

/**
 * Returns objects array of pickup person fields or empty array in case that we don't have pickup person fields
 * @returns {Array}
 */
function getPickupPersonData() {
	var returnData = [];
	var pickupPerson = $('.js-picking-person-form').find('input, select');
	if (pickupPerson.length) {
		returnData = pickupPerson.serializeArray();
	}

	var pickerOption = $('.js-picked-by-someone-else');
	var isBeingPickedBySomeoneElse = $('.picking-person-check').find('input, select');

	if (pickerOption && isBeingPickedBySomeoneElse.length && pickerOption.is(':checked')) {
		returnData.push(isBeingPickedBySomeoneElse.serializeArray()[0]);
	}

	return returnData;
}

exports.init = function (pageInitEvents) {
	if (pageInitEvents) {
		hostPageInitEvents = pageInitEvents;
	}

	initializeEvents();
};