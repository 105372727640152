'use strict';

var progress = require('../progress');
var ajax = require('../ajax');
var forms = require('../forms');
var inputValidation = require('../pages/checkout/inputvalidation');
var submitForm = require('../pages/checkout/submitForm');

exports.init = function(context) {
	if (context.toLowerCase() === 'account') {
		initAutofillCityAndStateOnAccount();
	}
	else {
		initAutofillCityAndStateOnCheckout();
		validatePincode();
	}
};

/**
 * Updates address with new data from responseData (used for address auto complete)
 * @param {Object} responseData contains fields success, city and state
 * @param {Object} addressFieldIds ids of elements needed (postal, city and state)
 * @param {Boolean} showError should error message appear or not
 */
function autofillStateAndCity(responseData, addressFieldIds, showError) {
	if (responseData == null || responseData == '') {
		return;
	}
	var addressCity = $(addressFieldIds['city']);
	var addressState = $(addressFieldIds['state']);
	var addressPostal = $(addressFieldIds['pincode']);
	var pincodeErrorWrapper = $('.pincode-error-wrapper');
	var pincodeWrapper = $('.pincode-input-field');
	// Container selectors
	var inputContainerClasses = '.input-container';
	var addressData = JSON.parse(responseData);

	// If there is an error in response show error message according to showError
	// and empty city and state values
	if (Object.keys(addressData).length == 0 || !addressData.success) {
		if (showError) {
			var $errorEl = addressPostal.addClass('error').removeClass('valid').next('.error');
			addressPostal.attr('aria-invalid', 'true');
			addressPostal.closest(inputContainerClasses).removeClass('is-valid').addClass('has-error');
			if (!$errorEl || $errorEl.length === 0) {
				$errorEl = $('<span class="pincode-error"></span>');
				pincodeErrorWrapper.empty().append($errorEl);
				$errorEl.text(Resources.PINCODE_DELIVERY_UNAVAILABLE).show();
				pincodeWrapper.addClass('with-error');
			}
			else {
				pincodeWrapper.removeClass('with-error');
			}
		}
		addressCity.val('');
		addressCity.closest(inputContainerClasses).removeClass('is-valid');
		addressState.val('');
		return;
	}
	else {
		pincodeWrapper.removeClass('with-error');
	}

	// If the response status is success fill in the city and state
	if (addressData.city !== '') {
		addressCity.val(addressData.city).trigger('focusin').trigger('focusout');
	}

	if (addressData.state !== '') {
		addressState.val(addressData.state).trigger('focusin').trigger('focusout');
	}
}

// if the pincode that exists isn`t in the list of pincodes on BE
// than make it invalid
function validatePincode() {
	var pincode = $('#dwfrm_checkout_shipping_address_postal').val();
	if (pincode) {
		$('body').ready(function() {
			var data = [{
				name: 'action',
				value: 'autofillStateAndCity'
			}, {
				name: 'pincode',
				value: pincode
			}, {
				name: 'responseType',
				value: 'json'
			}];
			ajax.load({
				url: Urls.autofillStateAndCity,
				requestType: 'POST',
				data: data,
				callback: function(data) {
					var addressFieldIds = {
						'pincode': '#dwfrm_checkout_shipping_address_postal',
						'city': '#dwfrm_checkout_shipping_address_city',
						'state': '#dwfrm_checkout_shipping_address_states_state',
					};
					autofillStateAndCity(data, addressFieldIds, true);
				}
			});
		});
	}
}

function initAutofillCityAndStateOnCheckout() {
	$('body').off('change', '#dwfrm_checkout_shipping_address_postal').on('change', '#dwfrm_checkout_shipping_address_postal',
		function(e) {
			var pincode = $('#dwfrm_checkout_shipping_address_postal').val();
			var data = [{
				name: 'action',
				value: 'autofillStateAndCity'
			}, {
				name: 'pincode',
				value: pincode
			}, {
				name: 'responseType',
				value: 'json'
			}];
			progress.show();
			ajax.load({
				url: Urls.autofillStateAndCity,
				requestType: 'POST',
				data: data,
				keepLoaderOpen: true,
				callback: function(data) {
					var addressFieldIds = {
						'pincode': '#dwfrm_checkout_shipping_address_postal',
						'city': '#dwfrm_checkout_shipping_address_city',
						'state': '#dwfrm_checkout_shipping_address_states_state',
					};
					autofillStateAndCity(data, addressFieldIds, true);
					forms.checkInputFields();
					progress.hide();
				}
			});
			e.stopImmediatePropagation();
		});

	$('body').off('change', '#dwfrm_checkout_billing_address_postal').on('change', '#dwfrm_checkout_billing_address_postal',
		function(e) {
			var pincode = $('#dwfrm_checkout_billing_address_postal').val();
			var data = [{
				name: 'action',
				value: 'autofillStateAndCity'
			}, {
				name: 'pincode',
				value: pincode
			}, {
				name: 'responseType',
				value: 'json'
			}];
			progress.show();
			ajax.load({
				url: Urls.autofillStateAndCity,
				requestType: 'POST',
				data: data,
				callback: function(data) {
					var addressFieldIds = {
						'pincode': '#dwfrm_checkout_billing_address_postal',
						'city': '#dwfrm_checkout_billing_address_city',
						'state': '#dwfrm_checkout_billing_address_states_state',
					};
					autofillStateAndCity(data, addressFieldIds);
					forms.checkInputFields();
					progress.hide();
				}
			});
			e.stopImmediatePropagation();
		});
}

// for India autofill state and city based on pincode provided
function initAutofillCityAndStateOnAccount() {
	$('body').off('change', '#dwfrm_profile_address_postal').on('change', '#dwfrm_profile_address_postal',
		function(e) {
			var pincode = $('#dwfrm_profile_address_postal').val();
			var data = [{
				name: 'pincode',
				value: pincode
			}];
			progress.show();
			ajax.load({
				url: Urls.autofillStateAndCity,
				requestType: 'POST',
				data: data,
				callback: function(data) {
					var addressFieldIds = {
						'pincode': '#dwfrm_profile_address_postal',
						'city': '#dwfrm_profile_address_city',
						'state': '#dwfrm_profile_address_states_state',
					};
					autofillStateAndCity(data, addressFieldIds);
					forms.checkInputFields();
					progress.hide();
				}
			});
			e.stopImmediatePropagation();
		});

	$('body').off('change', '#dwfrm_profile_customer_zipcode').on('change', '#dwfrm_profile_customer_zipcode',
		function(e) {
			var pincode = $('#dwfrm_profile_customer_zipcode').val();
			var data = [{
				name: 'pincode',
				value: pincode
			}];
			progress.show();
			ajax.load({
				url: Urls.autofillStateAndCity,
				requestType: 'POST',
				data: data,
				callback: function(data) {
					var addressFieldIds = {
						'pincode': '#dwfrm_profile_customer_zipcode',
						'city': '',
						'state': '#dwfrm_profile_customer_state',
					};
					autofillStateAndCity(data, addressFieldIds);
					forms.checkInputFields();
					progress.hide();
				}
			});
			e.stopImmediatePropagation();
		});
}
