var forms = require('../../forms'),
	progress = require('../../progress'),
	ajax = require('../../ajax'),
	dialog = require('../../dialog'),
	payment = require('./payment'),
	util = require('../../util');

if (SitePreferences.AMAZON_PAY_ENABLED) {
	var amazonPayments = require('../../amazonPayments');
}

var hostPageInitEvents;

/**
 * Submits the checkout form using AJAX. Afterwards updates the page with new information.
 * @param {Object} options Allows you to customize the AJAX request with extra details:
 * 		action: the name of the action to send to the server
 * 		extraParameters: an array of name/value pairs to send to the server on top of the form itself
 * 		callback: a function that will be called after all default replacements are done
 */
function submitAjaxForm(options) {
	var data = getRequestData();
	var url = Urls.checkoutSubmitForm;
	if (options) {
		if (options.extraParameters) {
			if (options.skipPageData) {
				data = options.extraParameters;
			}
			else {
				data = data.concat(options.extraParameters);
			}
		}
		if (options.action) {
			data.push({ name: 'action', value: options.action });
		}
		if (options.url) {
			url = options.url;
		}
		if (options.isStateChanged) {
			data.push({ name: 'isStateChanged', value: options.isStateChanged });
		}
		if (options.responseType && options.responseType.toLowerCase() === 'json') {
			data.push({ name: 'responseType', value: 'json' });
		}
		if( typeof ometria !== 'undefined' && User.userData.visitorStatus == 'Guest' && options.action == 'saveContactDetails' && data[0].name == 'dwfrm_checkout_billing_email_emailAddress' ){
			var email = data[0].value;
			ometria.identify(email);
		}
	}
	var restoreFocus = $(document.activeElement).closest('.js-checkout-bag').find('.js-skip-link').attr('href');
	var keyboardNavActive = $(this).hasClass('focus-visible');
	progress.show();
	ajax.load({
		url: url,
		requestType: 'POST',
		data: data,
		keepLoaderOpen: options.keepLoaderOpen || false,
		callback: function(response) {
			handleSubmitCallback(response, options, restoreFocus, keyboardNavActive);
		}
	});
}

function handleSubmitCallback(response, options, restoreFocus, keyboardNavActive) {
	if (!response) { return; }

	if (options && options.responseType && options.responseType.toLowerCase() === 'json') {
		//in case that we have respone type json we are just calling the callback if exists
		if (options && options.callback) {
			options.callback(response);
		}
		return;
	}

	// if "Continue" button clicked on the popup and if no items remaining in the basket then we redirect to cart page where it is empty
	if (options.action == 'continueOvernightShippingMethod' && util.find(response, '[data-js="iscartpage"]').length > 0) {
		location.href = Urls.cartShow;
		return;
	}

	var deliveryOptionHasChanged = isDeliveryOptionChanged(response);

	var $response = $(response);
	var shippingSectionIsUpdated = false;
	var action = options ? options.action : '';
	var showUnavailableProductsInStorePopup = (action === 'setStore' || action === 'unavailableItemsPopup' || (action === 'updateShippingMethod' && options && options.isShipToMeOvernightMethod))
		&& $response.find('[data-js="store-unavailable-products-popup-content"]').length > 0;
	var isDetailsPopup = options ? options.isDetailsPopup : false;
	var keepAllUnavailableAlert = options ? options.keepAllUnavailableAlert : false;

	if (action === 'setAllCheckoutFields') {
		if (SitePreferences.IS_RESKIN) {
			$('[data-js="checkout-login"]').remove();
		}
		else {
			$('.login-links-desktop').remove();
			$('.login-links.only-mobile').remove();
			$('.login-info').remove();
		}
	}

	var newPaymentSection = util.find(response, '[data-js="payment-section"]');
	$('[data-js="payment-section"]').attr('class', newPaymentSection.attr('class'));

	var newPaymentSectionWrapper = util.find(response, '[data-js="payment-section-wrapper"]');
	$('[data-js="payment-section-wrapper"]').attr('class', newPaymentSectionWrapper.attr('class'));

	var adyenSessionScriptSection = util.find(response, '#adyenSessionScript');
	$('#adyenSessionScript').replaceWith(adyenSessionScriptSection);

	if (action === 'updateShippingMethod' || deliveryOptionHasChanged || action === 'setAllCheckoutFields') {
		// When a shipping method changes, we need to update the entire shipping method block.
		// This is because for in-store pickup there is no shippin address.
		var htmlToReplaceWith = $response.find('.js-checkout-method');
		if (options.keepFlyOutOpen) {
			htmlToReplaceWith.find('[data-js="shipping-details-overlay"]').addClass('opened');
		}
		$('.js-checkout-method').replaceWith(htmlToReplaceWith);
		shippingSectionIsUpdated = true;

		$('.checkout-payment').replaceWith($response.find('.checkout-payment'));
	}
	else {
		if (!keepAllUnavailableAlert) {
			// in other cases, we update the list of applicable shipping methods only.
			$('.js-checkout-delivery-options').replaceWith($response.find('.js-checkout-delivery-options'));
		}

		if (!isDetailsPopup) {
			if (keepAllUnavailableAlert) {
				if ($response.find('[data-js="omni-store-availability"]').length > 0) {
					$('[data-js="omni-store-availability"]').html($response.find('[data-js="omni-store-availability"]').html());
					$('[data-js="omni-store-availability"]').addClass('with-results');
				}
			}
			else {
				$('.js-checkout-delivery-details').replaceWith($response.find('.js-checkout-delivery-details'));
			}
		}

		if ($('[data-js="checkout-shipping-readonly"]').length && action !== 'useShippingAsBilling') {
			$('[data-js="checkout-shipping-readonly"]').replaceWith(util.find(response, '[data-js="checkout-shipping-readonly"]'));
		}

		$('.checkout-giftcard-block').replaceWith($response.find('.checkout-giftcard-block'));

		if ($response.find('[data-js="warnings-errors-shippment"]').length) {
			$('.shipping-method-details').replaceWith($response.find('.shipping-method-details'));
		}
	}

	if (showUnavailableProductsInStorePopup) {
		dialog.open({
			html: util.find(response, '[data-js="store-unavailable-products"]').html(),
			closeAll: false,
			options: {
				dialogClass: 'unavailable-items-dialog'
			}
		});
	}

	if (!isDetailsPopup && $response.find('.js-store-results').length > 0) {
		$('.js-store-results').html($response.find('.js-store-results').html());
	}

	// when pickup point is selected and order toggles between free/not free - we refresh the div containing the billing person form (js-checkout-shipping)
	if ($response.find('.pickup-point.is-selected').length > 0) {
		if (('.js-free-order-billing-person-form').length > 0
			|| $response.find('.js-free-order-billing-person-form').length > 0) {
			$('.js-checkout-shipping').replaceWith($response.find('.js-checkout-shipping'));
		}
	}

	var wasContactDetailsEditable = $('[data-js="checkout-contact"]').data('editable');
	var updatedContactDetails = $(response).find('.js-checkout-contact');
	var isContactDetailsEditable = updatedContactDetails.data('editable');

	if ((isContactDetailsEditable !== wasContactDetailsEditable) || action === 'saveContactDetails' || action === 'editContactDetails' || action === 'setAllCheckoutFields') {
		$('.js-checkout-contact').replaceWith(updatedContactDetails);
		forms.initEmailTypoSuggestionEvents();
		if (!shippingSectionIsUpdated) {
			$('.delivery-options-title').replaceWith($response.find('.delivery-options-title'));
		}
	}

	payment.updatePaymentSection(response, action);

	if (!shippingSectionIsUpdated) {
		if ($('.js-checkout-billing').length == 0) {
			$('.js-checkout-payment').append($response.find('.js-checkout-billing'));
		} else {
			$('.js-checkout-billing').replaceWith($response.find('.js-checkout-billing'));
		}
	}
	if (action === 'updatePaymentMethod') {
		var $paymentPanel = $('.adyen-issuer-row');

		if ($paymentPanel && $paymentPanel.length > 0) {
			for (var i = 0; i < $paymentPanel.length; i++) {
				var currentPaymentPanel = $paymentPanel[i];
				var paymentWrapper = $(currentPaymentPanel).closest('.js-radio-container-payment');

				var issuerValue = paymentWrapper.attr('data-issuer');

				var cssClass = $(currentPaymentPanel).attr('data-class');
				var newPaymentPanel = $response.find('.' + cssClass);
				$('.is-active').find('.' + cssClass).replaceWith(newPaymentPanel);

				if (issuerValue) {
					var newIssuerSelector = newPaymentPanel.find('[data-js="payment-method-issuer"]');
					if (newIssuerSelector && newIssuerSelector.length > 0) {
						newIssuerSelector.val(issuerValue);
					}
				}
			}
		}

		$('.adyen-issuer-row').trigger('issuerchange');
	}

	$('[data-js="click-and-reserve-promo-info-wrapper"]').replaceWith($response.find('[data-js="click-and-reserve-promo-info-wrapper"]'));
	$('.js-cart-order-values').replaceWith($response.find('.js-cart-order-values'));
	$('[data-js="checkout-bag-header"]').replaceWith($response.find('[data-js="checkout-bag-header"]'));
	$('.js-mini-cart').replaceWith($response.find('.js-mini-cart'));
	$('.js-checkout-cta').replaceWith($response.find('.js-checkout-cta'));

	if (SitePreferences.IS_RESKIN) {
		$('[data-js="step-indicator"]').replaceWith($response.find('[data-js="step-indicator"]'));
	}
	else {
		if (action === 'setAllCheckoutFields' || action === 'applepayoperations') {
			$('[data-js="step-indicator"]').replaceWith($response.find('[data-js="step-indicator"]'));
		}
		else {
			$('[data-js="step-indicator"]').replaceWith($response.filter('[data-js="step-indicator"]'));
		}
	}

	if (hostPageInitEvents) {
		hostPageInitEvents();
	}

	if (SitePreferences.AMAZON_PAY_ENABLED) {
		$('[data-js="amazon-pay-wrapper"]').replaceWith($response.find('[data-js="amazon-pay-wrapper"]'));
		$('[data-js="amazon-pay-wrapper-mobile"]').replaceWith(util.find(response, '[data-js="amazon-pay-wrapper-mobile"]'));
		$('[data-js="amazon-pay-wrapper"]').data('initialized', false);

		if (action !== 'saveShippingDetails') {
			amazonPayments.init();
		}
	}

	if (restoreFocus && keyboardNavActive) {
		$(restoreFocus).find(':visible:focusable:first').trigger('focus');
	}

	forms.formRadiobuttonKeyboardFocusEffects();
	forms.checkInputFields();
	if (options && options.callback) {
		options.callback(response);
	}
}

/**
 * Checks if we have different selected options on page and in ajax response
 * @param {String} data ajax response data
 */
function isDeliveryOptionChanged(data) {
	var currentSelectedDeliveryOption = $('.js-checkout-delivery-options > .is-selected').data('deliveryoptionid');
	var newSelectedDeliveryOption = $(data).find('.js-checkout-delivery-options > .is-selected').data('deliveryoptionid');

	if (SitePreferences.IS_RESKIN) {
		currentSelectedDeliveryOption = $('.js-checkout-delivery-options > .js-is-selected').data('deliveryoptionid');
		newSelectedDeliveryOption = $(data).find('.js-checkout-delivery-options > .js-is-selected').data('deliveryoptionid');
	}

	return currentSelectedDeliveryOption !== newSelectedDeliveryOption;
}
/**
 * Populate and return request data
 * @returns {Array} array of request data objects
 */
function getRequestData () {
	var data = $('[data-js="checkout-form"]').find('input,select,textarea').serializeArray();
	var filteredData = [];
	for (var i = 0; i < data.length; i++) {
		if (data[i].name.indexOf('dwfrm_checkout_payment_creditCard') === -1 && data[i].name !== 'action') {
			filteredData.push(data[i]);
		}
	}
	var $giftWrappingInput = $('#gift-wrapping-checkbox');
	if ($giftWrappingInput.length > 0) {
		filteredData.push({ name: 'giftWrappingChecked', value: $giftWrappingInput.is(':checked') });
	}
	return filteredData;
}

exports.init = function(callbackFunction) {
	hostPageInitEvents = callbackFunction;
};
exports.getRequestData = getRequestData;
exports.submitAjaxForm = submitAjaxForm;
exports.handleSubmitCallback = handleSubmitCallback;