'use strict';

var util = require('../util'),
	trackingHelper = require('./trackingHelper'),
	customEvents = require('./customEvents'),
	trackSuggestionsTimeout = null;

/**
 * Gets page number from the query string
 */
function getPageNumber() {
	var params = util.getQueryStringParams(window.location.search.substr(1));
	if (params.start) {
		return params.start - 1;
	}

	return 0;
}

/**
 * Gets the list name from current query string
 * @param {*} queryString
 * @returns
 */
function getSearchList(queryString) {
	if (queryString != null && queryString.indexOf('q=') != -1) {
		return 'Search Results';
	}

	if ($('.js-categorysearch-category').length) {
		return 'Category';
	}
	if ($('#js-gtm-listname').length) {
		return $('#js-gtm-listname').val();
	}

	return 'Unknown';
}

/**
 * Pushes data to track product impressions
 * @param {*} productTiles
 * @param {*} listName
 * @param {*} impressionsArray
 */
function addProductImpressions(productTiles, listName, impressionsArray) {
	// IE don't support default values for function parameters
	var startIndex = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

	productTiles.each(function(index, value) {
		var $this = $(this);
		var productTrackingObj = trackingHelper.getTrackingProduct($this, false, (startIndex + index + 1), listName, productTiles.length);
		impressionsArray.push(productTrackingObj);

		// 3.2 product click
		$(this).find('a:not(.js-quickviewbutton, .js-product-edit)').off('click.productClick').on('click.productClick', function(e) {
			var $this = $(this);
			//if we navigate with tab and tabindex is -1 do early return
			if ($this.closest('li').hasClass('focus-visible') && $this.attr('tabindex') < 0) { return; }

			var customEventAction = $this.parents().hasClass('js-search-popularitems') ? 'Click search suggestion - Popular items' : 'Click search suggestion';
			customEvents.trackQuickSearchItemClick(customEventAction, productTrackingObj.name);

			if (window.sessionStorage) {
				sessionStorage.setItem(trackingHelper.sessionStorageListName, listName);
			}

			var destinationUrl = $this.attr('href');
			var trackingObj = {
				'event': 'productClick',
				'eventCallback': function() {
					document.location = destinationUrl;
				},
				'eventTimeout': 1000,
				'ecommerce': {
					'click': {
						'actionField': {
							'list': listName
						},
						'products': [productTrackingObj]
					}
				}
			};
			trackingHelper.pushToDataLayer(trackingObj);
		});

		$(this).find('a.js-quickviewbutton').off('click.productClick').on('click.productClick', function(e) {
			if (window.sessionStorage) {
				sessionStorage.setItem(trackingHelper.sessionStorageListName, listName);
			}
		});
	});
}

/*
    This method goes through the page and will send 'promoView' events for all elements
    that exist on the page with the attribute data-promoid.
    It will also add a click event to the promotion that sends a 'promoClick' event.
    Note: this method is intended to be called once since it does not track if the event has already been sent.
*/
function promotionImpressions(wrapperElement) {
	var productPromotionsArray = [];
	var bannerPromotionsArray = [];
	var promotionBlocks = $('#main, #main-content, #pd-main, header').find('a[data-promoid],div[data-promoid]');
	var bannerElementsCount = 0;
	var highlightedElementCount = 0;

	// in case we want to get the promo impressions only within a specific wrapping element then we get those promotion data from within that wrapper element only
	if (wrapperElement) {
		promotionBlocks = wrapperElement.find('a[data-promoid],div[data-promoid]');
	}

	promotionBlocks.each(function() {
		var clickableElement = trackingHelper.getClickableElement($(this));

		if (!clickableElement || !(clickableElement.is(':visible') || clickableElement.children().is(':visible'))) {
			return;
		}

		// get the promotion tracking object

		var position = $(this).data('promoposition');

		if (!position) {
			position = $(this).closest('[data-js="tile-wrapper"]').data('promoposition');
		}

		var promoid = $(this).data('promoid');

		if (position == null) {
			if (promoid.toLowerCase().indexOf('bannerpromo') == 0 || promoid.toLowerCase().indexOf('productpromo') == 0) {
				bannerElementsCount++;
				position = bannerElementsCount;
			}
			else {
				highlightedElementCount++;
				position = highlightedElementCount;
			}
		}

		var promotionObj = {
			'id': promoid,
			'name': $(this).data('promoname'),
			'creative': $(this).data('promocreative'),
			'position': position
		};

		clickableElement.off('click.promotionImpressions').on('click.promotionImpressions', function(e) {
			$(this).trigger('promotionImpressionsClick');
		});

		clickableElement.off('promotionImpressionsClick').on('promotionImpressionsClick', function(e) {
			var trackingObj = {
				'event': 'promotionClick',
				'ecommerce': {
					'promoClick': {
						'promotions': [promotionObj]
					}
				},
				'eventCallback': function(){},
				eventTimeout: 3000
			};
			trackingHelper.pushToDataLayer(trackingObj);

			return false;
		});

		if ($(this).data('promotype')) {
			var type = $(this).data('promotype');

			if (type.toLowerCase().indexOf('productpromo') == 0) {
				productPromotionsArray.push(promotionObj);
			}
			else {
				bannerPromotionsArray.push(promotionObj);
			}
		}
		else {
			bannerPromotionsArray.push(promotionObj);
		}
	});

	if (productPromotionsArray.length > 0) {
		trackingHelper.ecommerceMerge({
			'ecommerce': {
				'promoView': {
					'promotions': productPromotionsArray
				}
			}
		});
	}

	if (bannerPromotionsArray.length > 0) {
		var trackingObj = {
			'event': 'promotionView',
			'ecommerce': {
				'promoView': {
					'promotions': bannerPromotionsArray
				}
			}
		};
		trackingHelper.pushToDataLayer(trackingObj);
	}
}

/**
 * Binds the events to tracj product impressions
 */
function productImpressions() {
	// recommended product impression
	var impressionsArray = [];
	var addedProductsTile = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

	// common selector for PD components that contains product tiles
	if ($('.tile-wrap').length) {
		$('.tile-wrap').each(function(i, pdTileBlock) {
			var listName = $(pdTileBlock).attr('data-componentname');
			var productTiles = $(pdTileBlock).find('.tile-detail');
			var pageNumber = getPageNumber();
			addProductImpressions(productTiles, listName, impressionsArray, pageNumber);
		});
	}

	if ($('.js-search-result-articles').length) {
		var listName = getSearchList($('#js-rawurl').val().split('?')[1]);
		var productTiles = addedProductsTile ? addedProductsTile : $('.js-search-result-articles').find('.js-product-tile');
		var pageNumber = getPageNumber();
		addProductImpressions(productTiles, listName, impressionsArray, pageNumber);
	}

	// recommendations
	// We only push visible product tiles to the dataLayer. This is because the same rendering template for product recommendations can  be used
	// for a component which becomes visible on a user interaction and there might be also some other recommendations on the page
	// which are already visible on page load. So only the visible ones should be pushed to dataLayer.
	if ($('.js-ourpicks:visible').length) {
		var listName = 'ProductPage Cross sell';
		var rawurl = $('#js-rawurl').val().toLowerCase();
		if (rawurl.indexOf('search-show') !== -1) {
			listName = 'You might like - No result';
		}
		else if (rawurl.indexOf('cart-show') !== -1) {
			listName = 'You might like - Cart';
		}
		else if (rawurl.indexOf('wishlist-show') !== -1) {
			listName = 'You might like - Wishlist';
		}
		else if (rawurl.indexOf('account-') !== -1 || rawurl.indexOf('address-list')) {
			listName = 'You might like - Account';
		}

		var productTiles = $('.js-ourpicks:visible').find('.js-product-tile');

		addProductImpressions(productTiles, listName, impressionsArray);
	}

	// wishlist products
	if ($('[data-js="wishlist-product"]').length) {
		var listName = 'MyWishlist';
		var productTiles = $('[data-js="wishlist-product"]');

		addProductImpressions(productTiles, listName, impressionsArray);
	}

	if (impressionsArray.length > 0) {
		trackingHelper.ecommerceMerge({
			'event': 'productImpression',
			'ecommerce': {
				'currencyCode': $('#js-currency').val(),
				'impressions': impressionsArray
			}
		});
	}
}

/**
 * Product listing update event trigger
 * @param {*} event
 */
function updateProductListing(event) {
	trackingHelper.ecommerceObject = null;
	var addedProductsTile = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

	productImpressions(addedProductsTile);
	promotionImpressions();

	if (trackingHelper.ecommerceObject != null) {
		trackingHelper.pushToDataLayer(trackingHelper.ecommerceObject);
		triggerImpressionsEvent();
	}
}

/**
 * Show search suggestions event trigger
 */
function showSearchSuggestions() {
	if (trackSuggestionsTimeout != null) {
		clearTimeout(trackSuggestionsTimeout);
		trackSuggestionsTimeout = null;
	}

	trackSuggestionsTimeout = setTimeout(function() {
		trackingHelper.ecommerceObject = null;
		var impressionsArray = [];
		var promoImpressions = { 'promotions': [] };
		var productTiles = $('#search-suggestions').find('.js-product-tile');

		addProductImpressions(productTiles, 'Searchbox', impressionsArray);
		addPromoImpressions(productTiles, promoImpressions);
		pushProductImpression(impressionsArray, promoImpressions);
		triggerImpressionsEvent();
	}, 1000);
}

/**
 * Show search popular items event trigger
 * @param {*} event
 */
function showSearchPopularItems(event) {
	trackingHelper.ecommerceObject = null;
	var impressionsArray = [];
	var promoImpressions = { 'promotions': [] };
	var productTiles = $('.js-search-popularitems').find('.js-product-tile');

	addProductImpressions(productTiles, 'SearchPopularItems', impressionsArray);
	addPromoImpressions(productTiles, promoImpressions);
	pushProductImpression(impressionsArray, promoImpressions);
	triggerImpressionsEvent();
}

/**
 * Recommendations loaded event trigger
 * @param {*} event
 */
function recommendationsLoaded(event) {
	trackingHelper.ecommerceObject = null;
	var impressionsArray = [];
	var promoImpressions = { 'promotions': [] };

	// get the product tiles only which are nested within the current recommendation block which fired this event
	// otherwise if there are multiple recommendation block then we might get all of them with this selector
	var productTiles = $(event.currentTarget).find('.js-product-tile');

	addProductImpressions(productTiles, 'EinsteinHighlightedItems', impressionsArray);
	addPromoImpressions(productTiles, promoImpressions);
	pushProductImpression(impressionsArray, promoImpressions);
	triggerImpressionsEvent();
}

/**
 * Pushes data to track product impressions
 * @param {*} impressionsArray
 * @param {*} promoImpressions
 */
function pushProductImpression(impressionsArray, promoImpressions) {
	var trackingObj = {
		'event': 'productImpression',
		'ecommerce': {
			'currencyCode': $('#js-currency').val(),
			'impressions': impressionsArray,
			'promoView': promoImpressions
		}
	};

	trackingHelper.pushToDataLayer(trackingObj);
}

/**
 * Pushes data to trigger product impression clicks
 */
function triggerImpressionsEvent() {
	var trackingObj = {
		'event': 'GA Tracking',
		'eventCategory': 'ProductImpressions',
		'eventAction': 'Click',
		'eventLabel': undefined,
		'eventValue': undefined,
		'eventNonInteraction': false
	};

	trackingHelper.pushToDataLayer(trackingObj);
}

/*
    This method goes through the page and will send 'promoView' events for all elements
    that are given as the productTiles parameter with the attribute data-promoid.
    It will also add a click event to the promotion that sends a 'promoClick' event.
    Note: this method is intended to be called after an AJAX call for any new elements.
*/
function addPromoImpressions(productTiles, impressionsArray) {
	productTiles.each(function(index, value) {
		var $this = $(this);

		if ($this.data('promoid')) {
			var clickableElement = trackingHelper.getClickableElement($(this));

			// if there is valid clickable element
			if (clickableElement) {
				var promoImpressionObj = {
					'id': $(this).data('promoid'),
					'name': $(this).data('promoname'),
					'creative': $(this).data('promocreative'),
					'position': index + 1
				};
				impressionsArray.promotions.push(promoImpressionObj);

				// promoClick event
				clickableElement.off('click.addPromoImpressions').on('click.addPromoImpressions', function(e) {
					$(this).trigger('addPromoImpressionsClick');
				});

				clickableElement.off('addPromoImpressionsClick').on('addPromoImpressionsClick', function(e) {
					var trackingObj = {
						'event': 'promotionClick',
						'ecommerce': {
							'promoClick': {
								'promotions': [promoImpressionObj]
							}
						},
						'eventCallback': function(){},
						eventTimeout: 3000
					};
					trackingHelper.pushToDataLayer(trackingObj);

					return false;
				});
			}
		}
	});
}

function initializeEvents() {
	productImpressions();

	// lister page update (sorting, filtering, load more, ..)
	$(document).on('updateProductListing', updateProductListing);

	// search suggestions
	$(document).on('showSearchSuggestions', showSearchSuggestions);

	// search popular items
	$(document).on('showSearchPopularItems', showSearchPopularItems);

	// einstein recommendations are loaded
	// there might be multiple einstein recommendations on the page and some of them might be not visible
	// so we only want this event to be fired for the ones which are visible
	$(document).on('recommendationsloaded', 'div.js-recommendations:visible', recommendationsLoaded);

	// promotion impressions
	promotionImpressions();
}

/**
 * Initialize all events for impressions GTM tracking
 */
exports.init = function () {
	initializeEvents();
};