var _ = require('lodash'),
	flags = require('../../flags'),
	submitForm = require('./submitForm'),
	addressComplete = require('../../addresscomplete'),
	forms = require('../../forms'),
	util = require('../../util');
var market = SitePreferences.LOCALE ? SitePreferences.LOCALE.substring(3) : '';


function initializeEvents() {
	var $shippingForm = $('.js-shipping-form');
	//get all shipping address fields except pincode and state because they have their own events
	var shippingAddressFields = '.js-shipping-form input:not(.pincode-input-field):not(.js-add-to-address-book), .js-shipping-form select:not(.js-state):not(.js-address-list)';
	flags.setFlag('preventBillingPersonUpdate', true);

	$('body').off('focusin.addressfields').on('focusin.addressfields', shippingAddressFields, _.debounce(function() {
		if ($('[data-js="warnings-errors-shippment"]').length) {
			$('[data-js="warnings-errors-shippment"]').remove();
		}
	}));
	//Initialize checks for billing address input fields
	forms.inputFieldMaxlength();

	//update shipping address section on checkout
	//debounce is used because if we use auto populate by browser, it will change multiple fields at the same time and each change will trigger this event
	//we don't want to send multiple requests in that case, so we use debounce and it will just trigger once
	$('body').off('change.addressfields').on('change.addressfields', shippingAddressFields,
		_.debounce(function() {
			//we need to check status of the flag so ve can prevent multiple ajax calls at the same time
			var preventShippingAddressUpdate = flags.getFlag('preventShippingAddressUpdate');
			if (preventShippingAddressUpdate) {
				//set flag in case that we prevent this call and it needs to be later called (e.g. user clicks on state field but doesn't change it)
				flags.setFlag('shippingAddressUpdateTriggered', true);
				return;
			}

			var isTriggeredByAddressSelect = flags.getFlag('isTriggeredByAddressSelect');
			if (isTriggeredByAddressSelect) {
				$('[data-js="shipping-add-to-address-book"]').addClass('u-hidden');
			}
			else if ($('select[name$="_addressList"]').length > 0) {
				$('select[name$="_addressList"]')[0].selectedIndex = 0;
				$('[data-js="shipping-add-to-address-book"]').removeClass('u-hidden');
				util.eraseCookie('_preferred_shipping_address_id');
			}

			flags.setFlag('isTriggeredByAddressSelect', false);
		}, 500));

	var billingAddressFields = '.js-billing-form input:not(.js-add-to-address-book), .js-billing-form select:not(.js-address-list)';

	$('body').off('change.billingaddressfields').on('change.billingaddressfields', billingAddressFields,
		_.debounce(function() {
			var isTriggeredByAddressSelect = flags.getFlag('isTriggeredByBillingAddressSelect');
			if (isTriggeredByAddressSelect) {
				$('[data-js="billing-add-to-address-book"]').addClass('u-hidden');
			}
			else if ($('select[name$="_billing_addressList"]').length > 0) {
				$('select[name$="_billing_addressList"]')[0].selectedIndex = 0;
				$('[data-js="billing-add-to-address-book"]').removeClass('u-hidden');
				util.eraseCookie('_preferred_billing_address_id');
			}

			flags.setFlag('isTriggeredByBillingAddressSelect', false);
		}, 500));

	$('body').off('change.pickupperson').on('change.pickupperson', '.js-picking-person-form input', function() {
		var $pickupPersonForm = $('.js-picking-person-form input');
		var filled = true;
		$pickupPersonForm.each(function() {
			filled = filled && $(this).val();
		});
		var preventBillingPersonUpdate = flags.getFlag('preventBillingPersonUpdate');
		if (filled && !preventBillingPersonUpdate) {
			submitForm.submitAjaxForm({ action: 'updateShippingAddress' });
		}
	});

	$(document).off('addressautocompleted').on('addressautocompleted', function(e, form) {
		if ($(form).hasClass('js-shipping-form')) {
			submitForm.submitAjaxForm({ action: 'updateShippingAddress' });
		}
		else {
		}
	});

	//useAsBilling will be checked so collapse billing form by default
	if ($('input[id$="_useAsBillingAddress"]').length > 0 && $('input[id$="_useAsBillingAddress"]')[0].checked) {
		$('.js-billing-form').slideUp(200);
	}

	// combine shipping and billing forms
	$('body').off('change.billingaddress').on('change.billingaddress', 'input[id$="_useAsBillingAddress"]', function() {
		var options = {
			action: 'useShippingAsBilling'
		};

		if ($('input[id$="_useAsBillingAddress"]')[0].checked !== true) {
			util.eraseCookie('_preferred_billing_address_id');
			options.extraParameters = [{ name: 'edit', value: true }, { name: 'container', value: 'js-checkout-billing' }];
		}

		submitForm.submitAjaxForm(options);
	});

	// useAsBilling will be checked so collapse billing form by default (for payment only page)
	$('body').off('change.billingaddress.paymentonly').on('change.billingaddress.paymentonly', 'input[id$="_useAsBillingAddressPaymentOnly"]', function() {
		var useAsBillingAddress = $('input[id$="_useAsBillingAddressPaymentOnly"]');
		var isChecked = useAsBillingAddress[0].checked;

		if (isChecked) {
			$('.js-billing-form').slideUp(200);
			$('.js-billing-form').addClass('u-hidden');
			useAsBillingAddress.val(true);
			useAsBillingAddress.attr('checked', true);
		}
		else {
			$('.js-billing-form').slideDown(200);
			$('.js-billing-form').removeClass('u-hidden');
			useAsBillingAddress.val(false);
			useAsBillingAddress.attr('checked', false);
		}
	});

	$('body').off('change.state').on('change.state', '.js-shipping-form .js-state', function() {
		submitForm.submitAjaxForm({ action: 'updateShippingAddress', isStateChanged: true });
		flags.setFlag('shippingAddressUpdateTriggered', false);
		flags.setFlag('shippingAddressStateChanged', false);
	});

	$('body').off('focus.state', '.js-shipping-form .js-state').on('focus.state', '.js-shipping-form .js-state', function() {
		flags.setFlag('preventShippingAddressUpdate', true);
	});

	$('body').off('blur.state', '.js-shipping-form .js-state').on('blur.state', '.js-shipping-form .js-state', function() {
		flags.setFlag('preventShippingAddressUpdate', false);

		//after shipping address is changed if user clicks on state field we prevent ajax call so they can't change it during ajax call
		//if they don't change the state we want to update shipping address so previous change can be aplied
		var shippingAddressUpdateTriggered = flags.getFlag('shippingAddressUpdateTriggered');
		if (shippingAddressUpdateTriggered) {
			$shippingForm.find('input').trigger('change');
		}
	});

	$('body').off('focus.addresslist', '.js-shipping-form select[name$="_addressList"]')
		.on('focus.addresslist', '.js-shipping-form select[name$="_addressList"]', function() {
			flags.setFlag('preventShippingAddressUpdate', true);
		});

	$('body').off('blur.addresslist', '.js-shipping-form select[name$="_addressList"]')
		.on('blur.addresslist', '.js-shipping-form select[name$="_addressList"]', function() {
			flags.setFlag('preventShippingAddressUpdate', false);

			//after shipping address is changed if user clicks on address list field we prevent ajax call so they can't change it during ajax call
			//if they don't change the address we want to update shipping address so previous change can be applied
			var shippingAddressUpdateTriggered = flags.getFlag('shippingAddressUpdateTriggered');
			if (shippingAddressUpdateTriggered) {
				$shippingForm.find('input').trigger('change');
			}
		});

	// select address from list
	$shippingForm.off('change.addresslist').on('change.addresslist', 'select[name$="_addressList"]', function () {
		var selected = $(this).children(':selected').first();
		var selectedAddress = $(selected).data('address');
		if (!selectedAddress) {
			// clear address fields data if default option is selected
			clearAddressForm($shippingForm);
			return;
		}

		//save the address choice of the customer in a session cookie
		util.createCookie('_preferred_shipping_address_id', selectedAddress.ID);
		flags.setFlag('preventShippingAddressUpdate', true);

		var $stateField = $shippingForm.find('.js-state');
		if (!$stateField.length) {
			//in case of designated delivery we need to switch to designated delivery state field
			$stateField = $('.js-designateddelivery-detail-container .js-state');
		}

		if ($stateField.val() !== selectedAddress.stateCode) {
			$stateField.val(selectedAddress.stateCode);
			flags.setFlag('shippingAddressStateChanged', true);
		}

		//update form inputs and trigger form inputs change event
		util.fillAddressFields(selectedAddress, $shippingForm);
		flags.setFlag('isTriggeredByAddressSelect', true);
		$shippingForm.find('input').trigger('change');
		//we also need to trigger change event on the designated delivery state field
		$('.js-designateddelivery-detail-container .js-state').trigger('change');
	});

	var $billingForm = $('.js-billing-form');
	// select address from list
	$billingForm.off('change.addresslist').on('change.addresslist', 'select[name$="_addressList"]', function () {
		var selected = $(this).children(':selected').first();
		var selectedAddress = $(selected).data('address');
		if (!selectedAddress) {
			// clear address fields data if default option is selected
			clearAddressForm($billingForm);
			return;
		}

		//save the address choice of the customer in a session cookie
		util.createCookie('_preferred_billing_address_id', selectedAddress.ID);

		//populate form inputs
		util.fillAddressFields(selectedAddress, $billingForm);

		flags.setFlag('isTriggeredByBillingAddressSelect', true);
		$('[data-js="billing-add-to-address-book"]').addClass('u-hidden');
	});
}
/**
 * Function used to clear shipping and billing address fields if default option is selected
 * @param {*} $addressForm 
 */
function clearAddressForm($addressForm) {
	$addressForm.find(':input').each(function () {
		if (this.classList.contains('js-address-list')) {
			return;
		}
		var parentContainer = this.parentElement.parentElement;
		$(this).val('');
		$(parentContainer).removeClass('is-valid is-filled');
	}
	);
}

exports.init = function() {
	initializeEvents();

	if (market === 'CA' || $('[data-js="google-autocomplete-form"]').length > 0) {
		addressComplete.init();
	}
};
