/**
 * @file This file manage the variations in Product Detail Page
**/

'use strict';

var _ = require('lodash'),
	ajax = require('../../ajax'),
	image = require('./image'),
	video = require('../../video'),
	tooltip = require('../../tooltip'),
	util = require('../../util'),
	sizeSelector = require('../../sizeselector'),
	flyout = require('../../flyout'),
	wishlist = require('../wishlist'),
	yotpoReviews = require('../../yotpoReviews'),
	storeAvailability = require('./storeavailability'),
	swiper = require('../../swiper'),
	pdpAccordion = require('../../pdpAccordion'),
	productDetail = require('./productDetailCta'),
	qtyUpdate = require('../../quantitySelector');

var accessibility = (util.isMobile()) ? require('../../accessibilityForMobile') : require('../../accessibility');

var selectedSwatchWrapperId = '';

var setSwatchWrapperId = function(id) {
	selectedSwatchWrapperId = id;
};

/**
 * Updates the URL on the browser.
 * @param {String} href
 * @param {Booleand} isQuickView
 * @param {Boolean} isProductEdit
 */
var updatePageURL = function(href, isQuickView, isProductEdit) {
	var currentURL = window.location.href;
	var selectedVariationURL = util.removeParamFromURL(href, 'source');
	var cleanURL, updatedPageURL;

	if (isQuickView) {
		// if variant was updated from quickview dialog we don't update the whole page URL, but only the value of the quickviewExpanded param
		cleanURL = util.removeParamFromURL(currentURL, 'quickviewExpanded');
		updatedPageURL = util.appendParamToURL(cleanURL, 'quickviewExpanded', selectedVariationURL);
		util.updateBrowserURLByReplacingTheCurrentState(updatedPageURL);
	}
	else if (isProductEdit) {
		cleanURL = util.removeParamFromURL(currentURL, 'editProduct');
		updatedPageURL = util.appendParamToURL(cleanURL, 'editProduct', selectedVariationURL);
		util.updateBrowserURLByReplacingTheCurrentState(updatedPageURL);
	}
	else {
		util.updateBrowserURLByReplacingTheCurrentState(selectedVariationURL);
	}

	return selectedVariationURL;
};

/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {Boolean} isColorSwatch - If the content updated either for colorswatch or size
 * @param {String} href - url of the new product variant
 * @param {String} url - url for update description content
 * @param {Int} clickPosition - total distance from the first swatch to the swatch customer clicked
 * @param {function} callback - callback function, not mandatory
 **/
var updateContent = function (isColorSwatch, href, url, clickPosition, callback, element) {
	var $pdpForm = $('.pdpForm');
	var isQuickView = href.indexOf('source=quickview') > -1;
	var isProductEdit = href.indexOf('source=editproduct') > -1;
	var qty = $pdpForm.find('input[name="Quantity"]').first().val();
	var params = {
		Quantity: isNaN(qty) ? '1' : qty,
		format: 'ajax',
		productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
	};

	// currentQuantity param is used for the Update Cart modal, while Quantity param is used for the Gift Card PDP on CA market
	if (isProductEdit) {
		var currentQty = $('[data-js="cart-qty-input"]').val();
		params.currentQuantity = currentQty;
		var lineItemID = $('.js-pdp-product-info').data('line-item-uuid');
		params.lineItemID = lineItemID;
	}

	//focus-visible is the class given by the keyboard focus plugin on current focus element
	var isKeyboardNav = element ? element.closest('[data-js="product-content"]').find('.focus-visible').length
		: $('[data-js="product-content"]').find('.focus-visible').length;

	accessibility.setKeyboardNavigation(isKeyboardNav);

	var selectedVariationURL = updatePageURL(href, isQuickView, isProductEdit);

	var target = element ? element.closest('[data-js="product-content"]') : $('[data-js="product-content"]');
	var ppElement = element ? element.closest('#product-content').find('.pricing-policy-container:visible') : $('#product-content').find('.pricing-policy-container:visible');
	storeAvailability.cancelRequest();
	ajax.load({
		url: util.appendParamsToUrl(href, params),
		callback: function (data) {
			if (isColorSwatch) {
				image.replaceImages($(data).find('.product-image-container'), isQuickView || isProductEdit);
				swiper.initializePdpSwiper(isQuickView || isProductEdit);
				swiper.initializeRightPdpSwiper(isQuickView || isProductEdit);

				$('[data-js="tech-badges"]').replaceWith($(data).filter('[data-js="tech-badges"]'));
				initTechBadgeAssetEvents();
				// load wistia script if needed
				video.initWistia(data);
				video.init();
			}

			if (isQuickView || isProductEdit) {
				target.replaceWith($(data).find('[data-js="product-content"]'));
				initializeEvents();
				updateMoreDetailsURL(selectedVariationURL);

				qtyUpdate.updateQty();
			}
			else {
				if (url) {
					updateDescriptionContent(url, clickPosition);
				}
				// Before adding the HTML to the page we need to correct the selected size type to avoid it from flickering to the default value
				var productInfoData = sizeSelector.updateSizeSelector($(data).find('#product-content .js-pdp-product-info'));
				//SFCC-886 -> have to replace whole div because data attributes are updated
				$('#product-content .js-pdp-product-info').replaceWith(productInfoData);
				//SFCC-670 -> have to replace only inner html because we have to keep classes on this container
				$('#product-content .js-product-bottom-info').html($(data).find('#product-content .js-product-bottom-info').html());

				// update the price (on some pages we have it twice for mobile and other for desktop)
				$('[data-js="product-price-wrapper"] [data-js="product-price"]').replaceWith($(data).find('[data-js="product-price-wrapper"] [data-js="product-price"]'));
				$('[data-js="product-price-wrapper-mobile"] [data-js="product-price"]').replaceWith($(data).find('[data-js="product-price-wrapper-mobile"] [data-js="product-price"]'));
				$('.pricing-policy-container').replaceWith(ppElement);

				// update more details section because shoe care product section may vary for different colors/sizes
				$('.pdp-more-details').html($(data).find('.pdp-more-details').html());

				// update shoe care block and init shoe care swiper
				$('.pdp-shoe-care-block').replaceWith($(data).filter('.pdp-shoe-care-block'));
				swiper.initShoeCareRecommendationSwiper();

				// update shop the look block and init shop the look swiper
				$('.pdp-shopthelook-block').replaceWith($(data).filter('.pdp-shopthelook-block'));
				swiper.initShopTheLookRecommendationSwiper();

				// update content asset video container
				$('.video-container').replaceWith(util.find(data, '.video-container'));

				// update urgency (low stock) message
				$('[data-js="urgency-message"]').replaceWith($(data).find('[data-js="urgency-message"]'));

				$('[data-js="pdp-highlights"]').replaceWith($(data).filter('[data-js="pdp-highlights"]'));

				storeAvailability.getStoreAvailability();
			}
			tooltip.init();
			sizeSelector.init(isQuickView);
			flyout.init();
			yotpoReviews.refreshWidgets(); // reinit yotpo bottomLine widget (rating stars above product name on pdp)
			wishlist.init();
			tooltip.initializeCustomTooltip();
			pdpAccordion.init();

			if (!isQuickView && !isProductEdit) {
				updateHighlightsBlock();
			}

			if (!util.isMobile() && !isQuickView && !isProductEdit) {
				productDetail.initStickyBar();
			}

			$(document).trigger('colorchanged');

			if (isKeyboardNav) {
				if (SitePreferences.IS_ECOMMERCE_ENABLED) {
					accessibility.setFocusOnAddToCart();
				}
				else {
					accessibility.setFocusOnSelectedSwatch(selectedSwatchWrapperId);
				}
			}

			if (callback) { callback(); }
		}
	});
};

/**
 * @description update product short description with new variant from url, load new content to .js-product-description-text-block panel
 * @param {String} url - url of the new product variant description controller
 * @param {Int} clickPosition - total distance from the first swatch to the swatch customer clicked
 **/
var updateDescriptionContent = function (url, clickPosition) {
	ajax.load({
		url: url,
		target: $('.js-product-description-text-block'),
		callback: function () {
			var colorSlider = $('.js-color-swatch.color');
			if (clickPosition) { $(colorSlider).animate({ scrollLeft: clickPosition }, 500); }
		},
	});
};

var updateHighlightsBlock = function() {
	var blockWrapper = $('[data-js="highlight-products"]');
	var pid = $('input#pid').val();

	if (blockWrapper.length > 0 && pid) {
		var url = util.appendParamToURL(Urls.productHighlightsBlock, 'pid', pid);

		ajax.load({
			url: url,
			callback: function (response) {
				blockWrapper.html(util.find(response, '[data-js="highlight-products"]').html());
			}
		});
	}
};

var updateMoreDetailsURL = function(selectedVariationURL) {
	if (selectedVariationURL) {
		$('[data-js="moredetailslink"]').prop('href', selectedVariationURL);
	}
};


//Tech Badge Asset Events
function initTechBadgeAssetEvents() {
	if (util.isTabletAndBelow() && $('[data-js="tech-badges"]').length > 0) {
		var techBadges = $('[data-js="tech-badges-wrapper"]').children('[data-js="tech-badge"]');
		techBadges.first().addClass('selected');
		$('[data-js="tech-badges__text-container"]').html(techBadges.first().find('[data-js="tech-badge__content"]').html());

		$('[data-js="tech-badge"]').on('click', function (e) {
			techBadges.not(this).removeClass('selected');
			$(this).addClass('selected');
			$('[data-js="tech-badges__text-container"]').html($(this).find('[data-js="tech-badge__content"]').html());
		});
	}
}

var initializeEvents = function() {
	// for the PDP Page Designer blocks
	if ($('.wistia_embed').length) {
		video.initWistia();
	}

	accessibility.attachTooltipKeyboardEvents();
	var $pdpMain = $('#pdpMain');
	if ($('body').hasClass('quickview-is-open')) {
		$pdpMain = $('#QuickViewDialog').find('#pdpMain');
	}
	// hover on swatch - should update main image with swatch image
	$pdpMain.on('mouseenter mouseleave', '.swatchanchor', function () {
		var largeImg = $(this).data('lgimg'),
			$imgZoom = $pdpMain.find('.main-image'),
			$mainImage = $pdpMain.find('.primary-image');

		if (!largeImg) { return; }
		// store the old data from main image for mouseleave handler
		$(this).data('lgimg', {
			hires: $imgZoom.attr('href'),
			url: $mainImage.attr('src'),
			alt: $mainImage.attr('alt'),
			title: $mainImage.attr('title')
		});
		// set the main image
		image.setMainImage(largeImg);
	});

	// click on swatch - should replace product content with new variant
	$pdpMain.on('click', '.c-product-info .swatchanchor', function (e) {
		e.preventDefault();
		var $this = $(this);

		if ($this.parents('li').hasClass('unselectable') || $this.parents('li').hasClass('selected')) {
			return;
		}

		var clickPosition = $this.parent('li').index();
		var spaceOccupied = parseInt($this.parent('li').css('width'), 10) + parseInt($this.parent('li').css('margin-right'), 10);
		var isColorSwatch = !!$(this).parents('#color-wrapper').length;

		var wrapperId = $this.parents('ul').attr('id');
		setSwatchWrapperId(wrapperId);

		var colorChangeCallback = isColorSwatch ? accessibility.focusToFirstSize : null;

		if (isColorSwatch) {
			//Update images and swiper if it's a color change
			updateContent(isColorSwatch, this.href, $this.attr('data-description-href'), clickPosition * spaceOccupied, colorChangeCallback, $this);
		}
		else {
			changeSelectedSize($this);
		}
	});

	// if more then 8 swatches - expand hidden swatches
	$pdpMain.on('click', '.c-product-info .see-more a', function (e) {
		e.preventDefault();
		$(this).closest('.values-long-list').addClass('color-list-expanded');
	});

	// if more then 8 swatches - close expanded hidden swatches list
	$pdpMain.on('click', '.c-product-info .see-less a', function (e) {
		e.preventDefault();
		$(this).closest('.values-long-list').removeClass('color-list-expanded');
	});

	// change drop down variation attribute - should replace product content with new variant
	$pdpMain.on('change', '.variation-select', function () {
		if ($(this).val().length === 0) { return; }
		var isColorSwatch = $(this).attr('data-js') === 'color';
		updateContent(isColorSwatch, $(this).val(), null, null, null, $(this));
	});
	initTechBadgeAssetEvents();
};

/**
 * Updates the necessary things on the page with the data from selected size.
 * @param {Object} sizeElement clicked size element
 */
var changeSelectedSize = function(sizeElement) {
	var productInfo = sizeElement.data('productinfo');
	
	// update the url in the browser
	var isQuickView = sizeElement[0].href.indexOf('source=quickview') > -1;
	var isProductEdit = sizeElement[0].href.indexOf('source=editproduct') > -1;

	if (!isQuickView && !isProductEdit) {
		// update sticky bar details
		productDetail.updateStickyBarProductInfo(productInfo, true);
	}

	var productBlockMainWrapper = sizeElement.closest('#pdpMain');

	// update product id with the selected size
	productBlockMainWrapper.find('#pid').val(productInfo.ID);

	// update data variation attributes
	productBlockMainWrapper.find('.product-variations').attr('data-attributes', JSON.stringify(productInfo.productVariationAttributes));
	productBlockMainWrapper.find('.product-variations').data('attributes', productInfo.productVariationAttributes);

	// update data values for GTM
	var productTopInfoElement = productBlockMainWrapper.find('#product-top-info');
	productTopInfoElement.attr('data-size', productInfo.value);
	productTopInfoElement.data('size', productInfo.value);
	productTopInfoElement.attr('data-variant-id', productInfo.ID);
	productTopInfoElement.data('variant-id', productInfo.ID);
	productTopInfoElement.attr('data-name', productInfo.name);
	productTopInfoElement.data('name', productInfo.name);
	productTopInfoElement.attr('data-stock-level', productInfo.stockLevel);
	productTopInfoElement.data('stock-level', productInfo.stockLevel);

	// update product name in case it has a different name for the selected variant
	productBlockMainWrapper.find('.js-product-name').text(productInfo.name);
	
	// update add/remove wishlist values
	var wishlistButton = productBlockMainWrapper.find('[data-js="add-to-wishlist"]');
	if (wishlistButton.length) {
		wishlistButton.attr('data-pid', productInfo.ID);
		wishlistButton.data('pid', productInfo.ID);
		wishlistButton.attr('data-add', productInfo.addToWishlistUrl);
		wishlistButton.data('add', productInfo.addToWishlistUrl);
		wishlistButton.attr('data-remove', productInfo.removeFromWishlistUrl);
		wishlistButton.data('remove', productInfo.removeFromWishlistUrl);
		wishlistButton.attr('data-price', productInfo.minPrice);
		wishlistButton.data('price', productInfo.minPrice);
		wishlist.checkProductsInWishList();
		wishlistButton.removeClass('u-hidden');
	}

	// adjust add to cart button values and visibility
	productBlockMainWrapper.find('#add-to-cart').removeAttr('disabled');
	productBlockMainWrapper.find('.msg-error').addClass('u-hidden');
	productBlockMainWrapper.find('[data-js="js-pdp-sticky-bar"]').find('[data-js="sticky-error"]').addClass('u-hidden');
	productBlockMainWrapper.find('#add-to-cart').attr('title', Resources.ADD_TO_BAG);
	productBlockMainWrapper.find('#add-to-cart').val(Resources.ADD_TO_BAG_DESCRIPTION);

	productBlockMainWrapper.find('#add-to-cart').find('[data-js="add-to-cart-text"]').text(Resources.ADD_TO_BAG);

	// update variation group swatch urls
	var vgWrapper = productBlockMainWrapper.find('[data-js="color-swatches-list"]');
	var sizeParamRegexWithTrailingParams = new RegExp('dwvar_([^_]*(__)?)*_size=.*?[?:^&]', 'g');
	var sizeParamRegexWithoutTrailingParams = new RegExp('dwvar_([^_]*(__)?)*_size=.*', 'g');

	// loop all variation group elements
	vgWrapper.find('li').each(function() {
		var anchor = $(this).children('a');
		var href = anchor.attr('href');
		var descriptionHref = anchor.data('description-href');

		if (sizeParamRegexWithTrailingParams.test(href)) {
			href = href.replace(sizeParamRegexWithTrailingParams, 'dwvar_' + productInfo.masterID + '_size=' + productInfo.value + '&');
		}
		else if (sizeParamRegexWithoutTrailingParams.test(href)) {
			href = href.replace(sizeParamRegexWithoutTrailingParams, 'dwvar_' + productInfo.masterID + '_size=' + productInfo.value);
		}
		else {
			href = href + '&dwvar_' + productInfo.masterID + '_size=' + productInfo.value;
		}

		if (sizeParamRegexWithTrailingParams.test(descriptionHref)) {
			descriptionHref = descriptionHref.replace(sizeParamRegexWithTrailingParams, 'dwvar_' + productInfo.masterID + '_size=' + productInfo.value + '&');					
		}
		else if (sizeParamRegexWithoutTrailingParams.test(descriptionHref)) {
			descriptionHref = descriptionHref.replace(sizeParamRegexWithoutTrailingParams, 'dwvar_' + productInfo.masterID + '_size=' + productInfo.value);					
		}
		else {
			descriptionHref = descriptionHref + '&dwvar_' + productInfo.masterID + '_size=' + productInfo.value;
		}

		anchor.attr('href', href);
		anchor.attr('data-description-href', descriptionHref);
		anchor.data('description-href', descriptionHref);
	});

	// make size selected
	var wrapper = productBlockMainWrapper.find('div.js-size-container');
	wrapper.find('li').removeClass('selected');
	wrapper.find('.size-' + productInfo.ID).addClass('selected');

	// update selected size values
	productBlockMainWrapper.find('[data-js="variation-size-selected"]').html(productInfo.displayValue);
	productBlockMainWrapper.find('[data-js="variation-size-alt-selected"]').html(productInfo.alternateSizeDisplayValue);
	productBlockMainWrapper.find('[data-js="variation-size-selected"]').removeClass('hidden-attr');
	productBlockMainWrapper.find('[data-js="variation-size-alt-selected"]').removeClass('hidden-attr');

	// update urgency (low stock) message
	if (productInfo.isUrgencyMsgVisible) {
		productBlockMainWrapper.find('[data-js="urgency-message"]').addClass('visible');
	}
	else {
		productBlockMainWrapper.find('[data-js="urgency-message"]').removeClass('visible');
	}

	updatePageURL(sizeElement[0].href, isQuickView, isProductEdit);
	updateMoreDetailsURL(util.removeParamFromURL(sizeElement[0].href, 'source'));

	// update availability of already selected store
	var storeAvailabilityContainer = productBlockMainWrapper.find('[data-js="storeavailability"]');
	if (storeAvailabilityContainer.length) {
		productBlockMainWrapper.find('[data-js="select-store-wrapper"]').addClass('u-hidden');
		productBlockMainWrapper.find('[data-js="selected-store-wrapper"]').removeClass('u-hidden');

		var url = storeAvailabilityContainer.data('url');
		var updatedUrl = url.replace(new RegExp('pid=[^&]*&', 'g'), 'pid=' + productInfo.ID + '&');

		storeAvailabilityContainer.attr('data-url', updatedUrl);
		storeAvailabilityContainer.data('url', updatedUrl);

		storeAvailabilityContainer.html(productBlockMainWrapper.find('[data-js="store-pickup-info-loader"]').html());

		storeAvailability.getStoreAvailability();
	}
	else {
		productBlockMainWrapper.find('[data-js="select-store-wrapper"]').removeClass('u-hidden');
		productBlockMainWrapper.find('[data-js="selected-store-wrapper"]').addClass('u-hidden');

		productBlockMainWrapper.find('[data-js="inactive-store-select-button"]').addClass('u-hidden');
		productBlockMainWrapper.find('[data-js="active-store-select-button"]').removeClass('u-hidden');
	}

	// update/display product promotions
	if (productInfo.pdpPromoDetails) {
		var pdpPromoTemplate = productBlockMainWrapper.find('#productpromotions-template').html();
		var templateFn = _.template(pdpPromoTemplate);
		var promoHtml = templateFn(productInfo.pdpPromoDetails);

		productBlockMainWrapper.find('[data-js="pdp-promotion"]').html(promoHtml);
		productBlockMainWrapper.find('[data-js="pdp-promotion"]').removeClass('u-hidden');
		tooltip.initializeCustomTooltip();
	}
	else {
		productBlockMainWrapper.find('[data-js="pdp-promotion"]').html('');
		productBlockMainWrapper.find('[data-js="pdp-promotion"]').addClass('u-hidden');
	}

	// update/display product badge
	var badgeWrapper = productBlockMainWrapper.find('[data-js="pdp-badge-wrapper"]');
	if (badgeWrapper.length) {
		if (productInfo.badgeData) {
			badgeWrapper.children('.js-badge').attr('class', 'js-badge badge').data('current', productInfo.badgeData).attr('data-current', JSON.stringify(productInfo.badgeData));
			badgeWrapper.find('[data-js="badge-title"]').html(productInfo.badgeData.text);
			badgeWrapper.removeClass('u-hidden');
		}
		else {
			badgeWrapper.addClass('u-hidden');
			badgeWrapper.children('.js-badge').attr('class', 'js-badge badge').data('current', {}).removeAttr('data-current');
			badgeWrapper.find('[data-js="badge-title"]').html('');
		}
	}
	//Update Badge Description
	var badgeDescription = productBlockMainWrapper.find('.notification-msg__content');
	if (productInfo.badgeData && productInfo.badgeData.badgeProductInfo) {
		badgeDescription.html(productInfo.badgeData.badgeProductInfo);
	}

	// update/display discount badge
	var discountBadgeWrapper = productBlockMainWrapper.find('[data-js="pdp-discount-badge-wrapper"]');
	if (discountBadgeWrapper.length) {
		if (productInfo.discountBadgeData) {
			discountBadgeWrapper.children('.js-badge').attr('class', 'js-badge badge').data('current', productInfo.discountBadgeData).attr('data-current', JSON.stringify(productInfo.discountBadgeData));
			discountBadgeWrapper.find('[data-js="badge-title"]').html(productInfo.discountBadgeData.text);
			discountBadgeWrapper.removeClass('u-hidden');
		}
		else {
			discountBadgeWrapper.addClass('u-hidden');
			discountBadgeWrapper.children('.js-badge').attr('class', 'js-badge badge').data('current', {}).removeAttr('data-current');
			discountBadgeWrapper.find('[data-js="badge-title"]').html('');
		}
	}

	// update prices
	if (productInfo.priceData) {
		var priceElement = productBlockMainWrapper.find('[data-js="product-price"]');
		var showMsrpNow = priceElement.data('msrp');
		productInfo.priceData.showMsrpNow = showMsrpNow;

		var pdpPriceTemplate = productBlockMainWrapper.find('#variantprices-template').html();
		var templateFn = _.template(pdpPriceTemplate);
		var priceHtml = templateFn(productInfo.priceData);

		priceElement.html(priceHtml);
		priceElement.data('msrp', showMsrpNow);

		// update gtm price attributes
		if (productInfo.priceData.standardPriceValue) {
			productTopInfoElement.attr('data-price', productInfo.priceData.standardPriceValue);
			productTopInfoElement.data('price', productInfo.priceData.standardPriceValue);
			productTopInfoElement.attr('data-discount', productInfo.priceData.discountAmount);
			productTopInfoElement.data('discount', productInfo.priceData.discountAmount);
			productTopInfoElement.attr('data-discount-price', productInfo.priceData.salesPriceValue);
			productTopInfoElement.data('discount-price', productInfo.priceData.salesPriceValue);
		}

		// update gtm promo data attributes
		if (productInfo.gtmPromoData) {
			productTopInfoElement.attr('data-promoid', productInfo.gtmPromoData.id);
			productTopInfoElement.data('promoid', productInfo.gtmPromoData.id);
			productTopInfoElement.attr('data-promoname', productInfo.gtmPromoData.name);
			productTopInfoElement.data('promoname', productInfo.gtmPromoData.name);
			productTopInfoElement.attr('data-promocreative', productInfo.gtmPromoData.creative);
			productTopInfoElement.data('promocreative', productInfo.gtmPromoData.creative);
			productTopInfoElement.attr('data-promotype', productInfo.gtmPromoData.type);
			productTopInfoElement.data('promotype', productInfo.gtmPromoData.type);
		}
		else {
			productTopInfoElement.removeAttr('data-promoid');
			productTopInfoElement.removeData('promoid');
			productTopInfoElement.removeAttr('data-promoname');
			productTopInfoElement.removeData('promoname');
			productTopInfoElement.removeAttr('data-promocreative');
			productTopInfoElement.removeData('promocreative');
			productTopInfoElement.removeAttr('data-promotype');
			productTopInfoElement.removeData('promotype');
		}
	}

	// update afterpay and zippay data
	var $paymentWidgetWrapper = productBlockMainWrapper.find('[data-js="payment-widget-wrapper"]');
	var $afterPayElement = productBlockMainWrapper.find('[data-js="afterpay-widget"]');
	var $zipPayElement = productBlockMainWrapper.find('[data-js="zippay-widget"]');
	if ($afterPayElement.length > 0) {
		if (productInfo.displayAfterPayDetails) {
			$afterPayElement.removeClass('u-hidden');
			productBlockMainWrapper.find('[data-js="afterpay-label"]').text(productInfo.afterPayPrice);
		}
		else {
			$afterPayElement.addClass('u-hidden');
		}
	}
	if ($zipPayElement.length > 0) {
		if (productInfo.displayZipPayDetails) {
			$zipPayElement.removeClass('u-hidden');
		}
		else {
			$zipPayElement.addClass('u-hidden');
		}
	}
	if ($paymentWidgetWrapper.length > 0) {
		if (!productInfo.displayAfterPayDetails && !productInfo.displayZipPayDetails) {
			$paymentWidgetWrapper.addClass('u-hidden');
		}
		else {
			$paymentWidgetWrapper.removeClass('u-hidden');
		}
	}

	//focus-visible is the class given by the keyboard focus plugin on current focus element
	var isKeyboardNav = sizeElement ? sizeElement.closest('[data-js="product-content"]').find('.focus-visible').length
		: $('[data-js="product-content"]').find('.focus-visible').length;
	accessibility.setKeyboardNavigation(isKeyboardNav);
	if (isKeyboardNav) {
		if (SitePreferences.IS_ECOMMERCE_ENABLED) {
			accessibility.setFocusOnAddToCart();
		}
	}
};

module.exports = {
	init: function() {
		initializeEvents();
	},

	updateContent: function (isColorSwatch, href, url, clickPosition, callback, element) {
		updateContent(isColorSwatch, href, url, clickPosition, callback, element);
	},

	setSwatchWrapperId: setSwatchWrapperId
};
