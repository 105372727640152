'use strict';

var $loader;

/**
 * @function
 * @description Shows an AJAX-loader on top of a given container
 * @param {Element} container The Element on top of which the AJAX-Loader will be shown
 */
var show = function (container) {
	var target = $('body');

	if (container && $(container).length === 1) {
		target = $(container);
	}
	else if ($('#main').length === 1) {
		target = $('#main');
	}

	$loader = $loader || $('.loader');

	if ($loader.length === 0) {
		$loader = $('<div/>').addClass('loader')
			.append($('<div/>').addClass('loader-indicator'));
	}

	if ($loader.data('js') && $loader.data('js') === 'shown') {
		return;
	}

	$loader.appendTo(target);

	// since .show() is an animation with default duration of 400ms with 'fast' we reduce it to 200ms
	// but more important as second (complete) parameter we send the function to attach an attribute with value 'shown'
	// this will be used for automated tests to detect when exactly the loader is shown and when it is hidden
	$loader.show('fast', function() {
		$loader.attr('data-js', 'shown');
	});
};
/**
 * @function
 * @description Hides an AJAX-loader
 */
var hide = function () {
	if ($loader) {
		$loader.hide('fast');
		$loader.remove();
		$loader = null;
	}
};

exports.show = show;
exports.hide = hide;
