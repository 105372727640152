'use strict';

var dialog = require('../../dialog'),
	keyboardConstans = require('../../constants').keyboard;

var mapObject = null;
var loadedStoresNo = 0;
var visibleStoreNo = 0;
var visibleStoresLoadMax = 20;
var $activeStore;

function init(map) {
	mapObject = map;
	initializeEvents();
}

function initializeEvents() {
	//Search click
	$('.js-searchmap').off('click').on('click', function (e) {
		e.preventDefault();

		var searchLocation = $('.js-search-map-field').val();
		if (!searchLocation) {
			return;
		}

		searchLocation = $.trim(searchLocation);
		if (searchLocation === '') {
			return;
		}

		var maxDistance = getMaxDistance(true);
		mapObject.updateNearMeFlag(false);
		updateNearMeNotification(false);
		mapObject.searchAddress(searchLocation, maxDistance, false);
	});

	//Search enter
	$('.js-search-map-field').off('keypress').keypress(function (e) {
		if (e.which == keyboardConstans.ENTER_KEY) {
			e.preventDefault();
			$('.js-searchmap').trigger('click');
		}
	});

	//State select for no map
	$('.js-search-state-field-no-map').off('change').on('change', function (e) {
		e.preventDefault();
		$(window).scrollTop(0);

		// get the selected state
		var locationSelector = $('.js-search-state-field-no-map');
		var searchLocation = locationSelector.val();

		if (searchLocation == null) {
			searchLocation = '';
		}
		mapObject.searchAddress(searchLocation);
	});

	//Distance select (1500 value is for whole country)
	$('.js-stores-maxdistance').off('change').on('change', function (e) {
		e.preventDefault();
		var maxDistance = getMaxDistance(false);
		if (e.currentTarget.value != 1500) {
			mapObject.searchAddress('', maxDistance, false);
			return;
		}
		mapObject.updateNearMeFlag(false);
		updateNearMeNotification(false);
		$('#js-search-map-field').val('');

		mapObject.searchAddress('', maxDistance, true);
	});

	//Sort by select (Ecco store first/distance first)
	$('.js-stores-sortby').off('change').on('change', function (e) {
		e.preventDefault();
		var sortBy = getSortBy();
		mapObject.sortStoreResults(sortBy);

		// save selection to cookie
		document.cookie = 'storelocator-sortby=' + $(this).val() + '; path=/';
	});

	//Near me click
	$('.js-search-stores-mylocation').off('click.usemylocation').on('click.usemylocation', function (e) {
		e.preventDefault();

		// remove the value in city/zipcode field when user clicks on "use my location"
		$('#js-search-map-field').val('');

		var maxDistance = getMaxDistance(true);
		mapObject.updateNearMeFlag(true);
		mapObject.searchAddress('', maxDistance, false);
	});

	//Store details collapse/expand
	$('.store-details-link').on('click', function (e) {
		e.preventDefault();
		dialog.open({
			url: $(e.target).attr('href')
		});
	});

	//Store click
	$('.store-title .store-acc').off('click').on('click', function (e) {
		e.preventDefault();
		var $clickedStore = $(this);

		if ($activeStore && $activeStore[0] != $clickedStore[0] && $activeStore.closest('.c-store-info').hasClass('is-open')) {
			$activeStore.closest('.c-store-info').find('.store-panel').slideToggle();
			$activeStore.closest('.c-store-info').toggleClass('is-open');
		}
		else if ($activeStore) {
			$activeStore = null;
		}

		$clickedStore.closest('.c-store-info').toggleClass('is-open');

		if (!SitePreferences.SHOW_ALL_STORE_INFORMATION) {
			$clickedStore.closest('.c-store-info').find('.store-panel').slideToggle(400, function() {
				if ($clickedStore.closest('.c-store-info').hasClass('is-open')) {
					$clickedStore.find('a:first').attr('aria-expanded', 'true');
				}
				else {
					$clickedStore.find('a:first').attr('aria-expanded', 'false');
				}
			});
		}

		setTimeout(function() {
			$('.js-store-results').animate({
				scrollTop: ($clickedStore[0].offsetTop - $('.js-store-results')[0].offsetTop - 38)
			}, 400);
		}, 400);
		$activeStore = $clickedStore;
	});
}

/**
 * Displays store results. We pass all visible stores and display the results for those up to [visibleStoresLoadMax]
 * @param {Array} stores
 */
function displayStoreResults(stores) {
	$('.js-store-results').scrollTop(0);

	if (!stores || !stores.length) {
		displayStoreResultsError(Resources.STORE_NOSTORESINTHISAREA);
		return;
	}

	var resultsHtml = getStoreResultHtml(stores, true);

	if (!resultsHtml) {
		displayStoreResultsError(Resources.STORE_NOSTORESINTHISAREA);
		return;
	}

	$('.js-store-results').html(resultsHtml);
	initializeEvents();

	//In case that there are more visible stores than results we display load more button
	if (stores.length > loadedStoresNo) {
		var loadMoreBtnHtml = '<div class="stores-loadmore js-stores-loadmore">'
			+ '<a class="btn js-loadmore" role="button" href="javascript:;">'
			+ '<span>' + Resources.STORE_LOADMORE + '</span></a></div>';
		$('.js-store-results').append(loadMoreBtnHtml);
		//Load more click (get a new batch of stores and display them)
		$('.js-loadmore').off('click.loadmore').on('click.loadmore', function (e) {
			e.preventDefault();
			var additionalStores = mapObject.getAdditionalStores(loadedStoresNo, visibleStoresLoadMax);
			loadMoreResults(additionalStores);
		});
	}
}

/**
 * Display all stores from the store batch
 * @param {Array} stores
 */
function loadMoreResults(stores) {
	if (!stores || !stores.length) {
		return;
	}

	var storesNoBeforeLoad = loadedStoresNo;
	var resultsHtml = getStoreResultHtml(stores);

	if (!resultsHtml) {
		return;
	}

	var loadMoreOffset = $('.js-stores-loadmore')[0].offsetTop;

	$('.js-stores-loadmore').before(resultsHtml);
	initializeEvents();

	//If all stores are loaded remove load more button
	if (loadedStoresNo === visibleStoreNo) {
		$('.js-stores-loadmore').remove();
	}

	$('.js-store-results').animate({
		scrollTop: (loadMoreOffset - $('.js-store-results')[0].offsetTop - 30)
	}, 500);
	var firstNewlyAddedStore = $('.js-store-results .c-store-info').get(storesNoBeforeLoad);

	if (firstNewlyAddedStore) {
		$(firstNewlyAddedStore).find('.store-acc a').trigger('focus');
	}
}

/**
 * Prepares results HTML containing all the stores that we want to display
 * @param {Array} stores
 * @param {Boolean} saveVisibleStoreNo
 * @returns {String}
 */
function getStoreResultHtml(stores, saveVisibleStoreNo) {
	var resultsHtml = '';
	if (!stores || !stores.length) {
		return resultsHtml;
	}

	if (saveVisibleStoreNo) {
		visibleStoreNo = stores.length;
	}

	var end = Math.min(stores.length, visibleStoresLoadMax);
	for (var i = 0; i < end; i++) {
		var store = stores[i];
		//If the store is already loaded in the results remove it (this can happen when we click on map marker before the store is loaded through load more)
		var alreadyExistingResult = $('.js-store-results').find('[data-storeid="' + store.storeID + '"]');
		if (alreadyExistingResult.length) {
			alreadyExistingResult.parent().parent().remove();
		}
		resultsHtml += getStoreDetail(store.data, store.distance);
		loadedStoresNo++;
	}

	return resultsHtml;
}

/**
 * Returns HTML for passed store result
 * @param {Object} item
 * @param {Number} distance
 * @returns {String}
 */
function getStoreDetail(item, distance) {
	var storeHtml = '';
	var storeCategories = '';
	for (var i = 0; i < item.productLines.length; i++) {
		storeCategories += '<li>' + item.productLines[i] + '</li>';
	}

	var distanceSpan = '';
	if (distance != null && typeof(distance) != 'undefined' && !SitePreferences.STORE_HIDE_MAP) {
		var unitMultiplier = $('#js-distance-unit-multiplier').val();
		var distance = Math.round((distance / unitMultiplier) * 10) / 10;
		distanceSpan = '<span class="store-title-distance">' + distance
			+ ' <span class="distance-value">' + Resources.STORE_DISTANCEUNIT + '</span></span>';
	}

	storeHtml = '<div class="c-store-info">'
		+ '<div class="store-title">' + distanceSpan + '<div class="store-acc" data-storeid="' + item.storeID + '">';

	var collapseBtnHtml = '';
	if (!SitePreferences.SHOW_ALL_STORE_INFORMATION) {
		collapseBtnHtml = '<a href="javascript:;" role="button" aria-expanded="false">' + item.name + '</a>';
	}
	else {
		collapseBtnHtml = '<span class="store-name">' + item.name + '</span>';
	}

	var addressHtml = '</div>'
		+ '<span class="store-title-address">' + item.address + '</span>';

	var hoursHtml = '<div class="store-opening">' + getOpeningTimesHTML(item.storeHours) + '</div>';

	var storeExtraInfo = getStoreExtraInfo(item);

	var directionLinkHtml = getDirectionsLinkHTML(item) + '</div>'
		+ '<div class="store-panel"><div class="store-categories"><ul>' + storeCategories + '</ul></div>';
	if (SitePreferences.SHOW_ALL_STORE_INFORMATION) {
		directionLinkHtml = '</div>'
			+ '<div class="store-panel-show"><div class="store-categories"><ul>' + storeCategories + '</ul></div>';
	}

	var contactHtml = '<div class="store-contact"><div class="store-contact-list">'
		+ getDirectionsLinkHTML(item)
		+ (item.phone != null && item.phone != ''
			? '<a href="tel:' + item.phone + '"><i class="icon-phone" aria-hidden="true"></i>' + Resources.STORE_CALL + ' ' + item.phone + '</a>' : '')
		+ '</div></div></div></div>';

	if (SitePreferences.STORE_DISPLAYHOURS) {
		//always display hours
		storeHtml += collapseBtnHtml + addressHtml + hoursHtml + directionLinkHtml + storeExtraInfo + contactHtml;
	}
	else {
		//display hours in extendable block
		storeHtml += collapseBtnHtml + addressHtml + directionLinkHtml + hoursHtml + storeExtraInfo + contactHtml;
	}

	return storeHtml;
}

/**
 * Returns HTML for opening hours
 * @param {Object} openingTimes
 * @returns {String}
 */
function getOpeningTimesHTML(openingTimes) {
	let openingTimesString = '';
	if (openingTimes.length && openingTimes.length > 0) {
		openingTimesString = '<h3>' + Resources.OPENING_TIMES + '</h3><div class="store-opening-inner">';
		for (let i = 0; i < openingTimes.length; i++) {
			openingTimesString += '<div class="store-opening-line">';
			//in case that we split hours line into label and data print it separated, otherwise just print it as a line
			if (!!openingTimes[i].label && !!openingTimes[i].data) {
				openingTimesString += '<span class="store-day">' + openingTimes[i].label + '</span>';
				if (!openingTimes[i].temporaryClosed) {
					openingTimesString += '<span class="store-hour">' + openingTimes[i].data + '</span>';
				}
			}
			else {
				openingTimesString += openingTimes[i];
			}

			openingTimesString += '</div>';
		}
		openingTimesString += '</div>';
	}
	return openingTimesString;
}

/**
 * Returns HTML for store extra info
 * @param {Object} store
 * @returns {String}
 */
function getStoreExtraInfo(store) {
	return '<div class="store-locator-details">'
		+ store.holidaysAndClosingDays
		+ store.storeEvents
		+ store.specialServices
		+ store.storeProgram
		+ '</div>';
}

/**
 * Returns HTML for directions link
 * @param {Object} item
 * @returns {String}
 */
function getDirectionsLinkHTML(item) {
	if (SitePreferences.STORE_HIDE_MAP) {
		return '';
	}

	var addressText = item.address1 + ' ,' + item.city + ', ' + $('#js-currentcountry').val();
	addressText = item.isECCOStore ? 'ECCO, ' + addressText : addressText;
	var googleMapUrl = 'http://maps.google.com/?&q=' + encodeURI(addressText);
	return '<a class="store-directions" href="' + googleMapUrl + '" target="_blank"><i class="icon-store" aria-hidden="true"></i>' + Resources.STORE_DIRECTIONS + '</a>';
}

/**
 * Renders an error message
 * @param {String} msg
 */
function displayStoreResultsError(msg) {
	$('.js-store-results').html('<div class="error-form storelocator-error"><p class="error-form-msg">' + msg + '</p></div>');
}

/**
 * Renders or hides the near me message
 * @param {Boolean} isVisible
 */
function updateNearMeNotification(isVisible) {
	setTimeout(function () {
		if (isVisible) {
			$('.js-nearme-notification').removeClass('visually-hidden');
			return;
		}

		$('.js-nearme-notification').addClass('visually-hidden');
	}, 100);
}

/**
 * Either expands or renders store details
 * @param {Object} store
 */
function openStoreDetail(store) {
	if ($('*[data-storeid="' + store.storeID + '"]').length > 0) {
		$('*[data-storeid="' + store.storeID + '"]').trigger('click');
	}
	else {
		$('.js-stores-loadmore').before(getStoreDetail(store.data, store.distance));
		initializeEvents();
		$('*[data-storeid="' + store.storeID + '"]').trigger('click');
	}
}

/**
 * Returns max distance value (if we pass reset distance the waluewill be reseted to default one)
 * @param {Boolean} resetDistance
 * @returns {String}
 */
function getMaxDistance(resetDistance) {
	var currentValue = $('.js-stores-maxdistance').val();

	if (resetDistance && currentValue == 1500) {
		var defaultValue = $('.js-stores-maxdistance option:first').val();
		$('.js-stores-maxdistance').val(defaultValue);
		currentValue = defaultValue;
	}

	if (currentValue) {
		return parseInt(currentValue);
	}

	return parseInt($('#js-googlemapstores').data('zoom'));
}

/**
 * Returns sort by value
 * @returns {String}
 */
function getSortBy() {
	var sortBySelector = $('.js-stores-sortby');
	if (!sortBySelector.length) {
		return '';
	}
	return sortBySelector.val();
}

/**
 * Sets [loadedStoresNo] to 0
 */
function resetLoadedStores() {
	loadedStoresNo = 0;
}

module.exports = {
	init: init,
	getSortBy: getSortBy,
	displayStoreResults: displayStoreResults,
	openStoreDetail: openStoreDetail,
	resetLoadedStores: resetLoadedStores,
	displayStoreResultsError: displayStoreResultsError,
	updateNearMeNotification: updateNearMeNotification
};
