'use strict';

var ajax = require('./ajax'),
	dialog = require('./dialog'),
	detailsmodaldialog = require('./detailsmodaldialog'),
	forms = require('./forms'),
	progress = require('./progress'),
	validator = require('./validator'),
	util = require('./util'),
	page = require('./page');

var accessibility = (util.isMobile()) ? require('./accessibilityForMobile') : require('./accessibility');

var newsletter = {
	scrollPosition: 0,
	init: function () {
		var $footerform = $('.js-footer-newletter-form'),
			$btnPopup = $footerform.find('.js-popup-newletter-form-btn'),
			$emailInputField = $footerform.find('.js-footer-newsletter-email-address'),
			$newsletterSignupBlock = $('.js-newsletter-signup-block');

		initPDEvents();
		this.createDialogContainer();
		this.responseHTML = '<span class="icon-envelope-active"></span><span>' + Resources.SUBSRIPTION_SUCEEDED + '</span>';

		var self = this;
		$emailInputField.on('keydown', function (e) {
			if ($btnPopup.length && e.which === 13) {
				//if btn for dialog exosts set footer email field as focus element after dialog is closed
				newsletter.focusElement = this;
				newsletter.isKeyboardNavigation = $(this).hasClass('focus-visible');
				$btnPopup.trigger('click');
			}
		});

		// to show the signup form as a modal
		$btnPopup.on('click', function (e) {
			e.preventDefault();
			newsletter.scrollPosition = $(window).scrollTop();

			if (!newsletter.focusElement) {
				//set button as focus element after dialog is closed
				newsletter.isKeyboardNavigation = $(this).hasClass('focus-visible');
				newsletter.focusElement = this;
			}

			$('#main-content').addClass('newsletter-is-open');

			if (!$footerform.valid()) {
				return;
			}

			var url = $(this).data('url'),
				target = self.$container,
				callback = function (response, error) {
					if (error) {
						self.hideDialog();
						return;
					}

					var emailAddress = $('.js-footer-newsletter-email-address').val();
					$('.js-popup-newsletter-email-address').val(emailAddress);
					$('.js-popup-newsletter-email-address').trigger('change');
					initPopupEvents();
					self.showDialog();
					$(window).scrollTop(0);
				};

			self.loadData(url, callback, target);
		});

		//close dialog on X and skip button
		$(document).on('click', '.js-close-subscription, .js-skip-step2', function (e) {
			e.preventDefault();
			newsletter.hideDialog();
		});

		//close dialog on ESC
		$(document).on('keydown', function (e) {
			if (e.which === 27) {
				e.preventDefault();
				newsletter.hideDialog();
			}
		});

		// floating label on footer-form
		$(document).on('focus', '[data-js="newsletter-form"]', function () {
			var $this = $(this);
			$this.parent().addClass('input-with-focus');
		});

		$(document).on('blur', '[data-js="newsletter-form"]', function () {
			var $this = $(this);
			if ($this.val().trim() === '') {
				$this.parent().removeClass('input-with-focus');
			}
			else {
				$this.parent().addClass('input-with-focus');
			}
		});

		$emailInputField.on('keydown', function (e) {
			if (!(e.which === 9) && $emailInputField.hasClass('ignore-validation')) {
				$emailInputField.removeClass('ignore-validation');
				$emailInputField.addClass('required');
			}
		});

		// to post the form in footer
		$footerform.on('submit', function (e) {
			e.preventDefault();

			if (!($emailInputField.val()) && $emailInputField.hasClass('ignore-validation')) {
				$emailInputField.removeClass('ignore-validation');
				$emailInputField.addClass('required');
			}

			if (!$footerform.valid()) {
				return;
			}

			//Here we check if checkbox is rendered and if checkbox is checked
			if (($('#checkbox-newsletter').length) && !($('#checkbox-newsletter').prop('checked'))) {
				return;
			}

			var url = $footerform[0].action;

			var dataArray = $footerform.serializeArray();
			var data = {};
			dataArray.forEach(function(element) {
				data[element.name] = element.value;
			});

			var $btn = $footerform.find('.js-footer-newletter-form-btn');
			var attrname = $btn.attr('name');
			data[attrname] = attrname;

			ajax.load({
				requestType: 'POST',
				data: data,
				url: url,
				callback: function (response, error) {
					if (error) {
						newsletter.hideDialog();
						return;
					}

					$footerform.remove();
					$('.js-newsletter-signup-success').removeClass('u-hidden').find('#subscribedEmail').text(data.dwfrm_newsletter_email);
					$(document).trigger('newsletterSubscribe', [data.dwfrm_newsletter_email]);
				}
			});
		});

		// format date of birth
		$('body').off('change keyup', '#dwfrm_newsletter_dateofbirth').on('change keyup', '#dwfrm_newsletter_dateofbirth', function (e) {
			e.preventDefault();
			util.formatDateOfBirth(e);
		});
	},
	dialogContainerExists: function () {
		return this.$container && (this.$container.length > 0);
	},
	createDialogContainer: function () {
		if (!this.dialogContainerExists()) {
			this.$container = $('<div />', {
				'class': 'newsletter-container js-newsletter-container u-hidden'
			}).appendTo($('body'));
		}
	},
	getDialogContainer: function () {
		return this.$container;
	},
	loadData: function (url, callback, target) {
		ajax.load({
			target: target,
			url: url,
			callback: callback
		});
	},
	//shows newsletter dialog
	showDialog: function () {
		this.$container.removeClass('u-hidden');
		if (this.isKeyboardNavigation) {
			//if keyboard navigation is used trap focus in step1 dialog
			var elements = this.$container.find('.js-close-subscription, .js-subscription-form-step1 input, .js-subscription-step1');
			accessibility.focusTrap(elements, true);
		}
	},
	//hides newsletter dialog
	hideDialog: function () {
		this.$container.addClass('u-hidden');
		$('#main-content').removeClass('newsletter-is-open');
		$(window).scrollTop(this.scrollPosition);
		if (this.isKeyboardNavigation) {
			//if keyboard navigation is used focus on focus element
			accessibility.simulateTabKeyFocus($(this.focusElement));
		}
		this.focusElement = null;
	}
};

module.exports = newsletter;

var initPDEvents = function () {
	var $pdForm = $('[data-js="pd-newsletter-form"]');

	//handles the page designer newsletter subscription event
	$pdForm.on('submit', function (e) {
		e.preventDefault();

		// get current form where there can be multiple forms on the page
		var currentForm = $(this);

		// We get all the required fields in the current page designer newsletter subscription form
		var $reqInputs = currentForm.find('input.required, textarea.required');
		var dateOfBirth = currentForm.find('#dwfrm_newsletter_dateofbirth');
		var allValid = true;
		$reqInputs.each(function (index, element) {
			if (!$(element).valid()) {
				allValid = false;
			}
		});

		//if dateofbirth field is rendered we check is it valid
		var dateOfBirthValue = dateOfBirth.val();
		if (dateOfBirth.length > 0 && !util.validateDateOfBirth(dateOfBirthValue, dateOfBirth)) {
			allValid = false;
			dateOfBirth.removeClass('valid').addClass('error');
			dateOfBirth.closest('.input-container').removeClass('is-valid').addClass('has-error');
		}

		if (allValid) {
			var $PDnewsletterBtn = currentForm.find('[data-js="pd-newsletter-form-btn"]');
			var formAction = $PDnewsletterBtn.attr('name');
			var success = function (emailAddress) {
				const isImageForm = currentForm.data('pdType') === 'image-form';
				if (isImageForm) {
					currentForm.hide();
				}
				else {
					currentForm.closest('.js-pd-newsletter-signup-block').hide();
				}

				const $subscriptionSuccess = currentForm.closest('[data-js="newsletter-form-comp-wrapper"]').find('.js-pd-newsletter-subscription-success');
				$subscriptionSuccess.show();

				if (emailAddress) {
					if (isImageForm) {
						$subscriptionSuccess.find('#subscribedEmail').text(emailAddress);
					}
					$(document).trigger('newsletterSubscribe', [emailAddress]);
				}
			};
			postForm(success, currentForm, formAction, true);
		}
	});
};

// Newsletter Popup
var initPopupEvents = function () {
	var $newsletterForm = $('form[id$="SubscriptionForm"]'),
		$subscribeBtn1 = $newsletterForm.find('.js-subscription-step1'),
		$subscribeBtn2 = $newsletterForm.find('.js-subscription-step2');

	$subscribeBtn1.on('click', function (e) {
		e.preventDefault();
		$('.newsletter-container').addClass('newsletter-on-step2'); // To improve the position of the confirmation popup

		// We get all the required fields in the 1st part of the form. Changes per site, dont hardcode
		var $reqInputs = $newsletterForm.find('.js-subscription-form-step1').find('input.required');
		var allValid = true;

		$reqInputs.each(function (index, element) {
			if (!$(element).valid()) {
				allValid = false;
			}
		});

		if (allValid) {
			let formAction = $(this).attr('name'),
				success = function (emailAddress) {
					$('.js-footer-newletter-form').remove();
					$('.js-newsletter-signup-block').html(newsletter.responseHTML);
					$('.js-subscription-form-step1').hide();
					$('.js-subscription-form-step2').show();
					if (newsletter.isKeyboardNavigation) {
						//if we use keyboard navigation trap focus in step2 dialog
						var elements = newsletter.$container.find('.js-close-subscription, .js-subscription-form-step2 input:not([type=hidden]), .js-subscription-form-step2 select, .js-skip-step2, .js-subscription-step2');
						accessibility.focusTrap(elements, true);
					}

					if (emailAddress) {
						$(document).trigger('newsletterSubscribe', [emailAddress]);
					}

					newsletter.focusElement = $('#brand-logo-home');
					//Modal animation
					window.scrollTo();
				};

			postForm(success, $newsletterForm, formAction);
		}
	});

	$subscribeBtn2.on('click', function (e) {
		e.preventDefault();
		let isEmptyForm2 = true;
		var dateOfBirth = $('#dwfrm_newsletter_dateofbirth');
		var formValid = true;
		$('.newsletter-container').removeClass('newsletter-on-step2'); // To improve the position of the confirmation popup

		$.each($('.js-subscription-form-step2').find('input, select').not('[type=hidden]'), function (i, element) {
			if (!!$(element).val()) {
				isEmptyForm2 = false;
			}

			if (!$(element).valid()) {
				formValid = false;
			}
		});

		if (isEmptyForm2) {
			newsletter.hideDialog();
		}

		//if dateofbirth field is rendered we check is it valid
		var dateOfBirthValue = dateOfBirth.val();
		if (dateOfBirth.length > 0 && !util.validateDateOfBirth(dateOfBirthValue, dateOfBirth)) {
			formValid = false;
			dateOfBirth.removeClass('valid').addClass('error');
			dateOfBirth.closest('.input-container').removeClass('is-valid').addClass('has-error');
		}

		let formAction = $(this).attr('name'),
			success = function () {
				// hide the form and show the message
				newsletter.getDialogContainer().find('.js-newsletter-content').html(newsletter.responseHTML);
			};

		if (formValid) {
			postForm(success, $newsletterForm, formAction);
		}
	});

	$newsletterForm.find('.privacy-policy').on('click', function (e) {
		e.preventDefault();

		dialog.open({
			url: $(e.target).attr('href'),
			closeAll: false,
			options: {
				dialogClass: 'service-dialog',
				open: function () {
					var openDialog = $(this);
					dialog.bindOverlayClose(openDialog);
				}
			}
		});

		return false;
	});

	validator.init();
	forms.init();
	detailsmodaldialog.init();
};

var postForm = function (successCallback, $newsletterForm, formaction, isPDComponent) {
	progress.show();

	var data = $newsletterForm.serialize();

	// add form action to data
	data += '&' + formaction + '=';

	// make sure the server knows this is an ajax request
	if (data.indexOf('ajax') === -1) {
		data += '&format=ajax';
	}

	$.ajax({
		type: 'POST',
		url: $newsletterForm.attr('action'),
		data: data,
		success: function (response) {
			if (typeof response === 'object'
				&& !response.success
				&& response.error === 'CSRF Token Mismatch') {
				page.redirect(Urls.csrffailed);
			}
			else if (typeof response === 'object' && response.success) {
				// popup form post succeeded
				progress.hide();
				var emailAddress = $newsletterForm.find('#dwfrm_newsletter_email').val();
				successCallback(emailAddress);
			}
			else if (typeof response === 'string') {
				// popup form not successfull
				newsletter.getDialogContainer().html(response);
				progress.hide();
			}

			if (isPDComponent) {
				var pdComponentId = $newsletterForm.closest('.img-two-column').data('pd-component-id');
				var successWrapper = $newsletterForm.closest('[data-js="newsletter-form-comp-wrapper"]').find('.js-pd-newsletter-subscription-success');
				$(document).trigger('triggerNewsletter', [pdComponentId, successWrapper]);
			}
		},
		failure: function () {
			newsletter.getDialogContainer().html('<h1>' + Resources.SERVER_ERROR + '</h1>');
			progress.hide();
		}
	});
};
