var _ = require('lodash'),
	inputValidation = require('./inputvalidation'),
	submitForm = require('./submitForm'),
	globalPayment = require('../../payment'),
	tooltip = require('../../tooltip'),
	adyenCheckout = null,
	adyenAction = null,
	accessibility = require('../../accessibility');

if (SitePreferences.ADYEN_ENABLED) {
	adyenCheckout = require('../../adyen-checkout');
	adyenAction = require('../../adyen-action');
	adyenCheckout.initBilling();
}

function initializeEvents() {
	$(document).ready(function() {
		if ($('.js-paypal-placeorder').length > 0) {
			$('html, body').animate({
				scrollTop: $('.js-paypal-placeorder').offset().top
			}, 400);
		}

		if ($('.js-standalonepayment').length > 0) {
			if (SitePreferences.ADYEN_ENABLED) {
				adyenCheckout = require('../../adyen-checkout');
				adyenAction = require('../../adyen-action');
				adyenCheckout.initBilling();
			}
		}

		//Mobile app payment paypal block replacement by selected payment method
		if ($('.js-selected-paymentmethod').length > 0 && $('.js-selected-paymentmethod').val() === 'PayPal' && $('.js-paypal-container').length > 0) {
			$('.js-paypal-container').removeClass('u-hidden');
		} else {
			$('.js-placeorder-container').removeClass('u-hidden')
		}
	});

	$('.js-payment-method').off('change').on('change', function () {
		var $this = $(this);

		// On change the current displayed payment form will be hidden and the selected one will be showen
		// The fields of the payment form will be cleared.
		// Because of that also the validation need to be reset (errors and valid indications)

		//Remove active class to payment containers
		$('.js-radio-container-payment').removeClass('is-active');

		$('.issuer').parent().hide();
		$('.adyen-issuer-row').hide();
		$('.adyen-issuer-row').addClass('u-hidden');

		$('.adyen-issuer-row').off('issuerchange').on('issuerchange', function() {
			$this.parent().find('.js-panel-issuer-' + $this.val()).show();
			$this.parent().find('.js-panel-issuer-' + $this.val()).removeClass('u-hidden');
		});

		$('.js-payment-panel').slideUp(400, function () {
			//Reset values and clear errors
			var $panel = $(this);

			if (!SitePreferences.ADYEN_ENABLED) {
				//Clear input and select fields
				$panel.find('input').val('');
				$panel.find('select').each(function () {
					var $select = $(this);
					$select.val($select.find('option:first').val());
				});
			}

			//Remove aria attributes
			$panel.find('input, fieldset').removeAttr('aria-invalid aria-describedby');

			//Remove classes starting with creditcard- so that the icon is hidden also
			$panel.find('.input-container').removeClass('is-filled is-valid has-error');
			$panel.find('.input-container').removeClass(function (index, css) {
				return (css.match(/(^|\s)creditcard-\S+/g) || []).join(' ');
			});

			//Remove error message
			$panel.find('span.error').remove();

			//Remove valid and error class
			$panel.find('.error').removeClass('error');
			$panel.find('.valid').removeClass('valid');
			$panel.find('.select-container').removeClass('is-filled is-valid has-error');
		});

		$(document).trigger('paymentmethod-changed', $this.val());

		//Add active class to selected input payment
		$this.closest('.js-radio-container-payment').addClass('is-active');

		if (SitePreferences.ADYEN_ENABLED) {
			// remove previous payment method's issuer
			$('#dwfrm_adyPaydata_issuer').val('');
			$('#dwfrm_adyPaydata_vpa').val('');

			// re-initialize Adyen WebComponent if CC method is active
			if ($('.js-checkout-payment').find('.js-radio-container-payment.is-credit_card').hasClass('is-active') && adyenCheckout !== null) {
				adyenCheckout.initBilling();
			}
			else {
				// clear Adyen WebComponent if other payment method is active
				adyenCheckout.unmount();
			}
		}

		//Show active payment container
		$('.' + $this.data('panel')).slideDown(400);

		//Send ajax request to check if cash on delivery and apply/deduct cost
		var selectedPaymentMethod = $this.val();

		if (selectedPaymentMethod) {
			updatePaymentMethod(selectedPaymentMethod);

			if (!SitePreferences.IS_RESKIN) {
				if (selectedPaymentMethod === 'Konbini') {
					$('.js-other-stores').removeClass('payment-method__store--active');
					$('.js-seven-eleven').removeClass('payment-method__store--active');
				}
				else if (selectedPaymentMethod.includes('upi')) {
					$('.js-upi-qr').removeClass('payment-method__store--active');
					$('.js-upi-collect').removeClass('payment-method__store--active');
				}
			}
		}
	});

	$('.js-konbini-payment-method').off('click').on('click', function () {
		var $this = $(this);

		var selectedPaymentMethod = $this.val();

		if (selectedPaymentMethod) {
			if (!SitePreferences.IS_RESKIN) {
				$('.js-seven-eleven').removeClass('payment-method__store--active');
			}

			$('#is-econtext_seven_eleven').val(selectedPaymentMethod);

			updatePaymentMethod(selectedPaymentMethod);
		}
	});

	$('.js-upi-payment-method').off('click').on('click', function () {
		var $this = $(this);

		var selectedPaymentMethod = $this.val();

		if (selectedPaymentMethod) {
			$('.js-upi-collect').removeClass('payment-method__store--active');
			updatePaymentMethod(selectedPaymentMethod);
		}
	});

	$(document).on('initcvctooltip', initializeCVCTooltip);
}

function initializeCVCTooltip() {
	var cvcField = $('.adyen-checkout__field__cvc');
	if (!cvcField.find('.custom-tooltip').length) {
		var tooltipSource = $('[data-js="cvn-tooltip"]');

		if (tooltipSource.length > 0) {
			cvcField.append(tooltipSource.html());

			var labelHeight = cvcField.find('.adyen-checkout__label').height();
			var currentTooltipElement = cvcField.find('[data-js="custom-tooltip"]');
			currentTooltipElement.attr('data-labelHeight', labelHeight);

			if (!SitePreferences.IS_RESKIN) {
				currentTooltipElement.attr('data-useTopPositioning', true);
			}

			tooltip.initializeCustomTooltip();
		}
	}
}

function initializeCybersourceEvents() {
	var paymentInstrumentRemoved = false;
	var savedCreditCardSelected = false;

	// select credit card from list - for logged in customers
	$('#creditCardList').off('change').on('change', function () {
		var cardUUID = $(this).val();
		if (!cardUUID) { return; }

		var options = {
			action: 'selectCreditCard',
			responseType: 'json',
			extraParameters: [
				{ name: 'creditCardUUID', value: cardUUID }
			],
			callback: populateCreditCardForm
		};

		submitForm.submitAjaxForm(options);

		// remove server side error
		$('.required.error').removeClass('error');
		$('.error-message').remove();
	});

	/**
     * @function
     * @description Updates the credit card form with the attributes of a given card
     * @param {String} response
     */
	function populateCreditCardForm(response) {
		if (response) {
			var data = JSON.parse(response);
			if (data.resultObj) {
				paymentInstrumentRemoved = false;
				savedCreditCardSelected = true;
				setCCFields(data.resultObj);
				$('[data-js="save-credit-card"]').addClass('u-hidden');
				return true;
			}
			if (data.message) {
				window.alert(data.message);
				return false;
			}
		}
		window.alert(Resources.CC_LOAD_ERROR);
		return false;
	}

	/**
     * @function
     * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
     * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
     */
	function setCCFields(data) {
		var creditCard = $('.js-creditcard-fieldset');
		creditCard.find('select[name$="_creditCardList"]').val(data.selectedCardID);
		// Change event is designed to fire on change by user interaction. Programmatic changes don't cause this event to be fired.
		// For this reason trigger('change') is used here - to force triggering the event. If not used, val() won`t change the value of input element.
		// trigger('focusin').trigger('focusout') are used to refresh the validation on input element after it has been filled with  the new value.
		// The real value of the element will be data.number, but the value presented on FE to the user will be data.maskedNumber.
		var cardNumber = creditCard.find('input[name*="_creditCard_number"]');
		var cardType = data.type.toLowerCase();
		cardNumber.val(data.number).trigger('change');
		cardNumber.attr('data-is-masked', true);
		cardNumber.val(data.maskedNumber).trigger('focusin').trigger('focusout');

		// change card type logo
		cardNumber.parents('.input-container').removeClass(function(index, css) {
			return (css.match(/(^|\s)creditcard-\S+/g) || []).join(' ');
		}).addClass('creditcard-' + cardType);

		// If the user enters invalid cvn and then picks another credit card we need to empty the cvn field and remove the errors.
		creditCard.find('input[name$="_cvn"]').val('');
		inputValidation.clearError(creditCard.find('input[name$="_cvn"]'));
		inputValidation.clearError(creditCard.find('input[name$="_number"]'));
		
		creditCard.find('input[name$="_monthAndYear"]').val(data.expirationMonthAndYear).trigger('focusin').trigger('focusout');
		creditCard.find('select[name$="_type"]').val(data.type).trigger('focusin').trigger('focusout');
		creditCard.find('input[name$="creditCard_owner"]').val(data.holder).trigger('focusin').trigger('focusout');
	}

	var paymentFields = 'input[name$="_creditCard_number"], input[name$="_creditCard_owner"], input[name$="_creditCard_expiration_monthAndYear"], select[name$="_creditCard_expiration_month"], select[name$="_creditCard_expiration_year"], select[name$="_creditCardList"]';

	//debounce is used because if we use auto populate by browser, it will chnage multiple fields at the same time and each change will trigger this event
	//we don't want to send multiple requests in that case, so we use debounce and it will just trigger once
	$('body').off('change.creditcardfields').on('change.creditcardfields', paymentFields,
		_.debounce(function() {
			var isSelectCardList = $(this).attr('id') === 'creditCardList';
			var skipRemovePaymentInstrumentFlow = isSelectCardList && $(this).val() != '';
			var forceClearCard = isSelectCardList && $(this).val() == '';

			//We skip removing payment instrument when we change to new saved credit cart since it is already done during selectCard action
			if (skipRemovePaymentInstrumentFlow) {
				return;
			}

			var $creditCard = $('.js-creditcard-fieldset');
			var customerHasSavedCards = $creditCard.find('[data-js="customer-has-saved-cards"]').length > 0;
			//the flag paymentInstrumentRemoved prevents sending unneccessary ajax requests on all changes of the payment form
			if (!paymentInstrumentRemoved && customerHasSavedCards) {
				var options = {
					action: 'removePaymentInstrument',
					extraParameters: [{ name: 'savedCreditCardSelected', value: savedCreditCardSelected }],
					callback: function(data) {
						if (!data) { return; }

						if (forceClearCard) {
							clearCreditCard();
						}

						// creditCardList shouldn`t be reset if user picked one of the saved credit cards
						if (!savedCreditCardSelected) {
							$creditCard.find('select[name$="_creditCardList"]').val('');
							$('[data-js="save-credit-card"]').removeClass('u-hidden');
						}
						savedCreditCardSelected = false;
						paymentInstrumentRemoved = true;
					}
				};

				submitForm.submitAjaxForm(options);
			}
		}, 500));
}

/**
 * Ajax call to Checkout-SubmitForm. Updates shipping items to add/remove Cash on Delivery
 * @param {String} selectedPaymentMethod
 */
function updatePaymentMethod(selectedPaymentMethod) {
	var options = {
		action: 'updatePaymentMethod',
		extraParameters: [{ name: 'paymentMethodID', value: selectedPaymentMethod }]
	};

	if (selectedPaymentMethod === 'upi_collect') {
		options.callback = function() {
			accessibility.simulateTabKeyFocus($('#dwfrm_adyPaydata_vpa'));
		};
	}

	submitForm.submitAjaxForm(options);
}

/**
 * Toggles payment section either by adding u-hidden class or by replacing the payment block
 * @param {String} data represents AJAX response data
 * @param {String} action
 */
function togglePaymentSection(data, action) {
	var sectionActions = ['saveContactDetails', 'editContactDetails', 'editShippingDetails', 'saveShippingDetails', 'setAllCheckoutFields'];
	var responseCartTotal = $(data).find('#currentOrderTotal').val();
	var pageCartTotal = $('#currentOrderTotal').val();
	var isFreeOrderSwitch = pageCartTotal !== responseCartTotal && (responseCartTotal === '0.0' || pageCartTotal === '0.0');
	var isContactSectionReadonly = $(data).find('[data-js="checkout-contact"]').hasClass('js-readonly-section');
	var isShippingSectionReadonly = $(data).find('[data-js="shipping-details-block"]').hasClass('js-readonly-section');
	var showPaymentSection = isContactSectionReadonly && isShippingSectionReadonly;
	var isRemovePaymentInstrument = action === 'removePaymentInstrument';
	var isKonbini = false;
	var isUpi = false;

	if (action === 'updatePaymentMethod') {
		isKonbini = $('.is-econtext_seven_eleven').hasClass('is-active') || $('.is-econtext_stores').hasClass('is-active');
		isUpi = $('.is-upi_collect').hasClass('is-active') || $('.is-upi_qr').hasClass('is-active');
	}

	if (sectionActions.indexOf(action) === -1) {
		// if ajax call isn't for saving/editing contact details or shipping details section we replace the payment section or do nothing
		if (isFreeOrderSwitch || isRemovePaymentInstrument || isKonbini || isUpi) {
			//We call replace in two cases: 
			//	on removing payment instrument
			//	on free order switch (either page or response contain free order but not both)
			replacePaymentSection(data);
		}
		return;
	}

	//for free order we don't save cc data so we can replace payment section
	if (pageCartTotal === '0.0') {
		replacePaymentSection(data);
		return;
	}

	//we use class u-hidden to hide it on FE that way we keep cc info (except cvn) if customers edit are sure that we don't save
	var creditCard = $('.js-creditcard-fieldset');
	if (creditCard.length) {
		creditCard.find('input[name$="_cvn"]').val('');
	}

	if (showPaymentSection) {
		if ($('.payment-methods-row').length) {
			$('.payment-methods-row').removeClass('u-hidden');
			if ($('.js-checkout-billing').length) {
				$('.js-checkout-billing').removeClass('u-hidden');
			}
			else {
				$('.js-checkout-payment').append($(data).find('.js-checkout-billing'));
			}
			$('.js-checkout-cta').removeClass('u-hidden');

			// initialize Adyen WebComponent
			if (adyenCheckout !== null && $('.js-checkout-payment').find('.js-radio-container-payment.is-credit_card').hasClass('is-active')) {
				adyenCheckout.initBilling();
			}

			return;
		}
		//in case that payment section is not hidden we need to replace it
		replacePaymentSection(data, true);

		return;
	}

	$('.payment-methods-row').addClass('u-hidden');
	$('.js-checkout-billing').addClass('u-hidden');
	$('.js-checkout-cta').addClass('u-hidden');
}

/**
 * Replaces payment section if either response or page payment section is empty (in that case we are working with free orders)
 * @param {String} data represents AJAX response data
 */
function replacePaymentSection(data, keepCCInfo) {
	var responsePaymentSection = $(data).find('.js-checkout-payment');
	var pagePaymentSection = $('.js-checkout-payment');
	var isChangeOnPaymentMethods = false;
	var isKonbini = $('.is-econtext_seven_eleven').hasClass('is-active') || $('.is-econtext_stores').hasClass('is-active');
	var isUpi = $('.is-upi_collect').hasClass('is-active') || $('.is-upi_qr').hasClass('is-active');

	var hidePaymentSection = $.trim(responsePaymentSection.html()) === '';
	var isPaymentAlreadyHidden = $.trim(pagePaymentSection.html()) === '';

	if (!hidePaymentSection || !isPaymentAlreadyHidden) {
		// we detect change on payment methods if there is change in number (length) of payment methods, or
		// if credit card method is selected / deselected
		var pagePaymentMethods = $('.js-radio-container-payment');
		var pageCreditCardSelected = $('.js-radio-container-payment.is-credit_card').hasClass('is-active');
		var pagePaypalReadonly = $('.checkout-paypal-readonly');
		var responsePaymentMethods = $(data).find('.js-radio-container-payment');
		var responseCreditCardSelected = $(data).find('.js-radio-container-payment.is-credit_card').hasClass('is-active');
		var responsePaypalReadonly = $(data).find('.checkout-paypal-readonly');

		if ((pagePaymentMethods.length !== responsePaymentMethods.length)
			|| (pageCreditCardSelected && !responseCreditCardSelected)
			|| (!pageCreditCardSelected && responseCreditCardSelected)
			|| (pagePaypalReadonly.length !== responsePaypalReadonly.length)) {
			isChangeOnPaymentMethods = true;
		}
	}

	if (hidePaymentSection || isPaymentAlreadyHidden || isChangeOnPaymentMethods || isKonbini || isUpi) {
		if (isKonbini) {
			responsePaymentSection.find('.is-econtext_seven_eleven').addClass('is-active');
		}
		else if (isUpi) {
			responsePaymentSection.find('.is-upi_collect').addClass('is-active');
		}

		var oldWrappers = pagePaymentSection.find('.js-radio-container-payment');

		// loop the current payment method wrappers and update the issuer value in the new one that will replace the current
		for (var i = 0; i < oldWrappers.length; i++) {
			var oldWrapperClass = $(oldWrappers[i]).attr('data-class');
			var issuer = $(oldWrappers[i]).attr('data-issuer');

			if (issuer) {
				responsePaymentSection.find('.' + oldWrapperClass).attr('data-issuer', issuer);
				responsePaymentSection.find('.' + oldWrapperClass).find('[data-js="payment-method-issuer"]').val(issuer);
			}
		}

		pagePaymentSection.html(responsePaymentSection.html());

		// initialize Adyen WebComponent
		if (adyenCheckout !== null && responsePaymentSection.find('.js-radio-container-payment.is-credit_card').hasClass('is-active')) {
			adyenCheckout.initBilling();
		}

		if ($('.js-selected-paymentmethod').val() === 'CREDIT_CARD' && !keepCCInfo) {
			clearCreditCard();
		}

		globalPayment.init();
	}
}

/**
 * Clears credit card fields, used with cybersource
 */
function clearCreditCard() {
	var creditCard = $('.js-creditcard-fieldset');
	var cardNumber = creditCard.find('input[name*="_creditCard_number"]');
	var cardExpiration = creditCard.find('input[name$="_monthAndYear"]');
	var cardOwner = creditCard.find('input[name$="creditCard_owner"]');
	var cvn = creditCard.find('input[name$="creditCard_cvn"]');

	creditCard.find('select[name$="_creditCardList"]').val('');
	creditCard.find('select[name$="_type"]').val('');
	cardNumber.attr('cctype', '');

	clearFieldAndRemoveValidation(cardNumber);
	clearFieldAndRemoveValidation(cardExpiration);
	clearFieldAndRemoveValidation(cardOwner);
	clearFieldAndRemoveValidation(cvn);
}

/**
 * Removes any value and validation or focus classesfrom the field
 * @param {Object} field
 */
function clearFieldAndRemoveValidation(field) {
	var inputContainer = field.closest('.input-container');

	field.val('');
	field.removeClass('is-valid');
	field.removeClass('error');
	inputContainer.removeClass('is-valid');
	inputContainer.removeClass('has-error');
	field.closest('.input-field').removeClass('input-with-focus');

	var errorMsg = inputContainer.find('span.error');
	if (errorMsg.length) {
		errorMsg.remove();
	}
}

/**
 * Calls method for updating payment method
 * @param {String} data
 * @param {String} action
 */
function updatePaymentSection(data, action) {
	togglePaymentSection(data, action);
}

exports.init = function() {
	initializeEvents();
	globalPayment.init();

	if (adyenCheckout && typeof(adyenCheckout) != 'undefined') {
		if (adyenAction) {
			adyenAction.init();
		}

		if ($('#dwfrm_adyPaydata_issuer').length > 0) {
			// if there is already a selected issuer then make it selected on the selector
			if ($('#dwfrm_adyPaydata_issuer').val()) {
				$('[data-js="payment-method-issuer"]').val($('#dwfrm_adyPaydata_issuer').val());
				$('.js-radio-container-payment.is-active').attr('data-issuer', $('#dwfrm_adyPaydata_issuer').val());
			}
			else {
				// determine the issuer selector of currently selected payment method (so the visible one)
				var currentPaymentMethodIssuerSelector = $('[data-js="payment-method-issuer"]').filter(':visible');

				if (currentPaymentMethodIssuerSelector.length > 0) {
					// get the selected value from it
					var issuerValue = currentPaymentMethodIssuerSelector.val();

					// if the selected value is empty then get the first value
					if (!issuerValue) {
						issuerValue = currentPaymentMethodIssuerSelector.find('option:first-child').val();
						currentPaymentMethodIssuerSelector.val(issuerValue);
					}

					$('#dwfrm_adyPaydata_issuer').val(issuerValue);
				}
			}
		}

		// when issuer changed then put it on the input
		$('body').off('change', '[data-js="payment-method-issuer"]').on('change', '[data-js="payment-method-issuer"]', function(e) {
			$(this).closest('.js-radio-container-payment').attr('data-issuer', $(this).val());
			$('#dwfrm_adyPaydata_issuer').val($(this).val());
		});
	}
	else {
		initializeCybersourceEvents();
	}
};
exports.updatePaymentSection = updatePaymentSection;
