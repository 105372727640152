'use strict';

var trackingHelper = require('./trackingHelper');

/**
 * Pushes data to track check store availability button click event
 * @param {*} event
 * @param {*} productId
 */
function checkStoreAvailabilityEvent(event, productId) {
	var trackingObj = {
		'event': 'GA Tracking',
		'eventCategory': 'Store Availability',
		'eventAction': 'Click on Check Store Availability',
		'eventLabel': productId
	};
	trackingHelper.pushToDataLayer(trackingObj);
}

/**
 * Pushes data to track store availability search results event
 * @param {*} event
 * @param {*} isNearMe
 */
function searchStoresEvent(event, isNearMe) {
	var resultList = $('[data-js="store-results-list"]');
	var numberOfStoreResults = resultList.length > 0 ? resultList.data('numberofstoreresults') : null;

	var trackingObj = {
		'event': 'GA Tracking',
		'eventCategory': 'Store Availability',
		'eventAction': isNearMe ? 'Store Search - Near Me' : 'Store Search',
		'eventLabel': numberOfStoreResults
	};
	trackingHelper.pushToDataLayer(trackingObj);
}

/**
 * Pushes data to track select store event
 * @param {*} event
 * @param {*} storeId
 */
function selectStoreEvent(event, storeId) {
	var trackingObj = {
		'event': 'GA Tracking',
		'eventCategory': 'Store Availability',
		'eventAction': 'Select Store',
		'eventLabel': storeId
	};
	trackingHelper.pushToDataLayer(trackingObj);
}

/**
 * Pushes data to track store availability popup close event
 * @param {*} event
 * @param {*} storeId
 */
function closeStoreDialogEvent(event, storeId) {
	var trackingObj = {
		'event': 'GA Tracking',
		'eventCategory': 'Store Availability',
		'eventAction': 'Close Store Availability',
		'eventLabel': storeId
	};
	trackingHelper.pushToDataLayer(trackingObj);
}

function initializeEvents() {
	$(document).on('checkStoreAvailability', checkStoreAvailabilityEvent);
	$(document).on('searchStores', searchStoresEvent);
	$(document).on('selectStore', selectStoreEvent);
	$(document).on('closeStoreDialog', closeStoreDialogEvent);
}

/**
 * Initialize all events for pagedesigner GTM tracking
 */
exports.init = function () {
	initializeEvents();
};