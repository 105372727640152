'use strict';

var forms = require('../forms');

const componentForm = {
	subpremise: {
		selector: '[id$="address_address1"]',
		type: 'long_name'
	},
	street_number: {
		selector: '[id$="address_address1"]',
		type: 'long_name'
	},
	route: {
		selector: '[id$="address_address1"]',
		type: 'long_name'
	},
	locality: {
		selector: '[id$="address_city"]',
		type: 'long_name'
	},
	administrative_area_level_1: {
		selector: '[id$="address_states_state"]',
		type: 'short_name'
	},
	postal_code: {
		selector: '[id$="address_postal"]',
		type: 'long_name'
	},
	postal_code_suffix: {
		selector: '[id$="address_postal"]',
		type: 'long_name'
	}
};

var initializeStarted = false;

/**
 * Initialize google address complete
 */
function initialize() {
	if ($('[data-js="google-autocomplete-form"]').length === 0) {
		return;
	}

	if (initializeStarted) {
		return;
	}

	if (window.google === undefined || window.google.maps === undefined || window.google.maps.places === undefined) {
		var locale = $('#js-currentlocale').val().split('_')[0];
		initializeStarted = true;

		$.getScript('//maps.googleapis.com/maps/api/js?key=' + SitePreferences.GOOGLEMAP_APIKEY + '&libraries=places&language=' + locale,
			function () {
				initAutocomplete();
			});
	}
	else {
		initAutocomplete();
	}
}

/**
 * Initialize the auto complete on the field
 */
function initAutocomplete() {
	var currentCountry = $('#js-currentcountry').val();
	var inputs = $('[id$="address_address1"]:not(.js-autocomplete-enabled)');

	// Loop all auto complete fields to initialize autocomplete for them.
	for (var i = 0; i < inputs.length; i++) {
		var input = inputs[i];

		if (!$(input).is(':visible')) {
			continue;
		}

		// Create the autocomplete object, restricting the search predictions to geographical location types.
		var autocomplete = new google.maps.places.Autocomplete(
			input, {
				types: ["geocode"],
				componentRestrictions: {country: currentCountry}
			}
		);

		// Keep the field id together with the autocomplete object.
		autocomplete.fieldId = input.id;

		// Avoid paying for data that you don't need by restricting the set of place fields that are returned to just the address components.
		autocomplete.setFields(['address_component', 'place_id']);

		// When the user selects an address from the drop-down, populate the address fields in the form.
		google.maps.event.addListener(autocomplete, 'place_changed', fillInAddress);

		$(input).addClass('js-autocomplete-enabled');
	}

	initializeStarted = false;
}

/**
 * Fill in the details of selected address in the form
 */
function fillInAddress() {
	var autocomplete = this;
	var field = $('#' + autocomplete.fieldId);
	var $formWrapper = field.closest('fieldset');

	// remove existing values from all fields in the form
	for (var componentName in componentForm) {
		var component = componentForm[componentName];
		var field = $formWrapper.find(component.selector);
		field.val('');
		field.closest('.input-container').removeClass('is-filled');
		field.closest('.input-container').removeClass('is-valid');
		field.trigger('focusin').trigger('focusout');
	}

	// Get the place details from the autocomplete object.
	const place = autocomplete.getPlace();

	var postalCode = '';
	var postalCodeSuffix = '';
	var address = '';
	var streetNumber = '';
	var unitNumber = '';

	// Get each component of the address from the place details,
	// and then fill-in the corresponding field on the form.
	for (const component of place.address_components) {
		const addressType = component.types[0];

		if (componentForm[addressType]) {
			const val = component[componentForm[addressType].type];
			
			if (addressType === 'postal_code') {
				postalCode = val;
				continue;
			}
			else if (addressType === 'postal_code_suffix') {
				postalCodeSuffix = val;
				continue;
			}
			else if (addressType === 'route') {
				address = val;
				continue;
			}
			else if (addressType === 'street_number') {
				streetNumber = val;
				continue;
			}
			else if (addressType === 'subpremise') {
				unitNumber = val;
				continue;
			}

			var selector = componentForm[addressType].selector;

			updateValue($formWrapper, selector, val);
		}
	}

	// address line 1 = street number + route
	if ((address || streetNumber) && componentForm['route']) {
		var addressValue = unitNumber ? unitNumber + '/' + streetNumber : streetNumber;
		addressValue += addressValue ? (' ' + address) : address;

		var selector = componentForm['route'].selector;
		updateValue($formWrapper, selector, addressValue);
	}

	// post code = postal code + postal code suffix
	if (postalCode && componentForm['postal_code']) {
		if (postalCodeSuffix) {
			postalCode = postalCode + '-' + postalCodeSuffix;
		}

		var selector = componentForm['postal_code'].selector;
		updateValue($formWrapper, selector, postalCode);
	}

	field.trigger('change.addressfields');
}

/**
 * Updates the value of element with given selector.
 * @param {object} $formWrapper Wrapper around the form
 * @param {string} selector Selector for the field
 * @param {string} val Value to update the field with
 */
function updateValue($formWrapper, selector, val) {
	var $inputElement = $formWrapper.find(selector),
		$container = $inputElement.closest('.input-container');

	$inputElement.val(val).trigger('focusin').trigger('focusout');

	if ($formWrapper.length != 1) { return; }

	$container.addClass('is-filled');
	var isValid = $formWrapper.validate().element($inputElement);

	if (isValid) {
		$container.addClass('is-valid');
		forms.checkInputFields();
	}
}

exports.init = function () {
	initialize();
};