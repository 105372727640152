'use strict';

var dataLayer = window.dataLayer || [];
var ecommerceObject = null;

/**
 * Pushes basic tracking data to datalayer
 * @param {String} category
 * @param {String} action
 * @param {String} label
 * @param {String} value
 * @param {String} eventNonInteraction
 */
function pushBasicTrackingEvent(category, action, label, value, eventNonInteraction) {
	var gtmObj = {
		event: 'GA Tracking',
		eventCategory: category,
		eventAction: action,
		eventLabel: label
	};

	if (value) {
		gtmObj.eventValue = value;
	}

	if (eventNonInteraction) {
		gtmObj.eventNonInteraction = eventNonInteraction;
	}

	pushToDataLayer(gtmObj);
}

/**
 * Pushes tracking data and then redirects to desired URL after callback
 * @param {String} category The event category
 * @param {*} action The event action
 * @param {*} label The event label
 * @param {*} callbackDestinationUrl The URL that will be redirected after tracking event pushed
 */
function pushTrackingEventAndGoToDestination(category, action, label, callbackDestinationUrl) {
	var gtmObj = {
		'event': 'GA Tracking',
		'eventCallback': function() {
			document.location = callbackDestinationUrl;
		},
		'eventTimeout': 1000,
		'eventCategory': category,
		'eventAction': action,
		'eventLabel': label,
		'eventValue': 0,
		'eventNonInteraction': false
	};

	pushToDataLayer(gtmObj);
}

/**
 * Pushes received tracking data to dataLayer
 * @param {Object} gtmObj The data that needs to be pushed
 */
function pushToDataLayer(gtmObj) {
	if (googleAnalyticInitialized && !!window.google_tag_manager) {
		try {
			dataLayer.push(gtmObj);
		}
		catch (err) {
			if (!(typeof console === 'undefined')) {
				// don't break page javascript, when there is an error in the tracking code
				console.error('Error in tracking script: ' + err.message + ', ' + err.stack);
			}
		}
	}
}

/**
 * Returns clickable element related to current element
 * @param {Object} element
 * @returns {Object}
 */
function getClickableElement(element) {
	if (element.is('a') || !!element.attr('href')) {
		return element;
	}
	var clickableElement = element.find('a');
	if (clickableElement.length > 0) {
		return clickableElement;
	}

	//in case of product tiles
	var productTile = element.closest('.js-product-tile');
	clickableElement = productTile.find('a');
	if (clickableElement.length > 0) {
		return clickableElement;
	}

	clickableElement = element.closest('a');
	if (clickableElement.length > 0) {
		return clickableElement;
	}

	return null;
}

/**
 * If the given input is null then return empty string.
 * @param {*} input The input
 * @param {*} toLowerCase If true then convert to lower case
 * @returns
 */
function emptyIfNull(input, toLowerCase) {
	var converted = input == null ? '' : input;

	if (toLowerCase) {
		converted = converted.toString().toLowerCase();
	}

	return converted;
}

/**
 * Gets the product tracking details from the given object.
 * @param {Object} jqueryObj The Dom element
 * @param {Boolean} includeStockLevel
 * @param {Integer} position
 * @param {*} list
 * @returns
 */
function getTrackingProduct(jqueryObj, includeStockLevel, position, list) {
	var name = jqueryObj.data('name');
	var id = jqueryObj.data('itemid') !== undefined ? jqueryObj.data('itemid').toString() : jqueryObj.data('itemid');
	var brand = jqueryObj.data('brand');
	var price = parseFloat(jqueryObj.data('price'));
	var categories = jqueryObj.data('categories');
	var variant = emptyIfNull(jqueryObj.data('color'));
	var variantgroupId = emptyIfNull(jqueryObj.data('variantgroup-id')).toString();
	var size = emptyIfNull(jqueryObj.data('size'));
	var discount = emptyIfNull(jqueryObj.data('discount'));
	var discountPrice = emptyIfNull(jqueryObj.data('discount-price'));
	var stockLevel = emptyIfNull(jqueryObj.data('stock-level'));
	var viewsDay = jqueryObj.data('views-day');

	if (size != null && size != '') {
		variant = variant + ' + ' + size;
	}

	var product = {
		'name': name,
		'id': id,
		'brand': brand,
		'price': '',
		'category': categories,
		'variant': variant,
		'viewsDay': viewsDay,
		variantgroupId: variantgroupId
	};

	if (includeStockLevel && stockLevel) {
		product.stockLevel = stockLevel;
	}

	if (price) {
		product.price = price;
	}

	if (discount > 0) {
		product.discountAmount = discount;
		product.price = discountPrice;
		product.originalPrice = price;
	}

	// list
	if (list != null && list != '') {
		$.extend(product, { 'list': list });
	}
	// position
	if (position != null && position != '') {
		$.extend(product, { 'position': position });
	}
	// availability
	var availability = jqueryObj.data('availability');
	if (availability != null && availability != '') {
		$.extend(product, { 'availability': availability });
	}
	// quantity
	var quantity = jqueryObj.data('quantity');
	if (quantity != null && quantity != '') {
		$.extend(product, { 'quantity': quantity });
	}

	// availability
	var variantId = jqueryObj.data('variant-id');
	if (variantId != null && variantId != '') {
		$.extend(product, { 'variantSKU': variantId });
	}

	return product;
}

/**
 * Merges the given object with ecommerceObject
 * @param {Object} mergeObject
 */
function ecommerceMerge(mergeObject) {
	// https://www.simoahava.com/analytics/enhanced-ecommerce-tips-and-learnings/#1.1
	// "..Only the most recent ecommerce object is included in the hit!.." Use this to merge.
	if (this.ecommerceObject == null) {
		this.ecommerceObject = mergeObject;
	}
	else {
		this.ecommerceObject = $.extend(true, {}, this.ecommerceObject, mergeObject);
	}
}

function pushEcommerceObject() {
	if (this.ecommerceObject != null) {
		pushToDataLayer(this.ecommerceObject);
	}
}

function isCartUrl() {
	return $('#js-rawurl').val().indexOf('Cart-Show') != -1;
}

exports.ecommerceObject = ecommerceObject;
exports.sessionStorageListName = 'gtmlistName';
exports.isCartUrl = isCartUrl;
exports.ecommerceMerge = ecommerceMerge;
exports.pushToDataLayer = pushToDataLayer;
exports.getTrackingProduct = getTrackingProduct;
exports.pushEcommerceObject = pushEcommerceObject;
exports.getClickableElement = getClickableElement;
exports.pushBasicTrackingEvent = pushBasicTrackingEvent;
exports.pushTrackingEventAndGoToDestination = pushTrackingEventAndGoToDestination;