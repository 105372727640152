'use strict';

var _ = require('lodash'),
	countries = require('../countries'),
	video = require('../video'),
	minicart = require('../minicart'),
	searchplaceholder = require('../searchplaceholder'),
	tooltip = require('../tooltip'),
	validator = require('../validator'),
	swiperCarousel = require('../swiper'),
	productTile = require('../product-tile'),
	tracking = require('../tracking/index'),
	newsletter = require('../newsletter'),
	contactus = require('../contactus'),
	languageswitcher = require('../languageswitcher'),
	pagedesigner = require('../pagedesigner'),
	plpSwitchMobileView = require('../plpSwitchMobileView'),
	lazyloadContentImages = require('../lazyloadContentImages'),
	pdpAccordion = require('../pdpAccordion'),
	scrollToTop = require('../scrollToTop'),
	polyfills = require('../polyfills'),
	qtySelector = require('../quantitySelector'),
	fbconversion = require('../fbconversion'),
	stickyHeader = require('../stickyheader'),
	stickyIndicator = require('../stickystepindicator'),
	hideModalsPopup = require('../hideModalsPopup'),
	mainSearch = require('../mainSearch'),
	mainNavDropdown = require('../mainNavDropdown'),
	plpMobileFilter = require('../plpMobileFilter'),
	cookiePrivacy = require('./cookiePrivacy'),
	contentDialog = require('./contentDialog'),
	content = require('../content'),
	util = require('../util'),
	searchsuggest = require('../searchsuggest'),
	deviceSpecificImages = require('./deviceSpecificImages'),
	privacyPolicy = require('./privacyPolicy'),
	cartreminder = require('./cartReminder'),
	recommendations = require('./recommendations'),
	bgImgLazysizes = require('./bgImgLazysizes'),
	toggle = require('./toggle'),
	cleanCareProtectTabs = require('../cleanCareProtectTabs'),
	JQuery = require('./jQuery');

var accessibility = (util.isMobile()) ? require('../accessibilityForMobile') : require('../accessibility');


function initializeDom() {
	$('html').addClass('js');
	if (SitePreferences.LISTING_INFINITE_SCROLL) {
		$('html').addClass('infinite-scroll');
	}
}

//Clean Care and Protect tabs
cleanCareProtectTabs.init();

function windowEvents() {
	// Initializes on Scroll event
	const $scrollParent = util.getScrollParent();
	$scrollParent.on('scroll', function() {
		stickyHeader.init();
		stickyIndicator.init();
	});

	// on PD content pages (or also home page with PD) we don't scroll window to be able to have the effects on the text of components
	// the element having class "pt_.." is being scrolled
	// so here we trigger the scroll event in that case for third parties in case the scrolling parent element is not "window"
	// if it is "window" then those third parties will already catch the scroll event on "window"
	if ($scrollParent.get(0) !== window) {
		$scrollParent.on('scroll', (e) => {
			// we fire the window scroll event for the 3rd parties which are using scroll event (like Yotpo image gallery)
			window.dispatchEvent(new CustomEvent('scroll'));
		});
	}

	// Initializes on Resize event
	$(window).on('resize', function() {
		mainNavDropdown.resize();
		mainSearch.resize();
		stickyHeader.init();
		stickyIndicator.init();
		hideModalsPopup.init();
	});

	// Initializes on Resize event with debounce function
	$(window).on('resize',
		_.debounce(function () {
			plpMobileFilter.init();
		}, 100));
}

var appEvents = {
	onLoadInit: function() {
		JQuery.init();
		cookiePrivacy.init();
		contentDialog.init();
		content.init();
		util.generalExtensions();
		windowEvents();
	},
	init: function() {
		searchsuggest.init($('.nav-wrap .js-header-search'), Resources.SIMPLE_SEARCH);
		privacyPolicy.init();
		recommendations.init();
		cartreminder.init();
		bgImgLazysizes.init();
		toggle.init();
	},
	globalComponentsInit: function() {
		initializeDom();
		deviceSpecificImages.init();
		tracking.init();
		video.init();
		countries.init();
		tooltip.init();
		minicart.init();
		validator.init();
		searchplaceholder.init();
		swiperCarousel.init();
		productTile.init();
		stickyHeader.init();
		stickyIndicator.init();
		newsletter.init();
		accessibility.init();
		contactus.init();
		hideModalsPopup.init();
		mainNavDropdown.init();
		mainSearch.init();
		plpMobileFilter.init();
		languageswitcher.init();
		pagedesigner.init();
		plpSwitchMobileView.init();
		lazyloadContentImages.init();
		qtySelector.init();
		scrollToTop.init();
		polyfills.init();
		pdpAccordion.init();
		fbconversion.init();
		util.limitCharacters();
	}
}

module.exports = appEvents;