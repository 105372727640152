/* eslint-disable no-undef */

'use strict';

var Promise = require('promise');
var dialog = require('./dialog');

function createCheckoutSessionWithConfirm() {
	return new Promise(function (resolve, reject) {
		showConfirmPopup(resolve, reject);
	});
}

function createCheckoutSessionWithoutConfirm() {
	return new Promise(function (resolve, reject) {
		createCheckoutSession(resolve, reject);
	});
}

function showConfirmPopup(resolve, reject) {
	var dialogContainer = $('[data-js="checkout-amazon-continue"]');

	if (dialogContainer) {
		dialog.open({
			html: dialogContainer.html(),
			options: {
				dialogClass: 'amazonpay-dialog'
			},
			callback: function() {
				$('[data-js="no-change"]').on('click', function(e) {
					e.preventDefault();
					dialog.close();
				});
				$('[data-js="select-amazon"]').off('click').on('click', function(e) {
					e.preventDefault();
					createCheckoutSession(resolve, reject);
				});
			}
		});
	}
}

function createCheckoutSession(resolve, reject) {
	$.ajax({
		url: AmazonURLs.createCheckoutSession,
		type: 'POST',
		success: function (data) {
			resolve(data.checkoutSessionId || '');
			dialog.close();
		},
		error: function () {
			reject();
		}
	});
}

var amazonPaymentsObject = {
	addButtonToCheckoutPage: function () {
		var amazonButton = $('#AmazonPayButtonCheckout');

		if (amazonButton.length) {
			var container = amazonButton.closest('[data-js="amz-pay-checkout"]');
			var createCheckoutSessionFunction = createCheckoutSessionWithoutConfirm;

			if (container.length > 0 && container.data('confirm') === true) {
				createCheckoutSessionFunction = createCheckoutSessionWithConfirm;
			}

			// eslint-disable-next-line
			amazon.Pay.renderButton('#AmazonPayButtonCheckout', {
				merchantId: AmazonSitePreferences.AMAZON_MERCHANT_ID,
				createCheckoutSession: createCheckoutSessionFunction,
				ledgerCurrency: AmazonSitePreferences.AMAZON_CURRENCY,
				checkoutLanguage: AmazonSitePreferences.AMAZON_CHECKOUT_LANGUAGE,
				productType: AmazonSitePreferences.AMAZON_PRODUCT_TYPE,
				sandbox: AmazonSitePreferences.AMAZON_SANDBOX_MODE,
				placement: 'Checkout'
			});

			this.setAsInitialized();
		}
	},
	addMobileButtonToCheckoutPage: function () {
		var amazonButton = $('#AmazonPayButtonCheckoutMobile');

		if (amazonButton.length) {
			var container = amazonButton.closest('[data-js="amz-pay-checkout"]');
			var createCheckoutSessionFunction = createCheckoutSessionWithoutConfirm;

			if (container.length > 0 && container.data('confirm') === true) {
				createCheckoutSessionFunction = createCheckoutSessionWithConfirm;
			}

			// eslint-disable-next-line
			amazon.Pay.renderButton('#AmazonPayButtonCheckoutMobile', {
				merchantId: AmazonSitePreferences.AMAZON_MERCHANT_ID,
				createCheckoutSession: createCheckoutSessionFunction,
				ledgerCurrency: AmazonSitePreferences.AMAZON_CURRENCY,
				checkoutLanguage: AmazonSitePreferences.AMAZON_CHECKOUT_LANGUAGE,
				productType: AmazonSitePreferences.AMAZON_PRODUCT_TYPE,
				sandbox: AmazonSitePreferences.AMAZON_SANDBOX_MODE,
				placement: 'Checkout'
			});

			this.setAsInitialized();
		}
	},
	addButtonToCartPage: function () {
		if ($('#AmazonPayButtonCart').length) {
			// eslint-disable-next-line
			amazon.Pay.renderButton('#AmazonPayButtonCart', {
				merchantId: AmazonSitePreferences.AMAZON_MERCHANT_ID,
				createCheckoutSession: createCheckoutSessionWithoutConfirm,
				ledgerCurrency: AmazonSitePreferences.AMAZON_CURRENCY,
				checkoutLanguage: AmazonSitePreferences.AMAZON_CHECKOUT_LANGUAGE,
				productType: AmazonSitePreferences.AMAZON_PRODUCT_TYPE,
				sandbox: AmazonSitePreferences.AMAZON_SANDBOX_MODE,
				placement: 'Cart'
			});

			this.setAsInitialized();
		}
	},
	initiateBindChangeActions: function () {
		if ($('.edit-shipping').length || $('.change-payment').length || $('[data-js="edit-contact"]').length) {
			$.ajax({
				url: AmazonURLs.getCheckoutSession,
				type: 'GET',
				success: function (data) {
					if ($('.edit-shipping').length) {
						// eslint-disable-next-line
						amazon.Pay.bindChangeAction('.edit-shipping', {
							amazonCheckoutSessionId: data.checkoutSessionId,
							changeAction: 'changeAddress'
						});
					}
					if ($('[data-js="edit-contact"]').length) {
						// eslint-disable-next-line
						amazon.Pay.bindChangeAction('[data-js="edit-contact"]', {
							amazonCheckoutSessionId: data.checkoutSessionId,
							changeAction: 'changeAddress'
						});
					}
					if ($('.change-payment').length) {
						// eslint-disable-next-line
						amazon.Pay.bindChangeAction('.change-payment', {
							amazonCheckoutSessionId: data.checkoutSessionId,
							changeAction: 'changePayment'
						});
					}
				}
			});
		}
	},
	placeOrderAction: function () {
		$('.process-order-amazon').click(function (e) {
			e.preventDefault();
			if ($('.process-order-amazon').data('action')) {
				window.location.href = $('.process-order-amazon').data('action');
			}
		});
	},
	setAsInitialized: function() {
		var wrapper = $('[data-js="amazon-pay-wrapper"]');
		if (wrapper.length > 0) {
			wrapper.attr('data-initialized', true);
		}
	},
	init: function () {
		this.addButtonToCartPage();
		this.addButtonToCheckoutPage();
		this.addMobileButtonToCheckoutPage();
		this.initiateBindChangeActions();
		this.placeOrderAction();
	}
};

exports.init = function () {
	var wrapper = $('[data-js="amazon-pay-wrapper"]');
	if (wrapper.length === 0) {
		return;
	}

	var isAmazonPayInitialized = wrapper.attr('data-initialized');
	if (isAmazonPayInitialized === 'true') {
		return;
	}

	amazonPaymentsObject.init();
};