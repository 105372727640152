'use strict';

var util = require('../../util');
/**
 * @description Initialize event handlers for product description on product detail page
    so we can initialize this event where ever we want.
 */

exports.init = function (isQuickView) {
	if (!isQuickView) {
		initStickyBar();
	}

	initEvents();
};


/**
 * This method determines whether if add to cart container should be stick or not
 */
function manageAddToCartButtonContainerStickyness() {
	var addToCartButtonContainer = $('.js-product-bottom-info');
	var successfulMessage = $('[data-js="pdp-successful-msg"]');
	if (!addToCartButtonContainer.length || successfulMessage.is(':visible')) { return; }

	var isVisible = util.elementInViewport(addToCartButtonContainer.get(0), 0);
	var isFooterVisible = util.elementInViewport($('.js-footer').get(0), 0);

	if (util.isTabletAndBelow()) {
		$('.js-product-bottom-info .js-color-container').toggleClass('u-hidden', $('.js-color-swatch.color .selected').length > 0 && !isVisible);// Hide color variation container when color is selected and is sticky
		if (isVisible) {
			addToCartButtonContainer.css('min-height', addToCartButtonContainer.children().height());
		}

		var topOfElement = addToCartButtonContainer.offset().top;
		var bottomOfElement = topOfElement + addToCartButtonContainer.outerHeight(true);
		var scrollTopPosition = $(window).scrollTop() + $(window).height() + 100;
		var windowScrollTop = $(window).scrollTop() - 100;

		if ((windowScrollTop > topOfElement && windowScrollTop < bottomOfElement)
			|| (scrollTopPosition < bottomOfElement && scrollTopPosition > topOfElement)
			|| (windowScrollTop <= topOfElement && scrollTopPosition >= bottomOfElement)) {
			addToCartButtonContainer.addClass('step-before-sticky');
		}
		else {
			addToCartButtonContainer.removeClass('step-before-sticky');
		}
		addToCartButtonContainer.toggleClass('is-sticky', !isVisible); // Add class when is not visible
		if (isFooterVisible) {
			addToCartButtonContainer.addClass('step-before-sticky'); // hide the sticky add to cart when reach the footer
		}
	}
}

/**
 * This method determines accessbility of stickybar and  whether if stickybar should be stick or not
 */
function manageStickyBarStickiness() {
	var stickyRefItem = $('.pdp-add-to-cart');
	var stickyBar = $('.js-sticky-bar');
	if (stickyRefItem.length > 0 && stickyBar.length > 0) {
		var windowScrollTop = $(window).scrollTop();
		var pageWrapper = $('.pt_product-detail');
		var headerNavHeight = $('.js-header .c-header-nav').outerHeight(); // calculate overflowing of sticky header
		var hasStickyHeader = $('.sticky-header').length > 0;
		var stickyHeaderOverflow = hasStickyHeader ? headerNavHeight : 0; // overflow height of sticky header

		var boundary = stickyRefItem.outerHeight() + stickyRefItem.offset().top - stickyHeaderOverflow; // boundary for stickiness

		if (boundary > windowScrollTop) {
			stickyBar.removeClass('is-visible');
			pageWrapper.removeClass('sticky-addtocart');
		}
		else {
			stickyBar.addClass('is-visible');
			pageWrapper.addClass('sticky-addtocart');
		}
	}
}

function initEvents() {
	var addToCartButtonContainer = $('.js-product-bottom-info');
	if (!addToCartButtonContainer.length) { return; }
	var isVisible = util.elementInViewport(addToCartButtonContainer.get(0), 0);

	$(document).on('checkaddtocartbuttonvisibility', manageAddToCartButtonContainerStickyness);
	$(document).on('checkaddtocartbuttonvisibility', manageStickyBarStickiness);

	// Sticky Section
	$(window).on('scroll', manageAddToCartButtonContainerStickyness);
	$(window).on('scroll', manageStickyBarStickiness);

	// Open options
	$('.js-product-options').off('click').on('click', function (e) {
		if (util.isSmallScreenSize()) {
			e.preventDefault();
			$('.js-product-bottom-info').toggleClass('option-is-open');
		}
	});

	// Close Options
	$('.product-close-section .js-icon-close').off('click').on('click', function (e) {
		if (util.isSmallScreenSize()) {
			$('.js-product-bottom-info').removeClass('option-is-open');
		}
	});

	if (util.isSmallScreenSize() && isVisible) {
		addToCartButtonContainer.css('min-height', addToCartButtonContainer.children().height());
	}

	//trigger the scroll
	$(window).trigger('scroll');//ensure if you're in current position when page is refreshed
}

exports.initStickyBar = initStickyBar;
exports.updateStickyBarProductInfo = updateStickyBarProductInfo;
exports.updateSelectedSizeTypeForStickyBar = updateSelectedSizeTypeForStickyBar;

/**
 * Initializes the size selectors on sticky bar.
 */
function initStickyBar() {
	// initialize name
	var stickyBar = $('[data-js="js-pdp-sticky-bar"]');
	stickyBar.find('[data-js="sticky-name"]').text($('.js-product-name:visible').text());

	// initialize price
	var priceRange = $('[data-js="min-max-pricing"]');
	if (priceRange.length) {
		stickyBar.find('[data-js="sticky-price"]').html(priceRange.html());
	}
	else {
		stickyBar.find('[data-js="sticky-price"]').text($('[data-js="pricevalue"]:visible').text());

		var standardPrice = $('[data-js="standard-price"]:visible');
		if (standardPrice.length) {
			var tempPriceElement = standardPrice.clone();
			tempPriceElement.children().remove();
			stickyBar.find('[data-js="sticky-standard-price"]').text(tempPriceElement.text());
			stickyBar.find('[data-js="sticky-standard-price"]').removeClass('u-hidden');
			stickyBar.find('[data-js="sticky-price"]').addClass('with-standard-price');
		}
	}

	// initialize image
	var imageElement = $('[data-stickybarimage]');
	if (imageElement.length) {
		var imageURL = imageElement.attr('data-stickybarimage');
		stickyBar.find('[data-js="sticky-image"] img').attr('src', imageURL);
	}

	stickyBar.find('[data-js="sticky-error"]').addClass('u-hidden');

	var stickySizeSelector = $('#sticky-size-selector');
	var stickyAltSizeSelector = $('#sticky-alt-size-selector');

	// init size selectors
	initStickyBarSizeSelector(stickySizeSelector, '#size-wrapper');
	var selectedSizeElement = initStickyBarSizeSelector(stickyAltSizeSelector, '#size-alt-wrapper');

	updateSelectedSizeTypeForStickyBar();

	// on size changed
	onStickyBarSizeChanged(stickySizeSelector);
	onStickyBarSizeChanged(stickyAltSizeSelector);

	// update name and price with the values from currently selected size if there is
	if (selectedSizeElement) {
		var productInfo = selectedSizeElement.data('productinfo');
		updateStickyBarProductInfo(productInfo);
	}

	// make the actual button clicked
	$('[data-js="sticky-add-to-cart"]').off('click').on('click', function() {
		$('.js-add-to-cart').trigger('click');
	});

	// scroll to top action
	$('[data-js="scroll-top-button"]').off('click').on('click', function () {
		$('html, body').animate({ scrollTop: 0 }, '2000');
	});
}

/**
 * Updates the product name and price on the stick bar.
 */
function updateStickyBarProductInfo(productInfo, makeSizeSelected) {
	if (!productInfo) {
		return;
	}

	var stickyBar = $('[data-js="js-pdp-sticky-bar"]');

	if (!stickyBar.length) {
		return;
	}

	if (makeSizeSelected) {
		stickyBar.find('#sticky-size-selector').val(productInfo.value);
		stickyBar.find('#sticky-alt-size-selector').val(productInfo.value);
	}

	stickyBar.find('[data-js="sticky-name"]').text(productInfo.name);

	stickyBar.find('[data-js="sticky-standard-price"]').text('');
	stickyBar.find('[data-js="sticky-standard-price"]').addClass('u-hidden');
	stickyBar.find('[data-js="sticky-price"]').removeClass('with-standard-price');

	if (productInfo.priceData) {
		stickyBar.find('[data-js="sticky-price"]').text(productInfo.priceData.salesPrice);

		if (productInfo.priceData.showStandardPrice) {
			stickyBar.find('[data-js="sticky-standard-price"]').text(productInfo.priceData.standardPrice);
			stickyBar.find('[data-js="sticky-standard-price"]').removeClass('u-hidden');
			stickyBar.find('[data-js="sticky-price"]').addClass('with-standard-price');
		}
	}
}

/**
 * Initializes the size selector on sticky bar
 */
function initStickyBarSizeSelector(sizeSelector, sizeWrapperClass) {
	sizeSelector.empty();

	if (!$(sizeWrapperClass).length) {
		sizeSelector.remove();
	}

	var selectedValue = '';
	var selectedSizeElement;

	sizeSelector.append($("<option></option>").val('').text(Resources.SELECT_SIZE).attr('disabled', 'disabled').attr('selected', 'selected')); // Add select label

	// loop all sizes and create an option for each
	$.each($(sizeWrapperClass).find('a.swatchanchor'), function(i, element) {
		var swatchWrapper = $(element).closest('li');
		var isSelected = swatchWrapper.hasClass('selected');

		if (isSelected) {
			selectedValue = $(element).data('value');
			selectedSizeElement = $(element);
		}

		var isSelectable = swatchWrapper.hasClass('selectable');

		sizeSelector.append(`<option value="${$(element).data('value')}" ${isSelectable ? "" : " class='unavailable-option' disabled='disabled'"}>${$(element).text()}</option>`);
	});

	if (selectedValue) {
		sizeSelector.val(selectedValue);
	}

	return selectedSizeElement;
}

/**
 * Binds the event to size selectors.
 */
function onStickyBarSizeChanged(sizeSelector) {
	var sizeActiveTag = sizeSelector.attr('data-size-tag');

	sizeSelector.off('change').on('change', function (e) {
		// make the actual size swatch anchor clicked
		$('[data-js="' + sizeActiveTag + '-' + $(this).val() + '"]').trigger('click');
	});
}

/**
 * Shows or hide the size selector according to the active size type
 */
function updateSelectedSizeTypeForStickyBar() {
	// get active size tag
	var activeSizeTag = $(document.body).data('sizetype') !== undefined
		? $(document.body).data('sizetype')
		: $('#defaultsizeselector').val();

	// make the currently shown size tag visible
	$('.js-stick-size-selector').addClass('u-hidden');
	$('.js-stick-size-selector.' + activeSizeTag).removeClass('u-hidden');
}
