'use strict';

var ajax = require('../ajax'),
	progress = require('../progress');

function splashPageValidation() {
	var $formWrapper = $('[data-js="js-splashpage-form"]');
	var $errorMsg = $('[data-js="js-splashpage-error-msg"]');
	var accessCodeField = $formWrapper.find('.input-text');

	if ($formWrapper.length) {
		accessCodeField.on('focus', function() {
			$formWrapper.removeClass('splashpage-error');
			$errorMsg.addClass('u-hidden');
		});
	}
}

function initSignupEvents() {
	var $signupForm = $('.js-outlet-signup-form'),
		$emailInputField = $signupForm.find('#dwfrm_splashsignup_email');

	// to post the splashpage signup form
	$signupForm.on('submit', function(e) {
		e.preventDefault();

		if (!($emailInputField.val()) && $emailInputField.hasClass('ignore-validation')) {
			$emailInputField.removeClass('ignore-validation');
			$emailInputField.addClass('required');
		}

		if (!$signupForm.valid()) {
			return;
		}

		//Here we check if checkbox is rendered and if checkbox is checked
		if (($('#checkbox-splashpage-signup').length) && !($('#checkbox-splashpage-signup').prop('checked'))) {
			return;
		}

		progress.show();

		var url = $signupForm[0].action;

		var dataArray = $signupForm.serializeArray();
		var data = {};
		dataArray.forEach(function(element) {
			data[element.name] = element.value;
		});

		var $btn = $signupForm.find('.js-submit-splashpage-signup');
		var attrname = $btn.attr('name');
		data[attrname] = attrname;

		ajax.load({
			requestType: 'POST',
			data: data,
			url: url,
			callback: function (response) {
				progress.hide();

				if (response) {
					var respJson = JSON.parse(response);
					if (respJson.success) {
						$signupForm.html('<div>' + Resources.SPLASHPAGE_SIGNUP_SUCCESS + '</div>');

						//trigger tracking event
						$(document).trigger('newsletterSubscribe', [data.dwfrm_newsletter_email]);
					}
					else {
						$signupForm.append('<div>' + Resources.SPLASHPAGE_SIGNUP_FAILURE + '</div>');
					}
				}
				else {
					$signupForm.append('<div>' + Resources.SPLASHPAGE_SIGNUP_FAILURE + '</div>');
				}
			}
		});
	});
}

function toggleBetweenAccessAndSignup() {
	$('body').on('click', '.js-toggle-splashpage-access-block', function() {
		var $this = $(this);
		var parentWrapperClass = '.access-box-wrapper';

		// If visible do nothing
		if ($this.closest(parentWrapperClass).hasClass('open')) {
			return;
		}

		// Add focus possible to hidden button
		$('.js-toggle-splashpage-access-block').attr({
			'aria-disabled': 'false',
			'tabindex': '0'
		});

		// Remove focus to visible button
		$this.attr({
			'aria-disabled': 'true',
			'tabindex': '-1'
		});

		// Show current block
		$(parentWrapperClass).toggleClass('open');
		$('.access-box-inner').slideToggle(500);
		// If we are on access page hide the h1+button that we click on to switch to access page
		// And show the h1+button that we click on to switch to signup page (opossite logic for signup page)
		$('.js-toggle-splashpage-access-block').toggle();
	});
}

function initCountdownTimer() {
	if (window.SitePreferences.OUTLET_OPENING_TIME && $('[data-js="countdown"]').length > 0) {
		var countDownDate = new Date(window.SitePreferences.OUTLET_OPENING_TIME).getTime();
		var $hoursEl = $('[data-js="hours"]');
		var $minutesEl = $('[data-js="minutes"]');
		var $secondsEl = $('[data-js="seconds"]');

		// Update the count down every 1 second
		var x = setInterval(function() {
			// Get today's date and time
			var now = new Date().getTime();

			// Find the distance between now and the count down date
			var distance = countDownDate - now;

			// Time calculations for days, hours, minutes and seconds
			var hours = Math.floor(distance / (1000 * 60 * 60));
			var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			var seconds = Math.floor((distance % (1000 * 60)) / 1000);

			if (hours < 10) {
				hours = '0' + hours;
			}
			if (minutes < 10) {
				minutes = '0' + minutes;
			}
			if (seconds < 10) {
				seconds = '0' + seconds;
			}
			$hoursEl.html(hours);
			$minutesEl.html(minutes);
			$secondsEl.html(seconds);

			// If the count down is finished, write some text
			if (distance < 0) {
				clearInterval(x);
				$hoursEl.html('00');
				$minutesEl.html('00');
				$secondsEl.html('00');
			}
		}, 1000);
	}
}

// when wrong access code is entered, if the user clicks on sign up now in the error message
// the signup form should be displayed
function openSignupOnWrongAccessCode() {
	$('#splashpage-access-error-open-signup').on('click', function() {
		$('.js-toggle-splashpage-access-block').trigger('click');
	});
}

exports.init = function () {
	splashPageValidation();
	initSignupEvents();
	toggleBetweenAccessAndSignup();
	initCountdownTimer();
	openSignupOnWrongAccessCode();
};