'use strict';

var progress = require('../progress'),
	validator = require('../validator'),
	ajax = require('../ajax'),
	dialog = require('../dialog'),
	util = require('../util');

var accessibility = (util.isMobile()) ? require('../accessibilityForMobile') : require('../accessibility');

function initializeEvents() {
	var $giftcardbalanceForm = $('.js-giftcardbalance-form');
	var $checkBalance = $giftcardbalanceForm.find('.js-checkbalance');

	$('.js-card-numbers').on('keyup blur change', function(e) {
		if ($giftcardbalanceForm.validate().checkForm()) {
			$checkBalance.removeAttr('disabled');
		}
		else {
			$checkBalance.attr('disabled', 'disabled');
		}
	});

	$($checkBalance).on('click', function(e) {
		e.preventDefault();
		if (!$giftcardbalanceForm.valid()) {
			return;
		}
		progress.show();

		$.ajax({
			type: 'GET',
			url: util.appendParamsToUrl(Urls.giftcardCheckBalance, {
				'cardNumbers': $('.js-card-numbers').val(),
				'reCaptchaResponse': $('#reCaptchaResponse').val()
			}),
			success: function(response) {
				dialog.open({
					html: response,
					options: {
						dialogClass: 'giftcard-dialog'
					}
				});

				//Here we attach giftcard dialog events
				accessibility.attachGiftCardDialogEvents();

				$giftcardbalanceForm[0].reset();
				$giftcardbalanceForm.find('.is-valid').removeClass('is-valid');
				if (typeof grecaptcha !== 'undefined') {
					grecaptcha.reset();
				}
				$checkBalance.attr('disabled', 'disabled');

				progress.hide();
			},

			failure: function() {
				dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
				if (typeof grecaptcha !== 'undefined') {
					grecaptcha.reset();
				}
				progress.hide();
			},

			statusCode: {
				410: function() {
					$('.js-captcha-error-msg').text(Resources.CAPTCHA_ERROR).show();
					$('.js-captcha-error-msg').show();

					if (typeof grecaptcha !== 'undefined') {
						grecaptcha.reset();
					}
					progress.hide();
				}
			}
		});
	});
}

exports.init = function () {
	initializeEvents();
};