'use strict';

var _ = require('lodash'),
	compareWidget = require('../compare-widget'),
	productTile = require('../product-tile'),
	progress = require('../progress'),
	sizeSelector = require('../sizeselector'),
	util = require('../util'),
	plpFilter = require('../plpFilter'),
	plpSwitchMobileView = require('../plpSwitchMobileView'),
	scrollToTop = require('../scrollToTop'),
	constants = require('../constants');

var accessibility = (util.isMobile()) ? require('../accessibilityForMobile') : require('../accessibility');

/**
 * Handle infinite scroll loading of products on PLP by using placeholder container
 */
function infiniteScroll() {
	// getting the hidden div, which is the placeholder for the next page
	var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');

	if (loadingPlaceHolder.length !== 1 || !util.elementInViewport(loadingPlaceHolder.get(0), 250)) {
		return;
	}

	// update state of the loading placeholder and show custom loader
	updateLoadingElements(loadingPlaceHolder);

	// get url hidden in DOM
	var gridUrl = loadingPlaceHolder.attr('data-grid-url');

	retrieveNextChunkAndUpdatePage(loadingPlaceHolder, gridUrl, function() {
		accessibility.attachPLPEvents();
	});
}

/**
 * Handle loading of previous chunks on PLP after return from PDP
 */
function infiniteScrollLoadPrevious() {
	var topOffset = 250;
	var plpBanner = $('.plp-banner:visible');
	if (plpBanner.length === 1) {
		topOffset += plpBanner.height();
	}

	// we make sure that user approaches the top of the screen before we load the previous chunk
	if (window.pageYOffset > topOffset || window.pageXOffset > 250) {
		return;
	}

	// getting the hidden div, which is the placeholder for the next page
	var loadingPlaceHolder = $('.infinite-scroll-placeholder-previous[data-loading-state="unloaded"]');

	if (loadingPlaceHolder.length !== 1) {
		return;
	}

	// update state of the loading placeholder and show custom loader
	loadingPlaceHolder.attr('data-loading-state', 'loading');
	$('[data-js="pagination-loader-previous"]').parent().addClass('visible');

	// get url hidden in DOM
	var gridUrl = loadingPlaceHolder.attr('data-grid-url');

	retrievePreviousChunkAndUpdatePage(loadingPlaceHolder, gridUrl, function() {
		accessibility.attachPLPEvents();
	});
}

/**
 * Handle Show more button events which trigger infinite scroll mode on mobile and desktop.
 */
function initializeShowMore() {
	var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="not-ready"]');
	var isCombinedPagination = SitePreferences.LISTING_PAGINATION_STYLE === 'combined';
	// do not remove 'Show more' button in case of combined pagination
	if (loadingPlaceHolder.length !== 1 && !isCombinedPagination) {
		$('[data-js="show-more-wrapper"]').remove();
	}

	$('#showMoreProducts').on('click', function(e) {
		e.preventDefault();
		$('[data-js="show-more-wrapper"]').remove();

		// update state of the loading placeholder and show custom loader
		updateLoadingElements(loadingPlaceHolder);

		var gridUrl;
		if (isCombinedPagination) {
			gridUrl = $('.page-next').attr('href');
			$('div.infinite-scroll-placeholder').remove();
		}
		else {
			gridUrl = $(this).data('href');
		}

		retrieveNextChunkAndUpdatePage(loadingPlaceHolder, gridUrl, function() {
			$('.js-search-result-articles').each(function (index, chunk) {
				if (gridUrl.indexOf($(chunk).data('current-grid-chunk')) !== -1) {
					$(chunk).children().first().trigger('focus');
					accessibility.attachPLPEvents();
				}
			});
		});
	});
}

/**
 * Calculations for the infinite scroll pagination progress bar and label
 */
function updatePaginationProgress(currentTotalCount) {
	// getting the hidden div, which is the placeholder for the next page
	var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');

	// if not found try to find the starting placeholder which is used for the Show more trigger
	if (loadingPlaceHolder.length !== 1) {
		loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="not-ready"]');
	}

	// if loading already started we want to select that placeholder
	if (loadingPlaceHolder.length !== 1) {
		loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="loading"]');
	}

	var showingCount,
		totalCount;

	if (loadingPlaceHolder.length !== 1) {
		// if there is no placeholder to load next chunk that means we just loaded the last chunk
		// that's why we set both values to total count
		showingCount = currentTotalCount;
		totalCount = currentTotalCount;
	}
	else {
		showingCount = loadingPlaceHolder.data('showing-count');
		totalCount = loadingPlaceHolder.data('total-count');
	}

	if (showingCount !== undefined && totalCount !== undefined) {
		// update the label "Showing 20 out of xx"
		$('[data-js="pagination-progress-counter"]').text(function () {
			return Resources.SHOWING_PROGRESS_LABEL.replace('[showing]', showingCount).replace('[total]', totalCount);
		});
	}
}

/**
 * Handle state of placeholder element and show loader while retrieving new chunk of products on PLP
 */
function updateLoadingElements(loadingPlaceHolder) {
	loadingPlaceHolder.attr('data-loading-state', 'loading');
	$('[data-js="pagination-loader"]').addClass('visible');
}

/**
 * Retrieving new chunk of products on PLP in infinite scroll mode
 */
function retrieveNextChunkAndUpdatePage(loadingPlaceHolder, url, callback) {
	var currentTotalCount = loadingPlaceHolder.data('total-count');

	$.ajax({
		type: 'GET',
		dataType: 'html',
		url: url,
		success: function (response) {
			util.updateBrowserURL(util.removeParamFromURL(url, 'format'));
			$('[data-js="pagination-loader"]').removeClass('visible');

			// update place holder element
			loadingPlaceHolder.attr('data-loading-state', 'loaded');
			// highlight keywords in case of content search
			response = highlightKeyword(response);
			$('#search-result-items').append($(response).find('#search-result-items').html());
			$('div.products-list-block').append($(response).find('.infinite-scroll-placeholder[data-loading-state="unloaded"]'));
			//get page counter from response in case of combined pagination and re-init 'Show more' button in order tp get new data url
			if (SitePreferences.LISTING_PAGINATION_STYLE === 'combined') {
				$('div.products-list-options-block > .c-pagination').replaceWith($(response).find('div.products-list-options-block > .c-pagination'));
				$('[data-js="pagination-block"]').replaceWith($(response).find('[data-js="pagination-block"]'));
				initializeShowMore();
			}
			updatePaginationProgress(currentTotalCount);
			productTile.init();

			//Update product listing with the new added products tile only
			var addedProductsTile = $(response).find('.js-product-tile');
			$(document).trigger('updateProductListing', [ addedProductsTile ]);

			callback();
		}
	});
}

/**
 * Retrieving previous chunk of products on PLP in infinite scroll mode
 */
function retrievePreviousChunkAndUpdatePage(loadingPlaceHolder, url, callback) {
	$.ajax({
		type: 'GET',
		dataType: 'html',
		url: url,
		success: function (response) {
			// get current scroll position of the page
			var firstChunk = $('div.products-list-block').find('.js-search-result-articles').first();
			var curOffset = firstChunk.offset().top - $(document).scrollTop();

			$('[data-js="pagination-loader-previous"]').parent().removeClass('visible');

			// update place holder element
			loadingPlaceHolder.attr('data-loading-state', 'loaded');
			// highlight keywords in case of content search
			response = highlightKeyword(response);
			$('div.products-list-block').prepend($(response).find('.infinite-scroll-placeholder-previous[data-loading-state="unloaded"]'));
			$('#search-result-items').prepend($(response).find('#search-result-items').html());

			// restore the scroll position of the page
			$(document).scrollTop(firstChunk.offset().top - curOffset);

			productTile.init();

			//Update product listing with the new added products tile only
			var addedProductsTile = $(response).find('.js-product-tile');
			$(document).trigger('updateProductListing', [ addedProductsTile ]);

			callback();
		}
	});
}

/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing(url, forceUpdate, theElement) {
	if (!url || (!forceUpdate && url === window.location.href)) {
		return;
	}
	progress.show();

	$('#main').load(util.appendParamToURL(url, 'format', 'ajax'), function () {
		compareWidget.init();
		productTile.init();
		sizeSelector.init();
		accessibility.attachPLPEvents();
		plpFilter.init();

		initializeContentEvents();
		showExpandAndCollapse();
		if (SitePreferences.LISTING_INFINITE_SCROLL) {
			initializeShowMore();
			// initialize progress bar
			updatePaginationProgress();
			scrollToTop.init();
		}

		progress.hide();
		if (!forceUpdate) {
			window.history.pushState(undefined, '', url);
		}
		$('html, body').animate({ scrollTop: 0 }, 500);

		$(document).trigger('updateProductListing');

		if (!theElement) {
			return;
		}

		var elementDataJS = theElement.data('js');
		if (elementDataJS === 'filter-item') {
			var filterName = theElement.attr('data-filter-name');
			var filterValue = theElement.attr('data-filter-value');

			$(document).trigger('plpFilteredOrSorted', [filterName, filterValue]);
		}
		else if (elementDataJS === 'sorting-item') {
			var sortingOption = theElement.attr('data-sorting-value');

			$(document).trigger('plpFilteredOrSorted', ['sorting', sortingOption]);
		}

		var flyoutParent = theElement.parents('[data-js="plp-refine-flyout"]');
		var isMobile = flyoutParent.length && flyoutParent.hasClass('mobile-view');
		if (isMobile) {
			plpFilter.updateMobileFlyout(theElement);
		}
		else {
			if (accessibility.isKeyBoardNavigationActive()) {
				accessibility.setFocusOnFirstProduct();
			}
		}
		// reinitalize so that pagination flow is handled
		plpSwitchMobileView.init();
	});
}

$(window).on('popstate', function () {
	window.location.reload();
});

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
	var $main = $('#main');
	// compare checked
	$main.on('click', 'input[type="checkbox"].compare-check', function () {
		var cb = $(this);
		var tile = cb.closest('.product-tile');

		var func = this.checked ? compareWidget.addProduct : compareWidget.removeProduct;
		var itemImg = tile.find('.product-image a img').first();
		func({
			itemid: tile.data('itemid'),
			uuid: tile[0].id,
			img: itemImg,
			cb: cb
		});
	});

	// handle toggle refinement blocks
	$main.on('click', '.refinement h3', function () {
		$(this).toggleClass('expanded')
			.siblings('ul').toggle();
	});

	// handle events for updating grid
	$main.on('click', '.refinements a, .pagination a, [data-js="filters"] a', function (e) {
		// don't intercept for category and folder refinements, as well as unselectable
		if ($(this).parents('.category-refinement').length > 0
			|| $(this).parents('.folder-refinement').length > 0
			|| $(this).parents('.size-selector').length > 0
			|| $(this).parent().hasClass('unselectable')) {
			return;
		}
		e.preventDefault();

		updateProductListing(this.href, false, $(this));
	});

	// handle events item click. append params.
	$main.on('click', '.js-product-tile a:not("#quickviewbutton")', function () {
		var a = $(this);
		// get current page refinement values
		var wl = window.location;

		var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
		var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

		// merge hash params with querystring params
		var params = $.extend(hashParams, qsParams);
		if (!params.start) {
			params.start = 0;
		}
		// get the index of the selected item and save as start parameter
		var tile = a.closest('.product-tile');
		var idx = tile.data('idx') ? + tile.data('idx') : 0;

		// convert params.start to integer and add index
		params.start = (+params.start) + (idx + 1);
		// set the hash and allow normal action to continue
		a[0].hash = $.param(params);
	});

	// handle sorting change
	$main.on('click', '[data-js="product-sort-categories"] a', function (e) {
		e.preventDefault();
		updateProductListing($(this).attr('href'), false, $(this));
	}).on('change', '.items-per-page select', function () {
		var refineUrl = $(this).find('option:selected').val();
		if (refineUrl === 'INFINITE_SCROLL') {
			$('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
		}
		else {
			$('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
			updateProductListing(refineUrl);
		}
	});

	plpFilter.init();
	initializeContentEvents();
	showExpandAndCollapse();
	if (SitePreferences.LISTING_INFINITE_SCROLL) {
		initializeShowMore();
		// initialize progress bar
		updatePaginationProgress();
	}

	$(window).on('resize', function() {
		plpFilter.resize();
	});
}

function initializeContentEvents() {
	$('[data-js="plp-bottom-desc-expand"]').off('click').on('click', function() {
		if ($('[data-js="plp-bottom-desc"]').hasClass('show-less')) {
			$('[data-js="plp-bottom-desc"]').removeClass('show-less').addClass('show-more');
			$(this).text(Resources.READ_LESS);
		}
		else {
			$('[data-js="plp-bottom-desc"]').removeClass('show-more').addClass('show-less');
			$(this).text(Resources.READ_MORE);
		}
	});
}

/**
 * Set page offset from top after customer returns from PDP to PLP/search page (focuses the proper product)
 */
function setPageScroll() {
	var isSearchPage = $('.pt_product-search-result').length > 0;

	// check if we are on plp/search page,
	// if we previousle saved the product ID into state object of the browser history
	// and if we returned back to plp/search by using BACK button of browser (check navigation type documentation)
	if (isSearchPage
		&& history.state
		&& history.state.productId
		&& window.performance
		&& window.performance.navigation.type === window.performance.navigation.TYPE_BACK_FORWARD
	) {
		// take over scroll restoration from browser by setting this value from 'auto' to 'manual'
		if (history.scrollRestoration) {
			history.scrollRestoration = 'manual';
		}

		// get the offset of clicked product based on ID we stored in history
		var scrollOffset = $('[data-variantgroup-id=' + history.state.productId + ']').offset().top;
		// scroll to the product
		$('html, body').scrollTop(scrollOffset - 40);
	}

	// invoke product loading on scroll events
	// do not execute infiniteScroll in case of combined pagination
	if (SitePreferences.LISTING_PAGINATION_STYLE !== 'combined') {
		$(window).on('scroll', infiniteScroll);
		$(window).on('scroll', infiniteScrollLoadPrevious);
	}
}

/**
 *  Highlight keywords in case of content search. Changes HTML on current document or on the subbmitted page
 * 	@param page Optional HTML document to be changed
 */
function highlightKeyword(page) {
	var $page = $('body');
	if (page) {
		$page = $(page);
	}
	if ($page.find('.js-search-result-articles').data('search-phrase')) {
		var searchPhrase = $page.find('.js-search-result-articles').data('search-phrase');
		var searchPhraseRegExp = new RegExp(`\\b${searchPhrase}`, 'ig');
		var results = $page.find('.js-search-result-articles').find('li');
		$.each(results, function(i, element) {
			var description = $(element).find('.article-list__description');
			if (description.length > 0) {
				var descriptionHTML = $(description).html();
				descriptionHTML = descriptionHTML.replace(searchPhraseRegExp, function(match) {
					return '<span class="highlighted">' + match + '</span>';
				});
				$(description).html(descriptionHTML);
			}
		});
		if (page) {
			return $page;
		}
	}
	else {
		if (page) {
			return page;
		}
	}
}

/**
 *  show expand/collapse only if the content was collapsed
 */
function showExpandAndCollapse() {
	$('[data-js="plp-bottom-desc"]').children('p').each(function() {
		var fullTextCount = $(this)[0].scrollHeight;
		var renderedTextCount = $(this)[0].clientHeight;

		if (fullTextCount > renderedTextCount) {
			$(this).siblings('.expand-desc__wrapper').css('display', 'block');
		}
	});
}

exports.init = function () {
	compareWidget.init();
	productTile.init();
	sizeSelector.init();
	initializeEvents();
	highlightKeyword();
	if (SitePreferences.LISTING_INFINITE_SCROLL) {
		setPageScroll();
	}
};
