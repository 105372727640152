var submitForm = require('./submitForm'),
	util = require('../../util'),
	flags = require('../../flags');


function initializeEvents() {
	$('body').off('change.designateddelivery').on('change.designateddelivery',
		'.js-designateddelivery-detail-container .js-state', function() {
			submitForm.submitAjaxForm({ action: 'updateShippingAddress', isStateChanged: true });
			flags.setFlag('shippingAddressUpdateTriggered', false);
			flags.setFlag('shippingAddressStateChanged', false);
		});

	$('body').off('focus.designateddelivery', '.js-designateddelivery-detail-container .js-state')
		.on('focus.designateddelivery', '.js-designateddelivery-detail-container .js-state', function() {
			flags.setFlag('preventShippingAddressUpdate', true);
		});

	$('body').off('blur.designateddelivery', '.js-designateddelivery-detail-container .js-state')
		.on('blur.designateddelivery', '.js-designateddelivery-detail-container .js-state', function() {
			flags.setFlag('preventShippingAddressUpdate', false);

			var $shippingForm = $('.js-shipping-form');
			var shippingAddressUpdateTriggered = flags.getFlag('shippingAddressUpdateTriggered');

			//after shipping address is changed if user clicks on state field we prevent ajax call so they can't change it during ajax call
			//if they don't change the state we want to update shipping address so previous change can be aplied
			if (shippingAddressUpdateTriggered) {
				$shippingForm.find('input').trigger('change');
			}
		});

	//save data for designated delivery to cart object on each change of data on checkout page
	$('body').off('change', '.js-designateddelivery-detail-container .js-designated-delivery-time')
		.on('change', '.js-designateddelivery-detail-container .js-designated-delivery-time', function() {
			saveDesignatedToCartObj();
		});

	//validate designated delivery block
	$('body').off('change', '.js-designated-delivery-group')
		.on('change', '.js-designated-delivery-group', function() {
			$('.js-designated-delivery').valid();
			$(this).valid();
		});

	// this causes double click, so we prevent it
	$('[data-js="designated-delivery-wrap"]').find('label').off('click').on('click', function(e) {
		e.preventDefault();
		e.stopImmediatePropagation();
		e.stopPropagation();
	});
}


/**
 * Ajax call to save designated delivery on the BE
 */
function saveDesignatedToCartObj() {
	submitForm.submitAjaxForm({ action: 'saveDesignatedDelivery' });
}

exports.init = function() {
	initializeEvents();
	util.initializeDatePickers(null, saveDesignatedToCartObj);
};