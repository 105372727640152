'use strict';

var dialog = require('./dialog'),
	page = require('./page'),
	forms = require('./forms'),
	registerform = require('./registerform'),
	progress = require('./progress'),
	util = require('./util'),
	tooltip = require('./tooltip'),
	validator = require('./validator'),
	submitForm = require('./pages/checkout/submitForm'),
	ometriaTracker = require('./tracking/ometriaTracking');

/**
 * Events for Check Order popup
 */
function initCheckOrderStatusPopupEvents() {
	var $orderTrackForm = $('form[id$="_ordertrack"]');
	var $submit = $orderTrackForm.find('button[name$="_checkorder"]');

	$($submit).on('click', function (e) {
		if (!$orderTrackForm.valid()) {
			return;
		}

		e.preventDefault();
		progress.show();

		var data = $orderTrackForm.serialize();

		// add form action to data
		data += '&' + $submit.attr('name') + '=';

		// make sure the server knows this is an ajax request
		if (data.indexOf('ajax') === -1) {
			data += '&format=ajax';
		}

		if (data.indexOf('modal') === -1) {
			data += '&modal=true';
		}

		$.ajax({
			type: 'POST',
			url: $orderTrackForm.attr('action'),
			data: data,
			success: function (response) {
				if (typeof response === 'object'
					&& !response.success
					&& response.error === 'CSRF Token Mismatch') {
					page.redirect(Urls.csrffailed);
				}
				else if (typeof response === 'object' && response.redirecturl) {
					page.redirect(response.redirecturl);
				}
				else if (typeof response === 'string') {
					dialog.$container.html(response);
					initCheckOrderStatusPopupEvents();
					validator.init();
					forms.init();
					progress.hide();
				}
			},
			failure: function () {
				dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
				progress.hide();
			}
		});
	});
}

/**
 * Events for Login popup
 */
function initLoginPopupEvents(isCheckout, isCart) {
	var $loginForm = $('form[id$="_login"]');

	if ($loginForm && $loginForm.length > 0) {
		var $submit = $loginForm.find('button[name$="_login"]');

		$($submit).on('click', function (e) {
			if (!$loginForm.valid()) {
				return;
			}

			e.preventDefault();
			progress.show();

			var data = $loginForm.serialize();

			// add form action to data
			data += '&' + $submit.attr('name') + '=';

			// make sure the server knows this is an ajax request
			if (data.indexOf('ajax') === -1) {
				data += '&format=ajax';
			}

			if (data.indexOf('modal') === -1) {
				data += '&modal=true';
			}

			if (isCheckout) {
				if (data.indexOf('ischeckout') === -1) {
					data += '&ischeckout=true';
				}
			}

			if (isCart) {
				if (data.indexOf('iscart') === -1) {
					data += '&iscart=true';
				}
			}

			$.ajax({
				type: 'POST',
				url: $loginForm.attr('action'),
				data: data,
				success: function (response) {
					if (typeof response === 'object'
						&& !response.success
						&& response.error === 'CSRF Token Mismatch') {
						page.redirect(Urls.csrffailed);
					}
					else if (typeof response === 'string') {
						if (response.indexOf('js-checkout-block') > -1) {
							dialog.close();
							//Ometria identify user
							var ometriaUserContainer = $(response).find('#js-user-id');
							ometriaTracker.ometriaUserIdentify(ometriaUserContainer);
							//we populate all checkout fileds without refreshing the page
							submitForm.handleSubmitCallback(response, { action: 'setAllCheckoutFields' });
							progress.hide();

							//Fire checkoutOption 'logged-in' for step 2 and checkout events for step 3 and step 4 (if shipping method was populated)
							$('.js-checkout-contact').trigger('tracklogin');
							$('.js-checkout-method').trigger('trackCheckoutStep');
							if (response.indexOf('js-radio-container-payment') > -1) {
								$('.js-checkout-payment').trigger('trackCheckoutStep');
							}
						}
						else if (response.indexOf('js-cart-shopping-block') > -1
							|| response.indexOf('data-js="wishlist-list"') > -1
							|| response.indexOf('data-js="empty-wishlist"') > -1) {
							//Fire checkoutOption 'logged-in' for step 2
							$('.js-checkout-contact').trigger('tracklogin');

							//we redirect to same page if logged in from cart or wishlist page
							page.refresh();
						}
						else {
							dialog.$container.html(response);
							initLoginPopupEvents(isCheckout, isCart);
							progress.hide();
						}
					}
					else if (typeof response === 'object' && response.refreshpage) {
						var isOrderSubmitPage = $('#js-rawurl').val().indexOf('Order-Submit') != -1;
						if (isOrderSubmitPage) {
							window.location = window.Urls.MyAccount;
						} else {
							refreshPage();
						}
					}
				},
				failure: function () {
					dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
					progress.hide();
				}
			});
		});

		initPasswordResetLink(isCheckout);
		initPrivacyPolicyPopup($loginForm);
	}

	validator.init();
	forms.init();
	login.init();
}

function refreshPage() {
	// if current page is home page and reset password token query string param exists then remove it
	var url = window.location.href;
	url = util.removeParamFromURL(url, 'passwordtoken');
	window.location = url;
}

/**
 * Events for Registration popup
 */
function initRegistrationPopupEvents(isCheckout, isCart) {
	var $registration = $('[data-js="account-registration-modal"]');
	var $registrationForm = $registration.find('form');
	var $submit = $registrationForm.find('[data-js="create-account-modal"]');

	initPrivacyPolicyPopup($registrationForm);

	$.each($registrationForm.find('.form-caption'), function (index, value) {
		$(value).remove();
	});

	if (isCheckout) {
		//populate form with data for old checkout
		if ($registration.find('input.email').val() === '' && $('.js-contactdetails-fieldset').find('input.email').val()) {
			$registration.find('input.email').val($('.js-contactdetails-fieldset').find('input.email').val());
		}
		if ($registration.find('input.phone').val() === '' && $('.js-contactdetails-fieldset').find('input.phone').val()) {
			$registration.find('input.phone').val($('.js-contactdetails-fieldset').find('input.phone').val());
		}
	}

	registerform.init();

	$($submit).off('click').on('click', function (e) {
		e.preventDefault();

		if (!$registrationForm.valid()) {
			return;
		}

		progress.show();

		var data = $registrationForm.serialize();

		// add form action to data
		data += '&' + $submit.attr('name') + '=';

		// make sure the server knows this is an ajax request
		if (data.indexOf('ajax') === -1) {
			data += '&format=ajax';
		}

		if (data.indexOf('modal') === -1) {
			data += '&modal=true';
		}

		if (isCheckout) {
			if (data.indexOf('ischeckout') === -1) {
				data += '&ischeckout=true';
			}
		}

		if (isCart) {
			if (data.indexOf('iscart') === -1) {
				data += '&iscart=true';
			}
		}

		$.ajax({
			type: 'POST',
			url: $registrationForm.attr('action'),
			data: data,
			success: function (response) {
				if (typeof response === 'object'
					&& !response.success
					&& response.error === 'CSRF Token Mismatch') {
					page.redirect(Urls.csrffailed);
				}
				else if (typeof response === 'string') {
					if (response.indexOf('js-registration-success') > -1) {
						//Fire a GA Tracking event if subscribed in newsletter
						$(document).trigger('newsletterSubscribeCheckoutRegistration');

						//render registration success modal
						dialog.$container.html($(response).find('[data-js="js-registration-success"]'));
						//Ometria identify user
						var ometriaUserContainer = $(response).find('#js-user-id');
						ometriaTracker.ometriaUserIdentify(ometriaUserContainer);

						//we populate all checkout fileds without refreshing the page
						submitForm.handleSubmitCallback(response, { action: 'setAllCheckoutFields' });
						$('[data-js="js-registration-success"] > button.js-close').off('click').on('click', function () {
							dialog.close();
						});

						//Fire checkoutOption 'logged-in' for step 2 and checkout events for step 3
						$('.js-checkout-contact').trigger('tracklogin');
						$('.js-checkout-method').trigger('trackCheckoutStep');
					}
					else if (response.indexOf('data-js="js-registration-modal-success"') > -1) {
						dialog.$container.html(response);

						//Fire a GA Tracking event if subscribed in newsletter
						$(document).trigger('newsletterSubscribeCheckoutRegistration');

						//Ometria identify user
						var ometriaUserContainerElement = $(response).find('#js-user-id');
						ometriaTracker.ometriaUserIdentify(ometriaUserContainerElement);

						$('.js-checkout-contact').trigger('tracklogin');

						dialog.addCloseFunction(function() {
							page.refresh();
						});

						$('[data-js="js-registration-modal-success"] > button.js-close').off('click').on('click', function () {
							dialog.close();
						});
					}
					else {
						dialog.$container.html(response);
					}

					initLoginPopupEvents(isCheckout, isCart);
					initRegistrationPopupEvents(isCheckout, isCart);
					progress.hide();
				}
			},
			failure: function () {
				dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
				progress.hide();
			}
		});
	});
}

/**
 * Binds click event to the link to open the popup for header login popup
 */
function initHeaderLoginLink() {
	$('[data-js="login-link"]').off('click').on('click', function (e) {
		e.preventDefault();
		dialog.setMouseUpOnAnchor();

		var url = $(e.target).attr('data-href');

		// in case user clicks on the inner span then we get the url from the wrapping anchor
		if ($(e.target).hasClass('js-login-link')) {
			url = $(e.target).closest('[data-js="login-link"]').attr('data-href');
		}

		url = util.appendParamToURL(url, 'modal', true);

		dialog.open({
			url: util.ajaxUrl(url),
			options: {
				dialogClass: 'login-account',
				open: function () {
					$('html').addClass('dialog-is-open');
					initLoginPopupEvents(false);
					var openDialog = $(this);
					dialog.bindOverlayClose(openDialog);
					$('.dialog-content').removeAttr('tabindex');
					initRegistrationPopupEvents(false);
					dialog.setMouseUpOnAnchor();
				}
			},
			beforeLoad: function (content) {
				var accountWrapper = util.find(content, '[data-js="account-page"]');

				if (accountWrapper && accountWrapper.length > 0) {
					refreshPage();
					return false;
				}

				return true;
			}
		});
	});
}

/**
 * Binds click event to the link to open the popup for checkout/cart login/register popup
 */
function initCheckoutLoginRegisterLink() {
	$('.js-login-popup').off('click').on('click', function (e) {
		e.preventDefault();
		dialog.setMouseUpOnAnchor();

		var url = $(e.target).attr('href');
		url = util.appendParamToURL(url, 'modal', true);

		var isCheckout = $(this).hasClass('js-checkout-btn');
		var isCart = $(this).hasClass('js-cart-btn');

		if ($(this).hasClass('js-newpassword-confirm')) {
			dialog.removeCloseFunction();
		}

		dialog.open({
			url: util.ajaxUrl(url),
			options: {
				dialogClass: 'login-account'
			},
			callback: function () {
				$('html').addClass('dialog-is-open');
				initLoginPopupEvents(isCheckout, isCart);
				var openDialog = $(this);
				dialog.bindOverlayClose(openDialog);
				$('.dialog-content').removeAttr('tabindex');
				initRegistrationPopupEvents(isCheckout, isCart);
				dialog.setMouseUpOnAnchor();
				tooltip.initializeCustomTooltip();
			}
		});

		// save entered phone in order to keep it in case user has not one in account
		var $phoneInput = $('.js-contactdetails-fieldset').find('input.phone');

		if ($phoneInput && $phoneInput.length > 0) {
			var $phonePrefixInput = $('.js-contactdetails-fieldset').find('input.phoneprefix');
			var phoneInputVal = $phoneInput.val();
			var isPhoneInputValid = $phoneInput.closest('form').validate().check($phoneInput);

			var isPhonePrefixValid = $phonePrefixInput.length === 0 || ($phonePrefixInput.valid() && $phonePrefixInput.val() !== '');
			var isPhoneValid = $phoneInput.length === 0 || (isPhoneInputValid && phoneInputVal !== '');

			if (isPhonePrefixValid && isPhoneValid && isCheckout) {
				submitForm.submitAjaxForm({ action: 'updateShippingAddress' });
			}
		}
	});
}

/**
 * Binds click event to the link on login popup to open the popup for check order status popup
 */
function initCheckOrderStatusLink() {
	$('.js-check-order').off('click').on('click', function (e) {
		e.preventDefault();
		dialog.setMouseUpOnAnchor();

		var url = $(e.target).attr('href');
		url = util.appendParamToURL(url, 'modal', true);

		dialog.open({
			url: util.ajaxUrl(url),
			options: {
				dialogClass: 'login-account',
				open: function () {
					$('html').addClass('dialog-is-open');
					initCheckOrderStatusPopupEvents();
					validator.init();
					forms.init();
					var openDialog = $(this);
					dialog.bindOverlayClose(openDialog);
					$('.dialog-content').removeAttr('tabindex');
					dialog.setMouseUpOnAnchor();
				}
			}
		});
	});
}

function initPrivacyPolicyPopup(currentPopup) {
	currentPopup.find('.privacy-policy').on('click', function (e) {
		e.preventDefault();

		// we have to use a specific "target" for this dialog so it doesn't replace the existing one, also "closeAll" is set to false to keep the existing one opened
		dialog.open({
			url: $(e.target).attr('href'),
			target: 'privacy-popup-wrapper',
			closeAll: false,
			options: {
				dialogClass: 'service-dialog',
				open: function () {
					var openDialog = $(this);
					dialog.bindOverlayClose(openDialog);
				}
			}
		});

		return false;
	});
}

function initEvents() {
	// o-auth binding for which icon is clicked
	$('.oAuthIcon').on('click', function () {
		$('#OAuthProvider').val(this.id);
	});

	// toggle the value of the rememberme checkbox
	$('#dwfrm_login_rememberme').off('change').on('change', function () {
		if ($('#dwfrm_login_rememberme:checked').length > 0) {
			$('#rememberme').val('true');
		}
		else {
			$('#rememberme').val('false');
		}
	});

	$(document).off('bindloginlinkevent', '[data-email-check="account-registration"]')
		.on('bindloginlinkevent', '[data-email-check="account-registration"]', initCheckoutLoginRegisterLink);

	$('.js-login-popup, .js-password-reset').off('mousedown').on('mousedown', function (e) {
		dialog.setMouseDownOnAnchor();
	});

	$('.js-login-popup, .js-password-reset').off('mouseup').on('mouseup', function (e) {
		dialog.setMouseUpOnAnchor();
	});

	$('.js-account-registration-modal .privacy-policy').off('mousedown').on('mousedown', function (e) {
		dialog.setMouseDownOnAnchor();
	});

	$('.js-account-registration-modal .privacy-policy').off('mouseup').on('mouseup', function (e) {
		dialog.setMouseUpOnAnchor();
	});

	$('.js-check-order').off('mouseup').on('mouseup', function (e) {
		dialog.setMouseUpOnAnchor();
	});

	$('.privacy-policy').off('mouseup').on('mouseup', function (e) {
		dialog.setMouseUpOnAnchor();
	});

	// force uppercase value for order ids
	$('.js-order-number').on('change', function () {
		var $this = $(this);
		$this.val($this.val().toUpperCase());
	});
}

/**
 * Initializes the events on the dialog where user enters their email address to request password reset.
 */
function initPasswordResetPopupEvents() {
	var requestPasswordText = $('.js-forgot-password p');
	var $requestPasswordForm = $('[name$="_requestpassword"]');
	var $loginUserName = $('[name$="_login_username"]');

	if ($loginUserName.val()) {
		$requestPasswordForm.find('[name$="_requestpassword_email"]').val($loginUserName.val());
		requestPasswordText.hide();
	}
	else {
		requestPasswordText.show();
	}

	var $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');

	var isCheckout = false;
	var isCart = false;

	if ($submit.hasClass('js-checkout-btn')) {
		isCheckout = true;
	}

	if ($submit.hasClass('js-cart-btn')) {
		isCart = true;
	}

	if (!isCheckout && !isCart) {
		initCheckOrderStatusLink();
	}

	$($submit).on('click', function (event) {
		if (!$requestPasswordForm.valid()) {
			return;
		}
		event.preventDefault();

		var data = $requestPasswordForm.serialize();

		// add form action to data
		data += '&' + $submit.attr('name') + '=';

		// make sure the server knows this is an ajax request
		if (data.indexOf('ajax') === -1) {
			data += '&format=ajax';
		}

		if (isCheckout && data.indexOf('ischeckout') === -1) {
			data += '&ischeckout=true';
		}

		if (isCart && data.indexOf('iscart') === -1) {
			data += '&iscart=true';
		}

		$.ajax({
			type: 'POST',
			url: $requestPasswordForm.attr('action'),
			data: data,
			success: function (response) {
				if (typeof response === 'object'
					&& !response.success
					&& response.error === 'CSRF Token Mismatch') {
					page.redirect(Urls.csrffailed);
				}
				else if (typeof response === 'string') {
					dialog.$container.html(response);

					$('[data-js="back-to-home-btn"]').off('click').on('click', function () {
						dialog.close();
					});
				}
			},
			failure: function () {
				dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
			}
		});
	});
}

/**
 * This is to init the events for the link which opens password reset dialog.
 */
function initPasswordResetLink(isCheckout, isCart) {
	$('#password-reset').on('click', function (e) {
		e.preventDefault();

		var $this = $(this);

		var url = $(e.target).attr('href');
		url = util.appendParamToURL(url, 'format', 'ajax');
		url = util.appendParamToURL(url, 'modal', 'true');

		if (isCheckout) {
			url = util.appendParamToURL(url, 'ischeckout', isCheckout);
		}

		if (isCart) {
			url = util.appendParamToURL(url, 'iscart', isCart);
		}

		dialog.open({
			url: url,
			options: {
				dialogClass: 'password-reset',
				open: function () {
					var openDialog = $(this);
					dialog.bindOverlayClose(openDialog);

					validator.init();
					forms.init();

					initPasswordResetPopupEvents();
					initCheckoutLoginRegisterLink();

					//Toggle data-expanded
					$this.attr('aria-expanded', true);

					initEvents();
				},
				close: function () {
					//Toggle data-expanded
					$this.attr('aria-expanded', false);
				}
			}
		});
	});
}

/**
 * This is for the events on the dialog which is to enter new password.
 */
function initPasswordRenewPopupEvents() {
	var $renewPasswordForm = $('[name="NewPasswordForm"]');
	var $submit = $renewPasswordForm.find('[name$="_resetpassword_send"]');

	$($submit).on('click', function (event) {
		if (!$renewPasswordForm.valid()) {
			return;
		}
		event.preventDefault();

		var data = $renewPasswordForm.serialize();

		// add form action to data
		data += '&' + $submit.attr('name') + '=';

		// make sure the server knows this is an ajax request
		if (data.indexOf('ajax') === -1) {
			data += '&format=ajax';
		}

		$.ajax({
			type: 'POST',
			url: $renewPasswordForm.attr('action'),
			data: data,
			success: function (response) {
				if (typeof response === 'object'
					&& !response.success
					&& response.error === 'CSRF Token Mismatch') {
					page.redirect(Urls.csrffailed);
				}
				else if (typeof response === 'string') {
					dialog.$container.html(response);

					// if new password confirm dialog
					if (util.find(response, '[data-js="request-password-block"]').length > 0) {
						$('[data-js="request-password-block"] a.js-close').off('click').on('click', function () {
							dialog.close();
						});

						initCheckoutLoginRegisterLink();

						dialog.addCloseFunction(function () {
							refreshPage();
						});
					}
					else {
						// this is set new password with error dialog
						initPasswordRenewPopupEvents();
						initEvents();
					}
				}
			},
			failure: function () {
				dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
			}
		});
	});
}

/**
 * This is the dialog where user enters new passwords.
 */
function initPasswordRenewDialog(url) {
	if (url) {
		url = util.appendParamToURL(url, 'format', 'ajax');
		url = util.appendParamToURL(url, 'modal', 'true');

		dialog.open({
			url: url,
			options: {
				dialogClass: 'password-reset',
				open: function () {
					var openDialog = $(this);
					dialog.bindOverlayClose(openDialog);

					var passwordResetRequestWrapper = openDialog.find('.js-forgot-password');

					// if this is password reset request dialog (in case the token expired then we get this dialog)
					if (passwordResetRequestWrapper && passwordResetRequestWrapper.length > 0) {
						initPasswordResetPopupEvents();
						initCheckoutLoginRegisterLink();
						initEvents();
					}
					else {
						initPasswordRenewPopupEvents();
						initEvents();
					}

					validator.init();
					forms.init();
				}
			}
		});
	}
}

var login = {
	/**
	 * @private
	 * @function
	 * @description init events for the login, register
	 */
	init: function () {
		forms.init();
		initPasswordResetLink();
		initHeaderLoginLink();
		initCheckOrderStatusLink();
		initCheckoutLoginRegisterLink();

		// keep this always at the end
		initEvents();
	},
	initPasswordRenewDialog: function (url) {
		initPasswordRenewDialog(url);
	}
};

module.exports = login;
